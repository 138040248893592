import type { GetLinkedTransactionsReturn } from "../api/types";
import { COMBOBOX_VALUES_STOP_PROPAGATION } from "../constants/constants";
import { type PropagationFormValues } from "../types";

import { isPurchaseOrderLink } from "./is-purchase-order-link";

const isInvoiceOrInvoiceLine = (type: string) =>
  ["invoice", "invoice_line"].some((item) => type.includes(item));

const isPurchaseOrderOrPurchaseOrderLine = (
  type: string,
  flatLinks?: GetLinkedTransactionsReturn[number]["links"]
) => {
  const [linkType, linkId] = type.split("-");
  const foundLink = flatLinks?.find(
    (link) => link.type === linkType && link.id === linkId
  );
  return isPurchaseOrderLink(foundLink);
};

type GetFieldsToSkip = {
  values: PropagationFormValues;
  stepName?: string;
  sourceUrl?: string | null;
  linkedTransactions?: GetLinkedTransactionsReturn;
};

export const getFieldsToSkip = ({
  values,
  stepName,
  sourceUrl,
  linkedTransactions,
}: GetFieldsToSkip) => {
  const isPurchaseOrder = sourceUrl?.includes("purchaseorders");
  const isInvoice = sourceUrl?.includes("invoices");

  if (!isPurchaseOrder && !isInvoice) return [];

  const flatLinks = linkedTransactions?.flatMap(
    (transaction) => transaction.links
  );
  const fields: string[] = [];

  for (const [parentKey, links] of Object.entries(values)) {
    const otherValuesList: (typeof links)[string][] = [];
    const deepLinkedValuesList: typeof links = {};

    for (const [type, values] of Object.entries(links)) {
      const isDeepLinked = isPurchaseOrder
        ? isInvoiceOrInvoiceLine(type)
        : isPurchaseOrderOrPurchaseOrderLine(type, flatLinks);

      if (isDeepLinked) {
        deepLinkedValuesList[type] = values;
      } else {
        otherValuesList.push(values);
      }
    }

    for (const otherValues of otherValuesList) {
      for (const [key, value] of Object.entries(otherValues)) {
        for (const [type, values] of Object.entries(deepLinkedValuesList)) {
          const isInvoiceLineCustomer =
            type.includes("invoice_line") && key === "customer";

          if (
            COMBOBOX_VALUES_STOP_PROPAGATION.includes(value) &&
            (key in values || isInvoiceLineCustomer) &&
            (!stepName || stepName === `${parentKey}.${type}`)
          ) {
            fields.push(`${parentKey}.${type}.${key}`);

            if (key === "accountCostCode") {
              fields.push(
                `${parentKey}.${type}.item`,
                `${parentKey}.${type}.account`
              );
            }
          }
        }
      }
    }
  }

  return fields;
};
