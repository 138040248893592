import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  memo,
} from "react";
import cn from "clsx";

import { useDialogContext } from "./dialog-context";
import { DialogProvider } from "./dialog-provider";
import styles from "./dialog.module.css";

type DefaultComponent = "div";

type Props = ComponentPropsWithoutRef<DefaultComponent> & {
  name: string;
  onBack?: () => void;
};

const DialogStep = forwardRef<ComponentRef<DefaultComponent>, Props>(
  ({ name, onBack, children, className, ...props }, ref) => {
    const { step } = useDialogContext();

    return (
      <div
        ref={ref}
        className={cn(className, styles["dialog-step"])}
        aria-hidden={step !== name}
        {...props}
      >
        <DialogProvider onBack={onBack}>{children}</DialogProvider>
      </div>
    );
  }
);

DialogStep.displayName = "DialogStep";

const MemoizedDialogStep = memo(DialogStep);

export { MemoizedDialogStep as DialogStep };
