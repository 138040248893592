import { type Identifiable } from "@shared/types";
import {
  getApi,
  getUrlBuilder,
  invalidate5xx,
  transformPayloads,
} from "@utils/api";
import { transformKeysToSnakeCase } from "@utils/schema/converters";
import { type AxiosResponse } from "axios";
import { type z } from "zod";

import type { VENDOR_REQUEST_TYPES } from "../constants/constants";
import { getBadRequestSchema } from "../utils/get-bad-request-schema";

import {
  achSchema,
  documentPutSchema,
  documentResponseSchema,
  vendorAchPutPayload,
  vendorCreatePayload,
  vendorPartialSchema,
  vendorPutPayload,
} from "./schemas";
import type {
  ACHInfo,
  DocumentResponse,
  OutgoingDocumentPayload,
  OutgoingVendorPayload,
  Vendor,
} from "./types";

type VendorWithRealm = Identifiable & { realm: string };

export const vendorsApi = getApi([invalidate5xx, transformPayloads]);

const vendorsUrlBuilder = getUrlBuilder("/api/vendors/");

export const handleResponse = <T extends z.ZodSchema>(
  resp: AxiosResponse,
  schema: T
): z.infer<T> => {
  if (resp.status === 400) {
    const badRequestSchema = getBadRequestSchema(schema as any);
    const parsed = badRequestSchema.parse(resp.data);
    throw parsed;
  }

  return schema.parse(resp.data);
};

export const vendorApi = {
  get: async (opts: Identifiable) => {
    const resp = await vendorsApi.get<Vendor>(vendorsUrlBuilder.forItem(opts));
    return handleResponse(resp, vendorPartialSchema);
  },
  put: async (vendor: VendorWithRealm) => {
    const payload = vendorPutPayload.parse(vendor);

    const resp = await vendorsApi.put<OutgoingVendorPayload>(
      vendorsUrlBuilder.forItem(vendor),
      payload
    );

    return handleResponse(resp, vendorPartialSchema);
  },
  create: async (vendor: VendorWithRealm) => {
    const payload = vendorCreatePayload.parse(vendor);

    const resp = await vendorsApi.post<OutgoingVendorPayload>(
      vendorsUrlBuilder.forCollection(),
      payload
    );

    return handleResponse(resp, vendorPartialSchema);
  },
};

export type ACHCreatePayload = Omit<ACHInfo, "id"> & {
  realm: string;
};

const bankingAchApi = getApi([invalidate5xx, transformPayloads]);

const bankingAchUrlBuilder = getUrlBuilder("/api/vendorbankingachs/");

export const vendorBankingAchApi = {
  get: async ({ id }: Identifiable) => {
    const resp = await bankingAchApi.get<ACHInfo>(
      bankingAchUrlBuilder.forItem({ id })
    );
    return handleResponse(resp, achSchema);
  },
  create: async (ach: ACHCreatePayload) => {
    const payload = vendorAchPutPayload.parse(ach);

    const resp = await bankingAchApi.post<ACHInfo>(
      bankingAchUrlBuilder.forCollection(),
      payload
    );
    return handleResponse(resp, achSchema);
  },
  delete: async (ach: Identifiable) => {
    const resp = await bankingAchApi.delete(bankingAchUrlBuilder.forItem(ach));
    if (resp.status !== 204) {
      throw new Error(`Unexpected response: ${resp.status}`);
    }
    return resp;
  },
};

type Realm = { realm: string };

type DocumentsPayload = OutgoingDocumentPayload & Realm;

const rawDocumentsApi = getApi([invalidate5xx]);

const documentsUrlBuilder = getUrlBuilder("/api/documents/");

export const documentsApi = {
  get: async (opts: Identifiable) => {
    const resp = await rawDocumentsApi.get<DocumentResponse>(
      documentsUrlBuilder.forItem(opts)
    );
    return handleResponse(resp, documentResponseSchema);
  },
  put: async (doc: DocumentsPayload & Identifiable) => {
    const data = documentPutSchema.parse(doc) as object;

    const payload = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      payload.append(key, value);
    });

    const config = { headers: { "content-type": "multipart/form-data" } };
    const resp = await rawDocumentsApi.put<DocumentResponse>(
      documentsUrlBuilder.forItem(doc),
      payload,
      config
    );

    return handleResponse(resp, documentResponseSchema);
  },
  create: async (doc: DocumentsPayload) => {
    const data = documentPutSchema.parse(doc) as object;

    const payload = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      payload.append(key, value);
    });

    const config = { headers: { "content-type": "multipart/form-data" } };
    const resp = await rawDocumentsApi.post<DocumentResponse>(
      documentsUrlBuilder.forCollection(),
      payload,
      config
    );

    return handleResponse(resp, documentResponseSchema);
  },
  remove: async (doc: Identifiable) => {
    return await rawDocumentsApi.delete(documentsUrlBuilder.forItem(doc));
  },
};

const vendorRequestsApi = getApi();

const urlBuilderV2 = getUrlBuilder("/api/vendor-comms/vendorpublicrequest/");

type Payload = OutgoingDocumentPayload | { requested_document_id: string };

type PostVendorAchRequestProps = {
  bill_id: number | null;
  vendor_id: number | string;
  vendor_email: string;
  message?: string | null;
  cc_requesting_user?: boolean;
};

type DeleteVendorAchRequestProps = {
  request_id?: number | string;
  vendor_id: number | string;
};

type PostVendorDocRequestProps = {
  document_types: string[];
  vendor_id: number | string;
  vendor_email: string;
  message?: string | null;
};

type DeleteVendorDocRequestProps = {
  request_id?: number | string;
  vendor_id: number | string;
};

type PostVendorPoRequestProps = {
  purchase_order_id: number | string;
  vendor_id: number | string;
  vendor_email: string;
  message?: string | null;
};

type PostVendorPoRequestPropsV2 = {
  purchaseOrderId: number | string;
  vendorId: number | string;
  vendorEmail: string;
  message?: string | null;
  files?: File[];
};

type DeleteVendorPoRequest = {
  request_id?: number | string;
  vendor_id: number | string;
};

type PostVendorPublicAchRequestProps = {
  account_number: string;
  routing_number: string;
  vendor: string;
};

type PostVendorPublicPOSignatureRequestProps = {
  signature: string;
};

export type VendorPublicRequestBaseParams = {
  realmUid: string;
  vendorUid: string;
  token: string;
};

export type VendorPublicRequestParams = {
  realmUid: string;
  vendorUid: string;
  token: string;
  type: keyof typeof VENDOR_REQUEST_TYPES;
};

export type RequestResponse = Request;

const transformParams = (queryParams: VendorPublicRequestParams) => ({
  realm_unique_id: queryParams.realmUid,
  vendor_unique_id: queryParams.vendorUid,
  ...queryParams,
});

export const documentsRequestsApi = {
  postVendorAchRequest: (params: PostVendorAchRequestProps) => {
    return vendorRequestsApi.post("/api/vendorrequests/ach/", {
      ...params,
    });
  },
  deleteVendorAchRequest: (params: DeleteVendorAchRequestProps) => {
    const { request_id, ...data } = params;
    return vendorRequestsApi.delete(
      `/api/vendorrequests/ach/?request_id=${request_id}`,
      {
        data,
      }
    );
  },
  postVendorDocRequest: (params: PostVendorDocRequestProps) => {
    return vendorRequestsApi.post("/api/vendorrequests/document/", {
      ...params,
    });
  },
  deleteVendorDocRequest: (params: DeleteVendorDocRequestProps) => {
    const { request_id, ...data } = params;
    return vendorRequestsApi.delete(
      `/api/vendorrequests/document/?request_id=${request_id}`,
      {
        data,
      }
    );
  },
  postVendorPoRequest: (params: PostVendorPoRequestProps) => {
    return vendorRequestsApi.post("/api/vendorrequests/po_signature/", params);
  },
  postVendorPoRequestV2: ({ files, ...params }: PostVendorPoRequestPropsV2) => {
    const payload = new FormData();
    Object.entries(transformKeysToSnakeCase(params)).forEach(([key, value]) => {
      payload.append(key, String(value));
    });
    files?.forEach((file) => {
      payload.append("files", file);
    });
    const config = { headers: { "content-type": "multipart/form-data" } };
    return vendorRequestsApi.post<{
      purchase_order_id: string;
      vendor_id: string;
      vendor_email: string;
      message: string;
      files: { id: string; file: string; name: string }[];
    }>("/api/vendorrequests/po_signature/", payload, config);
  },
  deleteVendorPoRequest: (params: DeleteVendorPoRequest) => {
    const { request_id, ...data } = params;
    return vendorRequestsApi.delete(
      `/api/vendorrequests/po_signature/?request_id=${request_id}`,
      {
        data,
      }
    );
  },
  getVendorPublicRequest: (queryParams: VendorPublicRequestParams) => {
    const params = transformParams(queryParams);
    return vendorRequestsApi
      .get(`/api/vendor-comms/vendorpublicrequest/`, { params })
      .then((response) => response.data);
  },
  postVendorPublicAchRequest: (
    queryParams: VendorPublicRequestParams,
    data: PostVendorPublicAchRequestProps
  ) => {
    const params = transformParams(queryParams);
    return vendorRequestsApi.post(urlBuilderV2.forCollection(), data, {
      params,
    });
  },
  postVendorPublicDocRequest: (
    params: VendorPublicRequestParams,
    formData: Payload
  ) => {
    const config = {
      params: transformParams(params),
      headers: { "content-type": "multipart/form-data" },
    };
    return vendorRequestsApi.post(
      urlBuilderV2.forCollection(),
      formData,
      config
    );
  },
  putVendorPublicDocRequest: (
    params: VendorPublicRequestParams,
    formData: Payload
  ) => {
    return vendorRequestsApi.put(urlBuilderV2.forCollection(), formData, {
      params: transformParams(params),
    });
  },
  postVendorPublicPOSignatureRequest: (
    queryParams: VendorPublicRequestParams,
    data: PostVendorPublicPOSignatureRequestProps
  ) => {
    const params = transformParams(queryParams);
    return vendorRequestsApi.post(urlBuilderV2.forCollection(), data, {
      params,
    });
  },
};
