import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Text,
  Textarea,
  type TextareaAttachmentAddon,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  type useDialog,
  useEvent,
  useForm,
} from "@adaptive/design-system/hooks";
import { formatCurrency, isEqual } from "@adaptive/design-system/utils";
import type { BillPaymentFormPayload } from "@api/bills/types";
import { putComment } from "@api/comments";
import { handleErrors } from "@api/handle-errors";
import type { CommentRef } from "@components/comments/comment";
import { LIEN_WAIVER_STATUS } from "@lien-waiver/constants";
import { CONFIRMATION_PAYMENT_STRINGS } from "@src/bills/constants";
import { useCreatePaymentV2Mutation } from "@src/shared/api/bills";
import { refetchCurrentBill } from "@store/billSlice";
import { useAppDispatch } from "@store/hooks";
import { useClientSettings } from "@store/user";
import { profiledUserSelector } from "@store/user/slice";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sumBy } from "@utils/sumBy";
import { useVendorAction } from "@vendors/hooks";
import type { Stage } from "@vendors/types";
import { z } from "zod";

type BillPaymentConfirmationDialogProps = {
  onClose: () => void;
  paymentsInfo: BillPaymentFormPayload;
  dialog: ReturnType<typeof useDialog>;
};
const paymentFormSchema = z.object({
  payments: z
    .array(
      z.object({
        message: z.string().nullish(),
        files: z.array(z.any()).min(0),
      })
    )
    .min(1),
});

export const BillPaymentConfirmationDialog = ({
  paymentsInfo,
  onClose,
  dialog,
}: BillPaymentConfirmationDialogProps) => {
  const { vendorCreditsEnabled } = useClientSettings();
  const { fetchById: fetchVendor, showVendorById } = useVendorAction();
  const dispatch = useAppDispatch();
  const user = useSelector(profiledUserSelector, isEqual);

  const [createPayment] = useCreatePaymentV2Mutation();

  const commentRef = useRef<CommentRef>(null);

  const totalAmountToPay = useMemo(
    () => sumBy(paymentsInfo.payments, "amountToPay"),
    [paymentsInfo]
  );

  //TODO: to be settled when multiple payments are implemented
  const [currentPaymentIndex] = useState(0);

  const currentPayment = useMemo(
    () => paymentsInfo.payments[currentPaymentIndex],
    [paymentsInfo, currentPaymentIndex]
  );

  const curriedOnClose = useCallback(() => {
    dialog.hide();
    onClose();
  }, [dialog, onClose]);

  const initialValues = useMemo(
    () => ({
      payments: paymentsInfo.payments.map(() => ({
        message: "",
        files: [] as File[],
      })),
    }),
    [paymentsInfo.payments]
  );

  const form = useForm({
    schema: paymentFormSchema,
    initialValues,
    onSubmit: async (values) => {
      try {
        const paymentOption =
          currentPayment.bankAccountOption.paymentMethods.find(
            (option) => option.paymentMethod === currentPayment.paymentMethod
          );
        const payload = [
          {
            bills: currentPayment.billUrls,
            options: {
              appliedProcessOn: paymentOption?.paymentSchedules?.length
                ? paymentOption?.paymentSchedules[0].processOn
                : currentPayment.currentDebitDate,
              appliedAmount: currentPayment.amountToPay,
              customerBankAccount:
                currentPayment.bankAccountOption?.customerBankAccount?.url,
              customerCard: currentPayment.bankAccountOption?.customerCard?.url,
              customerPaymentAccount:
                paymentOption?.customerPaymentAccount?.url,
              vendorBankAccount: paymentOption?.vendorBankAccount?.url,
              paymentMethod: paymentOption?.paymentMethod,
              appliedSignature: currentPayment.signature,
              processPrerequisites: [
                ...(paymentOption?.processPrerequisites ?? []),
                ...(currentPayment.payWhenLienWaiverIsSigned
                  ? [{ type: "lien_waiver_signature" }]
                  : []),
              ],
            },
            lienWaivers: paymentsInfo.lienWaivers.map((lienWaiver) => ({
              ...lienWaiver,
              lienWaiverTemplateId:
                lienWaiver.sendLienWaiverOnPayment &&
                lienWaiver?.lienWaiverTemplate
                  ? `${parseRefinementIdFromUrl(lienWaiver?.lienWaiverTemplate)}`
                  : null,
              status:
                !lienWaiver.sendLienWaiverOnPayment &&
                lienWaiver.status !== LIEN_WAIVER_STATUS.NOT_REQUIRED
                  ? LIEN_WAIVER_STATUS.NOT_SELECTED
                  : lienWaiver.status,
            })),
          },
        ];

        const paymentMade = await createPayment(payload).unwrap();
        const paymentMessage = values.payments[currentPaymentIndex];
        if (
          paymentMade.length > 0 &&
          (paymentMessage.message !== "" || paymentMessage.files.length > 0)
        ) {
          await putComment({
            text: paymentMessage.message,
            author: user.url,
            parent: paymentMade[0].url,
            files: paymentMessage.files,
          });
        }
        toast.success(
          `${CONFIRMATION_PAYMENT_STRINGS.SUCCESSFUL_PAYMENT_TITLE} ${paymentsInfo.payments.length > 1 ? "s" : ""}`
        );
        dispatch(
          refetchCurrentBill([
            "bill_payments_v2",
            "balance",
            "amount_to_pay",
            "payment_details",
          ])
        );
        curriedOnClose();
        fetchVendor(currentPayment.vendor.id);
      } catch (error) {
        handleErrors(error);
      }
    },
  });

  const curriedOpenVendor = useCallback(
    (vendorId: string, stage: Stage) => () => {
      showVendorById(vendorId, stage);
    },
    [showVendorById]
  );

  const onAddFiles = useEvent((files: File[], index: number) => {
    form.setValue(`payments.${index}.files`, files);
  });

  const attachment = useMemo<TextareaAttachmentAddon>(
    () => ({
      accept: window.EXPECTED_FILE_TYPES,
      maxSize: window.MAX_COMMENT_FILES_SIZE_MB,
      maxSizeValidation: "total",
      onChange: (files) => {
        onAddFiles(files, currentPaymentIndex);
      },
      multiple: window.MAX_NUMBER_OF_COMMENT_FILES,
    }),
    [onAddFiles, currentPaymentIndex]
  );

  return dialog.isRendered ? (
    <Dialog
      show={dialog.isVisible}
      variant="dialog"
      onClose={curriedOnClose}
      size="auto"
    >
      <DialogHeader>
        <Flex gap="md">
          <Text weight="bold">
            {CONFIRMATION_PAYMENT_STRINGS.CONFIRMATION_PAYMENT_TITLE}{" "}
          </Text>
          <Text color="brand-2">
            {formatCurrency(totalAmountToPay, {
              currencySign: true,
              allowNegative: true,
            })}
          </Text>
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Flex
          gap="2xl"
          direction="row"
          minWidth="800px"
          as="form"
          {...form.props}
        >
          <Card as={Flex} width="full">
            <Flex gap="xl" width="full" direction="column" separator>
              <Flex gap="xl" width="full" direction="column">
                <Flex
                  width="full"
                  align="center"
                  gap="xl"
                  justify="space-between"
                >
                  <Text size="sm">
                    {CONFIRMATION_PAYMENT_STRINGS.BILLS_TITLE}
                  </Text>
                  <Text weight="bold">
                    {formatCurrency(currentPayment.balance, {
                      currencySign: true,
                      allowNegative: true,
                    })}
                  </Text>
                </Flex>
                {vendorCreditsEnabled && (
                  <Flex direction="column">
                    <Flex
                      width="full"
                      align="center"
                      gap="xl"
                      justify="space-between"
                    >
                      <Text size="sm">
                        {CONFIRMATION_PAYMENT_STRINGS.CREDITS_TITLE}
                      </Text>
                      {/* Update this amounts when vendor credits are implemented */}
                      <Text weight="bold">$0.00</Text>
                    </Flex>
                    <Flex
                      width="full"
                      align="center"
                      gap="xl"
                      justify="space-between"
                    >
                      {/* Update this amounts when vendor credits are implemented */}
                      <Text size="sm" color="neutral-500">
                        $0 available
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex
                gap="3xl"
                width="full"
                justify="space-between"
                direction="column"
              >
                <Flex
                  width="full"
                  align="center"
                  gap="xl"
                  justify="space-between"
                >
                  <Text size="sm">
                    {CONFIRMATION_PAYMENT_STRINGS.PAYMENT_TITLE}
                  </Text>
                  <Text weight="bold">
                    {formatCurrency(currentPayment.amountToPay, {
                      currencySign: true,
                      allowNegative: true,
                    })}
                  </Text>
                </Flex>
                <Flex width="full" gap="lg" direction="column">
                  <Text size="sm">
                    {CONFIRMATION_PAYMENT_STRINGS.FROM_ACCOUNT_TITLE}
                  </Text>
                  <Flex
                    width="full"
                    align="center"
                    gap="xl"
                    justify="space-between"
                  >
                    <Text size="md" weight="bold">
                      {currentPayment.bankAccountOption?.label}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Card>
          <Flex gap="xl" width="full" direction="column" separator>
            <Flex gap="xl" width="full" direction="column">
              <Flex direction="column" gap="xs">
                <TextField
                  disabled
                  label={
                    CONFIRMATION_PAYMENT_STRINGS.VENDOR_EMAIL_ADDRESS_TITLE
                  }
                  messageVariant="hidden"
                  value={currentPayment.vendor.email ?? ""}
                />
                <Flex direction="column" gap="md">
                  <Text size="xs" color="neutral-500">
                    {
                      CONFIRMATION_PAYMENT_STRINGS.CHANGE_VENDOR_EMAIL_ADDRESS_TITLE
                    }{" "}
                    <Link
                      variant="success"
                      size="xs"
                      onClick={curriedOpenVendor(
                        currentPayment.vendor.id.toString(),
                        "info"
                      )}
                    >
                      {CONFIRMATION_PAYMENT_STRINGS.ADD_IT_HERE_TITLE}
                    </Link>
                  </Text>
                  {/* {currentPayment.sendLienWaiverOnPayment && (
                    <Text size="xs" color="neutral-500">
                      {CONFIRMATION_PAYMENT_STRINGS.SEND_LIEN_WAIVER_TITLE}
                    </Text>
                  )} */}
                </Flex>
              </Flex>
            </Flex>
            <Textarea
              ref={commentRef}
              minHeight={94}
              maxHeight={200}
              disabled={form.isSubmitting}
              messageVariant="absolute"
              label={CONFIRMATION_PAYMENT_STRINGS.ADD_COMMENT}
              placeholder={CONFIRMATION_PAYMENT_STRINGS.ADD_COMMENT_PLACEHOLDER}
              attachment={attachment}
              {...form.register(`payments.${currentPaymentIndex}.message`)}
              renderAfter={({ setFilesFromPicker }) => (
                <Flex gap="md" justify="flex-end" shrink={false} grow>
                  {setFilesFromPicker && (
                    <Tooltip
                      message={CONFIRMATION_PAYMENT_STRINGS.ATTACH_FILES}
                    >
                      <Button
                        size="sm"
                        color="neutral"
                        variant="ghost"
                        onClick={setFilesFromPicker}
                      >
                        <Icon name="paperclip" />
                      </Button>
                    </Tooltip>
                  )}
                </Flex>
              )}
            />
          </Flex>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex grow width="full">
          <Button
            variant="text"
            block
            size="lg"
            onClick={curriedOnClose}
            disabled={form.isSubmitting}
          >
            {CONFIRMATION_PAYMENT_STRINGS.BACK_TITLE}
          </Button>
        </Flex>
        <Tooltip
          message={
            !currentPayment.vendor.email
              ? CONFIRMATION_PAYMENT_STRINGS.ADD_VENDOR_EMAIL
              : ""
          }
          as={Flex}
          width="full"
          grow
        >
          <Button
            variant="solid"
            size="lg"
            block
            disabled={
              form.isSubmitting || !form.isValid || !currentPayment.vendor.email
            }
            type="submit"
            form={form.id}
          >
            {CONFIRMATION_PAYMENT_STRINGS.PAY_TITLE}{" "}
            {formatCurrency(totalAmountToPay, {
              currencySign: true,
              allowNegative: true,
            })}
          </Button>
        </Tooltip>
      </DialogFooter>
    </Dialog>
  ) : null;
};
