type GlobalEvents =
  | "sidebarExpand"
  | "sidebarCollapse"
  | "commentReplyButton"
  | "perPageLimitChange"
  | "chatOpen"
  | "closeDrawer";

type AuthEvents =
  | "reAuthenticateRequested"
  | "reAuthenticateSucceeded"
  | "reAuthenticateForgotPassword"
  | "reAuthenticateCancelled";

type SearchEvents = "searchGoTo" | "searchShow" | "searchByRecent";

type JobEvents = "jobBatchActions";

type DownloadEvents = "downloadRequest";

type NotificationEvents =
  | "notificationsShow"
  | "notificationsTabChange"
  | "notificationsOpenDetails"
  | "notificationsMarkAsRead"
  | "notificationsMarkAllAsRead"
  | "notificationsSwitchUnreadFilter";

type BillEvents =
  | "billCreate"
  | "billUpdate"
  | "billApprove"
  | "billPay"
  | "billLineAdded"
  | "billLineRemoved"
  | "billBatchActions"
  | "billDynamicActions"
  | "billAttachmentActions"
  | "billBatchActionsError"
  | "billOpenLinkedPurchaseOrder";

type VendorEvents = "vendorBatchActions";

type ExpenseEvents =
  | "expenseUpdate"
  | "expenseCreate"
  | "expenseBatchActions"
  | "expenseDynamicActions"
  | "expenseTutorial";

type CardFeed =
  | "cardFeedTutorial"
  | "cardFeedMatchCost"
  | "cardFeedMatchCostResolveConflicts"
  | "costMatchCardTransaction"
  | "costMatchCardTransactionResolveConflicts"
  | "cardFeedBatchActions";

type InvoiceEvents =
  | "invoiceCreate"
  | "invoiceAddEmptyLine"
  | "invoiceSyncToQuickBooks"
  | "invoiceAddLineFromCosts"
  | "invoiceDownload"
  | "invoiceBatchActions"
  | "invoiceLineCostBatchActions"
  | "invoiceDrawScheduleAddLine"
  | "invoiceDrawScheduleUpdateLine"
  | "invoiceTransactionSyncError"
  | "invoiceLineCostBatchActionsError";

type BudgetEvents =
  | "budgetLineUpdate"
  | "budgetLineExternalUpdate"
  | "budgetLineCategoryUpdate"
  | "budgetChangeCreate"
  | "budgetExternalChangeCreate"
  | "budgetChangeUpdate"
  | "budgetExternalChangeUpdate"
  | "budgetChangeDelete"
  | "budgetBatchActions"
  | "budgetImport"
  | "budgetAdvancedFilters"
  | "budgetViewMode"
  | "budgetTransactionBatchActions"
  | "budgetDrawnToDateBatchActions"
  | "budgetBatchActionsError";

type ApprovalWorkflowEvents =
  | "approvalWorkflowCreate"
  | "approvalWorkflowUpdate"
  | "approvalWorkflowDuplicate";

type PurchaseOrderEvents =
  | "purchaseOrderUnlinkLine"
  | "purchaseOrderBatchActions"
  | "purchaseOrderOpenLinkedBill"
  | "purchaseOrderMergeDialogOpen"
  | "purchaseOrderBatchActionsError";

type LienWaiverEvents =
  | "sendLienWaiverReminder"
  | "saveLienWaiverTemplate"
  | "deleteLienWaiverTemplate"
  | "markLienWaiverTemplateAsDefault"
  | "unmarkLienWaiverTemplateAsDefault"
  | "signLienWaiver";

type PropagationEvents =
  | "propagationDialogOpen"
  | "propagationDialogCancel"
  | "propagationDialogSave";

// Check backend/bob/analytics.py for backend defined events
// If you are adding an event here, make sure it matches anything on the backend
type EventName =
  | CardFeed
  | JobEvents
  | AuthEvents
  | BillEvents
  | VendorEvents
  | ExpenseEvents
  | InvoiceEvents
  | BudgetEvents
  | GlobalEvents
  | SearchEvents
  | NotificationEvents
  | ApprovalWorkflowEvents
  | PurchaseOrderEvents
  | DownloadEvents
  | LienWaiverEvents
  | PropagationEvents;

let previousPage: string | undefined;

let previousGroupId: string | undefined;

const logger = (method: string, params: Record<string, unknown>) =>
  console.log(`Analytics | ${method}`, params); // eslint-disable-line no-console

const getJuneInstance = () => (window.analytics ? window.analytics : undefined);

const getOpenReplayInstance = () =>
  window.OpenReplay ? window.OpenReplay : undefined;

export const track = (
  eventName: EventName,
  properties?: Record<string, unknown>
) => {
  const june = getJuneInstance();
  const openReplay = getOpenReplayInstance();

  if (openReplay) openReplay?.event?.(eventName, properties);

  if (june) {
    june?.track?.(eventName, {
      context: { groupId: previousGroupId },
      ...properties,
    });
  } else {
    logger("Track", {
      eventName,
      context: { groupId: previousGroupId },
      ...properties,
    });
  }
};

export const group = (
  groupId: string,
  properties?: Record<string, unknown>
) => {
  previousGroupId = groupId;
  const enhancedProperties = {
    ...(properties ?? {}),
    env: window.SENTRY_ENV ?? "unknown",
  };
  getJuneInstance()?.group?.(groupId, enhancedProperties) ??
    logger("Group", { groupId, properties: enhancedProperties });
};

export const identify = (
  usedId: string | number,
  properties?: Record<string, unknown>
) => {
  getJuneInstance()?.identify?.(String(usedId), properties) ??
    logger("Identify", { usedId, properties });
};

export const page = (name: string) => {
  if (previousPage === name) return;
  previousPage = name;

  const nameWithIdReplacedWithPlaceholder = name.replace(/\/\d+/g, "/:id");
  getJuneInstance()?.page?.(nameWithIdReplacedWithPlaceholder, {
    groupId: previousGroupId,
  }) ?? logger("Page", { name, groupId: previousGroupId });
};
