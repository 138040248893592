import type { VendorState } from "../store/slice";

import { getInitialForm } from "./get-initial-form";

export const getInitialState = (): VendorState => ({
  form: getInitialForm(),
  creationId: null,
  lastAction: null,
  lastCommit: null,
  fetchStatus: "idle",
  transactions: {},
});
