import React, { memo } from "react";
import { Button, Flex, Loader } from "@adaptive/design-system";
import { suffixify } from "@adaptive/design-system/utils";
import { useStageName } from "@components/sequential/context";
import { asStageFooter } from "@components/sequential/factory";
import type { StageFooterMap, StagesBase } from "@components/sequential/types";
import { useAppSelector } from "@store/hooks";

import { ROUTE_LABEL } from "../constants/constants";
import { useVendorInfo } from "../hooks/use-vendor-info";
import { selectVendorFetchStatus } from "../store/selectors";

const FooterAction = memo(() => {
  const stageName = useStageName();

  const fetchStatus = useAppSelector(selectVendorFetchStatus);

  const { hasUnsavedChanges, isSubmitting } = useVendorInfo();

  const isLoading = fetchStatus === "pending" || isSubmitting;

  return (
    <>
      <Flex />
      {fetchStatus !== "rejected" && (
        <Flex justify="flex-end" align="center" gap="2xl">
          <Button
            disabled={!hasUnsavedChanges || isLoading}
            variant="solid"
            type="submit"
            size="lg"
            form={suffixify("vendor", stageName || "")}
          >
            {isLoading ? <Loader /> : "Save"}
          </Button>
        </Flex>
      )}
    </>
  );
});

FooterAction.displayName = "FooterAction";

const FooterMapping = {
  [ROUTE_LABEL.INFO]: FooterAction,
  [ROUTE_LABEL.ACCOUNTING]: FooterAction,
  [ROUTE_LABEL.DOCUMENTS]: FooterAction,
} as StageFooterMap<StagesBase>;

export const FormFooter = asStageFooter(FooterMapping);
