import React, { useState } from "react";
import {
  Dialog,
  DialogStep,
  Flex,
  Link,
  Text,
  toast,
} from "@adaptive/design-system";
import {
  useEvent,
  type useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { generateUrlByHumanReadableType } from "@utils/generate-url-by-human-readable-type";

import type { CardTransaction } from "../api/types";

import { MatchingTransactionDialogEditStep } from "./matching-transaction-dialog-edit-step";
import { MatchingTransactionDialogGenericEdit } from "./matching-transaction-dialog-generic-edit";
import { MatchingTransactionDialogGenericSelectCardTransaction } from "./matching-transaction-dialog-generic-select-card-transaction";
import { MatchingTransactionDialogMatchStep } from "./matching-transaction-dialog-match-step";
import { MatchingTransactionDialogSelectCardTransactionStep } from "./matching-transaction-dialog-select-card-transaction-step";
import { MatchingTransactionDialogSelectCostStep } from "./matching-transaction-dialog-select-cost-step";
import type {
  CardFilter,
  DaysThreshold,
  LinkedCost,
  MatchingTransactionDialogSteps,
  MatchingTransactionOnChangeHandler,
  SelectCardOptions,
} from "./types";

export type MatchingTransactionDialogProps = {
  cost?: LinkedCost;
  dialog: ReturnType<typeof useMultiStepDialog<MatchingTransactionDialogSteps>>;
  onChange?: MatchingTransactionOnChangeHandler;
  selectCard?: SelectCardOptions;
  cardTransaction?: CardTransaction;
  skipRequest?: boolean;
  daysThreshold?: DaysThreshold;
  cardFilter?: CardFilter;
};

export const MatchingTransactionDialog = ({
  cost,
  dialog,
  onChange,
  selectCard = { title: "", subtitle: "" },
  cardTransaction,
  skipRequest,
  daysThreshold = { daysBefore: 1, daysAfter: 1 },
  cardFilter,
}: MatchingTransactionDialogProps) => {
  const [match, setMatch] = useState({
    cost: undefined,
    conflicts: [],
    cardTransaction: undefined,
  });

  const hasMatch = !!(cost?.cardTransaction || cardTransaction?.match);

  const onSuccess = useEvent<MatchingTransactionOnChangeHandler>((payload) => {
    const info = payload.cost
      ? dotObject.get(payload.cost, "parent") || payload.cost
      : undefined;

    if (cost) {
      toast.success(
        `Card transaction matched to cost${info.docNumber ? ` #${info.docNumber}` : ""}`
      );
    } else {
      toast.success(
        <Flex as="span" direction="column">
          <Text as="strong" weight="bold">
            Cost{info.docNumber ? ` #${info.docNumber}` : ""} matched to card
            transaction
          </Text>
          {info && (
            <Link href={generateUrlByHumanReadableType(info)} target="_blank">
              View cost
            </Link>
          )}
        </Flex>
      );
    }

    onChange?.(payload);

    dialog.hide();
  });

  const onUnmatch = useEvent(() => {
    if (cost) {
      const info = dotObject.get(cost, "parent") || cost;
      toast.success(
        `Card transaction was unmatched${info?.docNumber ? ` from cost #${info.docNumber}` : ""}`
      );
    } else if (cardTransaction) {
      const info =
        dotObject.get(cardTransaction, "match.parent") || cardTransaction.match;
      toast.success(
        `Cost${info?.docNumber ? ` #${info.docNumber}` : ""} was unmatched from card transaction`
      );
    }

    onChange?.({ cost: undefined, cardTransaction: undefined });

    dialog.hide();
  });

  const onUnmatchError = useEvent(() => {
    toast.error("Failed to unmatch card transaction");
    dialog.hide();
  });

  const onSelectError = useEvent((payload) => {
    setMatch({ cost, cardTransaction, ...payload });
    dialog.setStep("match");
  });

  return (
    <Dialog
      show={dialog.isVisible}
      size="auto"
      step={dialog.step}
      variant="multi-step-dialog"
      onClose={dialog.hide}
    >
      <DialogStep name="edit">
        {skipRequest ? (
          <MatchingTransactionDialogGenericEdit
            onUnmatch={onUnmatch}
            onChangeMatch={() => dialog.setStep("select")}
            cost={cost}
            cardTransaction={cardTransaction}
          />
        ) : (
          <MatchingTransactionDialogEditStep
            cost={cost}
            onUnmatch={onUnmatch}
            onUnmatchError={onUnmatchError}
            onChangeMatch={() => dialog.setStep("select")}
            cardTransaction={cardTransaction}
          />
        )}
      </DialogStep>
      <DialogStep name="select" onBack={hasMatch ? dialog.back : undefined}>
        {cost ? (
          skipRequest ? (
            <MatchingTransactionDialogGenericSelectCardTransaction
              cost={cost}
              onCancel={dialog.hide}
              onSubmit={onSuccess}
              daysThreshold={daysThreshold}
              cardFilter={cardFilter}
              {...selectCard}
            />
          ) : (
            <MatchingTransactionDialogSelectCardTransactionStep
              cost={cost}
              onError={onSelectError}
              onCancel={dialog.hide}
              onSuccess={onSuccess}
              daysThreshold={daysThreshold}
              cardFilter={cardFilter}
              {...selectCard}
            />
          )
        ) : cardTransaction ? (
          <MatchingTransactionDialogSelectCostStep
            onError={onSelectError}
            onCancel={dialog.hide}
            onSuccess={onSuccess}
            cardTransaction={cardTransaction}
          />
        ) : null}
      </DialogStep>
      <DialogStep name="match" onBack={dialog.back}>
        <MatchingTransactionDialogMatchStep
          onCancel={dialog.back}
          onSuccess={onSuccess}
          {...match}
        />
      </DialogStep>
    </Dialog>
  );
};
