import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Icon, Loader, toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { useCostCodesAccountsSimplified } from "@hooks/use-cost-codes-accounts-simplified";
import { useCustomersSimplified } from "@hooks/useCustomersSimplified";
import OneSchemaImporter from "@oneschema/react";
import { currencySchema, idSchema } from "@shared/utils/schema";
import { api } from "@utils/api";
import { getItemUrls } from "@utils/get-cost-code-account-values";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { z } from "zod";

type OneSchemaRecord = {
  amount?: number;
  costCode?: string;
  job?: string;
  description?: string;
};

type OnOneSchemaSuccessHandler = (data: {
  records: OneSchemaRecord[];
  embed_id: number;
}) => void;

const laborImportSchema = z.object({
  lines: z.array(
    z.object({
      amount: currencySchema,
      item_id: idSchema.optional(),
      account_id: idSchema.optional(),
      customer_id: idSchema.optional(),
      description: z.string().optional(),
    })
  ),
});

type LaborImportPayload = z.infer<typeof laborImportSchema>;

const transformData = <Data extends { label?: string; groupLabel?: string }>(
  data: Data[],
  withGroupLabel = false
) => {
  const labels = data.reduce((acc, item) => {
    let label = item.label;

    if (withGroupLabel) {
      label = `${item.label} - ${item.groupLabel}`;
    }

    return label ? [...acc, label] : acc;
  }, [] as string[]);

  return Array.from(new Set(labels));
};

export const ImportLaborButton = () => {
  const [oneSchemaIsOpen, setOneSchemaIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const lineItems = useCostCodesAccountsSimplified();
  const customers = useCustomersSimplified();

  const navigate = useNavigate();

  const columnsToUpdate = useMemo(
    () => [
      {
        key: "costCode",
        validation_options: { values: transformData(lineItems.data, true) },
      },
      {
        key: "job",
        validation_options: { values: transformData(customers.data) },
      },
    ],
    [lineItems.data, customers.data]
  );

  const onOneSchemaSuccess = useEvent<OnOneSchemaSuccessHandler>(
    async (data) => {
      const expenseLines = data.records.reduce(
        (acc, record) => {
          const costCode = lineItems.data.find(
            (item) =>
              item.label ===
              record.costCode?.replace(/ - (Cost code|Account)$/, "")
          );

          const customer = customers.data.find(
            (item) => item.label === record.job
          );

          if (!costCode) return acc;

          const itemId = parseRefinementIdFromUrl(costCode.value);
          const customerId = customer
            ? parseRefinementIdFromUrl(customer.value)
            : undefined;

          if (!itemId) return acc;

          const lineItemId = getItemUrls(costCode.value);

          acc.push({
            amount: record.amount ?? 0,
            item_id: lineItemId.item ? itemId : undefined,
            account_id: lineItemId.account ? itemId : undefined,
            customer_id: customerId,
            description: record.description,
          });

          return acc;
        },
        [] as LaborImportPayload["lines"]
      );

      setLoading(true);

      const expense = await api.post("/api/expenses/spreadsheet_import/", {
        lines: expenseLines,
        embed_id: data.embed_id,
      });

      setLoading(false);

      toast.success("Imported receipt successfully!");
      navigate(`/expenses/${expense.data.id}`);
    }
  );

  const templateOverrides = useMemo(
    () => ({
      columns_to_update: columnsToUpdate,
    }),
    [columnsToUpdate]
  );

  return (
    <>
      <Button
        color="neutral"
        variant="ghost"
        onClick={() => setOneSchemaIsOpen(true)}
      >
        <Icon name="arrow-up-from-bracket" />
        Import spreadsheet
      </Button>
      {loading && <Loader position="fixed" />}
      {window.ONESCHEMA_CLIENT_ID &&
        window.ONESCHEMA_LABOR_TEMPLATE_ID &&
        window.ONESCHEMA_JWT && (
          <OneSchemaImporter
            style={{
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 99999999,
              position: "fixed",
            }}
            inline={false}
            isOpen={oneSchemaIsOpen}
            devMode={import.meta.env.DEV}
            userJwt={window.ONESCHEMA_JWT}
            clientId={window.ONESCHEMA_CLIENT_ID}
            onSuccess={onOneSchemaSuccess}
            templateKey={window.ONESCHEMA_LABOR_TEMPLATE_ID}
            importConfig={{ type: "local" }}
            onRequestClose={() => setOneSchemaIsOpen(false)}
            templateOverrides={templateOverrides}
          />
        )}
    </>
  );
};
