import React from "react";
import { useEvent } from "@adaptive/design-system/hooks";

import type { CardTransaction } from "../api/types";
import { useUnmatchCardTransaction } from "../hooks/use-unmatch-card-transaction";

import { MatchingTransactionDialogGenericEdit } from "./matching-transaction-dialog-generic-edit";
import type { LinkedCost } from "./types";

export type MatchingTransactionDialogEditStepProps = {
  cost?: LinkedCost;
  onUnmatch: () => void;
  onUnmatchError: (e: Error) => void;
  onChangeMatch: () => void;
  cardTransaction?: CardTransaction;
};

export const MatchingTransactionDialogEditStep = ({
  cost,
  onUnmatch,
  onUnmatchError,
  onChangeMatch,
  cardTransaction,
}: MatchingTransactionDialogEditStepProps) => {
  const [unmatchCardTransaction, unmatchCardTransactionInfo] =
    useUnmatchCardTransaction();

  const enhancedOnUnmatch = useEvent(async () => {
    const cardTransactionId = cardTransaction?.id ?? cost?.cardTransaction?.id;

    if (cardTransactionId) {
      try {
        await unmatchCardTransaction(cardTransactionId).unwrap();
        onUnmatch();
      } catch (e) {
        onUnmatchError(e as Error);
      }
    }
  });

  return (
    <MatchingTransactionDialogGenericEdit
      onUnmatch={enhancedOnUnmatch}
      onChangeMatch={onChangeMatch}
      cost={cost}
      cardTransaction={cardTransaction}
      loading={unmatchCardTransactionInfo.isLoading}
    />
  );
};
