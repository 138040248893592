import { referenceNodeSchema } from "@api/expenses/response";
import {
  arraySchema,
  commentSchema,
  dateSchema,
  errorSchema,
  idSchema,
  userSchema,
} from "@utils/schema";
import { z } from "zod";

export const reviewStatusSchema = z.enum([
  "ALL",
  "PAID",
  "DRAFT",
  "APPROVAL",
  "FOR_PAYMENT",
  "PAYMENT_FAILED",
  "PARTIALLY_PAID",
  "ACH_PROCESSING",
  "ACH_INFO_REQUESTED",
]);

export const duplicateSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  docNumber: z.string(),
  reviewStatus: reviewStatusSchema,
  humanReadableType: z.enum(["Bill", "Vendor Credit"]),
});

export const checkBillDuplicationResponseSchema = z.object({
  duplicate: arraySchema(duplicateSchema),
});

export const linkedInvoicesResponseSchema = z.object({
  invoices: arraySchema(
    z.object({
      id: idSchema,
      docNumber: z.string(),
      customerId: idSchema,
      humanReadableType: z.enum(["Draw"]),
      url: z.string().url(),
    })
  ),
});

export const vendorSchema = z.object({
  id: idSchema,
  url: z.string(),
  displayName: z.string(),
  email: z.string().email().nullish(),
});

export const customerSchema = z.object({
  id: idSchema,
  displayName: z.string(),
  url: z.string(),
  realm: z.string().url(),
  parent: z.string().url().nullish(),
  displayNameWithoutCompany: z.string(),
  disabled: z.boolean(),
});

export const itemSchema = z
  .object({
    id: idSchema,
    url: z.string().url(),
    parent: z.string().url().nullish(),
    displayName: z.string(),
    fullyQualifiedName: z.string().nullish(),
  })
  .transform((item) => ({
    ...item,
    fullyQualifiedName: item.fullyQualifiedName ?? item.displayName,
  }));

export const accountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  parent: z.string().url().nullish(),
  displayName: z.string(),
  isPaymentAccount: z.boolean(),
  isCreditCard: z.boolean(),
  isBankAccount: z.boolean(),
  fullyQualifiedCategory: z.string(),
  isBudgetCode: z.boolean(),
  isVendorCode: z.boolean(),
  isRemovedVendorCode: z.boolean(),
  parentName: z.string().nullish(),
});

export const lineSchema = z.object({
  id: idSchema,
  description: z.string().nullish(),
  amount: z.string(),
  customer: customerSchema.nullish(),
  vendor: vendorSchema.nullish(),
  account: accountSchema.nullish(),
  item: itemSchema.nullish(),
  billableStatus: z.optional(
    z.enum(["Billable", "HasBeenBilled", "NotBillable"])
  ),
  isAVariance: z.optional(z.boolean()),
  order: z.number().nullish(),
});

export const attachmentSchema = z.object({
  document: z.string().url().nullish(),
  pdf: z.string().url().nullish(),
  id: idSchema,
  thumbnail: z.string().nullish(),
  url: z.string().url(),
});

export const fileExportSchema = z.object({
  document: z.string(),
  id: z.string(),
});

export const revisionEventSchema = z.object({
  author: z.object({
    id: z.number(),
    url: z.string(),
    fullName: z.string(),
  }),
  createdAt: z.string(),
  timelineEventType: z.string(),
});

// Define LIEN_WAIVER_STATUS as a const array
export const LIEN_WAIVER_STATUS = <const>[
  "not_selected",
  "not_required",
  "not_requested",
  "requested",
  "marked_as_requested",
  "signed",
  "marked_as_signed",
];

export const LIEN_WAIVER_STATUS_UPPER = <const>[
  "NOT_SELECTED",
  "NOT_REQUIRED",
  "REQUESTED",
  "MARKED_AS_REQUESTED",
  "SIGNED",
  "MARKED_AS_SIGNED",
];

// Zod schema for LIEN_WAIVER_STATUS
export const lienWaiverStatusSchema = z.enum(LIEN_WAIVER_STATUS);
export const lienWaiverStatusUpperSchema = z.enum(LIEN_WAIVER_STATUS_UPPER);

// Derived status arrays
export const LIEN_WAIVER_REQUESTED_STATUS = <const>[
  "requested",
  "marked_as_requested",
];
export const LIEN_WAIVER_SIGNED_STATUS = <const>["signed", "marked_as_signed"];
export const LIEN_WAIVER_LINKED_STATUS = <const>[
  ...LIEN_WAIVER_REQUESTED_STATUS,
  ...LIEN_WAIVER_SIGNED_STATUS,
];

// Zod schemas for derived arrays
export const lienWaiverRequestedStatusSchema = arraySchema(
  z.enum(LIEN_WAIVER_REQUESTED_STATUS)
);
export const lienWaiverSignedStatusSchema = arraySchema(
  z.enum(LIEN_WAIVER_SIGNED_STATUS)
);
export const lienWaiverLinkedStatusSchema = arraySchema(
  z.enum(LIEN_WAIVER_LINKED_STATUS)
);

export const lienWaiverSchema = z.object({
  bill: z.string().nullable(),
  billId: idSchema.nullish(),
  billPayment: z.string().nullish(),
  fileExport: fileExportSchema.nullish(),
  id: z.string(),
  url: z.string().url(),
  lienWaiverTemplate: z.string().nullish(),
  message: z.string().nullish(),
  printedName: z.string().nullish(),
  paymentAmount: z.string().nullish(),
  pdf: z.string().nullish(),
  status: lienWaiverStatusSchema,
  vendor: vendorSchema,
  vendorEmail: z.string().nullish(),
  vendorReminderCanBeSent: z.boolean(),
  signedAt: z.string().nullish(),
  revisionEvent: revisionEventSchema.nullish(),
  customer: customerSchema.nullish(),
});

export const billSchema = z.object({
  id: idSchema,
  date: z.string(),
  docNumber: z.string(),
  humanReadableType: z.string(),
  isInQuickbooks: z.boolean(),
  reviewStatus: z.string(),
  totalAmount: z.string(),
  url: z.string(),
});

export const accountTypeEnumSchema = z.enum([
  "depository",
  "loan",
  "credit",
  "investment",
  "other",
]);

export const customerBankAccountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  name: z.string(),
  currency: z.string().nullish(),
  type: accountTypeEnumSchema,
  mask: z.string(),
  plaidAccountId: z.string(),
  paymentAccount: accountSchema.nullish(),
  bankName: z.string(),
  verificationStatus: z.string().nullish(),
  isTransactionPullEnabled: z.boolean(),
  checkbookAccountIsActive: z.boolean().nullish(),
  lastSyncDate: z.string().nullish(),
});

export const customerSimpleSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: z.string(),
});

export const customerCardSchema = z.object({
  id: idSchema,
  name: z.string(),
  mask: z.string().nullish(),
  url: z.string().url(),
  user: userSchema.nullish(),
  account: accountSchema.nullish(),
  item: itemSchema.nullish(),
  fullName: z.string(),
  customer: customerSimpleSchema.nullish(),
});

export const vendorBankAccountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  routingNumber: z.string(),
  accountNumber: z.string(),
});

export const processPrerequisiteSchema = z.object({
  type: z.string(),
  isSatisfied: z.boolean(),
  satisfiedOn: z.nullable(z.string()),
});

export const PAYMENT_METHOD = {
  MARK_AS_PAID: "mark-as-paid",
  ACH: "ACH",
  PRINT_CHECK: "print-check",
  MAIL_CHECK: "mail-check",
  VIRTUAL_EMAIL_CHECK: "virtual-email-check",
  VIRTUAL_SMS_CHECK: "virtual-sms-check",
} as const;

export const paymentMethodEnumSchema = z.enum([
  PAYMENT_METHOD.MARK_AS_PAID,
  PAYMENT_METHOD.ACH,
  PAYMENT_METHOD.PRINT_CHECK,
  PAYMENT_METHOD.MAIL_CHECK,
  PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK,
  PAYMENT_METHOD.VIRTUAL_SMS_CHECK,
]);

export const paymentScheduleSchema = z.object({
  processOn: dateSchema,
  expectedDeliveryAfter: dateSchema,
  expectedDeliveryBefore: dateSchema,
});

export const paymentScheduledSchema = z.object({
  processedOn: dateSchema,
  deliveredOn: dateSchema,
  expectedDeliveryAfter: dateSchema,
  expectedDeliveryBefore: dateSchema,
});

export const optionSchema = z.object({
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerCardSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  vendorBankAccount: vendorBankAccountSchema.nullish(),
  paymentMethod: paymentMethodEnumSchema,
  hasUnmatchedCardTransactions: z.boolean(),
  processPrerequisites: arraySchema(processPrerequisiteSchema),
  paymentSchedules: arraySchema(paymentScheduleSchema),
});

export const paymentOptionSchema = z.object({
  bills: arraySchema(billSchema),
  options: arraySchema(optionSchema),
  vendor: vendorSchema,
  vendorCredits: arraySchema(billSchema),
});

export const paymentOptionResponseSchema = arraySchema(paymentOptionSchema);

export const createBillPaymentV2ResponseItemSchema = z.object({
  id: idSchema,
  url: z.string().url(),
});

export const createBillPaymentV2ResponseSchema = arraySchema(
  createBillPaymentV2ResponseItemSchema
);
export const paymentErrorsSchema = z.object({
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  error: z.string().nullish(),
  errorDetail: z.string().nullish(),
  link: z.string().nullish(),
});

export const billSimpleSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  humanReadableType: z.string().nullish(),
  docNumber: z.string(),
  reviewStatus: reviewStatusSchema,
  date: dateSchema.nullish(),
  isInQuickbooks: z.boolean(),
});

export const cardTransactionSimpleSchema = z
  .object({
    id: z.string().nullish(),
    amount: z
      .string()
      .nullish()
      .transform((amt) => (amt ? parseFloat(amt) : null)),
    date: dateSchema,
    name: z.string().nullish(),
    merchantName: z.string().nullish(),
    displayName: z.string().nullish(),
    url: z.string().url().nullish(),
    card: z.object({ user: userSchema.nullish() }).nullish(),
    paymentAccount: z.optional(referenceNodeSchema).or(z.null()),
    pending: z.boolean(),
    description: z.string().nullish(),
    mask: z.string().nullish(),
    plaidAccountOwner: z
      .object({
        id: idSchema,
        url: z.string().url(),
        accountOwner: z.string().nullish(),
      })
      .nullish(),
  })
  .nullish();

export const billPaymentSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  docNumber: z.string(),
  status: z.string(),
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerCardSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  vendorBankAccount: vendorBankAccountSchema.nullish(),
  paymentMethod: paymentMethodEnumSchema,
  processPrerequisites: arraySchema(processPrerequisiteSchema),
  vendor: vendorSchema,
  bills: arraySchema(
    z.object({
      bill: billSimpleSchema,
      appliedAmount: z.string(),
      openBalance: z.string(),
      customers: arraySchema(customerSimpleSchema),
    })
  ),
  appliedVendorCredits: arraySchema(
    z.object({
      vendorCredit: billSimpleSchema,
      appliedAmount: z.string(),
      openBalance: z.string(),
      customers: arraySchema(customerSimpleSchema),
    })
  ),
  lienWaivers: arraySchema(lienWaiverSchema),
  appliedAmount: z.string(),
  paymentSchedule: paymentScheduledSchema,
  syncErrors: arraySchema(errorSchema),
  paymentErrors: arraySchema(paymentErrorsSchema),
  updatedAt: z.string().nullish(),
  updatedBy: userSchema.nullish(),
  timelineEvents: arraySchema(commentSchema),
  isVoided: z.boolean(),
  appliedCardTransaction: cardTransactionSimpleSchema,
  hasUnmatchedCardTransactions: z.boolean().nullish(),
});

export const billPaymentV2Schema = z.object({
  id: idSchema,
  account: z.string().url(),
  url: z.string().url(),
  docNumber: z.string(),
  status: z.string(),
  customerCard: customerCardSchema.nullish(),
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  hasUnmatchedCardTransactions: z.boolean(),
  isVoided: z.boolean(),
  syncErrors: arraySchema(errorSchema),
  timelineEvents: arraySchema(commentSchema),
  updatedAt: z.string().nullish(),
  vendor: vendorSchema,
  vendorBankAccount: vendorBankAccountSchema.nullish(),
  processPrerequisites: arraySchema(processPrerequisiteSchema),
  paymentSchedule: paymentScheduledSchema,
  paymentMethod: paymentMethodEnumSchema,
  paymentErrors: arraySchema(paymentErrorsSchema),
  lienWaivers: arraySchema(lienWaiverSchema),
  bills: arraySchema(
    z.object({
      bill: billSimpleSchema,
      appliedAmount: z.string(),
      openBalance: z.string(),
      customers: arraySchema(customerSimpleSchema),
    })
  ),
  appliedCardTransaction: cardTransactionSimpleSchema,
  appliedAmount: z.string(),
  totalAmount: z.string(),
  createdIn: z.string(),
  transactionDate: dateSchema,
});

const plaidAccountOwnerV1Schema = z.object({
  id: idSchema,
  url: z.string(),
  accountBalance: z.string(),
  accountOwner: z.string().nullish(),
  user: userSchema.nullish(),
  paymentAccount: accountSchema.nullish(),
});

export const accountBalanceV1Schema = z.object({
  id: idSchema,
  url: z.string(),
  name: z.string(),
  balance: z.string(),
  currency: z.string(),
  type: z.string(),
  mask: z.string().nullish(),
  plaidAccountId: z.string(),
  paymentAccount: accountSchema,
  bankName: z.string(),
  verificationStatus: z.string(),
  users: arraySchema(userSchema).nullish(),
  plaidAccountOwners: arraySchema(plaidAccountOwnerV1Schema).nullish(),
  checkbookAccountIsActive: z.boolean(),
});

export const cancelBillPaymentResponseSchema = z.object({
  id: z.string(),
  account: z.null(),
  accountBalance: accountBalanceV1Schema,
  internalMethod: z.string(),
  createdAt: z.string(),
  externalMethod: z.null(),
  status: z.string(),
  totalAmount: z.string(),
  vendor: z.string(),
  vendorAddress: z.string().nullish(),
  vendorBankingAch: z.string().nullish(),
  vendorEmail: z.string(),
  vendorPhoneNumber: z.string().nullish(),
});
