import React, { memo, useEffect } from "react";
import {
  Disclosure,
  useDisclosureContext,
  useStoreState,
} from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

const DEFAULT_COMPONENT = "div";

import { suffixify } from "../../utils/suffixify";
import { Icon } from "../icon";

import { useCardContext } from "./card-context";
import styles from "./card.module.css";

type CardHeaderProps = {
  "data-testid"?: string;
};

const CardHeader = forwardRefAs<typeof DEFAULT_COMPONENT, CardHeaderProps>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      children,
      className,
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const disclosureContext = useDisclosureContext()!;

    const expanded = useStoreState(disclosureContext, "open");

    const { setHasHeader } = useCardContext();

    const hasContent = !!useStoreState(disclosureContext, "contentElement");

    useEffect(() => {
      setHasHeader(true);

      return () => setHasHeader(false);
    }, [setHasHeader]);

    return (
      <Component
        ref={ref}
        className={cn(styles["card-header"], className, {
          [styles["-expandable"]]: hasContent,
        })}
        {...props}
      >
        {hasContent && (
          <Disclosure
            className={styles["button"]}
            data-testid={suffixify(testId, "button")}
          />
        )}
        <div className={styles["content"]}>{children}</div>
        {hasContent && (
          <Icon
            name={expanded ? "chevron-up" : "chevron-down"}
            className={styles["icon"]}
          />
        )}
      </Component>
    );
  }
);

CardHeader.displayName = "CardHeader";

const MemoizedCardHeader = memo(CardHeader) as typeof CardHeader;

export { MemoizedCardHeader as CardHeader };
