import React, { type ReactElement, useMemo } from "react";
import { Flex, Tag, Text } from "@adaptive/design-system";
import { formatCurrency, suffixify } from "@adaptive/design-system/utils";
import type {
  Workflow,
  WorkflowCustomer,
  WorkflowItem,
  WorkflowVendor,
} from "@shared/api/workflows";
import { useWorkflowInfo } from "@shared/store/workflow";

type Props = {
  workflow: Workflow;
};

const getJobsTitles = (jobs: WorkflowCustomer[]) =>
  jobs.reduce((titles, job) => {
    return [...titles, <Tag key={`job-${job.id}`}>{job.displayName}</Tag>];
  }, [] as ReactElement[]);

const getCostCodesTitles = (costCodes: WorkflowItem[]) =>
  costCodes.reduce(
    (titles, costCode) => [
      ...titles,
      <Tag key={`cost-code-${costCode.id}`}>{costCode.displayName}</Tag>,
    ],
    [] as ReactElement[]
  );

const getVendorTitles = (vendors: WorkflowVendor[]) =>
  vendors.reduce(
    (titles, vendor) => [
      ...titles,
      <Tag key={suffixify("vendor", vendor.id)}>{vendor.displayName}</Tag>,
    ],
    [] as ReactElement[]
  );

export const WorkflowTitle = ({ workflow }: Props) => {
  const {
    hasAmountCondition,
    hasCostCodeCondition,
    hasJobCondition,
    hasVendorCondition,
  } = useWorkflowInfo();

  const workflowConditions = useMemo(() => {
    const tags = [];

    if (hasAmountCondition(workflow)) {
      if (!!workflow.amountFrom && !!workflow.amountTo) {
        tags.push(
          `the amount is between $${formatCurrency(
            workflow.amountFrom
          )} - $${formatCurrency(workflow.amountTo)}`
        );
      } else if (workflow.amountFrom) {
        tags.push(
          `the amount is greater than $${formatCurrency(workflow.amountFrom)}`
        );
      } else if (workflow.amountTo) {
        tags.push(
          `the amount is lower than $${formatCurrency(workflow.amountTo)}`
        );
      }
    }

    if (hasJobCondition(workflow)) {
      if (tags.length) tags.push(" and ");
      if (
        workflow.customerRequirementType &&
        ["ONE_OF", "NONE_OF"].includes(workflow.customerRequirementType)
      ) {
        const jobConditionText =
          workflow.customerRequirementType === "ONE_OF" ? "one of" : "none of";
        const jobText =
          workflow.customers.length > 1
            ? `the job is ${jobConditionText}`
            : `the job is ${workflow.customerRequirementType === "ONE_OF" ? "" : "not "}`;
        tags.push(jobText);
      }
      tags.push(...getJobsTitles(workflow.customers));
    }

    if (hasCostCodeCondition(workflow)) {
      if (tags.length) tags.push(" and ");
      tags.push(
        workflow.items.length > 1
          ? "the cost code is one of"
          : "the cost code is"
      );
      tags.push(...getCostCodesTitles(workflow.items));
    }

    if (hasVendorCondition(workflow)) {
      if (tags.length) tags.push(" and ");
      tags.push(
        workflow.vendors.length > 1 ? "the vendor is one of" : "the vendor is"
      );
      tags.push(...getVendorTitles(workflow.vendors));
    }

    if (workflow.customerRequirementType === "ANY_JOB") {
      if (tags.length) tags.push(" and ");
      tags.push("there is at least one job");
    }

    if (workflow.customerRequirementType === "NO_JOB") {
      if (tags.length) tags.push(" and ");
      tags.push("there is no job");
    }

    return tags;
  }, [
    hasAmountCondition,
    hasCostCodeCondition,
    hasJobCondition,
    hasVendorCondition,
    workflow,
  ]);

  return (
    <Flex gap="sm" wrap align="center" as={Text} size="md" weight="bold">
      If {workflowConditions}
    </Flex>
  );
};
