import { createContext } from "react";
import { createFormContext } from "@adaptive/design-system/hooks";

import type { PropagationContextValues, PropagationFormValues } from "../types";

export const PropagationContext = createContext<
  PropagationContextValues | undefined
>(undefined);

export const FormContext = createFormContext<PropagationFormValues>();
