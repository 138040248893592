import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  DateField,
  dialog,
  Dropdown,
  DropdownList,
  DropdownSelectableItem,
  type DropdownSelectableItemSingleOnChangeHandler,
  DropdownTrigger,
  Flex,
  Icon,
  TableConfigurationButton,
  TableProvider,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  Text,
  Textarea,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDeepMemo, useEvent, useForm } from "@adaptive/design-system/hooks";
import { dotObject, isEqual, omit } from "@adaptive/design-system/utils";
import {
  type InitiateDownloadInvoiceFilesPayload,
  type InvoiceUpdatePayload,
  useInitiateDownloadInvoiceFilesMutation,
  useLazyDownloadInvoiceFilesQuery,
} from "@api/invoices";
import { invoiceStatusSchema } from "@api/invoices/response";
import type { Workflow } from "@api/workflows";
import { Comments, type OnAddCommentHandler } from "@components/comments";
import { ErrorAlert } from "@components/common/error-alert";
import { TooManyLinesAlert } from "@components/common/too-many-lines-alert";
import { CounterLabel } from "@components/counter-label";
import { CustomersComboBox } from "@components/customers-combobox";
import {
  DownloadButton,
  type DownloadButtonProps,
  type OnDownloadHandler,
} from "@components/download-button";
import {
  Main,
  MainContent,
  MainFooter,
  MainHeader,
  MainHeaderBack,
  MainTitle,
} from "@components/main";
import { QuickBooksSyncedIcon } from "@components/quickbooks-icon";
import {
  StageNavigation,
  type StageNavigationProps,
} from "@components/stage-navigation";
import { Sticky, StickyMeasurer, StickyProvider } from "@components/sticky";
import { useIntegrationType } from "@hooks/use-integration-type";
import { ApprovalsSection } from "@src/bills/components/approvals-section";
import { INVOICE_STRINGS, useJobPermissions, useJobSettings } from "@src/jobs";
import { useInvoiceSettings } from "@src/jobs/invoice";
import { useClientSettings, useUserInfo } from "@store/user";
import { INVOICE_LINE_PROPAGATION_FIELDS } from "@transaction-propagation/constants";
import { usePropagationDialog } from "@transaction-propagation/hooks";
import { getLinesInfos, normalizeFields } from "@transaction-propagation/utils";
import * as analytics from "@utils/analytics";
import { isAccount } from "@utils/is-account";
import { scrollMainTop } from "@utils/scroll-main-top";
import isEqualDate from "date-fns/isEqual";
import { z } from "zod";

import {
  useInvoice,
  useInvoiceActions,
  useInvoiceLines,
  useInvoiceLinesIsLoading,
  useInvoiceMarkups,
  useInvoiceMarkupsIsLoading,
  useInvoicePermissions,
} from "./invoice-context";
import {
  DEFAULT_DRAW_SCHEDULE_SORT_BY,
  DEFAULT_DRAW_SCHEDULE_VIEW_MODE,
  DEFAULT_LINE_ITEMS_SORT_BY,
  InvoiceFormContext,
  useInvoiceFormDrawScheduleViewMode,
  useInvoiceFormMode,
  useInvoiceFormSetDrawScheduleSortBy,
  useInvoiceFormSetDrawScheduleViewMode,
} from "./invoice-form-context";
import { transformInvoiceGetToInvoicePayload } from "./utils";
import {
  InvoiceActionButton,
  InvoiceAddEmptyLineButton,
  InvoiceAddLineButton,
  InvoiceAddMarkupButton,
  InvoiceCostTable,
  InvoiceDynamicActions,
  InvoiceRejectButton,
  InvoiceSummary,
} from ".";

type StageNavigationOnChangeHandler = Exclude<
  StageNavigationProps["onChange"],
  undefined
>;

const getStages = (
  reviewStatus: "Paid" | "Draft" | "Approved" | "For Approval"
): StageNavigationProps["data"] => [
  {
    name: "Draft",
    title: "Create",
    subtitle: `Finalize the details of the ${INVOICE_STRINGS.LOWERCASE_INVOICE}`,
    disabled: reviewStatus === "Paid" ? "The draw is already paid" : "",
  },
  {
    name: "For Approval",
    title: "Sync to QuickBooks",
    subtitle: "Create a QuickBooks invoice",
  },
];

const STRINGS = {
  HAS_APPROVED: "You have already approved this draw",
  NO_PERMISSION: "Sorry, you don't have permission to perform this action",
  MISSING_APPROVERS_MODAL:
    "Other people are supposed to review the transaction before it can be approved.",
  MISSING_APPROVERS_TOAST:
    "Other people on the approval chain still need to review this draw",
  HAS_NO_LINES_DOWNLOAD_TOAST: "You can't export an empty draw",
};

const INITIAL_TYPE_V1 = {
  export_summary: true,
  export_summary_xlsx: true,
};

const InvoiceDownloadButton = memo(
  ({
    size,
    variant,
    onBeforeClick,
    lineItemsSortBy,
    drawScheduleSortBy,
  }: Pick<DownloadButtonProps, "size" | "variant" | "onBeforeClick"> & {
    drawScheduleSortBy: string;
    lineItemsSortBy: string;
  }) => {
    const lines = useInvoiceLines();

    const invoice = useInvoice(["id", "customer", "reviewStatus"]);

    const [downloadFiles] = useLazyDownloadInvoiceFilesQuery();

    const [initiateDownloadFiles] = useInitiateDownloadInvoiceFilesMutation();

    const { drawExportsV2Enabled } = useClientSettings();

    const { categoriesEnabled, changeTrackingEnabled } = useJobSettings();

    const hasNoLines = lines.length === 0;

    const downloadData = useMemo<DownloadButtonProps["type"]>(() => {
      if (drawExportsV2Enabled) {
        return [
          {
            label: "Draw package (PDF)",
            value: "draw_package",
            data: [
              {
                label: "Cover page",
                value: "cover_page",
              },
              {
                label: "Invoice (no subtotals)",
                value: "draw_request_no_subtotals",
              },
              {
                label: "Invoice (subtotaled by vendor)",
                value: "draw_request_subtotaled_vendor",
              },
              ...(categoriesEnabled
                ? [
                    {
                      label: "Invoice (by category)",
                      value: "draw_request_by_category",
                    },
                  ]
                : []),
              {
                label: "Budget",
                value: "draw_schedule",
              },
              ...(changeTrackingEnabled
                ? [
                    {
                      label: "Changes",
                      value: "changes",
                    },
                  ]
                : []),
              {
                label: "Transaction backup",
                value: "transaction_backup",
              },
            ],
          },
          {
            label: "Draw package (XLSX)",
            value: "draw_package_xlsx",
          },
          {
            label: "Transaction backup images (ZIP)",
            value: "transaction_backup_images_zip",
          },
        ];
      }

      return [
        {
          label: "Application for payment (PDF)",
          value: "export_summary",
        },
        {
          label: "Application for payment (XLSX)",
          value: "export_summary_xlsx",
        },
        ...(categoriesEnabled
          ? [
              {
                label: "Application for payment - By category (PDF)",
                value: "export_summary_category",
              },
            ]
          : []),
        {
          label: "Backup (PDF)",
          value: "export_pdf",
        },
        {
          label: "Backup (ZIP)",
          value: "export_zip",
        },
      ];
    }, [categoriesEnabled, changeTrackingEnabled, drawExportsV2Enabled]);

    const initialTypeV2 = useMemo(
      () => ({
        draw_package: true,
        cover_page: true,
        draw_request_no_subtotals: true,
        draw_request_subtotaled_vendor: false,
        draw_request_by_category: false,
        draw_schedule: true,
        changes: changeTrackingEnabled,
        transaction_backup: true,
        draw_package_xlsx: true,
        transaction_backup_images_zip: false,
      }),
      [changeTrackingEnabled]
    );

    const onDownloadV1 = useEvent<OnDownloadHandler>(({ params }) => {
      const body = params.toString();

      analytics.track("invoiceDownload", {
        jobId: invoice.customer.id,
        params: body,
        version: 1,
        invoiceId: invoice.id,
      });

      return downloadFiles({ id: invoice.id, params: body }).unwrap();
    });

    const onDownloadV2 = useEvent<OnDownloadHandler>(({ params }) => {
      const data = Object.fromEntries(params);

      const body = Object.keys(data).reduce(
        (acc, key) => ({ ...acc, [key]: data[key] === "true" }),
        {}
      ) as InitiateDownloadInvoiceFilesPayload["body"];

      analytics.track("invoiceDownload", {
        jobId: invoice.customer.id,
        params: body,
        version: 2,
        invoiceId: invoice.id,
      });

      return initiateDownloadFiles({
        id: invoice.id,
        body: {
          ...body,
          sort_draw_schedule_by: drawScheduleSortBy,
          sort_line_items_by: lineItemsSortBy,
        },
      }).unwrap();
    });

    const mode = useMemo(
      () => ({
        all: { enabled: true, children: "Export draw" },
        selection: { enabled: false },
      }),
      []
    );

    return (
      <Tooltip message={hasNoLines ? STRINGS.HAS_NO_LINES_DOWNLOAD_TOAST : ""}>
        <DownloadButton
          mode={mode}
          size={size}
          type={downloadData}
          variant={variant}
          disabled={hasNoLines}
          onDownload={drawExportsV2Enabled ? onDownloadV2 : onDownloadV1}
          data-testid="invoice"
          initialType={drawExportsV2Enabled ? initialTypeV2 : INITIAL_TYPE_V1}
          onBeforeClick={onBeforeClick}
        />
      </Tooltip>
    );
  }
);

InvoiceDownloadButton.displayName = "InvoiceDownloadButton";

const DRAW_SCHEDULE_VIEW_MODE_DATA = [
  { label: "Items", value: "jobCostMethod" },
  {
    label: (
      <Flex gap="sm" align="center">
        Category
        <Tooltip
          as={Icon}
          name="info-circle"
          size="sm"
          message="You cannot directly edit the price of a Category. View by Item to draw for a fixed amount"
        />
      </Flex>
    ),
    value: "categories",
  },
];

const AfterTabs = ({ value }: { value: string }) => {
  const mode = useInvoiceFormMode();

  const invoiceLines = useInvoiceLines();

  const { categoriesEnabled } = useJobSettings();

  const drawScheduleViewMode = useInvoiceFormDrawScheduleViewMode();

  const setDrawScheduleSortBy = useInvoiceFormSetDrawScheduleSortBy();

  const setDrawScheduleViewMode = useInvoiceFormSetDrawScheduleViewMode();

  const viewModeLabel = useMemo(
    () =>
      DRAW_SCHEDULE_VIEW_MODE_DATA.find(
        (item) => item.value === drawScheduleViewMode
      )?.label,
    [drawScheduleViewMode]
  );

  const onViewModeChange =
    useEvent<DropdownSelectableItemSingleOnChangeHandler>((value) => {
      setDrawScheduleSortBy(value);
      setDrawScheduleViewMode(value);
    });

  return (
    <Flex grow gap="md" justify="flex-end">
      {value === "draw-schedule" && (
        <Flex gap="xl">
          {categoriesEnabled && (
            <Dropdown>
              <DropdownTrigger animated>
                View by {viewModeLabel}
              </DropdownTrigger>
              <DropdownList>
                <DropdownSelectableItem
                  name="draw-schedule-view-mode"
                  data={DRAW_SCHEDULE_VIEW_MODE_DATA}
                  value={drawScheduleViewMode}
                  onChange={onViewModeChange}
                />
              </DropdownList>
            </Dropdown>
          )}
          <TableConfigurationButton size="sm" />
        </Flex>
      )}
      {mode === "edit" && (
        <>
          <InvoiceAddLineButton variant="solid">Add costs</InvoiceAddLineButton>
          <InvoiceAddEmptyLineButton variant="solid">
            Add line
          </InvoiceAddEmptyLineButton>
          {invoiceLines.length > 0 && (
            <InvoiceAddMarkupButton variant="solid" />
          )}
          <InvoiceActionButton variant="solid" />
        </>
      )}
    </Flex>
  );
};

const LineItemsTabLabel = memo(({ active }: { active?: boolean }) => {
  const invoiceLines = useInvoiceLines();

  const invoiceMarkups = useInvoiceMarkups();

  const invoiceLinesIsLoading = useInvoiceLinesIsLoading();

  const invoiceMarkupsIsLoading = useInvoiceMarkupsIsLoading();

  const counter = invoiceLines.length + invoiceMarkups.length;

  const isLoading =
    counter === 0 && (invoiceLinesIsLoading || invoiceMarkupsIsLoading);

  return (
    <CounterLabel
      label="Line items"
      active={active}
      counter={counter}
      loading={isLoading}
    />
  );
});

LineItemsTabLabel.displayName = "LineItemsTabLabel";

export const InvoiceForm = memo(() => {
  const invoice = useInvoice();

  const [searchParams, setSearchParams] = useSearchParams({
    status: "draw-schedule",
  });

  const tab = searchParams.get("status") ?? "draw-schedule";

  const navigate = useNavigate();

  const location = useLocation();

  const { canManage, canSyncJobs } = useJobPermissions();

  const { role, user } = useUserInfo();

  const { categoriesEnabled } = useJobSettings();

  const [lineItemsSortBy, setLineItemsSortBy] = useState("");

  const [drawScheduleSortBy, setDrawScheduleSortBy] = useState("");

  const [drawScheduleViewMode, setDrawScheduleViewMode] = useState(
    DEFAULT_DRAW_SCHEDULE_VIEW_MODE
  );

  const {
    updateInvoice,
    refetchInvoice,
    evaluateInvoice,
    updateInvoiceInfo,
    addInvoiceComment,
    updateInvoiceLines,
  } = useInvoiceActions();

  const invoiceLines = useInvoiceLines();

  const initialInvoiceValues = useDeepMemo(
    () => transformInvoiceGetToInvoicePayload(invoice),
    [invoice]
  );

  const [invoiceLinesBeforeSync, setInvoiceLinesBeforeSync] =
    useState(invoiceLines);

  const { canApproveInvoices, canBypassApprovalWorkflows } =
    useInvoicePermissions();

  const integrationType = useIntegrationType();

  const schema = useMemo(() => {
    const maxDocNumberLength =
      integrationType === "QBDT"
        ? window.QBDT_REF_NUMBER_MAX_LENGTH
        : window.QBO_REF_NUMBER_MAX_LENGTH;

    return z.object({
      date: z.date().nullable(),
      dueDate: z.date().nullable(),
      docNumber: z
        .string()
        .min(1, `${INVOICE_STRINGS.INVOICE} # is required`)
        .max(
          maxDocNumberLength,
          `${INVOICE_STRINGS.INVOICE} # should have no more than ${maxDocNumberLength} chars`
        ),
      description: z.string(),
      reviewStatus: invoiceStatusSchema,
    });
  }, [integrationType]);

  const {
    submit: submitInvoiceForm,
    setValues: setInvoiceFormValues,
    ...invoiceForm
  } = useForm<InvoiceUpdatePayload>({
    schema,
    onSubmit: async (values) => {
      await updateInvoiceInfo(values);
      toast.success(`${INVOICE_STRINGS.INVOICE} updated`);
    },
    initialValues: initialInvoiceValues,
  });

  const mode =
    invoiceForm.values.reviewStatus === "Paid" && !invoice.errors.length
      ? "view"
      : "edit";

  const selectedCustomer = useDeepMemo(
    () => ({
      label: invoice.customer.displayNameWithoutCompany,
      value: invoice.customer.id,
    }),
    [invoice.customer]
  );

  const commentsSelector = useDeepMemo(
    () => ({ url: invoice.url, comments: invoice.comments }),
    [invoice.url, invoice.comments]
  );

  const { isApproved, isApprover, alreadyEvaluated, canBypass } = {
    isApproved: invoice.isApproved,
    isApprover: invoice.approvalWorkflows.length
      ? invoice.canApprove
      : canApproveInvoices,
    canBypass: canBypassApprovalWorkflows,
    alreadyEvaluated: invoice.isApprovalEvaluated,
  };

  const isUserInWorkflow = useMemo(() => {
    const approvers = invoice.approvalWorkflows.flatMap((workflow) =>
      workflow.steps.flatMap((step) => step.approvers)
    );

    return approvers.some(
      (approver) =>
        (approver.type === "role" && approver.name === role?.name) ||
        (approver.type === "user" && approver.id === user.id)
    );
  }, [invoice.approvalWorkflows, user.id, role?.name]);

  const bypassActive =
    ((canBypass &&
      isUserInWorkflow &&
      invoice.isApprovalEvaluated &&
      invoice.approvalWorkflows.length) ||
      (canBypass && !isUserInWorkflow && invoice.approvalWorkflows.length)) &&
    window.WORKFLOW_APPROVAL_DRAWS_ENABLED;

  const isLastApprover = useMemo(
    () =>
      invoice.approvalWorkflows.some((workflow) => {
        const [missingStep, ...othersMissingSteps] = workflow.steps.filter(
          (step) =>
            !(
              (step.requirementType === "ONE_OF" &&
                step.approvers.some(
                  (approver) => approver.status === "APPROVED"
                )) ||
              (step.requirementType === "ALL_OF" &&
                step.approvers.every(
                  (approver) => approver.status === "APPROVED"
                ))
            )
        );

        if (!missingStep || othersMissingSteps.length > 0) return false;

        const userApprover = missingStep.approvers.find(
          (approver) =>
            (approver.type === "role" && role?.id.toString() === approver.id) ||
            (approver.type === "user" && approver.id === user.id)
        );

        if (missingStep.requirementType === "ONE_OF" && !!userApprover) {
          return true;
        }

        const isAlreadyApprovedByOthers = missingStep.approvers
          .filter((approver) => userApprover?.id !== approver.id)
          .every((approver) => approver.status === "APPROVED");

        return (
          missingStep.requirementType === "ALL_OF" &&
          !!userApprover &&
          isAlreadyApprovedByOthers
        );
      }),
    [invoice.approvalWorkflows, role?.id, user.id]
  );

  const canEvaluate =
    (isApprover && !alreadyEvaluated && !isApproved) ||
    (canBypass && !isApproved);

  const enhancedSetLineItemsSortBy = useCallback(
    (value: string) => {
      setLineItemsSortBy(value);
      localStorage.setItem(`invoice-lines-sorting-${invoice.id}`, value);
    },
    [invoice.id]
  );

  const enhancedSetDrawScheduleSortBy = useCallback(
    (value: string) => {
      setDrawScheduleSortBy(value);
      localStorage.setItem(
        `invoice-draw-schedule-sorting-${invoice.id}`,
        value
      );
    },
    [invoice.id]
  );

  const enhancedSetDrawScheduleViewMode = useCallback(
    (value: string) => {
      setDrawScheduleViewMode(value);
      localStorage.setItem(
        `invoice-draw-schedule-view-mode-${invoice.id}`,
        value
      );
    },
    [invoice.id]
  );

  const adjustPageScroll = useCallback(() => scrollMainTop(0), []);

  const onAddComment = useEvent<OnAddCommentHandler>(
    ({ user, text, id, url, parentCommentUrl, files }) => {
      addInvoiceComment({
        text,
        author: user,
        id,
        files,
        url,
        parentCommentUrl,
      });
    }
  );

  const onTabChange = useEvent(async (value: string) => {
    setSearchParams({ status: value });
  });

  const onBack = useEvent(() =>
    navigate(
      dotObject.get(
        location,
        "state.prev",
        `/jobs/${invoice.customer.id}?status=invoice`
      )
    )
  );

  const onReview = useEvent(async () => {
    if (!invoiceForm.validate(true)) return;

    if (invoiceForm.values.lines.length === 0) {
      return toast.error(
        `${INVOICE_STRINGS.INVOICE} must have at least one line`
      );
    }

    await onBeforeClick();

    await updateInvoice({ reviewStatus: "For Approval" });

    analytics.track("invoiceSyncToQuickBooks", {
      invoiceId: invoice.id,
      jobId: invoice.customer.id,
    });

    adjustPageScroll();
  });

  const onEdit = useEvent(() => {
    invoiceForm.setValue("reviewStatus", "Draft");
  });

  const onStageChange = useEvent<StageNavigationOnChangeHandler>((stage) => {
    invoiceForm.setValue("reviewStatus", stage);
    adjustPageScroll();
  });

  const showBypassDialog = useCallback(
    (onConfirm: () => void | Promise<void>) => {
      dialog.confirmation({
        title: "Bypass others approvers",
        message: STRINGS.MISSING_APPROVERS_MODAL,
        action: {
          primary: {
            onClick: onConfirm,
            children: "Bypass other approvals",
          },
        },
      });
    },
    []
  );

  const showSetDefaultTitleOrDescriptionDialog = useCallback(
    (onConfirm: () => void | Promise<void>, field: "title" | "description") => {
      dialog.confirmation({
        size: "md",
        title: `Set as default ${field} for this job?`,
        message: `Would you like to set this ${field} as the default on all draws for this job? You can edit this any time from the Draws tab.`,
        action: {
          primary: {
            onClick: onConfirm,
            children: "Yes, set as default",
          },
          secondary: {
            children: "No",
          },
        },
      });
    },
    []
  );

  const { updateInvoiceSettings } = useInvoiceSettings();

  const curriedOnFieldBlur = useCallback(
    (name: string) => () => {
      const previousValue = dotObject.get(initialInvoiceValues, name);
      const currentValue = dotObject.get(invoiceForm.values, name);
      const isDirty = previousValue !== currentValue;

      if (isDirty && invoiceForm.isValid) {
        submitInvoiceForm();

        if (name === "title" || name === "description") {
          showSetDefaultTitleOrDescriptionDialog(() => {
            updateInvoiceSettings({
              [name === "title" ? "defaultTitle" : "defaultDescription"]:
                currentValue,
            });
          }, name);
        }
      }
    },
    [
      initialInvoiceValues,
      invoiceForm.values,
      invoiceForm.isValid,
      submitInvoiceForm,
      showSetDefaultTitleOrDescriptionDialog,
      updateInvoiceSettings,
    ]
  );

  const curriedOnDateChange = useCallback(
    (name: string) => (value: Date | null) => {
      if (!value) return submitInvoiceForm();

      const isDirty = !isEqualDate(
        dotObject.get(initialInvoiceValues, name),
        value
      );

      if (isDirty && invoiceForm.isValid) submitInvoiceForm();
    },
    [initialInvoiceValues, invoiceForm.isValid, submitInvoiceForm]
  );

  const onApprove = useEvent(async () => {
    if (!isLastApprover) toast.warning(STRINGS.MISSING_APPROVERS_TOAST);

    await evaluateInvoice({ status: "APPROVED" });
    toast.success(`${INVOICE_STRINGS.INVOICE} approved`);
  });

  const onByPassApproval = useEvent(async () => {
    showBypassDialog(async () => {
      await evaluateInvoice({ status: "APPROVED", bypass: true });
      toast.success(`${INVOICE_STRINGS.INVOICE} approved`);
    });
  });

  const { saveWithPropagation, propagationDialog } = usePropagationDialog();

  const syncTransactions = useCallback(
    async (sync: boolean) => {
      if (!sync) return setInvoiceLinesBeforeSync(invoiceLines);

      const normalizedInvoiceLines = invoiceLines.map((line) => {
        const jobCostMethodUrl = dotObject.get(line, "jobCostMethod.url");
        const isAccountJobCostMethod = isAccount(jobCostMethodUrl);
        const account = isAccountJobCostMethod ? jobCostMethodUrl : undefined;
        const item = !isAccountJobCostMethod ? jobCostMethodUrl : undefined;

        return normalizeFields({
          ...line,
          item,
          name: invoice.docNumber,
          account,
        });
      });

      const normalizedInvoiceLinesBeforeSync = invoiceLinesBeforeSync.map(
        (line) => {
          const jobCostMethodUrl = dotObject.get(line, "jobCostMethod.url");
          const isAccountJobCostMethod = isAccount(jobCostMethodUrl);
          const account = isAccountJobCostMethod ? jobCostMethodUrl : undefined;
          const item = !isAccountJobCostMethod ? jobCostMethodUrl : undefined;

          return normalizeFields({ ...line, account, item });
        }
      );

      const linesWithChanges = normalizedInvoiceLines.filter((line) => {
        const refLine = normalizedInvoiceLinesBeforeSync.find(
          (ref) => "id" in ref && "id" in line && ref.id == line.id
        );

        if (!refLine) return false;

        return INVOICE_LINE_PROPAGATION_FIELDS.some(
          (key) =>
            dotObject.get(refLine, key, null) != dotObject.get(line, key, null)
        );
      });

      const parsedInvoiceId = Number(invoice.id);

      const linesInfosParams = {
        lines: linesWithChanges,
        parentId: parsedInvoiceId,
        staticLines: normalizedInvoiceLinesBeforeSync,
      };

      const linesInfos = getLinesInfos(linesInfosParams);

      try {
        const { propagate, toastMessage } =
          (await saveWithPropagation({
            id: parsedInvoiceId,
            type: "invoice",
            lines: linesInfos?.lines,
            values: {
              url: invoice.url,
              name: invoice.docNumber,
              lines: linesInfos?.values,
            },
          })) ?? {};

        if (propagate?.lines?.length || propagate?.fields?.length) {
          await updateInvoiceLines(invoiceLines, propagate);

          if (toastMessage) toast.success(toastMessage);
        }

        setInvoiceLinesBeforeSync(invoiceLines);
      } catch {
        // do nothing
      }
    },
    [
      invoice.id,
      invoice.url,
      invoiceLines,
      invoice.docNumber,
      updateInvoiceLines,
      saveWithPropagation,
      invoiceLinesBeforeSync,
    ]
  );

  const onSync = useEvent(() => syncTransactions(true));

  const onDoNotSync = useEvent(() => syncTransactions(false));

  const showSyncDialog = useCallback(
    () =>
      new Promise<void>((resolve) => {
        const dialogId = dialog.confirmation({
          title: "You edited a line item linked to an\n underlying cost",
          message:
            "By default, draw line edits won't update the linked\n  costs. Would you like to push the edits to the\n costs or keep the current changes as is?",
          action: {
            primary: {
              onClick: async () => {
                try {
                  await onSync();
                  resolve();
                  dialog.hide(dialogId);
                } catch {
                  // do nothing
                }
              },
              autoHide: false,
              children: "Update costs",
            },
            secondary: {
              onClick: async () => {
                await onDoNotSync();
                resolve();
              },
              children: "Don't update costs",
            },
          },
        });
      }),
    [onDoNotSync, onSync]
  );

  const isSyncBannerActive = useMemo(() => {
    const omitFields: any = [
      "item",
      "amount",
      "isExtra",
      "itemAccount",
      "transactionLine",
    ];

    const currentLines = invoiceLines
      .filter((line) => line.transactionLine)
      .map((line) => normalizeFields(omit(line, omitFields)));

    const previousLines = invoiceLinesBeforeSync
      .filter(
        (line) =>
          currentLines.some((otherLine) => otherLine.id == line.id) &&
          "transactionLine" in line &&
          line.transactionLine
      )
      .map((line) => normalizeFields(omit(line, omitFields)));

    if (!currentLines.length || !previousLines.length) return false;

    return !isEqual(previousLines, currentLines);
  }, [invoiceLines, invoiceLinesBeforeSync]);

  const onBeforeClick = useEvent(async () => {
    if (isSyncBannerActive) return showSyncDialog();
  });

  useEffect(() => {
    const hasAllLinesOnLinesBeforeSync = invoiceLines.every((line) =>
      invoiceLinesBeforeSync.some((refLine) => refLine.id == line.id)
    );

    const hasAllLinesBeforeSyncOnLines = invoiceLinesBeforeSync.every(
      (refLine) => invoiceLines.some((line) => refLine.id == line.id)
    );

    if (hasAllLinesOnLinesBeforeSync && hasAllLinesBeforeSyncOnLines) return;

    setInvoiceLinesBeforeSync(invoiceLines);
  }, [invoiceLines, invoiceLinesBeforeSync]);

  useEffect(() => {
    setInvoiceFormValues((values) => {
      const newValues = transformInvoiceGetToInvoicePayload(invoice);
      const hasChangedLines = !isEqual(values.lines, newValues.lines);
      const hasChangedMarkups = !isEqual(values.markups, newValues.markups);

      return hasChangedLines || hasChangedMarkups
        ? { ...values, lines: newValues.lines, markups: newValues.markups }
        : newValues;
    });
  }, [setInvoiceFormValues, invoice]);

  useEffect(() => {
    setLineItemsSortBy(
      window.IS_E2E
        ? DEFAULT_LINE_ITEMS_SORT_BY
        : (localStorage.getItem(`invoice-lines-sorting-${invoice.id}`) ??
            DEFAULT_LINE_ITEMS_SORT_BY)
    );
  }, [invoice.id]);

  useEffect(() => {
    const previousInvoiceDrawScheduleSortBy =
      localStorage.getItem(`invoice-draw-schedule-sorting-${invoice.id}`) ??
      DEFAULT_DRAW_SCHEDULE_SORT_BY;

    const previousInvoiceDrawScheduleViewMode =
      localStorage.getItem(`invoice-draw-schedule-view-mode-${invoice.id}`) ??
      DEFAULT_DRAW_SCHEDULE_VIEW_MODE;

    setDrawScheduleViewMode(
      window.IS_E2E
        ? DEFAULT_DRAW_SCHEDULE_SORT_BY
        : !categoriesEnabled &&
            previousInvoiceDrawScheduleViewMode === "categories"
          ? DEFAULT_DRAW_SCHEDULE_VIEW_MODE
          : previousInvoiceDrawScheduleViewMode
    );

    setDrawScheduleSortBy(
      window.IS_E2E
        ? DEFAULT_DRAW_SCHEDULE_SORT_BY
        : !categoriesEnabled &&
            previousInvoiceDrawScheduleSortBy === "categories"
          ? DEFAULT_DRAW_SCHEDULE_SORT_BY
          : previousInvoiceDrawScheduleSortBy
    );
  }, [invoice.id, categoriesEnabled]);

  return (
    <Main>
      <MainHeader variant="unspaced">
        <Flex height="full" gap="xl">
          <Flex
            grow
            gap="xl"
            height="full"
            shrink={false}
            align="center"
            padding={["none", "5xl", "none", "none"]}
          >
            <MainHeaderBack
              onClick={onBack}
              data-testid="invoice-back-button"
            />
            <Flex direction="column">
              <MainTitle>{`Create ${INVOICE_STRINGS.LOWERCASE_INVOICE}`}</MainTitle>
              <Text>{invoice.customer.displayName}</Text>
            </Flex>
          </Flex>
          <Flex grow>
            <StageNavigation
              data={getStages(invoice.reviewStatus)}
              value={
                invoiceForm.values.reviewStatus === "Draft"
                  ? "Draft"
                  : "For Approval"
              }
              initialValue={
                initialInvoiceValues.reviewStatus === "Draft"
                  ? "Draft"
                  : "For Approval"
              }
              onChange={onStageChange}
            />
          </Flex>
        </Flex>
      </MainHeader>
      <MainContent>
        <Flex gap="5xl" shrink={false} width="full" direction="column">
          <Flex gap="xl" direction="column">
            {(invoice.errors.length > 0 ||
              invoice.relatedErrors.length > 0) && (
              <ErrorAlert
                data={invoice.errors}
                relatedData={invoice.relatedErrors}
                onChange={refetchInvoice}
                objectType="Invoice"
              />
            )}

            <TooManyLinesAlert
              linesCount={invoice.linesCount}
              maxLines={window.MAX_INVOICE_LINES_PAGE_SIZE}
              transactionType="Draw"
            />

            <Alert variant="warning" show={isSyncBannerActive}>
              <AlertTitle>
                You edited a line item linked to an underlying cost
              </AlertTitle>
              <AlertContent as="div">
                <Flex direction="column" gap="xl">
                  <Text>
                    By default, draw line edit won&apos;t update the linked
                    costs. Click below to push the edits to the costs or keep
                    the current change as is.
                  </Text>
                  <Flex gap="xl">
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={onDoNotSync}
                      data-testid="invoice-do-not-sync"
                    >
                      Don&apos;t update costs
                    </Button>
                    <Button
                      size="sm"
                      onClick={onSync}
                      data-testid="invoice-sync-costs"
                    >
                      Update costs
                    </Button>
                  </Flex>
                </Flex>
              </AlertContent>
            </Alert>

            <Card>
              <CardHeader data-testid="invoice-details">
                <Flex width="full" justify="space-between">
                  <Flex align="center" gap="md">
                    <Text size="xl" weight="bold">
                      Export details
                    </Text>
                    {invoice.publishedToQuickbooks && <QuickBooksSyncedIcon />}
                  </Flex>

                  <InvoiceDownloadButton
                    variant="ghost"
                    onBeforeClick={onBeforeClick}
                    lineItemsSortBy={lineItemsSortBy}
                    drawScheduleSortBy={drawScheduleSortBy}
                  />
                </Flex>
              </CardHeader>
              <CardContent as="form" {...invoiceForm.props} hidden>
                <Flex gap="xl">
                  <CustomersComboBox value={selectedCustomer} disabled />
                  <DateField
                    label="Draw date"
                    portal
                    disabled={!canManage}
                    {...invoiceForm.register({
                      name: "date",
                      type: "date",
                      onChange: curriedOnDateChange("date"),
                    })}
                  />
                </Flex>
                <Flex gap="xl">
                  <TextField
                    label="Project name"
                    disabled={!canManage}
                    autoFocus
                    {...invoiceForm.register({
                      name: "toName",
                      type: "string",
                      onBlur: curriedOnFieldBlur("toName"),
                    })}
                  />
                  <DateField
                    label="Due date"
                    portal
                    disabled={!canManage}
                    {...invoiceForm.register({
                      name: "dueDate",
                      type: "date",
                      onChange: curriedOnDateChange("dueDate"),
                    })}
                  />
                </Flex>
                <Flex gap="xl" width="full">
                  <Flex direction="column" width="full">
                    <TextField
                      label="Export title"
                      disabled={!canManage}
                      {...invoiceForm.register({
                        name: "title",
                        type: "string",
                        onBlur: curriedOnFieldBlur("title"),
                      })}
                    />
                    <TextField
                      label="Draw #"
                      required
                      disabled={mode === "view" || !canManage}
                      messageVariant="absolute"
                      {...invoiceForm.register({
                        name: "docNumber",
                        type: "string",
                        onBlur: curriedOnFieldBlur("docNumber"),
                      })}
                    />
                  </Flex>
                  <Flex width="full">
                    <Textarea
                      label="Description"
                      disabled={!canManage}
                      minHeight={140}
                      maxHeight={140}
                      messageVariant="absolute"
                      {...invoiceForm.register({
                        name: "description",
                        type: "string",
                        onBlur: curriedOnFieldBlur("description"),
                      })}
                    />
                  </Flex>
                </Flex>
              </CardContent>
            </Card>

            <InvoiceFormContext.Provider
              value={{
                mode,
                lineItemsSortBy,
                drawScheduleSortBy,
                drawScheduleViewMode,
                setLineItemsSortBy: enhancedSetLineItemsSortBy,
                setDrawScheduleSortBy: enhancedSetDrawScheduleSortBy,
                setDrawScheduleViewMode: enhancedSetDrawScheduleViewMode,
              }}
            >
              <Flex shrink={false} direction="column">
                <TableProvider id={`${tab}-${invoice.customer.id}`}>
                  <StickyProvider>
                    <Tabs value={tab} onChange={onTabChange}>
                      <Sticky
                        style={{
                          paddingTop: "var(--spacing-xl)",
                          paddingBottom: "var(--spacing-2xl)",
                        }}
                      >
                        <TabsList>
                          <TabsTab value="draw-schedule">Draw schedule</TabsTab>
                          <TabsTab value="line-items">
                            <LineItemsTabLabel active={tab === "line-items"} />
                          </TabsTab>
                          <AfterTabs value={tab} />
                        </TabsList>
                      </Sticky>
                      <StickyMeasurer>
                        <TabsPanel as={Flex} direction="column">
                          {tab === "draw-schedule" && <InvoiceSummary />}
                          {tab === "line-items" && (
                            <Flex direction="column" gap="xl">
                              <InvoiceCostTable />
                            </Flex>
                          )}
                        </TabsPanel>
                      </StickyMeasurer>
                    </Tabs>
                  </StickyProvider>
                </TableProvider>
              </Flex>
            </InvoiceFormContext.Provider>

            {propagationDialog}
          </Flex>
          {window.WORKFLOW_APPROVAL_DRAWS_ENABLED ? (
            <ApprovalsSection
              objectId={parseInt(invoice.id, 10)}
              editable={["Draft", "For Approval"].includes(
                invoice.reviewStatus
              )}
              workflows={invoice.approvalWorkflows as unknown as Workflow[]}
              objectType="Invoice"
            />
          ) : null}

          <Comments
            selector={commentsSelector}
            onAddComment={canManage ? onAddComment : undefined}
          />
        </Flex>
      </MainContent>
      <MainFooter>
        <Flex justify="space-between">
          <InvoiceDynamicActions
            jobId={invoice.customer.id}
            reviewStatus={invoiceForm.values.reviewStatus}
            onEdit={onEdit}
          />

          <Flex gap="xl">
            {invoiceForm.values.reviewStatus === "Draft" && (
              <>
                <button
                  form={invoiceForm.id}
                  type="submit"
                  style={{ display: "none" }}
                  disabled={invoiceForm.isSubmitting || !canManage}
                />
                <InvoiceDownloadButton
                  size="lg"
                  variant="ghost"
                  onBeforeClick={onBeforeClick}
                  lineItemsSortBy={lineItemsSortBy}
                  drawScheduleSortBy={drawScheduleSortBy}
                />
                <Tooltip
                  message={
                    !canSyncJobs
                      ? "Sorry, you don't have permission to perform this action"
                      : ""
                  }
                >
                  <Button size="lg" onClick={onReview} disabled={!canSyncJobs}>
                    Sync to QuickBooks
                  </Button>
                </Tooltip>
              </>
            )}

            {["Approved", "Paid"].includes(invoiceForm.values.reviewStatus) && (
              <InvoiceDownloadButton
                size="lg"
                onBeforeClick={onBeforeClick}
                lineItemsSortBy={lineItemsSortBy}
                drawScheduleSortBy={drawScheduleSortBy}
              />
            )}

            {invoiceForm.values.reviewStatus === "For Approval" && (
              <>
                <Tooltip
                  message={
                    canEvaluate
                      ? undefined
                      : alreadyEvaluated
                        ? STRINGS.HAS_APPROVED
                        : STRINGS.NO_PERMISSION
                  }
                  placement="left"
                >
                  <InvoiceRejectButton disabled={!canEvaluate} />
                </Tooltip>

                {bypassActive && !isApproved ? (
                  <Button size="lg" onClick={onByPassApproval}>
                    Bypass Approval
                  </Button>
                ) : isApprover ? (
                  <Tooltip
                    message={
                      canEvaluate
                        ? undefined
                        : alreadyEvaluated
                          ? STRINGS.HAS_APPROVED
                          : STRINGS.NO_PERMISSION
                    }
                    placement="left"
                  >
                    <Button
                      size="lg"
                      onClick={onApprove}
                      disabled={!canEvaluate}
                    >
                      Approve
                    </Button>
                  </Tooltip>
                ) : null}
              </>
            )}
          </Flex>
        </Flex>
      </MainFooter>
    </Main>
  );
});

InvoiceForm.displayName = "InvoiceForm";
