import React, { type ComponentProps, memo, useCallback, useState } from "react";
import { useSearchParams } from "react-router";
import {
  Button,
  Flex,
  Icon,
  Loader,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { INVOICE_STRINGS } from "@src/jobs";
import { useJobPermissions } from "@src/jobs";
import * as analytics from "@utils/analytics";

import {
  useInvoice,
  useInvoiceActions,
  useInvoiceLines,
} from "./invoice-context";

type InvoiceAddEmptyLineButtonProps = Pick<
  ComponentProps<typeof Button>,
  "size" | "variant" | "block" | "children"
>;

export const InvoiceAddEmptyLineButton = memo(
  ({
    size = "sm",
    block,
    variant = "ghost",
    children = "Add empty line",
    ...props
  }: InvoiceAddEmptyLineButtonProps) => {
    const [, setSearchParams] = useSearchParams();

    const { id: invoiceId, customer } = useInvoice(["id", "customer"]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const invoiceLines = useInvoiceLines();

    const { canManage } = useJobPermissions();

    const { updateInvoiceLines } = useInvoiceActions();

    const enhancedIsSubmitting = invoiceLines.length === 0 && isSubmitting;

    const onClick = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await updateInvoiceLines([...invoiceLines, { isExtra: true }]);
      } finally {
        setIsSubmitting(false);
      }

      analytics.track("invoiceAddEmptyLine", {
        invoiceId,
        customerId: customer.id,
      });

      toast.success(
        `Empty line added to the ${INVOICE_STRINGS.LOWERCASE_INVOICE}`
      );

      setSearchParams({ status: "line-items" });
    }, [
      customer,
      invoiceId,
      invoiceLines,
      setSearchParams,
      updateInvoiceLines,
    ]);

    return (
      <Tooltip
        as={Flex}
        grow={block}
        width={block ? "full" : undefined}
        minWidth="81px"
        message={!canManage ? "You don't have permission to do this" : ""}
      >
        <Button
          size={size}
          block
          variant={variant}
          onClick={onClick}
          disabled={!canManage || enhancedIsSubmitting}
          {...props}
        >
          {enhancedIsSubmitting ? (
            <Loader />
          ) : (
            <>
              {variant === "ghost" && <Icon name="plus" />}
              {children}
            </>
          )}
        </Button>
      </Tooltip>
    );
  }
);

InvoiceAddEmptyLineButton.displayName = "InvoiceAddEmptyLineButton";
