import React, { memo } from "react";
import {
  type ResponsiveProp,
  useResponsiveProp,
} from "@adaptive/design-system/hooks";
import { forwardRefAs } from "@adaptive/design-system/utils";
import cn from "clsx";

import styles from "./main.module.css";

const DEFAULT_COMPONENT = "header";

type Props = {
  size?: ResponsiveProp<"xs" | "sm" | "md" | "lg">;
  variant?: ResponsiveProp<"spaced" | "unspaced">;
};

const MainHeader = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      size = "md",
      variant = "spaced",
      className,
      as: Component = DEFAULT_COMPONENT,
      ...props
    },
    ref
  ) => {
    const sizeValue = useResponsiveProp(size);
    const variantValue = useResponsiveProp(variant);

    return (
      <Component
        ref={ref}
        className={cn(className, styles["main-header"], {
          [styles[`-${sizeValue}`]]: sizeValue,
          [styles[`-${variantValue}`]]: variantValue,
        })}
        {...props}
      />
    );
  }
);

MainHeader.displayName = "MainHeader";

const MemoizedMainHeader = memo(MainHeader) as typeof MainHeader;

export { MemoizedMainHeader as MainHeader };
