import React, { useEffect } from "react";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  PasswordField,
} from "@adaptive/design-system";
import {
  useForm,
  type UseMultiStepDialogReturn,
} from "@adaptive/design-system/hooks";
import { PasswordRulesAlert } from "@components/password-rules-alert";
import { generatePasswordSchema } from "@utils/schema";
import { z } from "zod";

import { useChangePasswordMutation } from "../../../shared/hooks/useChangePasswordMutation";

import { type Step } from "./types";

type Props = { dialog: UseMultiStepDialogReturn<Step> };

type Fields = z.infer<typeof schema>;

const schema = z
  .object({
    old_password: z.string().min(1, "Your old password is required"),
    new_password: generatePasswordSchema(
      "Your password doesn't follow the rules"
    ),
    confirm_password: generatePasswordSchema(
      "Your confirm password doesn't follow the rules"
    ),
  })
  .refine((values) => values.new_password === values.confirm_password, {
    message: "These passwords don't match, try again",
    path: ["confirm_password"],
  });

const INITIAL_VALUES: Fields = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};

export const ProfileEditDialogPasswordForm = ({ dialog }: Props) => {
  const changePasswordMutation = useChangePasswordMutation();

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      await changePasswordMutation.mutate({
        new_password: values.new_password,
        old_password: values.old_password,
      });
    },
    initialValues: INITIAL_VALUES,
  });

  useEffect(() => {
    if (!dialog.isVisible && form.isDirty) form.reset();
  }, [dialog.isVisible, form]);

  return (
    <>
      <DialogHeader>Change password</DialogHeader>
      <DialogContent>
        <Flex gap="xl" direction="column">
          <form {...form.props}>
            <PasswordField
              label="Old password"
              required
              {...form.register("old_password")}
            />
            <PasswordField
              label="New password"
              required
              {...form.register("new_password")}
            />
            <PasswordField
              label="Repeat your password"
              required
              {...form.register("confirm_password")}
            />
          </form>

          <PasswordRulesAlert />
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button block color="neutral" variant="text" onClick={dialog.hide}>
          Cancel
        </Button>
        <Button block type="submit" form={form.id} disabled={form.isSubmitting}>
          Save
        </Button>
      </DialogFooter>
    </>
  );
};
