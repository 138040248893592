import React from "react";

import { FormAccounting } from "../components/form-accounting";
import { FormDocuments } from "../components/form-documents";
import { FormInfo } from "../components/form-info";
import { ROUTE_LABEL } from "../constants/constants";

export const getRouteConfigurations = (hasExpiredDocuments?: boolean) => [
  {
    element: <FormInfo />,
    index: true,
    path: "info",
    label: ROUTE_LABEL.INFO,
  },
  {
    element: <FormAccounting />,
    path: "payments",
    label: ROUTE_LABEL.ACCOUNTING,
  },
  {
    element: <FormDocuments />,
    path: "docs",
    label: ROUTE_LABEL.DOCUMENTS,
    error: hasExpiredDocuments && "Some documents have expired",
  },
];
