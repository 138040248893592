import React, {
  type ComponentPropsWithoutRef,
  memo,
  type ReactNode,
} from "react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { is } from "../../utils/is";
import { suffixify } from "../../utils/suffixify";

import styles from "./badge.module.css";

const MAX_COUNTER = 99;

const DEFAULT_COMPONENT = "span";

type BadgeProps = Omit<
  ComponentPropsWithoutRef<typeof DEFAULT_COMPONENT>,
  "color"
> & {
  size?: "md";
  color?: "error";
  value?: number | true;
  children: ReactNode;
  "data-testid"?: string;
};

const Badge = forwardRefAs<typeof DEFAULT_COMPONENT, BadgeProps>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      size = "md",
      color = "error",
      value,
      children,
      className,
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const isOverMax = is.number(value) && value > MAX_COUNTER;

    const enhancedCounter = isOverMax ? MAX_COUNTER : value;

    return (
      <Component
        ref={ref}
        className={cn(styles["badge"], className, {
          [styles[`-${size}`]]: size,
          [styles[`-${color}`]]: color,
          [styles["-dot"]]: is.true(value),
          [styles["-one-char"]]: is.number(value) ? value < 10 : true,
          [styles["-two-chars"]]: is.number(value)
            ? value >= 10 && value < 100
            : false,
          [styles["-three-chars"]]: is.number(value) ? value >= 100 : false,
        })}
        data-testid={testId}
        {...props}
      >
        {children}
        <span
          className={styles["value"]}
          aria-hidden={
            is.number(enhancedCounter) ? enhancedCounter <= 0 : !is.true(value)
          }
          data-testid={suffixify(testId, "value")}
        >
          {is.number(enhancedCounter) && (
            <>
              {enhancedCounter}
              {isOverMax ? "+" : ""}
            </>
          )}
        </span>
      </Component>
    );
  }
);

Badge.displayName = "Badge";

const MemoizedBadge = memo(Badge) as typeof Badge;

export { MemoizedBadge as Badge };
