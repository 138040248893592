import React, { type PropsWithChildren, type ReactNode } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Flex,
  Text,
} from "@adaptive/design-system";
import { parseStringCopy } from "@adaptive/design-system/utils";
import { ExternalLayout } from "@components/external-layout";
import { Layout } from "@components/layout";
import { VENDOR_REQUEST_TYPES } from "@vendors/constants";

import { STRINGS } from "./constants";

type RequestFormExpiredProps = {
  type?: string;
  logo?: ReactNode;
  height?: string;
  variant?: "simple" | "enhanced";
  companyName: string;
};

export const RequestFormExpired = ({
  type,
  height,
  variant = "enhanced",
  companyName,
}: RequestFormExpiredProps) => {
  return type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"] ? (
    <Alert variant="warning">
      <AlertTitle>{STRINGS.EXPIRED_REQUEST_ALERT_TITLE}</AlertTitle>
      <AlertContent as="div">
        <Flex gap="xl" direction="column">
          {parseStringCopy(STRINGS.EXPIRED_REQUEST_ALERT_CONTENT, {
            companyName: companyName,
          })}
        </Flex>
      </AlertContent>
    </Alert>
  ) : (
    <Layout
      logo={variant === "simple" ? null : undefined}
      title={STRINGS.EXPIRED_LINK_TITLE}
      height={variant === "simple" ? "auto" : height}
      subtitle={`To make changes to your information, contact ${companyName}`}
    />
  );
};

type RequestErrorProps = {
  type?: string;
  variant?: "simple" | "enhanced";
};

export const RequestError = ({
  type,
  variant = "enhanced",
}: RequestErrorProps) =>
  type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"] ? (
    <Flex direction="column" minHeight="150px">
      <Text weight="bold">{STRINGS.REQUEST_ERROR_TITLE}</Text>
    </Flex>
  ) : (
    <Layout
      logo={variant === "simple" ? null : undefined}
      title={STRINGS.REQUEST_ERROR_TITLE}
      height={variant === "simple" ? "auto" : undefined}
    />
  );

export const RequestFormSubmitted = ({
  type,
  children,
}: PropsWithChildren<{
  type?: string;
}>) => {
  return type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"] ? (
    <Flex direction="column" gap="xl">
      {children}
    </Flex>
  ) : (
    <ExternalLayout>
      {/* This is for "Add document" button */}
      {children}
    </ExternalLayout>
  );
};
