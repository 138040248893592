import React, { type ReactNode, useCallback, useMemo } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  dialog,
  Flex,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { formatDate } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { useAppSelector } from "@store/hooks";
import { documentsRequestsApi } from "@vendors/api";
import { useVendorAction, useVendorInfo } from "@vendors/hooks";
import { vendorSelector } from "@vendors/store";

import { AchRequestDialog } from "./ach-request-dialog";
import { achAlertVariant, STRINGS, VENDOR_REQUEST_STATUS } from "./constants";
import { type AchRequestDialogProps } from ".";

const promptCancellationConfirmation = (onConfirm: () => void) => {
  dialog.confirmation({
    title: (
      <>
        Are you sure you want to cancel
        <br />
        this ACH request?
      </>
    ),
    size: "auto",
    align: "center",
    variant: "dialog",
    message: (
      <Flex gap="xl" direction="column" minWidth="505px">
        <Text align="center">
          This cannot be undone. Pending payments for this
          <br />
          vendor will be cancelled.
        </Text>
        <Text align="center">
          You will need to send a new ACH request and re-
          <br />
          schedule any payments.
        </Text>
      </Flex>
    ),
    action: {
      primary: {
        children: "Cancel request",
        color: "error",
        onClick: onConfirm,
      },
      secondary: {
        children: "Keep request",
      },
    },
  });
};

type RequestVendorAchAlertProps = {
  customAchRequestAlertContent?: ReactNode;
};

export const RequestVendorAchAlert = ({
  customAchRequestAlertContent,
}: RequestVendorAchAlertProps) => {
  const { info: vendor } = useAppSelector(vendorSelector);

  const { fetchById } = useVendorAction();

  const onSubmitCanceltAch = useEvent(() => {
    fetchById(vendor.id);
  });

  const { latestAchRequest: request } = vendor;

  const createdAt = formatDate(request?.createdAt || "", "PPPp");

  const vendorEmail = request?.vendorEmail;

  const onConfirmAchRequestCancellation = useCallback(
    async (request_id?: string | number) => {
      try {
        await documentsRequestsApi.deleteVendorAchRequest({
          request_id: request_id,
          vendor_id: vendor.id,
        });
        toast.success("ACH request cancelled");
        onSubmitCanceltAch();
      } catch (error: any) {
        handleErrors(error);
      }
    },
    [onSubmitCanceltAch, vendor.id]
  );

  const alertTitle = useMemo(() => {
    if (request?.status === VENDOR_REQUEST_STATUS.EXPIRED) {
      return `The ACH details request you sent to ${vendorEmail} on ${createdAt} has expired.`;
    }
    if (request?.status === VENDOR_REQUEST_STATUS.PENDING) {
      return `You sent the vendor a request to ${vendorEmail} to provide their ACH details on
  ${createdAt}.`;
    }

    return "";
  }, [request, createdAt, vendorEmail]);

  const alertContent = useMemo(() => {
    if (request?.status === VENDOR_REQUEST_STATUS.EXPIRED) {
      return STRINGS.DEFAULT_EXPIRED_ACH_REQUEST_ALERT_CONTENT;
    }

    if (request?.status == VENDOR_REQUEST_STATUS.PENDING) {
      return (
        customAchRequestAlertContent ?? (
          <>
            <Text>{STRINGS.DEFAULT_PENDING_ACH_REQUEST_ALERT_CONTENT}</Text>
            <Button
              onClick={() =>
                promptCancellationConfirmation(() =>
                  onConfirmAchRequestCancellation(request?.id)
                )
              }
              variant="ghost"
              size="sm"
            >
              {STRINGS.CANCEL_ACH_LABEL}
            </Button>
          </>
        )
      );
    }
  }, [request, customAchRequestAlertContent, onConfirmAchRequestCancellation]);

  return (
    request && (
      <Alert variant={achAlertVariant(request.status)}>
        <AlertTitle>{alertTitle}</AlertTitle>
        <AlertContent as={Flex} gap="md" direction="column" align="flex-start">
          {alertContent}
        </AlertContent>
      </Alert>
    )
  );
};

type RequestVendorAchButtonProps = {
  size?: "sm" | "md";
  billId?: number | null;
  variant?: "ghost" | "solid";
  disabled?: boolean;
};

export const RequestVendorAchButton = ({
  billId,
  disabled,
  ...props
}: RequestVendorAchButtonProps) => {
  const { fetchById } = useVendorAction();

  const requestAchDialog = useDialog({ lazy: true });

  const { info: vendor } = useAppSelector(vendorSelector);

  const { canManagePaymentInfo } = useVendorInfo();

  const { latestAchRequest: request } = vendor;

  const initialValues = useMemo<AchRequestDialogProps["initialValues"]>(
    () => ({
      vendors: [
        {
          id: vendor.id,
          displayName: vendor.displayName,
          email: vendor.email,
          billId,
        },
      ],
    }),
    [vendor.id, vendor.displayName, vendor.email, billId]
  );

  const onSubmitRequestAch = useEvent(() => fetchById(vendor.id));

  return (
    <>
      {vendor.achCanBeRequested && (
        <Tooltip
          message={
            !canManagePaymentInfo ? "You don't have permission to do this" : ""
          }
          placement="left"
        >
          <Button
            onClick={requestAchDialog.show}
            disabled={!canManagePaymentInfo || !vendor.id || disabled}
            {...props}
          >
            {request ? STRINGS.RESEND_BUTTON_LABEL : STRINGS.SEND_BUTTON_LABEL}
          </Button>
        </Tooltip>
      )}

      {requestAchDialog.isRendered && (
        <AchRequestDialog
          dialog={requestAchDialog}
          multiple={false}
          onSubmit={onSubmitRequestAch}
          initialValues={initialValues}
        />
      )}
    </>
  );
};

type RequestVendorAchProps = Pick<
  RequestVendorAchButtonProps,
  "billId" | "disabled"
> &
  Pick<RequestVendorAchAlertProps, "customAchRequestAlertContent">;

export const RequestVendorAch = ({
  billId,
  disabled,
  customAchRequestAlertContent,
}: RequestVendorAchProps) => (
  <Flex direction="column" gap="xl">
    <RequestVendorAchAlert
      customAchRequestAlertContent={customAchRequestAlertContent}
    />
    <RequestVendorAchButton billId={billId} disabled={disabled} />
  </Flex>
);
