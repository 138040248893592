import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  memo,
} from "react";
import cn from "clsx";

import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import { getInitials } from "../../utils/get-initials";
import { isIcon } from "../../utils/is-icon";
import { Icon } from "../icon";
import { Image } from "../image";

import styles from "./avatar.module.css";

type DefaultComponent = typeof Image;

type SizeProp = keyof typeof SIZE | number;
type Props = Omit<ComponentPropsWithoutRef<DefaultComponent>, "alt"> & {
  name?: string;
  size?: ResponsiveProp<SizeProp>;
  variant?: "round" | "square";
  color?: "success" | "neutral" | "info";
};

const SIZE = { sm: 24, md: 32, lg: 40 } as const;

const ICON_SIZE = { sm: "xs", md: "sm", lg: "md" } as const;

const getSize = (size: SizeProp) =>
  typeof size === "number" ? size : size ? SIZE[size] : SIZE.md;

const getFontSize = (size: SizeProp) => (getSize(size) * 11) / SIZE.sm;

const Avatar = forwardRef<ComponentRef<DefaultComponent>, Props>(
  (
    {
      src,
      name,
      size: rawSize,
      style,
      variant = "round",
      color = "success",
      className,
      ...props
    },
    ref
  ) => {
    const size = useResponsiveProp(rawSize, "md");
    const enhancedProps = {
      style: {
        ...style,
        "--avatar-size": `${getSize(size)}px`,
        "--avatar-font-size": `${getFontSize(size)}px`,
      },
      className: cn(className, styles.avatar, {
        [styles["-round"]]: variant === "round",
        [styles["-square"]]: variant === "square",
        [styles[`-${color}`]]: color,
      }),
      ...props,
    };

    return src ? (
      <Image ref={ref} src={src} alt={name} {...enhancedProps} />
    ) : (
      <div
        ref={ref}
        role={name ? "image" : "presentation"}
        aria-label={name}
        {...enhancedProps}
      >
        {name &&
          (isIcon(name) ? (
            <Icon
              name={name}
              size={ICON_SIZE[size as keyof typeof ICON_SIZE] ?? "md"}
            />
          ) : (
            <span>{getInitials(name)}</span>
          ))}
      </div>
    );
  }
);

Avatar.displayName = "Avatar";

const MemoizedAvatar = memo(Avatar);

export { MemoizedAvatar as Avatar };
