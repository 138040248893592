import { useMemo } from "react";
import type { MarkupResponse } from "@api/budgets";
import {
  useCostCodesAccountsSimplified,
  type UseCostCodesAccountsSimplifiedProps,
} from "@hooks/use-cost-codes-accounts-simplified";
import { type Option } from "@shared/types";
import { getPreferredRevenueBudgetAmount } from "@src/jobs/detail-view/budget/budgets-table/utils";

type UseJobsCostCodeAccountSimplifiedProps =
  UseCostCodesAccountsSimplifiedProps & {
    disableMarkups?: Pick<MarkupResponse, "jobCostMethod">[];
  };

const DEFAULT_PROPS: UseJobsCostCodeAccountSimplifiedProps = {
  accountFilters: {
    enabled: window.BUDGETS_DRAW_ACCOUNTS_COST_CODE_ENABLED || false,
    can_accounts_link_to_lines_desktop: true,
  },
  costCodeFilters: {
    active: true,
    showDisableAsEnabled: true,
    canItemsLinkToLinesDesktop: true,
  },
  disableBudgetLines: [],
  disableMarkups: [],
};

type CostCodeAccountSimplifiedOption = Option & { type?: string };

export const useJobsCostCodeAccountSimplified = (
  props?: UseJobsCostCodeAccountSimplifiedProps
) => {
  const enhancedProps = useMemo<UseJobsCostCodeAccountSimplifiedProps>(
    () => ({
      accountFilters: {
        ...DEFAULT_PROPS.accountFilters,
        ...props?.accountFilters,
      },
      costCodeFilters: {
        ...DEFAULT_PROPS.costCodeFilters,
        ...props?.costCodeFilters,
      },
    }),
    [props]
  );

  const { data, ...costCodeAccounts } =
    useCostCodesAccountsSimplified(enhancedProps);

  const enhancedData = useMemo<CostCodeAccountSimplifiedOption[]>(() => {
    const groupLabelData = window.BUDGETS_DRAW_ACCOUNTS_COST_CODE_ENABLED
      ? data
      : data.map((item) => ({ ...item, groupLabel: undefined }));

    if (props?.disableBudgetLines?.length) {
      return groupLabelData.map((item) => {
        if (
          props?.disableBudgetLines?.find(
            (lineItem) =>
              lineItem?.jobCostMethod?.url === item.value &&
              getPreferredRevenueBudgetAmount(lineItem) !== 0
          )
        ) {
          return {
            ...item,
            disabled:
              "Lines with budget values set cannot be converted to markups",
          };
        }
        return item;
      });
    }

    if (props?.disableMarkups?.length) {
      return groupLabelData.map((item) => {
        if (
          props?.disableMarkups?.find(
            (lineItem) => lineItem?.jobCostMethod?.url === item.value
          )
        ) {
          return {
            ...item,
            disabled: "Changes cannot be created for markups",
          };
        }
        return item;
      });
    }

    return groupLabelData;
  }, [data, props?.disableBudgetLines, props?.disableMarkups]);

  return { data: enhancedData, ...costCodeAccounts };
};
