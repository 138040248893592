import { is } from "@adaptive/design-system/utils";
import { type Option } from "@shared/types";

export function isCostCode(
  optionOrStr: Option | undefined
): optionOrStr is Option;
export function isCostCode(
  optionOrStr: string | undefined
): optionOrStr is string;
export function isCostCode(optionOrStr: null | undefined): false;
export function isCostCode(
  optionOrStr: Option | null | undefined | string
): boolean {
  const value = (
    (is.string(optionOrStr) ? optionOrStr : optionOrStr?.value) ?? ""
  ).toLocaleLowerCase();

  return [
    "/item/",
    "/items/",
    "item",
    "items",
    "cost code",
    "cost codes",
    "cost_code",
    "cost_codes",
  ].some((item) => value.includes(item));
}
