import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Flex,
  Icon,
  Tag,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import { Items as LineItems, type ItemsProps } from "@components/items";
import {
  getBillableData,
  isBillable as isBillableLine,
} from "@shared/utils/billable-status";
import {
  selectExpenseCalculations,
  useExpenseLines,
  useExpensePermissions,
  useTax,
} from "@store/expenses";
import { expenseSelectors } from "@store/expenses/selectors";
import { useModalVisibility } from "@store/ui";
import { useUserInfo } from "@store/user";
import { isCostCode } from "@utils/is-cost-code";
import { noop } from "@utils/noop";

import { STRINGS } from "../stages/constants";

export const Items = () => {
  const isArchived = useSelector(expenseSelectors.isArchived, isEqual);

  const vendorId = useSelector(expenseSelectors.vendor, isEqual)?.id as number;

  const { setTax } = useTax();

  const reviewStatus = useSelector(expenseSelectors.reviewStatus, isEqual);

  const calculations = useSelector(selectExpenseCalculations, isEqual);

  const { setVisible } = useModalVisibility("SalesTax");

  const { canEditExpense } = useExpensePermissions();

  const {
    lines,
    setJob,
    setAmount,
    addNewLine,
    setBillable,
    setDescription,
    removeLineById,
    setCostAttribution,
  } = useExpenseLines();

  const isDraft = reviewStatus === "DRAFT";

  const isEditable = !isArchived && canEditExpense;

  const data = useMemo<ItemsProps["data"]>(
    () =>
      Object.values(lines).map((line) => {
        const amountValue = line.amount ?? 0;

        /**
         * @todo fix typescript issue with this format for some reason
         * it not working even though it has the correct types
         */
        const costCodeAccountValue: any =
          line.attribution?.url && line.attribution?.displayName
            ? {
                label: line.attribution.displayName,
                value: line.attribution.url,
              }
            : line.attribution?.url
              ? line.attribution.url
              : "";

        const isCostCodeAccountRequired = !isDraft;
        const isBillable = isBillableLine(line);

        /**
         * @todo fix typescript issue with this format for some reason
         * it not working even though it has the correct types
         */
        const jobCustomerValue: any =
          line.customer?.url && line.customer?.displayName
            ? { label: line.customer.displayName, value: line.customer.url }
            : line.customer?.url
              ? line.customer?.url
              : "";

        const isJobCustomerRequired =
          !isDraft && (isCostCode(line.attribution?.url) || isBillable);

        const hasRemainingBudget = !!jobCustomerValue && !!costCodeAccountValue;

        return {
          id: line.id,
          amount: {
            value: amountValue,
            required: true,
            errorMessage: amountValue === 0 ? STRINGS.ERROR_LINE_AMOUNT : "",
          },
          billable: getBillableData(line, !isEditable),
          jobCustomer: {
            value: jobCustomerValue,
            required: isJobCustomerRequired,
            errorMessage:
              !jobCustomerValue && isJobCustomerRequired
                ? STRINGS.ERROR_JOB
                : "",
          },
          extra: [
            {
              label: "Remaining budget",
              variant:
                line.remainingBudgetAfterThisExpense === null ||
                (line.remainingBudgetAfterThisExpense ?? -1) < 0 ||
                line.remainingBudgetAfterThisExpenseBlocked
                  ? "warning"
                  : "neutral",
              value:
                !hasRemainingBudget ||
                line.remainingBudgetAfterThisExpense === null ? (
                  <Text>Not on budget</Text>
                ) : line.remainingBudgetAfterThisExpenseBlocked ? (
                  <Flex as="span" align="center" gap="sm">
                    <Text>Save draft </Text>
                    <Tooltip
                      as={Icon}
                      size="sm"
                      name="info-circle"
                      color="neutral-800"
                      message={`Click "Save draft" below to update the Remaining budget`}
                    />
                  </Flex>
                ) : (
                  (line.remainingBudgetAfterThisExpense ?? 0)
                ),
              onClick: line.remainingBudgetAfterThisExpenseBlocked
                ? undefined
                : () => {
                    window
                      .open(`/jobs/${line.customer?.id}`, "_blank")
                      ?.focus();
                  },
            },
            line.linkedInvoiceLine
              ? {
                  label: "Linked draw",
                  variant: "neutral",
                  value: (
                    <Flex direction="row" gap="md" align="center">
                      <Tag
                        size="md"
                        color={
                          line.linkedInvoiceLine?.invoiceReviewStatus === "PAID"
                            ? "success"
                            : "info"
                        }
                      >
                        {line.linkedInvoiceLine?.invoiceReviewStatus === "PAID"
                          ? "Paid"
                          : "Unpaid"}
                      </Tag>
                      <Tooltip message="Open linked draw">
                        <Button
                          as="a"
                          color="neutral"
                          variant="text"
                          size="sm"
                          href={`/jobs/${line.linkedInvoiceLine?.customerId}/invoices/${line.linkedInvoiceLine?.invoiceId}?status=line-items&focus=${line.linkedInvoiceLine?.id}`}
                          target="_blank"
                        >
                          <Icon size="sm" name="link" />
                        </Button>
                      </Tooltip>
                    </Flex>
                  ),
                }
              : null,
          ],
          description: { value: line.description || "" },
          costCodeAccount: {
            value: costCodeAccountValue,
            required: isCostCodeAccountRequired,
            errorMessage:
              !costCodeAccountValue && isCostCodeAccountRequired
                ? STRINGS.ERROR_CC_ACCT
                : "",
          },
        };
      }),
    [lines, isDraft, isEditable]
  );

  const mutateStrategy = {
    amount: setAmount,
    billable: setBillable,
    closable: noop,
    jobCustomer: setJob,
    description: setDescription,
    costCodeAccount: setCostAttribution,
  };

  const { canViewAllCodes } = useUserInfo();
  const showAll = canViewAllCodes;

  const vendorFilters = useMemo(
    () => (vendorId ? { vendorId } : {}),
    [vendorId]
  );

  const componentsProps = useMemo<ItemsProps["componentsProps"]>(
    () => ({
      costCodeAccount: {
        accountFilters: { only_line_item_accounts: true, ...vendorFilters },
        costCodeFilters: { ...vendorFilters },
        showAll,
      },
    }),
    [vendorFilters, showAll]
  );

  const onChange = useEvent<ItemsProps["onChange"]>(({ id, name, value }) => {
    mutateStrategy[name](id, value as never);
  });

  const onAddSalesTax = useEvent(() => setVisible(true));

  const onRemoveSalesTax = useEvent(() => setTax(0));

  const salesTax = useMemo(
    () => ({
      onAdd: onAddSalesTax,
      amount: calculations.salesTax,
      subTotal: calculations.subTotal,
      onRemove: onRemoveSalesTax,
    }),
    [
      onAddSalesTax,
      onRemoveSalesTax,
      calculations.salesTax,
      calculations.subTotal,
    ]
  );

  const isNewItem = useCallback<ItemsProps["isNewItem"]>(
    (item) => typeof item.id === "number" && item.id < 1,
    []
  );

  return (
    <LineItems
      id="expense-items"
      data={data}
      total={calculations.total}
      onAdd={addNewLine}
      disabled={!isEditable}
      onChange={onChange}
      onRemove={removeLineById}
      salesTax={salesTax}
      isNewItem={isNewItem}
      data-testid="items"
      componentsProps={componentsProps}
    />
  );
};
