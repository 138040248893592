import React, { type ComponentProps } from "react";
import {
  Button,
  ButtonField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
} from "@adaptive/design-system";
import { useDialog, useEvent, useForm } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import {
  SignatureField,
  type SignatureSavedValue,
} from "@components/signature-field/signature-field";

import { STRINGS } from "./constants";

type SignatureFieldProps = {
  form?: string;
  disabled?: boolean;
  placeholder?: string;
  signedPlaceholder?: string;
} & ComponentProps<typeof SignatureField>;

const INITIAL_VALUES = {
  local: {} as SignatureSavedValue,
  saved: {} as SignatureSavedValue,
};

export const SignatureFieldButton = ({
  mode,
  form: formId,
  disabled,
  onChange,
  placeholder = STRINGS.PLACEHOLDER,
  signedPlaceholder = STRINGS.SIGNED_PLACEHOLDER,
}: SignatureFieldProps) => {
  const dialog = useDialog({ lazy: true });

  const form = useForm({
    initialValues: INITIAL_VALUES,
    onSubmit: (values) => {
      form.setValue("saved", values.local);
      onChange?.(values.local);
      dialog.hide();
    },
  });

  const haveValue =
    !!form.values.saved.drawSignature || !!form.values.saved.typeSignature;

  const onSignatureChange = useEvent((value) => {
    if (!isEqual(value, form.values.saved)) {
      form.setValue("local", value);
    }
  });

  const onCancel = useEvent(() => {
    form.setValue("local", form.values.saved);
    dialog.hide();
  });

  return (
    <>
      <Flex width="full" maxWidth="430px">
        <ButtonField
          form={formId}
          required
          label={STRINGS.SIGNATURE_LABEL}
          onClick={dialog.show}
          disabled={dialog.isRendered || disabled}
          placeholder={placeholder}
          value={haveValue ? signedPlaceholder : undefined}
          suffix={<Icon name="pen" color="neutral-500" />}
          loading={dialog.isRendered}
          messageVariant="absolute"
        />
      </Flex>

      {dialog.isRendered && (
        <Dialog show={dialog.isVisible} variant="dialog" onClose={dialog.hide}>
          <DialogHeader>{STRINGS.ADD_YOUR_SIGNATURE}</DialogHeader>

          <DialogContent>
            <form {...form.props}>
              <SignatureField
                mode={mode}
                onChange={onSignatureChange}
                initialValue={form.values.saved}
              />
            </form>
          </DialogContent>

          <DialogFooter>
            <Button
              block
              variant="text"
              color="neutral"
              type="button"
              onClick={onCancel}
            >
              {STRINGS.CANCEL}
            </Button>
            <Button block form={form.id} type="submit">
              {STRINGS.SAVE}
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};
