import { api } from "@store/api-simplified";
import {
  getSearchParamsFromFilters,
  handleRequest,
  handleResponse,
} from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import {
  batchUpdateCardTransactionsPayloadSchema,
  getCardTransactionsPayloadSchema,
  getCardTransactionsResponseSchema,
  getCostsPayloadSchema,
  getCostsResponseSchema,
  matchCardTransactionErrorResponseSchema,
  matchCardTransactionPayloadSchema,
  matchCardTransactionSuccessResponseSchema,
  unmatchCardTransactionPayloadSchema,
  updateCardTransactionPayloadSchema,
} from "./schemas";
import {
  type BatchUpdateCardTransactionsPayload,
  type BatchUpdateCardTransactionsResponse,
  type GetCardTransactionsPayload,
  type GetCardTransactionsResponse,
  type GetCostsPayload,
  type GetCostsResponse,
  type MatchCardTransactionPayload,
  type MatchCardTransactionResponse,
  type UnmatchCardTransactionPayload,
  type UnmatchCardTransactionResponse,
  type UpdateCardTransactionPayload,
  type UpdateCardTransactionResponse,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["Costs", "CardTransactions"],
});

const cardFeedApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    batchUpdateCardTransactions: builder.mutation<
      BatchUpdateCardTransactionsResponse,
      BatchUpdateCardTransactionsPayload
    >({
      queryFn: async (args, _, __, baseQuery) => {
        const { cardTransactionIds, ...payload } = handleRequest(
          args,
          batchUpdateCardTransactionsPayloadSchema
        ) as BatchUpdateCardTransactionsPayload;

        await Promise.all(
          cardTransactionIds.map((id) =>
            baseQuery({
              url: `cardtransactions/${id}/`,
              body: transformKeysToSnakeCase(payload),
              method: "PUT",
            })
          )
        );

        return { data: undefined };
      },
      invalidatesTags: (_, __, { cardTransactionIds }) =>
        cardTransactionIds.map((id) => ({ type: "CardTransactions", id })),
    }),
    updateCardTransaction: builder.mutation<
      UpdateCardTransactionResponse,
      UpdateCardTransactionPayload
    >({
      query: (args) => {
        const { cardTransactionId, ...payload } = handleRequest(
          args,
          updateCardTransactionPayloadSchema
        );

        return {
          url: `cardtransactions/${cardTransactionId}/`,
          method: "PUT",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async () => undefined,
        };
      },
      invalidatesTags: (_, __, { cardTransactionId }) => [
        { type: "CardTransactions", cardTransactionId },
        "GetBillPaymentOptions",
      ],
    }),
    unmatchCardTransaction: builder.mutation<
      UnmatchCardTransactionResponse,
      UnmatchCardTransactionPayload
    >({
      query: (args) => {
        const cardTransactionId = handleRequest(
          args,
          unmatchCardTransactionPayloadSchema
        );

        return {
          url: `cardtransactions/${cardTransactionId}/unmatch/`,
          method: "PUT",
          responseHandler: async () => undefined,
        };
      },
      invalidatesTags: (_, error) =>
        error
          ? []
          : [{ type: "CardTransactions", id: "LIST" }, "GetBillPaymentOptions"],
    }),
    matchCardTransaction: builder.mutation<
      MatchCardTransactionResponse,
      MatchCardTransactionPayload
    >({
      query: (args) => {
        const { cardTransactionId, ...payload } = handleRequest(
          args,
          matchCardTransactionPayloadSchema
        );

        return {
          url: `cardtransactions/${cardTransactionId}/match/`,
          method: "PUT",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              response.status === 400
                ? matchCardTransactionErrorResponseSchema
                : matchCardTransactionSuccessResponseSchema
            );
          },
        };
      },
      invalidatesTags: (_, error) =>
        error
          ? []
          : [{ type: "CardTransactions", id: "LIST" }, "GetBillPaymentOptions"],
    }),
    getCosts: builder.query<GetCostsResponse, GetCostsPayload>({
      query: (args) => {
        const params = handleRequest(args, getCostsPayloadSchema);

        return {
          url: `costs/?${getSearchParamsFromFilters(params).toString()}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getCostsResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(({ id }) => ({ type: "Costs", id }) as const),
              { type: "Costs", id: "LIST" },
            ]
          : [{ type: "Costs", id: "LIST" }],
    }),
    getCardTransactions: builder.query<
      GetCardTransactionsResponse,
      GetCardTransactionsPayload
    >({
      query: (args) => {
        const params = handleRequest(args, getCardTransactionsPayloadSchema);

        return {
          url: `cardtransactions/?${getSearchParamsFromFilters(params).toString()}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getCardTransactionsResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(
                ({ id }) => ({ type: "CardTransactions", id }) as const
              ),
              { type: "CardTransactions", id: "LIST" },
            ]
          : [{ type: "CardTransactions", id: "LIST" }],
    }),
  }),
});

export const {
  useGetCostsQuery,
  useGetCardTransactionsQuery,
  useMatchCardTransactionMutation,
  useUpdateCardTransactionMutation,
  useUnmatchCardTransactionMutation,
  useBatchUpdateCardTransactionsMutation,
} = cardFeedApi;
