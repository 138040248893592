import React, {
  type ComponentPropsWithoutRef,
  Fragment,
  memo,
  type ReactNode,
  useCallback,
  useMemo,
} from "react";
import cn from "clsx";

import { suffixify } from "../../utils";
import { dotObject } from "../../utils/dot-object";
import { Icon } from "../icon";
import { Text } from "../text";
import { Tooltip } from "../tooltip";

import styles from "./label-value-group.module.css";

type DefaultComponent = "div";

type RenderData = {
  label?: ReactNode;
  value?: ReactNode;
  hintMessage?: ReactNode;
};

type Props<Data> = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "children"
> & {
  data?: Data[] | Data[][];
  render?:
    | { label: string; value: string; hintMessage?: string }
    | ((item: Data) => RenderData);
  compact?: boolean;
  "data-testid"?: string;
};

const LabelValueGroup = <Data extends RenderData>({
  data = [],
  render,
  compact,
  className,
  "data-testid": testId,
  ...props
}: Props<Data>) => {
  const enhanceItem = useCallback(
    (item: Data) => {
      if (!render) {
        return {
          label: item?.label,
          value: item?.value,
          hintMessage: item?.hintMessage,
        };
      }

      if (typeof render === "function") return render(item);

      return {
        label: dotObject.get(item, render.label),
        value: dotObject.get(item, render.value),
        hintMessage: render?.hintMessage
          ? dotObject.get(item, render.hintMessage)
          : undefined,
      };
    },
    [render]
  );

  const enhancedData = useMemo(() => {
    const isMultiple =
      data !== null &&
      typeof data === "object" &&
      Array.isArray(dotObject.get(data, "0"));

    return isMultiple
      ? (data as Data[][]).map((group) => group.map(enhanceItem))
      : [(data as Data[]).map(enhanceItem)];
  }, [data, enhanceItem]);

  return (
    <div
      className={cn(styles["label-value-group"], className, {
        [styles["-compact"]]: compact,
      })}
      data-testid={testId}
      {...props}
    >
      {enhancedData.map((group, groupIndex) =>
        group.map(({ label, value, hintMessage }, itemIndex) => {
          const hasTermSeparator = compact
            ? groupIndex > 0 && itemIndex === 0
            : groupIndex > 0 || itemIndex > 0;

          const hasDefinitionSeparator =
            compact && groupIndex > 0 && itemIndex === 0;

          return (
            <Fragment key={suffixify(label, value)}>
              <Text
                as="span"
                size="sm"
                role="term"
                truncate
                className={cn(styles["term"], {
                  [styles["-separator"]]: hasTermSeparator,
                })}
                data-testid={suffixify(testId, "label")}
              >
                {label}
                {hintMessage && (
                  <Tooltip
                    as={Icon}
                    size="sm"
                    name="info-circle"
                    message={hintMessage}
                    className={styles["hint-message"]}
                    data-testid={suffixify(testId, "hint")}
                  />
                )}
              </Text>
              <Text
                as="span"
                size={compact ? "sm" : "md"}
                role="definition"
                weight={compact ? "bold" : "bolder"}
                truncate
                className={cn(styles["definition"], {
                  [styles["-separator"]]: hasDefinitionSeparator,
                })}
                data-testid={suffixify(testId, "value")}
              >
                {value || "-"}
              </Text>
            </Fragment>
          );
        })
      )}
    </div>
  );
};

const MemoizedLabelValueGroup = memo(LabelValueGroup) as typeof LabelValueGroup;

export { MemoizedLabelValueGroup as LabelValueGroup };
