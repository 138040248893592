import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router";
import {
  Button,
  dialog,
  Dropdown,
  DropdownList,
  DropdownSelectableItem,
  DropdownTrigger,
  Flex,
  Icon,
  Loader,
  MultipleTable,
  type MultipleTableFooter,
  type MultipleTableHeader,
  type MultipleTableProps,
  type TableEmptyState,
  type TableSelectAddon,
  type TableSortAddon,
  Text,
  toast,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import { formatCurrency, isEqual } from "@adaptive/design-system/utils";
import type { BudgetLinesBulkCreatePayload } from "@api/budgets";
import {
  useDeleteBudgetLineMarkupsMutation,
  useDeleteBudgetLineMutation,
  useDeleteMarkupMutation,
  useUpdateBudgetLineMutation,
} from "@api/budgets";
import { useAddBudgetLinesToJobMutation } from "@api/budgets/budget-lines";
import { budgetLineUpdateRequestPayloadSchema } from "@api/budgets/request";
import { handleErrors } from "@api/handle-errors";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import OneSchemaImporter, {
  type OneSchemaImporterProps,
} from "@oneschema/react";
import {
  useJobActions,
  useJobBudgetSelectedLines,
  useJobPermissions,
  useJobSettings,
} from "@src/jobs";
import { CURRENCY_FORMAT } from "@src/jobs/constants";
import { AddBudgetLineDialogButton } from "@src/jobs/detail-view/add-budget-line-dialog";
import { type ChangeDialogStep } from "@src/jobs/detail-view/budget/changes-dialog";
import {
  AddMarkupLineButton,
  EditFixedMarkupDialog,
} from "@src/jobs/detail-view/manage-markup-dialog";
import { useBudgetActions, useJobInfo } from "@store/jobs";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";
import { getItemUrls } from "@utils/get-cost-code-account-values";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sum } from "@utils/sum";

import type { MarkupDialogSteps } from "../../manage-markup-dialog/types";
import { ChangesDialog } from "../changes-dialog";
import { DrawnToDateDialog } from "../drawn-to-date-dialog";
import { EditInlineMarkupDialog } from "../edit-inline-markup-dialog";
import { JobTransactionsDialog } from "../job-transactions-dialog";
import { TransactionDialog } from "../transaction-dialog";

import { useLinesAndMarkups } from "./hooks/use-lines-and-markups";
import { BudgetsFilters } from "./budgets-filters";
import { BudgetsTableContext } from "./budgets-table-context";
import { getIsDeletable, getLineColumns, type Line } from "./lines";
import { getMarkupColumns, type Markup } from "./markups";
import {
  type CurriedEditLineItemHandler,
  type CurriedLineItemHandler,
  type CurriedOnDeleteMarkupHandler,
  type CurriedOnOpenOneSchemaHandlers,
  type CurriedOptionalLineItemHandler,
  type LineItemHandler,
  type OnEditFixedAmountMarkupHandler,
  type UpdateCategoryHandler,
} from "./types";
import { getItemById } from "./utils";

type OneSchemaRecord = {
  budget?: number;
  costCode?: string;
  category?: string;
  changeAmount?: number;
};

type OnOneSchemaSuccessHandler = (data: { records: OneSchemaRecord[] }) => void;

export const BudgetsTable = memo(() => {
  const { job } = useJobInfo();

  const [stickOffset, setStickyOffset] = useState(0);

  const [searchParams] = useSearchParams();

  const changeParam = searchParams.get("change");

  const locationParam = searchParams.get("location");

  const { canManage } = useJobPermissions();

  const budgetSelectedLines = useJobBudgetSelectedLines();

  const { categoriesEnabled, changeTrackingEnabled, ownersAmountEnabled } =
    useJobSettings();

  const {
    sort,
    lines,
    query,
    totals,
    setSort,
    markups,
    filters,
    setQuery,
    viewMode,
    isLoading,
    viewModes,
    rawFilters,
    hasFilters,
    lineTotals,
    setFilters,
    setViewMode,
    markupTotals,
    isError,
  } = useLinesAndMarkups();

  const settings = useClientSettings();

  const clientExternalRevisedBudgetEnabled =
    settings.externalRevisedBudgetEnabled ?? false;

  const { refetchJob, setBudgetSelectedLines, updateJobCategoriesEnabled } =
    useJobActions();

  const [currentMarkup, setCurrentMarkup] = useState<Markup | undefined>();

  const [currentBudgetLine, setCurrentBudgetLine] = useState<
    Line | undefined
  >();

  const { updateBudgedLockedStatus, copyBuilderBudgetToRevisedBudget } =
    useBudgetActions();

  const jobTransactionsDialog = useDialog({ lazy: true });

  const transactionDialog = useDialog({ lazy: true });

  const drawnToDateDialog = useDialog({ lazy: true });

  const changesDialog = useMultiStepDialog<ChangeDialogStep>({
    lazy: true,
    initialStep: "view-changes",
  });

  const changesDialogShow = useEvent(() => changesDialog.show());

  const drawnToDateDialogShow = useEvent(() => drawnToDateDialog.show());

  const markupEditInlineDialog = useDialog({ lazy: true });

  const markupEditFixedAmountDialog = useMultiStepDialog<MarkupDialogSteps>({
    lazy: true,
    initialStep: "markup-form",
  });

  const [deleteMarkup] = useDeleteMarkupMutation();

  const [updateBudgetLine] = useUpdateBudgetLineMutation();

  const [deleteBudgetLine] = useDeleteBudgetLineMutation();

  const [deleteBudgetLineMarkups] = useDeleteBudgetLineMarkupsMutation();

  const [budgetImportColumn, setBudgetImportColumn] = useState<
    "builderAmount" | "ownersAmount"
  >("builderAmount");

  const [oneSchemaIsOpen, setOneSchemaIsOpen] = useState(false);

  const [oneSchemaIsLoading, setOneSchemaIsLoading] = useState(false);

  const lineItems = useJobsCostCodeAccountSimplified();

  const [addBudgetLinesToJob, { isLoading: budgetIsLoading }] =
    useAddBudgetLinesToJobMutation();

  const header = useMemo<MultipleTableHeader>(
    () => ({ offset: stickOffset }),
    [stickOffset]
  );

  const columnsToAdd = useMemo(() => [], []);

  const columnsToRemove = useMemo(() => [], []);

  const columnsToUpdate = useMemo(
    () => [
      {
        key: "costCode",
        validation_options: {
          values: Array.from(
            new Set(
              lineItems.data.map((item) => {
                if (window.BUDGETS_DRAW_ACCOUNTS_COST_CODE_ENABLED) {
                  return `${item.label} - ${item.groupLabel}`;
                }
                return item.label;
              })
            )
          ),
        },
      },
    ],
    [lineItems.data]
  );

  const templateOverrides = useMemo(
    () => ({
      columns_to_add: columnsToAdd,
      columns_to_update: columnsToUpdate,
      columns_to_remove: columnsToRemove,
      validation_hooks_to_add: [],
    }),
    [columnsToAdd, columnsToRemove, columnsToUpdate]
  );

  const curriedOnOpenOneSchema = useCallback<CurriedOnOpenOneSchemaHandlers>(
    (column) => () => {
      analytics.track("budgetImport", { column, customerId: job.id });
      setBudgetImportColumn(column);
      setOneSchemaIsLoading(true);
      setOneSchemaIsOpen(true);
    },
    [job.id]
  );

  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setStickyOffset(sticky.height);
  });

  const onOneSchemaLaunched = useEvent(() => setOneSchemaIsLoading(false));

  const onOneSchemaSuccess = useEvent<OnOneSchemaSuccessHandler>(
    async (data) => {
      if (lineItems.status !== "success") return;
      const budgetLines = data.records.reduce(
        (acc, record) => {
          const costCode = lineItems.data.find(
            (item) =>
              item.label ===
              record.costCode?.replace(/ - (Cost code|Account)$/, "")
          );

          if (!costCode) return acc;

          const itemId = parseRefinementIdFromUrl(costCode.value);

          if (!itemId) return acc;

          const existingBudgetLine = acc.find((line) => {
            if ("groupLabel" in costCode && costCode.groupLabel) {
              return (
                (line.itemId === itemId && isCostCode(costCode.groupLabel)) ||
                (line.accountId === itemId && isAccount(costCode.groupLabel))
              );
            }
            return line.itemId === itemId;
          });

          if (existingBudgetLine) {
            if (!existingBudgetLine[budgetImportColumn]) {
              existingBudgetLine[budgetImportColumn] = Number(
                Number(record.budget).toFixed(2)
              );
            } else {
              existingBudgetLine[budgetImportColumn] = sum(
                existingBudgetLine[budgetImportColumn] || 0,
                record?.budget || 0
              );
            }
          } else {
            const lineItemId = getItemUrls(costCode.value);

            acc.push({
              itemId: lineItemId.item ? itemId : undefined,
              accountId: lineItemId.account ? itemId : undefined,
              [budgetImportColumn]: Number(Number(record.budget).toFixed(2)),
              ...(record.category
                ? { categoryDisplayName: record.category }
                : {}),
              ...(record.changeAmount
                ? { changeAmount: record.changeAmount }
                : {}),
            });
          }

          return acc;
        },
        [] as BudgetLinesBulkCreatePayload["budgetLines"]
      );

      try {
        await addBudgetLinesToJob({ budgetLines, customerId: job.id }).unwrap();
        await refetchJob();
        toast.success(`Budget imported`);
      } catch (e) {
        handleErrors(e);
      }
    }
  );

  const onOneSchemaError = useEvent<
    Exclude<OneSchemaImporterProps["onError"], undefined>
  >((error) => {
    toast.error(error.message);
  });

  const onRequestClose = useEvent(() => {
    setOneSchemaIsOpen(false);
  });

  const lineSelectIsSelectable = useCallback(() => canManage, [canManage]);

  const lineSelectOnChange = useEvent((lines: Line[]) =>
    setBudgetSelectedLines((previousBudgetSelectedLines) =>
      isEqual(previousBudgetSelectedLines, lines)
        ? previousBudgetSelectedLines
        : lines
    )
  );

  const lineSelect = useMemo<TableSelectAddon<Line>>(
    () => ({
      value: budgetSelectedLines,
      onChange: lineSelectOnChange,
      isSelectable: lineSelectIsSelectable,
    }),
    [lineSelectOnChange, budgetSelectedLines, lineSelectIsSelectable]
  );

  const lineSort = useMemo<TableSortAddon>(
    () => ({ value: sort, onChange: setSort }),
    [sort, setSort]
  );

  const lineEmptyState = useMemo<TableEmptyState>(() => {
    if (isError) return "error";

    return {
      title: hasFilters ? "No lines match your filters" : "Create budget",
      action: hasFilters ? (
        <Button as="label" htmlFor="budgets-filters-clear">
          Clear filters
        </Button>
      ) : (
        <Flex gap="md">
          <AddBudgetLineDialogButton
            size="md"
            variant="solid"
            tooltipPlacement="top"
          />
          <Button
            disabled={!canManage}
            onClick={curriedOnOpenOneSchema("builderAmount")}
          >
            Import cost budget
            <Icon name="arrow-up-from-bracket" />
          </Button>
        </Flex>
      ),
      subtitle: hasFilters
        ? "Try adjusting your search or filters to find what you’re looking for"
        : "You don’t have any costs or a budget associated with this project yet.",
    };
  }, [curriedOnOpenOneSchema, hasFilters, canManage, isError]);

  const lineColumns = useMemo(
    () =>
      getLineColumns({
        viewMode: viewMode.value,
        hasCategories: categoriesEnabled,
        hasChanges: changeTrackingEnabled,
        hasOwnersAmount: ownersAmountEnabled,
        hasExternalRevisedBudget: clientExternalRevisedBudgetEnabled,
      }),
    [
      viewMode.value,
      categoriesEnabled,
      changeTrackingEnabled,
      clientExternalRevisedBudgetEnabled,
      ownersAmountEnabled,
    ]
  );

  const markupColumns = useMemo(
    () =>
      getMarkupColumns({
        viewMode: viewMode.value,
        hasChanges: changeTrackingEnabled,
        hasCategories: categoriesEnabled,
        hasExternalBudget: ownersAmountEnabled,
        hasExternalRevisedBudget: clientExternalRevisedBudgetEnabled,
      }),
    [
      viewMode.value,
      categoriesEnabled,
      ownersAmountEnabled,
      changeTrackingEnabled,
      clientExternalRevisedBudgetEnabled,
    ]
  );

  const data = useMemo<MultipleTableProps<[Line, Markup]>["data"]>(() => {
    const hasMarkups = markups.length > 0;
    const expandReference =
      viewMode.value === "categories" ? "category.id" : "id";

    return [
      {
        data: lines,
        sort: lineSort,
        select: lineSelect,
        columns: lineColumns,
        expand: { reference: expandReference },
        loading: isLoading,
        emptyState: lineEmptyState,
      },
      {
        data: markups,
        hide: !hasMarkups || isLoading,
        expand: { reference: expandReference },
        columns: markupColumns,
      },
    ];
  }, [
    lines,
    markups,
    lineSort,
    isLoading,
    lineSelect,
    lineColumns,
    markupColumns,
    viewMode.value,
    lineEmptyState,
  ]);

  const curriedOnDeleteMarkup = useEvent<CurriedOnDeleteMarkupHandler>(
    (markup) => () => {
      if (!markup) return;

      const handler = async () => {
        const { isSeparateLine, markupType } = markup;

        try {
          if (isSeparateLine && markupType === "percentage") {
            await deleteBudgetLineMarkups({
              customer: job.id,
              markupId: markup.id,
            }).unwrap();
          } else {
            await deleteMarkup({
              markupId: markup.id,
              customer: job.id,
            }).unwrap();
          }

          if (markup.budgetLine && getIsDeletable(markup.budgetLine)) {
            toast.success("Markup item deleted");
          } else {
            toast.success(
              `Markup removed from ${markup.jobCostMethod?.displayName}`
            );
          }
        } catch (e) {
          handleErrors(e);
        }
      };

      dialog.confirmation({
        title: "Remove markup?",
        message:
          markup.budgetLine && getIsDeletable(markup.budgetLine)
            ? `Are you sure you want to remove the markup item ${markup.jobCostMethod?.displayName} from this job?`
            : `${markup.jobCostMethod?.displayName} will revert back to a budget line.`,
        action: {
          primary: {
            color: "error",
            onClick: handler,
            children: "Remove markup",
          },
        },
      });
    }
  );

  const onDeleteBudgetLine = useEvent<LineItemHandler>((budgetLine) => {
    if (!budgetLine) return;

    const handler = async () => {
      try {
        await deleteBudgetLine({
          customerId: job.id,
          budgetLineId: budgetLine.id,
        }).unwrap();

        toast.success(
          `${budgetLine.jobCostMethod.displayName} removed from the budget`
        );
      } catch (e) {
        handleErrors(e);
      }
    };

    dialog.confirmation({
      title: "Remove line item?",
      action: {
        primary: {
          color: "error",
          onClick: handler,
          children: "Remove line item?",
          "data-testid": "budget-remove-line-confirm-button",
        },
      },
    });
  });

  const enhancedSetViewMode = useEvent((value: string) => {
    analytics.track("budgetViewMode", { value });

    if (value === "categories" && !categoriesEnabled) {
      updateJobCategoriesEnabled(true);
    }

    setViewMode(value);
  });

  const onEditBudgetLine = useEvent<LineItemHandler>(async (budgetLine) => {
    const currentBudgetLine = getItemById(lines, budgetLine.id);

    if (!currentBudgetLine || !budgetLine) return;

    const hasNotChanged =
      currentBudgetLine.builderAmount == budgetLine.builderAmount &&
      currentBudgetLine.builderRevisedAmount ==
        budgetLine.builderRevisedAmount &&
      currentBudgetLine.ownersAmount == budgetLine.ownersAmount;

    if (hasNotChanged) return;

    try {
      await updateBudgetLine({
        ...budgetLineUpdateRequestPayloadSchema.parse({
          budgetLineId: budgetLine.id,
          customerId: job.id,
          builderAmount: budgetLine.builderAmount,
          ownersAmount: budgetLine.ownersAmount,
        }),
      });
      toast.success("Changes auto-saved");
    } catch (e) {
      handleErrors(e);
    }
  });

  const updateCategory = useEvent<UpdateCategoryHandler>(
    async ({ budgetLineId, categoryId }) => {
      try {
        await updateBudgetLine({
          category: categoryId || null,
          customerId: String(job.id),
          budgetLineId,
        });

        toast.success("Changes auto-saved");
        analytics.track("budgetLineCategoryUpdate", {
          budgetLineId: budgetLineId,
          categoryId,
          customerId: job.id,
        });
      } catch (e) {
        handleErrors(e);
      }
    }
  );

  const onToggleBudgetLockedStatus = useEvent(() => {
    const handler = async (copy = false) => {
      const isBuilderBudgetLocked = !job.is_builder_budget_locked;

      try {
        await updateBudgedLockedStatus({
          customerId: job.id,
          isBuilderBudgetLocked,
        });

        if (copy) copyBuilderBudgetToRevisedBudget();

        toast.success(
          `Budget ${isBuilderBudgetLocked ? "locked" : "unlocked"}`
        );
      } catch (e) {
        handleErrors(e);
      }
    };

    if (job.is_builder_budget_locked === false) {
      dialog.confirmation({
        title: "Copy Budget to Revised",
        message:
          "Would you also like to update the revised budget column to reflect the changes you made to the original budget?",
        action: {
          primary: {
            onClick: () => handler(true),
            children: "Update revised budget",
          },
          secondary: { children: "Skip", onClick: handler },
        },
      });
    } else {
      handler(job.is_builder_budget_locked === null);
    }
  });

  const curriedOnEditBuildersBudgetChange =
    useEvent<CurriedEditLineItemHandler>((budgetLine) => (value: number) => {
      onEditBudgetLine({
        ...budgetLine,
        builderAmount: value,
      });
      analytics.track("budgetLineUpdate", {
        budgetLineId: budgetLine.id,
        customerId: job.id,
      });
    });

  const curriedOnEditOwnersBudgetChange = useEvent(
    (budgetLine: Line) => (value: number) => {
      onEditBudgetLine({
        ...budgetLine,
        ownersAmount: value,
      });
      analytics.track("budgetLineExternalUpdate", {
        budgetLineId: budgetLine.id,
        customerId: job.id,
      });
    }
  );

  const curriedOnEditWorkflow = useEvent<CurriedLineItemHandler>(
    (budgetLine) => () => {
      setCurrentBudgetLine(budgetLine);
      markupEditInlineDialog.show();
    }
  );

  const curriedOnSeeTransactions = useEvent<CurriedLineItemHandler>(
    (budgetLine) => () => {
      if (!budgetLine) return jobTransactionsDialog.show();

      setCurrentBudgetLine(budgetLine);
      transactionDialog.show();
    }
  );

  const curriedOnSeeDrawnToDate = useCallback<CurriedOptionalLineItemHandler>(
    (budgetLine) => () => {
      setCurrentBudgetLine(budgetLine);
      drawnToDateDialogShow();
    },
    [drawnToDateDialogShow]
  );

  const curriedOnSeeChanges = useCallback<CurriedOptionalLineItemHandler>(
    (budgetLine) => () => {
      setCurrentBudgetLine(budgetLine);
      changesDialogShow();
    },
    [changesDialogShow]
  );

  const shouldShowSourceType = useMemo(
    () => !lines.every((line) => line.sourceType === lines[0].sourceType),
    [lines]
  );

  const onEditFixedAmountMarkup = useCallback<OnEditFixedAmountMarkupHandler>(
    (markup) => {
      setCurrentMarkup(markup);
      markupEditFixedAmountDialog.show();
    },
    [markupEditFixedAmountDialog]
  );

  const markupsFooter = useMemo(() => {
    const transactions = categoriesEnabled
      ? [
          {
            id: "name",
            render: <AddMarkupLineButton />,
          },
          {
            id: "category",
            render: (
              <Flex align="center" width="full" justify="flex-end">
                <Text weight="bold">Markup total</Text>
              </Flex>
            ),
          },
        ]
      : [
          {
            id: "name",
            render: (
              <Flex
                gap="md"
                width="full"
                align="center"
                justify="space-between"
              >
                <AddMarkupLineButton />
                <Text weight="bold">Markup total</Text>
              </Flex>
            ),
          },
        ];

    const costs = [
      {
        id: "budget",
        render: (
          <Flex width="full" align="center" justify="flex-end">
            <Text weight="bold">
              {formatCurrency(markupTotals.builderAmount, CURRENCY_FORMAT)}
            </Text>
          </Flex>
        ),
      },
    ];

    if (changeTrackingEnabled) {
      costs.push(
        {
          id: "changes",
          render: (
            <Flex width="full" align="center" justify="flex-end">
              <Text weight="bold">
                {formatCurrency(markupTotals.changeAmount, CURRENCY_FORMAT)}
              </Text>
            </Flex>
          ),
        },
        {
          id: "revisedBudget",
          render: (
            <Flex width="full" align="center" justify="flex-end">
              <Text weight="bold">
                {formatCurrency(
                  markupTotals.builderRevisedAmount,
                  CURRENCY_FORMAT
                )}
              </Text>
            </Flex>
          ),
        }
      );
    }

    costs.push({
      id: "actual",
      render: (
        <Flex width="full" align="center" justify="flex-end">
          <Text weight="bold">
            {formatCurrency(markupTotals.spent, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      ),
    });

    costs.push({
      id: "undrawnCosts",
      render: (
        <Flex width="full" align="center" justify="flex-end">
          <Text weight="bold">
            {formatCurrency(markupTotals.undrawnCosts, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      ),
    });

    costs.push({
      id: "unpaid",
      render: (
        <Flex width="full" align="center" justify="flex-end">
          <Text weight="bold">
            {formatCurrency(markupTotals.unpaidBills, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      ),
    });

    costs.push({
      id: "costsRemaining",
      render: (
        <Flex width="full" align="center" justify="flex-end">
          <Text
            weight="bold"
            color={
              markupTotals.builderRemainingAmount >= 0
                ? "neutral-800"
                : "warning-200"
            }
          >
            {formatCurrency(
              markupTotals.builderRemainingAmount,
              CURRENCY_FORMAT
            )}
          </Text>
        </Flex>
      ),
    });

    const revenues = [];

    if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
      revenues.push({ id: "markup", render: <div /> });
    }

    if (ownersAmountEnabled) {
      revenues.push({
        id: "ownersBudget",
        render: (
          <Flex width="full" align="center" justify="flex-end">
            <Text weight="bold">
              {formatCurrency(markupTotals.ownersAmount, CURRENCY_FORMAT)}
            </Text>
          </Flex>
        ),
      });

      if (changeTrackingEnabled && clientExternalRevisedBudgetEnabled) {
        revenues.push(
          {
            id: "revenuesChanges",
            render: (
              <Flex width="full" align="center" justify="flex-end">
                <Text weight="bold">
                  {formatCurrency(
                    markupTotals.externalChangeAmount,
                    CURRENCY_FORMAT
                  )}
                </Text>
              </Flex>
            ),
          },
          {
            id: "revenuesRevisedBudget",
            render: (
              <Flex width="full" align="center" justify="flex-end">
                <Text weight="bold">
                  {formatCurrency(
                    markupTotals.ownersRevisedAmount,
                    CURRENCY_FORMAT
                  )}
                </Text>
              </Flex>
            ),
          }
        );
      }
    }

    revenues.push(
      {
        id: "drawn",
        render: (
          <Flex width="full" align="center" justify="flex-end">
            <Text weight="bold">
              {formatCurrency(markupTotals.invoicedAmount, CURRENCY_FORMAT)}
            </Text>
          </Flex>
        ),
      },
      {
        id: "remaining",
        render: (
          <Flex width="full" align="center" justify="flex-end">
            <Text
              weight="bold"
              color={
                markupTotals.invoicedRemainingAmount >= 0
                  ? "neutral-800"
                  : "warning-200"
              }
            >
              {formatCurrency(
                markupTotals.invoicedRemainingAmount,
                CURRENCY_FORMAT
              )}
            </Text>
          </Flex>
        ),
      }
    );

    return [...transactions, ...costs, ...revenues];
  }, [
    markupTotals,
    categoriesEnabled,
    ownersAmountEnabled,
    changeTrackingEnabled,
    clientExternalRevisedBudgetEnabled,
  ]);

  const totalsFooter = useMemo(() => {
    const transactions = categoriesEnabled
      ? [
          { id: "name", render: <div /> },
          {
            id: "category",
            render: (
              <Flex width="full" align="center" justify="flex-end">
                <Text weight="bold">Grand total</Text>
              </Flex>
            ),
          },
        ]
      : [
          {
            id: "name",
            render: (
              <Flex width="full" align="center" justify="flex-end">
                <Text weight="bold">Grand total</Text>
              </Flex>
            ),
          },
        ];

    const costs = [
      {
        id: "budget",
        render: (
          <Flex width="full" align="center" justify="flex-end">
            <Text weight="bold">
              {formatCurrency(totals.builderAmount, CURRENCY_FORMAT)}
            </Text>
          </Flex>
        ),
      },
    ];

    if (changeTrackingEnabled) {
      costs.push(
        {
          id: "changes",
          render: (
            <Flex width="full" align="center" justify="flex-end">
              <Text weight="bold">
                {formatCurrency(totals.changeAmount, CURRENCY_FORMAT)}
              </Text>
            </Flex>
          ),
        },
        {
          id: "revisedBudget",
          render: (
            <Flex width="full" align="center" justify="flex-end">
              <Text weight="bold">
                {formatCurrency(totals.builderRevisedAmount, CURRENCY_FORMAT)}
              </Text>
            </Flex>
          ),
        }
      );
    }

    costs.push({
      id: "actual",
      render: (
        <Flex width="full" align="center" justify="flex-end">
          <Text weight="bold">
            {formatCurrency(totals.spent, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      ),
    });

    costs.push({
      id: "undrawnCosts",
      render: (
        <Flex width="full" align="center" justify="flex-end">
          <Text weight="bold">
            {formatCurrency(totals.undrawnCosts, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      ),
    });

    costs.push({
      id: "unpaid",
      render: (
        <Flex width="full" align="center" justify="flex-end">
          <Text weight="bold">
            {formatCurrency(totals.unpaidBills, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      ),
    });

    costs.push({
      id: "costsRemaining",
      render: (
        <Flex width="full" align="center" justify="flex-end">
          <Text
            weight="bold"
            color={
              totals.builderRemainingAmount >= 0 ? "neutral-800" : "warning-200"
            }
          >
            {formatCurrency(totals.builderRemainingAmount, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      ),
    });

    const revenues = [];

    if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
      revenues.push({ id: "markup", render: <div /> });
    }

    if (ownersAmountEnabled) {
      revenues.push({
        id: "ownersBudget",
        render: (
          <Flex width="full" align="center" justify="flex-end">
            <Text weight="bold">
              {formatCurrency(totals.ownersAmount, CURRENCY_FORMAT)}
            </Text>
          </Flex>
        ),
      });

      if (changeTrackingEnabled && clientExternalRevisedBudgetEnabled) {
        revenues.push(
          {
            id: "revenuesChanges",
            render: (
              <Flex width="full" align="center" justify="flex-end">
                <Text weight="bold">
                  {formatCurrency(
                    totals.externalChangeAmount ?? 0,
                    CURRENCY_FORMAT
                  )}
                </Text>
              </Flex>
            ),
          },
          {
            id: "revenuesRevisedBudget",
            render: (
              <Flex width="full" align="center" justify="flex-end">
                <Text weight="bold">
                  {formatCurrency(totals.ownersRevisedAmount, CURRENCY_FORMAT)}
                </Text>
              </Flex>
            ),
          }
        );
      }
    }

    revenues.push(
      {
        id: "drawn",
        render: (
          <Flex width="full" align="center" justify="flex-end">
            <Text weight="bold">
              {formatCurrency(totals.invoicedAmount, CURRENCY_FORMAT)}
            </Text>
          </Flex>
        ),
      },
      {
        id: "remaining",
        render: (
          <Flex width="full" align="center" justify="flex-end">
            <Text
              weight="bold"
              color={
                totals.invoicedRemainingAmount >= 0
                  ? "neutral-800"
                  : "warning-200"
              }
            >
              {formatCurrency(totals.invoicedRemainingAmount, CURRENCY_FORMAT)}
            </Text>
          </Flex>
        ),
      }
    );

    return [...transactions, ...costs, ...revenues];
  }, [
    totals,
    categoriesEnabled,
    ownersAmountEnabled,
    changeTrackingEnabled,
    clientExternalRevisedBudgetEnabled,
  ]);

  const footer = useMemo<MultipleTableFooter>(
    () => ({
      data: [markupsFooter, totalsFooter],
      offset: -64,
    }),
    [markupsFooter, totalsFooter]
  );

  /**
   * The process of opening an specific change in changes dialog starts here
   * but you can check the final logic inside the `ChangesDialog` component.
   */
  useEffect(() => {
    if (locationParam === "changes" && changeParam && !isLoading) {
      changesDialogShow();
    }
  }, [changeParam, isLoading, locationParam, changesDialogShow]);

  return (
    <>
      {(oneSchemaIsLoading || budgetIsLoading) && <Loader position="fixed" />}
      <BudgetsTableContext.Provider
        value={{
          ...lineTotals,
          query,
          filters,
          setQuery,
          viewMode: viewMode.value,
          rawFilters,
          setFilters,
          hasFilters,
          totalOwnersAmount: totals.ownersAmount,
          totalExternalChangeAmount: totals.externalChangeAmount,
          totalOwnersRevisedAmount: totals.ownersRevisedAmount,
          totalInvoicedAmount: totals.invoicedAmount,
          shouldShowSourceType,
          isOwnersBudgetLocked: job.is_owner_budget_locked,
          isBuilderBudgetLocked: job.is_builder_budget_locked,
          totalInvoicedRemainingAmount: totals.invoicedRemainingAmount,
          updateCategory,
          onDeleteBudgetLine,
          onEditBudgetLine,
          curriedOnDeleteMarkup,
          curriedOnEditWorkflow,
          onEditFixedAmountMarkup,
          curriedOnSeeTransactions,
          curriedOnSeeDrawnToDate,
          curriedOnSeeChanges,
          onToggleBudgetLockedStatus,
          curriedOnEditOwnersBudgetChange,
          curriedOnEditBuildersBudgetChange,
          curriedOnOpenOneSchema,
        }}
      >
        <StickyProvider onResize={onResize}>
          <Sticky
            style={{
              marginTop: "calc(var(--spacing-2xl) * -1)",
              paddingTop: "var(--spacing-2xl)",
              paddingBottom: "var(--spacing-lg)",
            }}
          >
            <BudgetsFilters>
              {settings.budgetsViewModeEnabled && (
                <Dropdown placement="bottom-end">
                  <DropdownTrigger animated>
                    View by {viewMode.label}
                  </DropdownTrigger>
                  <DropdownList>
                    <DropdownSelectableItem
                      name="view-mode"
                      data={viewModes}
                      value={viewMode.value}
                      onChange={enhancedSetViewMode}
                    />
                  </DropdownList>
                </Dropdown>
              )}
            </BudgetsFilters>
          </Sticky>
          <StickyMeasurer>
            <MultipleTable
              size="sm"
              data={data}
              header={header}
              footer={footer}
              data-testid="budget-table"
            />
          </StickyMeasurer>
        </StickyProvider>
      </BudgetsTableContext.Provider>
      {changesDialog.isRendered && (
        <ChangesDialog dialog={changesDialog} budgetLine={currentBudgetLine} />
      )}
      {jobTransactionsDialog.isRendered && (
        <JobTransactionsDialog dialog={jobTransactionsDialog} />
      )}
      {transactionDialog.isRendered && currentBudgetLine && (
        <TransactionDialog
          dialog={transactionDialog}
          budgetLine={currentBudgetLine}
        />
      )}
      {drawnToDateDialog.isRendered && (
        <DrawnToDateDialog
          dialog={drawnToDateDialog}
          budgetLine={currentBudgetLine}
        />
      )}
      {markupEditInlineDialog.isRendered && currentBudgetLine && (
        <EditInlineMarkupDialog
          dialog={markupEditInlineDialog}
          budgetLineItem={currentBudgetLine}
        />
      )}
      {markupEditFixedAmountDialog.isRendered && (
        <EditFixedMarkupDialog
          dialog={markupEditFixedAmountDialog}
          markup={currentMarkup}
        />
      )}
      {window.ONESCHEMA_CLIENT_ID &&
        window.ONESCHEMA_TEMPLATE_ID &&
        window.ONESCHEMA_JWT && (
          <OneSchemaImporter
            style={{
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 99999999,
              position: "fixed",
            }}
            inline={false}
            isOpen={oneSchemaIsOpen}
            devMode={import.meta.env.DEV}
            onError={onOneSchemaError}
            userJwt={window.ONESCHEMA_JWT}
            clientId={window.ONESCHEMA_CLIENT_ID}
            onSuccess={onOneSchemaSuccess}
            onLaunched={onOneSchemaLaunched}
            templateKey={window.ONESCHEMA_TEMPLATE_ID}
            importConfig={{ type: "local" }}
            onRequestClose={onRequestClose}
            templateOverrides={templateOverrides}
          />
        )}
    </>
  );
});

BudgetsTable.displayName = "BudgetsTable";
