import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
} from "react";

import type {
  DropdownOnSetValues,
  DropdownProps,
  DropdownValues,
} from "./dropdown";

type Context =
  | {
      flip: DropdownProps["flip"];
      trigger: DropdownProps["trigger"];
      maxWidth?: DropdownProps["maxWidth"];
      listSize?: DropdownProps["listSize"];
      setValues?: Dispatch<SetStateAction<DropdownValues>>;
      hideOnClick?: DropdownProps["hideOnClick"];
      setOnSetValues: Dispatch<SetStateAction<DropdownOnSetValues>>;
    }
  | undefined;

export const DropdownContext = createContext<Context>(undefined);

export const useDropdown = () => useContext(DropdownContext);
