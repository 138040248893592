import type { GetLinkedTransactionsReturn } from "../api/types";
import { TYPE_SORTING_ORDER, TYPE_STRINGS } from "../constants/constants";
import type {
  LinksTables,
  LinkTable,
  LinkTransactionType,
  PropagationDetails,
  PropagationFormValues,
} from "../types";

import { getDetailsFromUrl } from "./get-details-from-url";
import { getDisplayInfo } from "./get-display-info";
import { getLinkParentName } from "./get-link-parent-name";

type GetSummaryTablesParams = {
  propagationFields?: PropagationDetails;
  formValues: PropagationFormValues;
  linkedTransactions?: GetLinkedTransactionsReturn;
};
type GetSummaryTables = (params: GetSummaryTablesParams) => LinksTables;

const getTopTableSummary = ({
  propagationFields,
}: Pick<GetSummaryTablesParams, "propagationFields">) => {
  const topTransactionInfo = getDetailsFromUrl(propagationFields?.url || "");
  const displayInfoParams = {
    id: topTransactionInfo.id!,
    type: topTransactionInfo.type as LinkTransactionType,
    name: propagationFields?.name || "",
    url: propagationFields?.url,
  };
  const topTableTitle = getDisplayInfo(displayInfoParams);
  const objectType = getDisplayInfo(displayInfoParams, {
    hideName: true,
    capitalize: false,
  });
  const topTableData: LinkTable = [];

  const topFields = Object.keys(propagationFields?.fields || {});

  if (topFields.includes("deleted")) {
    topTableData.push({
      type: "deleted",
      objectType,
    });
  } else if (topFields.includes("reverted")) {
    topTableData.push({
      type: "reverted",
    });
  } else if (topFields.includes("archived")) {
    topTableData.push({
      type: "archived",
      objectType: getDisplayInfo(displayInfoParams, {
        hideName: true,
      }),
    });
  } else if (topFields.length) {
    topTableData.push({
      type: "fields",
      fields: topFields,
    });
  }

  const isArchivedOrReverted =
    topFields.includes("reverted") || topFields.includes("archived");

  if (propagationFields?.lines?.length && !isArchivedOrReverted) {
    propagationFields?.lines.forEach((line) => {
      const lineFields = Object.keys(line.fields || {});

      if (!lineFields.length) return;

      if (lineFields.includes("deleted")) {
        topTableData.push({
          type: "deleted",
          objectType: TYPE_STRINGS.line,
        });
      } else if (lineFields.length) {
        const containsCostCodeAccount =
          lineFields.includes("item") || lineFields.includes("account");

        if (containsCostCodeAccount) lineFields.push("accountCostCode");

        const filteredLineFields = containsCostCodeAccount
          ? lineFields.filter(
              (field) => field !== "item" && field !== "account"
            )
          : lineFields;

        topTableData.push({
          type: "fields",
          fields: filteredLineFields,
        });
      }
    });
  }

  return {
    title: topTableTitle,
    data: topTableData,
  };
};

const getLinkedTables = ({
  linkedTransactions,
  formValues,
  topTitle,
}: Omit<GetSummaryTablesParams, "propagationFields"> & {
  topTitle: string;
}) => {
  const topLinkedTo: string[] = [];
  const topUnlinkedTo: string[] = [];

  const linksTables: LinksTables = [];

  const flatLinks = linkedTransactions?.flatMap((item) => item.links) || [];

  Object.entries(formValues).forEach(([key, links]) => {
    const [type, id] = key.split("-");
    const linkedTransaction = linkedTransactions?.find(
      (transaction) => transaction.id === id && transaction.type === type
    );

    Object.entries(links).forEach(([key, values]) => {
      const [linkType, linkId] = key.split("-");
      const linkingTransaction = linkedTransaction?.links.find(
        (transaction) =>
          transaction.id === linkId && transaction.type === linkType
      );
      const isLine = ["line", "invoice_line"].includes(linkType);

      const table: LinksTables[number] = {
        title: linkingTransaction ? getDisplayInfo(linkingTransaction) : "",
        data: [],
      };
      const isUnlinked = Object.values(values).some(
        (value) => value === "unlink"
      );
      const isDeleted = Object.values(values).some(
        (value) => value === "delete"
      );

      const linkedLineInfo = getLinkParentName(
        linkingTransaction?.fields.linkedLine,
        flatLinks
      );
      const linksTo = linkedLineInfo || topTitle;

      if (isUnlinked) {
        table.data.push({
          type: "unlinked",
          from: [linksTo],
        });

        topUnlinkedTo.push(table.title);
        return linksTables.push(table);
      }

      if (isDeleted) {
        const displayInfo = getDisplayInfo(linkingTransaction!, {
          hideName: true,
        });
        table.data.push({
          type: "deleted",
          objectType: isLine ? `${displayInfo} Line` : displayInfo,
        });
        return linksTables.push(table);
      }

      // Always add to top object linked to if its not unlinked or deleted
      if (linksTo === topTitle) topLinkedTo.push(table.title);

      const changedFields = Object.entries(values).reduce(
        (accFields, [field, value]) => {
          if (value !== "old") accFields.push(field);
          return accFields;
        },
        [] as string[]
      );

      if (changedFields.length) {
        table.data.push(
          {
            type: "fields",
            fields: changedFields,
          },
          {
            type: "linkedTo",
            linkedTo: [linksTo],
          }
        );
        return linksTables.push(table);
      }
    });
  });

  return { topLinkedTo, topUnlinkedTo, linksTables };
};

export const getSummaryTables: GetSummaryTables = ({
  propagationFields,
  formValues,
  linkedTransactions,
}) => {
  const topTableSummary = getTopTableSummary({ propagationFields });

  const { topLinkedTo, topUnlinkedTo, linksTables } = getLinkedTables({
    linkedTransactions,
    formValues,
    topTitle: topTableSummary.title,
  });

  const tablesOrder = topTableSummary.title.includes("Draw")
    ? [...TYPE_SORTING_ORDER].reverse()
    : TYPE_SORTING_ORDER;
  const sortedTables = linksTables.sort((a, b) => {
    const typeA = a.title.split(" ")[0];
    const typeB = b.title.split(" ")[0];
    return tablesOrder.indexOf(typeA) - tablesOrder.indexOf(typeB);
  });

  if (topLinkedTo.length) {
    topTableSummary.data.push({
      type: "linkedTo",
      linkedTo: topLinkedTo,
    });
  }
  if (topUnlinkedTo.length) {
    topTableSummary.data.push({
      type: "unlinked",
      from: topUnlinkedTo,
    });
  }
  return [topTableSummary, ...sortedTables];
};
