import React, { useMemo } from "react";
import { Table } from "@adaptive/design-system";

import { usePropagationTable } from "../hooks/use-propagation-table";

export const PropagationTable = () => {
  const { columns, data } = usePropagationTable();

  const header = useMemo(() => ({ sticky: false }), []);

  return <Table size="sm" data={data} header={header} columns={columns} />;
};
