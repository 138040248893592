import React, {
  type FC,
  type MouseEventHandler,
  type PropsWithChildren,
  type ReactNode,
  useMemo,
} from "react";
import { Button, Flex } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";

import { useStageActions, useStageName } from "./context";
import {
  DefaultContentContainer,
  DefaultFooterContainer,
  StageContentFragment,
} from "./factory-components";
import type {
  Stage,
  StageContentMap,
  StageFooterMap,
  StagesBase,
} from "./types";

type Wrappable = PropsWithChildren<{
  Container?: FC<{ children: ReactNode | ReactNode[] }>;
}>;

type StageFooterProps = Wrappable & {
  onCancel?: () => void;
};

export const asStageFooter =
  <T extends StagesBase>(footerMap: StageFooterMap<T>) =>
  // eslint-disable-next-line react/display-name
  ({ Container = DefaultFooterContainer, onCancel }: StageFooterProps) => {
    const stageName = useStageName();

    const { advance, recede } = useStageActions();

    const close = useEvent<MouseEventHandler>((e) => {
      e.preventDefault();
      onCancel?.();
    });

    const Actions = useMemo(() => (footerMap as any)[stageName], [stageName]);

    return (
      <Container>
        <Button variant="text" color="neutral" size="lg" onClick={close}>
          Cancel
        </Button>
        <Flex justify="space-between" align="center" gap="2xl" grow>
          {Actions && <Actions onAdvance={advance} onRecede={recede} />}
        </Flex>
      </Container>
    );
  };

type StageContentProps = Wrappable & Record<string, unknown>;

export const asStageContent =
  <T extends StagesBase>(contentMap: StageContentMap<T>) =>
  // eslint-disable-next-line react/display-name
  ({
    Container = DefaultContentContainer,
    children,
    ...props
  }: StageContentProps) => {
    const stageName = useStageName();

    const Stage = useMemo(() => contentMap[stageName as Stage<T>], [stageName]);

    if (!Stage) return null;

    return (
      <Container>
        <StageContentFragment Stage={Stage} stageProps={props}>
          {children}
        </StageContentFragment>
      </Container>
    );
  };
