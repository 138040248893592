import type { Form } from "../store/slice";

import { getInitialAddress } from "./get-initial-address";
import { getInitialBanking } from "./get-initial-banking";
import { getInitialDocuments } from "./get-initial-documents";
import { getInitialVendor } from "./get-initial-vendor";

export const getInitialForm = (displayName?: string): Form => {
  const info: Form["info"] = {
    ...getInitialVendor(),
    address: getInitialAddress(),
  };

  return {
    info: {
      ...info,
      displayName: displayName || "",
    },
    banking: getInitialBanking(),
    documents: getInitialDocuments(),
    isSubmitting: false,
    errors: null,
    initialStage: "info",
    initialSnapshot: {
      info,
      banking: getInitialBanking(),
      documents: getInitialDocuments(),
    },
  };
};
