import React, { memo, useMemo } from "react";
import {
  ComboBox,
  Flex,
  Link,
  NumberField,
  PhoneField,
  Switch,
  Text,
  TextField,
} from "@adaptive/design-system";
import {
  CostCodeAccountComboBox,
  type CostCodeAccountComboBoxProps,
} from "@components/cost-code-account-combobox";
import { renderCostCodeAccountLabel } from "@components/cost-code-account-combobox/cost-code-account-combobox-utils";
import { useCommonVendorsSimplified } from "@hooks/useCommonVendorsSimplified";
import { STATE_OPTIONS } from "@shared/constants";
import { useAppSelector } from "@store/hooks";
import { useClientInfo, useClientSettings } from "@store/user";

import {
  DEFAULT_TRANSACTION_TYPES,
  VENDOR_CODES_ARTICLE_URL,
} from "../constants/constants";
import { useErrorField } from "../hooks/use-error-field";
import { useVendorAction } from "../hooks/use-vendor-action";
import { useVendorInfo } from "../hooks/use-vendor-info";
import { vendorSelectors } from "../store/selectors";

const COST_CODE_ACCOUNT_COMBOBOX_ACCOUNT_FILTERS: CostCodeAccountComboBoxProps<true>["accountFilters"] =
  { only_line_item_accounts: true };

const COST_CODE_ACCOUNT_COMBOBOX_FILTERS: CostCodeAccountComboBoxProps<true>["filters"] =
  ["costCodeAccount"];

export const FormInfo = memo(() => {
  const {
    displayName,
    email,
    phoneNumber,
    address,
    defaultItem,
    defaultAccount,
    defaultPaymentDays,
    defaultAccounts,
    defaultItems,
    commonVendor,
    types,
    isStoredEmailValid,
  } = useAppSelector(vendorSelectors.info);

  const { isSubmitting, canManageNonPaymentInfo } = useVendorInfo();

  const {
    setDisplayName,
    setEmail,
    setDefaultPaymentDays,
    setPhoneNumber,
    setCity,
    setState,
    setDefaultCostCodeAccount,
    setDefaultCostCodeAccounts,
    setCommonVendor,
    setAddressLine1,
    setAddressLine2,
    setRestrictedToContentTypes,
    setPostalCode,
  } = useVendorAction();

  const [displayNameError, resetDisplayNameError] =
    useErrorField("displayName");

  const [phoneError, resetPhoneError] = useErrorField("phoneNumber");

  const [emailError, resetEmailError] = useErrorField("email");

  const { city, line1, line2, state, postalCode } = address || {};

  const commonVendors = useCommonVendorsSimplified();

  const { client: currentClient } = useClientInfo();

  const { failSafeCostCodesEnabled } = useClientSettings();

  const costCodeAccountValue = useMemo(() => {
    if (!defaultItem && !defaultAccount) return "";

    return {
      value: (defaultItem?.url || defaultAccount?.url)!,
      label: (defaultItem?.displayName || defaultAccount?.displayName)!,
    };
  }, [defaultItem, defaultAccount]);

  const costCodeAccountValues = useMemo(() => {
    if (!defaultItems && !defaultAccounts) return [];

    return defaultItems?.concat(defaultAccounts || []).map((costCode) => ({
      value: (costCode?.url || costCode?.url)!,
      label: (costCode?.displayName || costCode?.displayName)!,
    }));
  }, [defaultItems, defaultAccounts]);

  return (
    <Flex direction="column" minHeight="full">
      {!failSafeCostCodesEnabled ? (
        <>
          <Flex direction="row" gap="xl">
            <Flex direction="column" width="full">
              <TextField
                label="Vendor name"
                value={displayName}
                onChange={setDisplayName}
                onFocus={resetDisplayNameError}
                errorMessage={displayNameError}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                required
                data-testid="vendor-name"
              />
            </Flex>
            <Flex direction="column" width="full">
              <Switch
                label="Receipts only"
                checked={types.includes("expense")}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                onChange={(val) =>
                  setRestrictedToContentTypes(val ? ["expense"] : [])
                }
                placement="top"
                hintMessage="If enabled, emails forwarded for this vendor will be converted to receipts"
              />
            </Flex>
          </Flex>
          <Flex direction="row" gap="xl">
            <Flex direction="column" width="full">
              <CostCodeAccountComboBox
                label={(props) => (
                  <>
                    Default&nbsp;
                    {renderCostCodeAccountLabel({
                      ...props,
                      transform: "lowercase",
                    })}
                  </>
                )}
                value={costCodeAccountValue}
                onChange={setDefaultCostCodeAccount}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                accountFilters={{ only_line_item_accounts: true }}
              />
            </Flex>
            <Flex direction="row" width="full">
              <NumberField
                label="Default payment terms (days)"
                value={defaultPaymentDays ?? null}
                onChange={setDefaultPaymentDays}
                disabled={isSubmitting || !canManageNonPaymentInfo}
                data-testid="default-payment-days"
                hintMessage="Fallback for due date if a date can't be read off of bills from this vendor."
              />
            </Flex>
          </Flex>

          <Flex width="full" gap="xl" justify="space-between" align="stretch">
            <TextField
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
              onFocus={resetEmailError}
              errorMessage={emailError}
              disabled={
                !canManageNonPaymentInfo || !isStoredEmailValid || isSubmitting
              }
              data-testid="vendor-email"
              helperMessage={
                !isStoredEmailValid &&
                "Invalid email, please edit directly in QuickBooks"
              }
            />

            <PhoneField
              label="Phone"
              value={phoneNumber || ""}
              onChange={setPhoneNumber}
              onFocus={resetPhoneError}
              errorMessage={phoneError}
              disabled={!canManageNonPaymentInfo || isSubmitting}
              data-testid="vendor-phone"
            />
          </Flex>

          <Flex width="full" gap="xl" justify="space-between" align="stretch">
            <TextField
              label="Address 1"
              value={line1 || ""}
              onChange={setAddressLine1}
              disabled={!canManageNonPaymentInfo || isSubmitting}
              data-testid="vendor-address1"
            />
            <TextField
              label="Address 2"
              value={line2 || ""}
              onChange={setAddressLine2}
              disabled={!canManageNonPaymentInfo || isSubmitting}
            />
          </Flex>

          <Flex width="full" gap="xl" justify="space-between" align="stretch">
            <Flex width="full">
              <TextField
                label="City"
                value={city || ""}
                onChange={setCity}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                data-testid="vendor-city"
              />
            </Flex>

            <Flex width="full" gap="xl" justify="space-between" align="stretch">
              <ComboBox
                data={STATE_OPTIONS}
                label="State"
                value={state || ""}
                onChange={(_, option) => setState(option?.value || "")}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                data-testid="vendor-state"
                placement="top"
                listSize={8}
              />
              <TextField
                label="ZIP"
                value={postalCode || ""}
                onChange={setPostalCode}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                data-testid="vendor-zip"
              />
            </Flex>
          </Flex>

          <ComboBox
            data={commonVendors.data}
            loading={commonVendors.loading === "loading"}
            label="Identify as common vendor"
            value={commonVendor || ""}
            onChange={setCommonVendor}
            disabled={!canManageNonPaymentInfo || isSubmitting}
            data-testid="common-vendor"
            placement={commonVendors.data.length > 1 ? "top" : "bottom"}
            listSize={8}
          />
        </>
      ) : (
        <>
          <Flex direction="row" gap="xl">
            <Flex direction="column" width="full">
              <TextField
                label="Vendor name"
                value={displayName}
                onChange={setDisplayName}
                onFocus={resetDisplayNameError}
                errorMessage={displayNameError}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                required
                data-testid="vendor-name"
              />
            </Flex>
          </Flex>
          <Flex width="full" gap="xl" justify="space-between" align="stretch">
            <TextField
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
              onFocus={resetEmailError}
              errorMessage={emailError}
              disabled={
                !canManageNonPaymentInfo || !isStoredEmailValid || isSubmitting
              }
              data-testid="vendor-email"
              helperMessage={
                !isStoredEmailValid &&
                "Invalid email, please edit directly in QuickBooks"
              }
            />

            <PhoneField
              label="Phone"
              value={phoneNumber || ""}
              onChange={setPhoneNumber}
              onFocus={resetPhoneError}
              errorMessage={phoneError}
              disabled={!canManageNonPaymentInfo || isSubmitting}
              data-testid="vendor-phone"
            />
          </Flex>

          <Flex width="full" gap="xl" justify="space-between" align="stretch">
            <TextField
              label="Address 1"
              value={line1 || ""}
              onChange={setAddressLine1}
              disabled={!canManageNonPaymentInfo || isSubmitting}
              data-testid="vendor-address1"
            />
            <TextField
              label="Address 2"
              value={line2 || ""}
              onChange={setAddressLine2}
              disabled={!canManageNonPaymentInfo || isSubmitting}
            />
          </Flex>

          <Flex width="full" gap="xl" justify="space-between" align="stretch">
            <Flex width="full">
              <TextField
                label="City"
                value={city || ""}
                onChange={setCity}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                data-testid="vendor-city"
              />
            </Flex>

            <Flex width="full" gap="xl" justify="space-between" align="stretch">
              <ComboBox
                data={STATE_OPTIONS}
                label="State"
                value={state || ""}
                onChange={(_, option) => setState(option?.value || "")}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                data-testid="vendor-state"
                placement="top"
                listSize={8}
              />
              <TextField
                label="ZIP"
                value={postalCode || ""}
                onChange={setPostalCode}
                disabled={!canManageNonPaymentInfo || isSubmitting}
                data-testid="vendor-zip"
              />
            </Flex>
          </Flex>
          <Flex width="full" gap="xl" justify="space-between" align="stretch">
            <NumberField
              label="Default payment terms (days)"
              value={defaultPaymentDays ?? null}
              onChange={setDefaultPaymentDays}
              disabled={isSubmitting || !canManageNonPaymentInfo}
              data-testid="default-payment-days"
              hintMessage="Fallback for due date if a date can't be read off of bills from this vendor."
            />
            <ComboBox
              data={DEFAULT_TRANSACTION_TYPES}
              label="Default transaction type"
              value={types[0]}
              onChange={(val) => setRestrictedToContentTypes([val])}
              disabled={!canManageNonPaymentInfo || isSubmitting}
              data-testid="email-forward-destination"
              placement="bottom"
              listSize={2}
              hintMessage={`Forward an email to ${currentClient?.email || "your client email"}, or drag & drop an image to the Bills page and it will be set as a Bill or Receipt automatically base on the vendor.`}
            />
          </Flex>
          <Flex
            width="full"
            gap="xl"
            justify="space-between"
            align="stretch"
          ></Flex>
          <CostCodeAccountComboBox
            label="Vendor codes"
            value={costCodeAccountValues}
            onChange={setDefaultCostCodeAccounts}
            disabled={!canManageNonPaymentInfo || isSubmitting}
            accountFilters={COST_CODE_ACCOUNT_COMBOBOX_ACCOUNT_FILTERS}
            filters={COST_CODE_ACCOUNT_COMBOBOX_FILTERS}
            multiple
            placeholder="Select"
            hintMessage={
              <>
                Limit cost codes on transactions for this vendor. New vendor
                codes are added automatically when used on bills, receipts or
                POs for this vendor.{" "}
                <Text
                  size="sm"
                  as={Link}
                  href={VENDOR_CODES_ARTICLE_URL}
                  target="_blank"
                  variant="success"
                  style={{ textDecoration: "none" }}
                  onClick={(e: any) => e.stopPropagation()}
                >
                  Learn more
                </Text>
                .
              </>
            }
          />
        </>
      )}
    </Flex>
  );
});

FormInfo.displayName = "FormInfo";
