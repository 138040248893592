import { type RefObject, useEffect } from "react";

import { useDeepMemo } from "../use-deep-memo";
import { useEvent } from "../use-event";

export const useMutationObserver = (
  ref: RefObject<HTMLElement | null>,
  onChange: MutationCallback,
  options: MutationObserverInit = {
    subtree: true,
    childList: true,
    attributes: true,
    characterData: true,
  }
) => {
  const enhancedOnChange = useEvent(onChange);

  const memoizedOptions = useDeepMemo(() => options, [options]);

  useEffect(() => {
    const el = ref.current;

    if (!el) return;

    const observer = new MutationObserver(enhancedOnChange);

    observer.observe(el, memoizedOptions);

    return () => {
      observer.disconnect();
    };
  }, [ref, enhancedOnChange, memoizedOptions]);
};
