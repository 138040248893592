import React, { useCallback, useMemo, useState } from "react";
import {
  Alert,
  AlertContent,
  Button,
  Card,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { usePeopleInfo } from "@store/user";

import { type Role, useRoleAction } from "../../../../shared/store/role";
import type { Dialog } from "../types";

type Props = {
  dialog: Dialog;
  role: Role;
};

export const DeleteRole: React.FC<Props> = ({ dialog, role }) => {
  const { roles, deleteRole } = useRoleAction();
  const { people } = usePeopleInfo();

  const [currentRole, setCurrentRole] = useState<Role | undefined>();
  const [loading, setLoading] = useState(false);
  const onSelectRole = useEvent((role: Role | undefined) => {
    setCurrentRole(role);
  });
  const peopleUsingRole = useMemo(
    () => people.filter((person) => person.role.url === role.url).length,
    [people, role]
  );

  const onDelete = useCallback(() => {
    if (role && currentRole) {
      setLoading(true);
      deleteRole(role, currentRole)
        .then(() => {
          toast.success(
            `Role ${role.name} was deleted and users were switched to ${currentRole.name}`
          );
          dialog.setStep("role-list");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [deleteRole, dialog, role, currentRole]);

  return (
    <>
      <DialogHeader>Deleting role: {role.name}</DialogHeader>
      <DialogContent>
        <Flex direction="column" justify="center" gap="md">
          <Alert variant="warning">
            <AlertContent>
              <Flex gap="sm">
                <Text as="span" size="md">
                  The role you are about to delete is being used by{" "}
                  <Text as="strong" weight="bold">
                    {peopleUsingRole}
                  </Text>{" "}
                  people
                </Text>
              </Flex>
            </AlertContent>
          </Alert>
          {roles
            .filter((item) => item.id !== role.id)
            .map((item) => {
              const isSelected = currentRole?.id === item.id;

              return (
                <Card
                  key={item.id}
                  as="button"
                  type="button"
                  color={isSelected ? "success" : "neutral"}
                  onClick={() => onSelectRole(item)}
                  variant={isSelected ? "solid" : "ghost"}
                >
                  <Flex gap="lg" align="center" justify="space-between">
                    <Flex direction="column" gap="sm">
                      <Text size="sm" weight="bold">
                        {item.name}
                      </Text>
                      <Text size="sm" align="justify">
                        {item.description}
                      </Text>
                    </Flex>
                  </Flex>
                </Card>
              );
            })}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button
          block
          color="error"
          disabled={!currentRole || loading}
          onClick={onDelete}
        >
          Switch users to a different role and delete this one
        </Button>
      </DialogFooter>
    </>
  );
};
