import { dotObject } from "@adaptive/design-system/utils";

import { FIELD_STRINGS } from "../constants/constants";
import type { PropagationDetails } from "../types";

type ValuesFieldsType =
  | PropagationDetails["fields"]
  | Exclude<PropagationDetails["lines"], undefined>[number]["fields"];

type GetFieldsToShow = (params: {
  values: ValuesFieldsType;
  prevValues: ValuesFieldsType;
  fields: string[];
  isForcedPropagation?: boolean;
}) => string[];

const validFields = Object.keys(FIELD_STRINGS).filter(
  (field) => field !== "customer"
);

export const getFieldsToShow: GetFieldsToShow = ({
  values,
  prevValues,
  fields,
  isForcedPropagation,
}) =>
  fields.filter((field) => {
    if (isForcedPropagation && validFields.includes(field)) return true;

    const newValue = values ? dotObject.get(values, field) : "";
    const prevValue = prevValues ? dotObject.get(prevValues, field) : "";

    return newValue !== prevValue;
  });
