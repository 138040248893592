import React from "react";
import { Route, Routes as ReactRouterRoutes } from "react-router";
import { withSentryReactRouterV6Routing } from "@sentry/react";

import { ForgotPassword } from "./components/forgot-password";
import { Login } from "./components/login";
import { ResetPassword } from "./components/reset-password";
import { SignUp } from "./components/sign-up";

const Routes = withSentryReactRouterV6Routing(ReactRouterRoutes);

export const Auth = () => {
  return (
    <Routes>
      <Route path="accounts">
        <Route element={<Login />} path="login" />
        <Route element={<ForgotPassword />} path="forgot-password" />
        <Route element={<SignUp />} path="sign-up/:token" />
        <Route element={<ResetPassword />} path="reset-password/:uid/:token" />
      </Route>
    </Routes>
  );
};
