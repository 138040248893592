export const PAYMENT_STEP_INFO_STRINGS = {
  PAYMENT_INFO_TITLE: "Payment",
  PAYMENT_FROM_TITLE: "Payment from",
  PAYMENT_TO_TITLE: "Payment to",
  PAYMENT_REF_TITLE: "Ref #",
  LIEN_WAIVER_TITLE: "Lien waiver",
  LIEN_WAIVER_SIGNED_TITLE: "signed",
  LIEN_WAIVER_REQUESTED_TITLE: "requested",
  VIEW_PAYMENT_DETAILS_WITH_LW: "View payment & lien waiver",
  VIEW_PAYMENT_DETAILS: "View payment",
  PAYMENT_METHOD_TITLE: "Payment method",
  CARD_TRANSACTION_TITLE: "Card transaction",
  EM_DASH: "—",
};

export const PAYMENT_STEP_FORM_STRINGS = {
  PAID: "paid",
  BILL_WAITING_FOR_PAYMENT_MESSAGE: "You paid all bills waiting for payment",
  BILL_ALREADY_PAID_MESSAGE: "This bill has already been paid",
  SELECT_ACCOUNT_TO_PAY: "Select an account from which to pay",
  SELECT_ACCOUNT_FROM_WHICH_BILL_PAID:
    "Select an account from which this bill was paid",
  AMOUNT_TO_PAY_REQUIRED: "Amount to pay is required",
  PAYMENT_AMOUNT_T0_HIGH:
    "Payment amount cannot be larger than the bill balance",
  RESTORE_TITLE: "Restore",
  REVIEW_AND_PAY_TITLE: "Review & pay",
  REVIEW_AND_PAY_UNAVAILABLE_MESSAGE:
    "“Review & pay” unavailable with selected payment method. Use “Mark as paid”",
  CLOSE_TITLE: "Close",
  CANCEL_PAYMENT_TITLE: "Cancel payment",
  UNSAVED_CHANGES_ALERT:
    "You have unsaved changes. Save changes first to proceed with payment",
  RESTORE_TRANSACTION_MESSAGE: "in order to make any modifications to it",
  FROM_ACCOUNT_TITLE: "Pay from",
  PAYMENT_SECTION_TITLE_WITH_LW: "Payment & lien waiver",
  PAYMENT_SECTION_TITLE: "Payment",
  CARD_TRANSACTION_TITLE: "Card transaction",
  CARD_TRANSACTION_PLACEHOLDER: "Match to an existing card transaction",
  OPEN_BALANCE_TITLE: "Open bill balance",
  VENDOR_CREDITS_TITLE: "Vendor credits",
  EDIT_CREDITS_TITLE: "Edit credits",
  AMOUNT_TO_PAY_TITLE: "This payment",
  REMAINING_BALANCE_TITLE: "Remaining balance",
  PAYMENT_METHOD_TITLE: "Payment method",
  VENDOR_ADDRESS_TITLE: "Vendor address",
  ADD_VENDOR_ADDRESS_TITLE: "Do you need to change the vendor’s address?",
  ADD_IT_HERE_TITLE: "Add it here",
  DEBIT_DATE_TITLE: "Debit date",
  EDIT_DEBIT_DATE_TITLE: "Edit date",
  ADD_ACH_DETAILS_TITLE: "Already have their ACH bank details?",
  PAY_WHEN_ACH_DATA_COLLECTED_TITLE:
    "Pay when vendor's bank details are collected",
  PAY_WHEN_LIEN_WAIVER_SIGNED_TITLE: "Pay when lien waiver is signed",
  SAVE_TITLE: "Save",
  CANCEL_TITLE: "Cancel",
  SELECT_DEBIT_DATE_TITLE: "Select debit date",
  DEBIT_TITLE: "Debit",
  CANNOT_SELECT_FUTURE_DATES:
    "If you select a date in the past you can only Mark as Paid.",
  PAID_SUCCESSFULLY: "You've successfully paid Bill",
  MATCHING_TRANSACTION_TO_NEW_PAYMENT_DIALOG_TITLE:
    "Select a transaction from your card history that matches with this payment",
  MATCHING_TRANSACTION_TO_NEW_PAYMENT_DIALOG_SUBTITLE:
    "If you don’t see what you’re looking for, your payment may not have been fully processed yet.",
  CLICK_HERE_TITLE: "Click here",
  LINK_ACCOUNT_TITLE: "Choose QuickBooks payment account",
};

export const CONFIRMATION_PAYMENT_STRINGS = {
  REMAINING_BALANCE_TITLE: "Resulting balance",
  FROM_ACCOUNT_TITLE: "From account",
  CONFIRMATION_PAYMENT_TITLE: "Total payment amount:",
  BILLS_TITLE: "Bills",
  CREDITS_TITLE: "Credits",
  PAYMENT_TITLE: "Payment",
  VENDOR_EMAIL_ADDRESS_TITLE: "Vendor email address",
  CHANGE_VENDOR_EMAIL_ADDRESS_TITLE:
    "Do you need to change the vendor's email?",
  ADD_IT_HERE_TITLE: "Add it here",
  ADD_VENDOR_EMAIL: "Set vendor email",
  BACK_TITLE: "Back",
  PAY_TITLE: "Pay",
  SEND_LIEN_WAIVER_TITLE:
    "Lien waiver & payment confirmation will be sent to this email",
  ADD_COMMENT: "Add a comment for the vendor or attach additional files",
  ADD_COMMENT_PLACEHOLDER:
    "Comments are visible to other users in your workspace. The vendor will be notified directly.",
  ATTACH_FILES: "Attach files",
  SUCCESSFUL_PAYMENT_TITLE: "You've successfully paid Bill",
};

export const BILL_PAID_SUCCESSFULLY_TOAST = (docNumber: string) =>
  `You've successfully paid Bill #${docNumber}!`;

export const RESTORE_TRANSACTION_LINK = (transactionType: string) =>
  `Restore this ${transactionType}`;

export const TRANSACTION_NOT_EDITABLE = (transactionType: string) =>
  `This ${transactionType} is not editable`;
