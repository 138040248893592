import type { Option } from "@shared/types";

import { isAccount } from "../is-account";

type ItemOption = Option & { id?: number | string };

export const getCostCodeAccountValues = (item: ItemOption) => {
  const id = item?.id?.toString();

  const isAccountValue = isAccount(item);

  return {
    item: !isAccountValue ? id : null,
    account: isAccountValue ? id : null,
  };
};

export const getItemUrls = (url: string) => {
  const isAccountValue = isAccount(url);

  return {
    item: !isAccountValue ? url : null,
    account: isAccountValue ? url : null,
  };
};
