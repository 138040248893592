import React from "react";
import { Link as ReactRouterLink } from "react-router";
import { Button, Flex, Icon } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { MainHeader, MainSubtitle, MainTitle } from "@components/main";
import { useExpenseAction, useExpensePermissions } from "@store/expenses";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";

import { STRINGS } from "./utils/constants";
import { ImportLaborButton } from "./importer";

const NewExpenseButton = () => {
  const { createNew } = useExpenseAction();

  const { canAddExpense } = useExpensePermissions();

  const onNewExpenseClick = useEvent(() => createNew());

  return canAddExpense ? (
    <Button
      as={ReactRouterLink}
      to="/expenses/new"
      state={{ prev: window.location.pathname + window.location.search }}
      onClick={onNewExpenseClick}
      data-testid="new-expense-button"
    >
      New receipt
    </Button>
  ) : null;
};

export const Heading = () => {
  const settings = useClientSettings();

  return (
    <MainHeader>
      <Flex align="center" height="full" gap="xl">
        <Flex direction="column" grow>
          <MainTitle>{STRINGS.title}</MainTitle>
          <MainSubtitle>{STRINGS.subtitle}</MainSubtitle>
        </Flex>
        {settings.cardFeedEnabled && (
          <Button
            as="a"
            href="https://help.adaptive.build/en/articles/9750251-new-expenses-experience-beta-release"
            color="neutral"
            target="_blank"
            variant="ghost"
            onClick={() => analytics.track("expenseTutorial")}
          >
            <Icon name="info-circle" />
            {STRINGS.viewTutorialAction}
          </Button>
        )}
        {settings.receiptImportEnabled && <ImportLaborButton />}
        <NewExpenseButton />
      </Flex>
    </MainHeader>
  );
};
