import React, { type PropsWithChildren } from "react";
import {
  Avatar,
  Button,
  Flex,
  LabelValueGroup,
  Tag,
  Text,
} from "@adaptive/design-system";
import { useDeepMemo, useMultiStepDialog } from "@adaptive/design-system/hooks";
import { useTwoFactorAuth } from "@store/ui";

import {
  Main,
  MainContent,
  MainHeader,
  MainTitle,
} from "../../../shared/components/main";
import { useUserInfo } from "../../../shared/store/user";

import { ProfileEditDialog } from "./profile-edit-dialog";
import { ProfileNotificationForm } from "./profile-notification-form";
import type { Step } from "./types";

type InfoValueProps = PropsWithChildren<{
  verified?: boolean;
  "data-testid": string;
}>;

const InfoValue = ({
  children,
  verified,
  "data-testid": testId,
}: InfoValueProps) => {
  const { checkTwoFactorAuth } = useTwoFactorAuth();

  return verified === undefined ? (
    children
  ) : (
    <Flex gap="sm" align="center" height="4xl">
      {children}
      <Flex>
        {verified ? (
          <Tag size="sm" color="success">
            Verified
          </Tag>
        ) : (
          <Button
            size="sm"
            color="warning"
            variant="ghost"
            onClick={() => checkTwoFactorAuth()}
            data-testid={testId}
          >
            Verify now
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export const Profile = () => {
  const { user, role } = useUserInfo();

  const profileEditDialog = useMultiStepDialog<Step>({
    initialStep: "profile",
  });

  const infoData = useDeepMemo(
    () => [
      {
        label: "Email",
        value: (
          <InfoValue
            verified={
              user.preferred_mfa_method === "EMAIL"
                ? user.email_verified
                : undefined
            }
            data-testid="verify-email"
          >
            {user.email}
          </InfoValue>
        ),
      },
      {
        label: "Phone",
        value: (
          <InfoValue
            verified={
              user.preferred_mfa_method === "SMS"
                ? user.phone_number_verified
                : undefined
            }
            data-testid="verify-phone-number"
          >
            {user.phone_number}
          </InfoValue>
        ),
      },
    ],
    [user]
  );

  return (
    <>
      <Main>
        <MainHeader>
          <MainTitle>My profile</MainTitle>
        </MainHeader>
        <MainContent>
          <Flex gap="5xl" shrink={false} maxWidth="972px" direction="column">
            <Flex gap="xl" direction="column">
              <Flex gap="4xl">
                <Avatar size={140} name={user.full_name} data-testid="avatar" />

                <Flex gap="md" direction="column">
                  <Flex direction="column">
                    <Text data-testid="user-name" size="2xl" weight="bold">
                      {user.full_name}
                    </Text>
                    {role?.name && (
                      <Text data-testid="user-role" size="sm" weight="bold">
                        {role.name}
                      </Text>
                    )}
                  </Flex>

                  <LabelValueGroup
                    data={infoData}
                    compact
                    data-testid="user-info"
                  />

                  <Flex>
                    <Flex>
                      <Button
                        block
                        size="sm"
                        variant="ghost"
                        onClick={profileEditDialog.show}
                        data-testid="edit-profile-button"
                      >
                        Edit
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <ProfileNotificationForm />
          </Flex>
        </MainContent>
      </Main>

      <ProfileEditDialog dialog={profileEditDialog} />
    </>
  );
};
