import signatureFont from "../../assets/fonts/signature.woff2";
import { loadFont } from "../load-font";

type GenerateSignatureHandler = (
  value: string,
  params?: { padding?: number; fontSize?: number }
) => Promise<{ url: string; name: string; width: number; height: number }>;

const RIGHT_OFFSET = 4;

const DEFAULT_PARAMS = { padding: 16, fontSize: 56 };

export const generateSignature: GenerateSignatureHandler = async (
  value,
  {
    padding = DEFAULT_PARAMS.padding,
    fontSize = DEFAULT_PARAMS.fontSize,
  } = DEFAULT_PARAMS
) => {
  await loadFont({ url: signatureFont, family: "Kristi" });

  const font = `${fontSize}px 'Kristi', cursive`;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) throw new Error("Failed to get canvas context");

  ctx.font = font;
  const metrics = ctx.measureText(value);
  const textWidth = metrics.width;
  const textHeight =
    metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

  const canvasWidth = textWidth + padding * 2 + RIGHT_OFFSET;
  const canvasHeight = textHeight + padding * 2;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  ctx.font = font;
  ctx.textBaseline = "alphabetic";
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  const x = padding;
  const y = padding + metrics.actualBoundingBoxAscent;
  ctx.fillText(value, x, y);

  const url = canvas.toDataURL();

  canvas.remove();

  return { url, name: value, width: canvasWidth, height: canvasHeight };
};
