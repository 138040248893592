import React, { memo } from "react";
import { MenuSeparator } from "@ariakit/react";

import styles from "./dropdown.module.css";

const DropdownSeparator = () => (
  <MenuSeparator className={styles["dropdown-separator"]} />
);

const MemoizedDropdownSeparator = memo(DropdownSeparator);

export { MemoizedDropdownSeparator as DropdownSeparator };
