import type { Address } from "../api/types";

export const getInitialAddress = (): Address => ({
  url: "",
  city: "",
  line1: "",
  line2: "",
  state: "",
  postalCode: "",
});
