const loadedFonts = new Set();

type LoadFontParams = { url: string; family: string };

export const loadFont = ({ url, family }: LoadFontParams) => {
  if (loadedFonts.has(family)) return Promise.resolve();

  const font = new FontFace(family, `url(${url})`);

  return font.load().then((loadedFace) => {
    loadedFonts.add(family);
    document.fonts.add(loadedFace);
  });
};
