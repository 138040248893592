import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link as ReactRouterLink, useLocation } from "react-router";
import {
  Button,
  Flex,
  Icon,
  Table,
  Tag,
  TagGroup,
  Text,
  Tooltip,
  VisuallyHidden,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
  suffixify,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { useConvertToAdaptiveMutation } from "@api/purchase-orders";
import { AdaptiveIcon } from "@components/adaptive-icon";
import { CostCodeAccountInfo } from "@components/cost-code-account-info";
import { QuickBooksIcon } from "@components/quickbooks-icon";
import { VENDOR_REQUEST_STATUS } from "@components/request-vendor-po-signature/constants";
import { setNeedsRefresh } from "@store/purchaseOrderListSlice";
import { usePurchaseOrderDrawer } from "@store/ui";
import { useClientSettings } from "@store/user";
import { isListEmpty } from "@utils/usefulFunctions";
import { poSignatureTagColor } from "@utils/usefulFunctions";

import {
  HUMAN_READABLE_PO_SIGNATURE_STATUS,
  PO_SIGNATURE_STATUSES,
} from "./constants";
import { PurchaseOrderLearnMoreLink } from "./purchase-order-learn-more-link";
import { promptConversionToast, promptConversionWarning } from "./utils";

const formatDateString = (dateString) =>
  `${formatDate(parseDate(dateString ?? "", ["yyyy-MM-dd hh:mm", "iso"]), "P")}`;

const TABLE_TEST_ID = "purchase-orders-table";

const TypeRender = ({ id, type }) => {
  const dispatch = useDispatch();

  const [convertToAdaptive, convertToAdaptiveInfo] =
    useConvertToAdaptiveMutation();

  const onConvert = useEvent(() => {
    const handle = async () => {
      try {
        await convertToAdaptive(id).unwrap();
        promptConversionToast();
        dispatch(setNeedsRefresh({ status: "open", refresh: true }));
        dispatch(setNeedsRefresh({ status: "closed", refresh: true }));
      } catch (e) {
        handleErrors(e);
      }
    };

    promptConversionWarning({ onConfirm: handle });
  });

  if (type === "qb") {
    return (
      <Flex align="center" justify="space-between">
        <Flex align="center" gap="md">
          <Tooltip
            as={QuickBooksIcon}
            size={18}
            message={
              <>
                You cannot edit this QuickBooks PO or any of its
                <br />
                linked bills in Adaptive. Convert it to an Adaptive
                <br />
                PO for full edit capabilities. <PurchaseOrderLearnMoreLink />
              </>
            }
          />
          <Text>QuickBooks</Text>
        </Flex>
        <Tooltip
          as={Button}
          size="sm"
          color="neutral"
          variant="text"
          onClick={onConvert}
          message="Convert to Adaptive PO"
          disabled={convertToAdaptiveInfo.isLoading}
        >
          <Icon name="merge" />
        </Tooltip>
      </Flex>
    );
  }

  if (type === "adaptive") {
    return (
      <Flex gap="md" align="center">
        <Tooltip
          as={AdaptiveIcon}
          width={18}
          height={18}
          variant="circle"
          message={
            <>
              This Adaptive PO is fully editable and does not
              <br />
              sync with QuickBooks. <PurchaseOrderLearnMoreLink />
            </>
          }
        />
        <Text>Adaptive</Text>
      </Flex>
    );
  }

  return null;
};

export const PurchaseOrdersTable = ({
  data,
  status,
  hasFilters,
  sort,
  loading,
  isError,
  selected,
  pagination,
  setSelected,
  stickyOffset,
  clearFilters,
}) => {
  const settings = useClientSettings();

  const { search } = useLocation();

  const columns = useMemo(() => {
    const columns = [
      {
        id: "doc_number",
        name: "PO #",
        width: 200,
        sortable: true,
        render: (row) => (
          <span data-testid={suffixify(TABLE_TEST_ID, "doc-number")}>
            {row.doc_number}
          </span>
        ),
      },
    ];

    if (settings.adaptivePosEnabled) {
      columns.push({
        id: "type",
        visibility: "always-visible",
        name: "PO type",
        minWidth: 180,
        render: (row) => <TypeRender {...row} />,
      });
    }

    columns.push(
      {
        id: "vendor__display_name",
        sortable: "asc",
        visibility: "always-visible",
        name: "Vendor name",
        width: "fill",
        minWidth: 200,
        render: (row) => (
          <span data-testid={suffixify(TABLE_TEST_ID, "display-name")}>
            {row.vendor?.display_name}
          </span>
        ),
      },
      {
        id: "total_amount",
        name: "Amount",
        width: 150,
        sortable: true,
        textAlign: "right",
        render: (row) => (
          <span data-testid={suffixify(TABLE_TEST_ID, "total-amount")}>
            {formatCurrency(row.total_amount || 0, {
              currencySign: true,
              allowNegative: true,
            })}
          </span>
        ),
      },
      {
        id: "open_balance",
        name: "Open balance",
        sortable: true,
        width: 160,
        textAlign: "right",
        render: (row) => (
          <span data-testid={suffixify(TABLE_TEST_ID, "open-balance")}>
            {formatCurrency(row.open_balance || 0, {
              currencySign: true,
              allowNegative: true,
            })}
          </span>
        ),
      },
      {
        id: "created_at",
        sortable: true,
        width: 150,
        name: "Created date",
        render: (row) => row.created_at && formatDate(new Date(row.created_at)),
      },
      {
        id: "date",
        sortable: true,
        width: 150,
        name: "Date",
        render: (row) => row.created_at && formatDate(new Date(row.date)),
        visibility: "hidden",
      },
      {
        id: "job_cost_method_display_name",
        name: "Cost codes / Accounts",
        width: "fill",
        minWidth: 215,
        render: (row) => {
          const items = row.items.reduce(
            (acc, item) =>
              item?.display_name ? [...acc, item.display_name] : acc,
            []
          );

          const accounts = row.accounts.reduce(
            (acc, account) =>
              account?.display_name ? [...acc, account.display_name] : acc,
            []
          );

          return (
            <CostCodeAccountInfo
              items={items}
              accounts={accounts}
              data-testid={suffixify(TABLE_TEST_ID, "cost-codes-accounts")}
            />
          );
        },
      },
      {
        id: "jobsCustomers",
        name: "Jobs",
        width: "fill",
        minWidth: 215,
        render: (row) => {
          const customers = row.customers.reduce(
            (acc, customer) =>
              customer?.display_name ? [...acc, customer.display_name] : acc,
            []
          );

          return customers.length > 0 ? (
            <TagGroup
              data={customers}
              limit="auto"
              data-testid={suffixify(TABLE_TEST_ID, "jobs-customers")}
            />
          ) : null;
        },
      }
    );

    if (window.VENDOR_PO_SIGNATURE_REQUEST_ENABLED) {
      columns.push({
        id: "signature",
        sortable: true,
        width: 170,
        name: "Signature",
        render: (row) => {
          const poRequest = row.latest_po_signature_request;

          const primaryStatus = !isListEmpty(row.po_signatures)
            ? PO_SIGNATURE_STATUSES.SIGNED
            : poRequest && poRequest.status === VENDOR_REQUEST_STATUS.PENDING
              ? PO_SIGNATURE_STATUSES.REQUEST_SENT
              : poRequest && poRequest.status === "EXPIRED"
                ? PO_SIGNATURE_STATUSES.REQUEST_EXPIRED
                : undefined;

          const message =
            primaryStatus === PO_SIGNATURE_STATUSES.SIGNED
              ? // in case there are multiple signatures, we only show the first one
                `${row.po_signatures[0].signed_by} (${formatDateString(row.po_signatures[0].created_at)})`
              : primaryStatus === PO_SIGNATURE_STATUSES.REQUEST_SENT ||
                  primaryStatus === PO_SIGNATURE_STATUSES.REQUEST_EXPIRED
                ? formatDateString(row.latest_po_signature_request?.created_at)
                : undefined;
          return (
            primaryStatus && (
              <Tooltip as={Flex} placement="top" message={message}>
                <Tag color={poSignatureTagColor(primaryStatus)}>
                  {HUMAN_READABLE_PO_SIGNATURE_STATUS[primaryStatus]}
                </Tag>
              </Tooltip>
            )
          );
        },
      });
    }

    return columns;
  }, [settings.adaptivePosEnabled]);

  const { show } = usePurchaseOrderDrawer();

  const row = useMemo(
    () => ({
      render: ({ row, props }) => (
        <ReactRouterLink {...props} to={`/purchase-orders/${row.id}${search}`}>
          <VisuallyHidden>Open {row.vendor?.display_name}</VisuallyHidden>
        </ReactRouterLink>
      ),
      isError: (row) =>
        (row.errors ?? []).length > 0 &&
        row.errors?.some((error) => !error.is_ignored),
    }),
    [search]
  );

  const emptyState = useMemo(() => {
    if (isError) return "error";

    if (hasFilters) {
      return {
        title: "No purchase orders match your filters",
        subtitle:
          "Try adjusting your search or filters to find what you're looking for",
        action: (
          <Button
            onClick={() => clearFilters()}
            data-testid="empty-state-clear-filters-button"
          >
            Clear filters
          </Button>
        ),
      };
    }

    return {
      title: `You currently have no ${status?.toLowerCase()} purchase orders`,
      subtitle: "Create a new purchase order",
      action: (
        <Button
          onClick={() => show()}
          data-testid="table-new-purchase-order-button"
        >
          Create new purchase order
        </Button>
      ),
    };
  }, [show, hasFilters, clearFilters, status, isError]);

  const hasData = data.length > 0;

  const header = useMemo(
    () => ({
      hide: !hasData,
      sticky: { offset: stickyOffset },
    }),
    [hasData, stickyOffset]
  );

  const select = useMemo(
    () => ({
      value: selected,
      onChange: setSelected,
    }),
    [selected, setSelected]
  );

  return (
    <Table
      row={row}
      data={data}
      size="sm"
      sort={sort}
      select={select}
      header={header}
      columns={columns}
      loading={loading}
      pagination={pagination}
      emptyState={emptyState}
      data-testid={TABLE_TEST_ID}
    />
  );
};
