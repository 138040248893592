import React, { forwardRef, memo } from "react";
import { Flex, Text, Tooltip } from "@adaptive/design-system";

import styles from "./stage-navigation.module.css";

type Name = string | number;

type Ref = HTMLDivElement;

export type StageNavigationProps = {
  data: {
    name: Name;
    title: string;
    subtitle: string;
    disabled?: string;
  }[];
  value?: Name;
  onChange?: (name: Name) => void;
  initialValue?: Name;
};

const StageNavigation = forwardRef<Ref, StageNavigationProps>(
  ({ data, value, initialValue, onChange }, ref) => {
    const currentIndex = data.findIndex(({ name }) => name === value);
    const initialIndex = initialValue
      ? data.findIndex(({ name }) => name === initialValue)
      : currentIndex;

    return (
      <div ref={ref} className={styles["wrapper"]}>
        {data.map(({ name, title, subtitle, disabled }, i) => (
          <Tooltip message={disabled} key={name}>
            <button
              onClick={() => onChange?.(name)}
              disabled={
                !!disabled ||
                i > initialIndex ||
                (i === initialIndex && value === initialValue)
              }
              className={styles["button"]}
              aria-current={currentIndex === i ? "step" : undefined}
              data-testid={`stage-navigation-${name}`}
              data-skip-focusable=""
            >
              <Text size="3xl" weight="bold" as="span">
                {i + 1}
              </Text>
              <Flex direction="column">
                <Text as="span" size="sm" weight="bold">
                  {title}
                </Text>
                <Text as="span" size="xs" truncate={2}>
                  {subtitle}
                </Text>
              </Flex>
            </button>
          </Tooltip>
        ))}
      </div>
    );
  }
);

StageNavigation.displayName = "StageNavigation";

const MemoizedStageNavigation = memo(StageNavigation);

export { MemoizedStageNavigation as StageNavigation };
