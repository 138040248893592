import type { Expense } from "@api/expenses";

import type { Displayable } from "../types";

import { StatusMap } from "./constants";

export const mapOptions = (data: Displayable[]) =>
  data.map(({ id, display_name }) => ({ id, display_name }));

export const getTableRouterUrlForStatus = (status: Expense["reviewStatus"]) => {
  const urlStatus =
    status !== "REVIEWED" && status in StatusMap
      ? StatusMap[status]
      : StatusMap.ALL;
  const url = new URL(window.location.origin);
  url.pathname = "/expenses";
  url.searchParams.set("status", urlStatus);
  return `${url.pathname}${url.search}`;
};
