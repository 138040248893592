import { transactionWorkflowSchema } from "@api/workflows/response/schema";
import {
  arraySchema,
  commentSchema,
  currencySchema,
  dateSchema,
  idSchema,
  userSchema,
} from "@utils/schema";
import { z } from "zod";

import { transformKeysToCamelCase } from "../../../utils/schema/converters";
import { errorSchema } from "../../errors";
import {
  type LineItem,
  type LineItemAttribution,
  type ReferenceNode,
} from "../types";

export const realm = z.string().url();
const id = z.string().or(z.number()).or(z.string().uuid());
const pagination = z.string().url();
export const humanReadable = z.enum(["Bill", "Receipt"]);
export const reviewStatus = z.enum(["DRAFT", "FOR_REVIEW", "REVIEWED"]);

export const referenceNodeSchema = z.object(
  {
    id: z.string().or(z.number()),
    url: z.string().url(),
    displayName: z.string().nullish(),
  },
  {
    description: "reference node",
  }
);

export const vendorSchema = referenceNodeSchema.extend({
  email: z.string().nullish(),
});

export const defaultReferenceNode = {
  id: undefined,
  url: undefined,
  displayName: undefined,
};

export const defaultVendor = {
  ...defaultReferenceNode,
  email: undefined,
};

const lineAttributionGroup = z.enum(["Cost code", "Account"]);

export const lineExpenseAttributionSchema = referenceNodeSchema;

const parseNodeToAttribution = (node: ReferenceNode): LineItemAttribution => ({
  ...node,
});

const parseToDataAttribution = (validated: LineItem) => {
  if (!validated) return validated;

  if (validated?.item?.url) {
    validated.attribution = parseNodeToAttribution(validated.item);
  } else if (validated?.account?.url) {
    validated.attribution = parseNodeToAttribution(validated.account);
  }
  delete validated["item"];
  delete validated["account"];

  return validated;
};

export const coreLineItemSchema = z.object({
  id: z.number().default(() => Math.random()),
  description: z.string().default(""),
  amount: z.number().default(0),
  customer: referenceNodeSchema.partial().default(() => defaultReferenceNode),
  vendor: vendorSchema.partial().default(() => defaultVendor),
  billableStatus: z
    .enum(["Billable", "HasBeenBilled", "NotBillable"])
    .default("NotBillable"),
  attribution: referenceNodeSchema
    .extend({
      type: lineAttributionGroup,
    })
    .partial()
    .default(() => ({
      ...defaultReferenceNode,
      type: undefined,
    })),
  order: z.number().nullish(),
});

export const linkedInvoiceLineSchema = z.object({
  id: idSchema,
  customerId: idSchema,
  invoiceId: idSchema,
  invoiceReviewStatus: z.enum(["DRAFT", "FOR_APPROVAL", "APPROVED", "PAID"]),
});

export const lineItemSchema = z.object({
  id: z.number(),
  description: z.optional(z.string().nullish()),
  amount: z
    .string()
    .nullish()
    .transform((amt) => (amt ? parseFloat(amt) : null)),
  customer: z.optional(referenceNodeSchema).nullish(),
  vendor: z.optional(vendorSchema).nullish(),
  account: z.optional(referenceNodeSchema).nullish(),
  item: z.optional(referenceNodeSchema).nullish(),
  linkedToDraftInvoice: z.boolean(),
  billableStatus: z
    .optional(z.enum(["Billable", "HasBeenBilled", "NotBillable"]))
    .default("NotBillable"),
  isAVariance: z.optional(z.boolean()),
  linkedInvoiceLine: z.optional(linkedInvoiceLineSchema).nullish(),
  //we have to add 'attribution' to the schema as optional
  //so that we can populate it in the 'transform' after validation
  attribution: z.optional(lineExpenseAttributionSchema),
  remainingBudgetAfterThisExpense: currencySchema.nullish(),
  /**
   * We use it internally to determine if we changed the remaining budget
   */
  remainingBudgetAfterThisExpenseBlocked: z
    .boolean()
    .nullish()
    .transform((value) => value === true),
  order: z.number().nullish(),
});

export const linkedInvoiceSchema = z.object({
  id: z.number(),
  customerId: z.number(),
  docNumber: z.string(),
  url: z.string().url(),
});

const arrayToObjectByChildId = (items: unknown) =>
  (items as LineItem[]).reduce<Record<string, LineItem>>((collection, line) => {
    return !line.id
      ? collection
      : {
          ...collection,
          [line.id]: line,
        };
  }, {});

export const lineCollection = z
  .array(lineItemSchema.transform(parseToDataAttribution))
  .transform(arrayToObjectByChildId);

export const transformMapSalesTaxToTax = (raw: unknown) => {
  const data = raw as Record<string, any>;
  const tax = parseFloat(data.salesTax);

  data.tax = {
    isSet: !isNaN(tax),
    value: isNaN(tax) ? 0 : tax,
  };
  return data;
};

const responseTransforms = (val: unknown) => {
  [
    // order is important
    transformKeysToCamelCase,
    transformMapSalesTaxToTax,
  ].forEach((tx) => {
    val = tx(val as any);
  });
  return val;
};

export const attachmentSchema = z.object({
  document: z.string().url().nullish(),
  pdf: z.string().url().nullish(),
  id: id,
  thumbnail: z.string().nullish(),
  url: z.string().url(),
});

export const expenseItemSchema = z.preprocess(
  responseTransforms,
  z
    .object({
      accounts: z.optional(referenceNodeSchema.array()),
      attachables: z.optional(attachmentSchema.array()),
      comments: commentSchema.array().nullish(),
      createdAt: dateSchema,
      customers: z.optional(referenceNodeSchema.array()),
      date: dateSchema,
      docNumber: z.string().nullish(),
      id: id,
      errors: arraySchema(errorSchema),
      errorsIgnoredExist: z.boolean().nullish(),
      errorsNotIgnoredExist: z.boolean().nullish(),
      relatedErrors: arraySchema(errorSchema),
      emailBodyAttachable: z.optional(attachmentSchema).nullish(),
      items: z.optional(referenceNodeSchema.array()),
      isArchived: z.boolean(),
      isCreator: z.boolean(),
      isTransactionGeneratedDraft: z.boolean(),
      assignee: userSchema.nullish(),
      createdBy: userSchema.nullish(),
      duplicate: arraySchema(
        z.object({ id: idSchema, humanReadableType: z.string() })
      ),
      isManualOcrPending: z.boolean().nullish(),
      linesCount: z.number(),
      lines: lineItemSchema
        .transform(parseToDataAttribution)
        .array()
        .transform(arrayToObjectByChildId)
        .nullish(),
      linkedInvoices: z.optional(linkedInvoiceSchema.array()),
      humanReadableType: humanReadable,
      fileSyncStatus: z.optional(z.string()),
      paymentAccount: z
        .optional(
          referenceNodeSchema.extend({
            isCreditCard: z.boolean(),
            isBankAccount: z.boolean(),
          })
        )
        .or(z.null()),
      cardTransaction: z
        .object({
          id: z.string().nullish(),
          amount: z
            .string()
            .nullish()
            .transform((amt) => (amt ? parseFloat(amt) : null)),
          date: dateSchema,
          name: z.string().nullish(),
          merchantName: z.string().nullish(),
          displayName: z.string().nullish(),
          url: z.string().url().nullish(),
          card: z.object({ user: userSchema.nullish() }).nullish(),
          paymentAccount: z.optional(referenceNodeSchema).or(z.null()),
          pending: z.boolean(),
          description: z.string().nullish(),
          mask: z.string().nullish(),
          plaidAccountOwner: z
            .object({
              id: id,
              url: z.string().url(),
              accountOwner: z.string().nullish(),
            })
            .nullish(),
        })
        .nullish(),
      privateNote: z.optional(z.string().nullish()),
      realm: z.string().url(),
      reviewStatus: reviewStatus,
      publishedToQuickbooks: z.optional(z.boolean()),
      tax: z.optional(
        z.object({
          isSet: z.boolean(),
          value: z.number(),
        })
      ),
      totalAmount: z
        .string()
        .nullish()
        .transform((amt) => (amt ? parseFloat(amt) : null)),
      url: z.string().url(),
      vendor: vendorSchema.nullish(),
      otherName: vendorSchema.nullish(),
      approvalWorkflows: z.optional(transactionWorkflowSchema.array()),
      syncInvoiceLines: z.optional(z.boolean()),
      unlinkInvoiceLinesOption: z
        .literal("Skip")
        .or(z.literal("Delete"))
        .or(z.literal("Unlink"))
        .optional(),
    })
    .transform((expense) => ({
      ...expense,
      vendor: expense.vendor || expense.otherName,
      otherName: undefined,
      initialReviewStatus: expense.reviewStatus,
    }))
);

export const expensesResponseSchema = z.object({
  count: z.number(),
  next: pagination.nullish(),
  previous: pagination.nullish(),
  results: expenseItemSchema.array(),
});

// response shapes are all over the board so only grabbing the field we need
export const expenseMutateResponse = z.preprocess(
  responseTransforms,
  z.object({
    id: id,
    url: z.string().url(),
    docNumber: z
      .string()
      .nullish()
      .transform((value) => value || ""),
    reviewStatus: reviewStatus,
  })
);

export const checkExpenseDuplicationResponseSchema = z.object({
  duplicate: arraySchema(
    z.object({
      id: idSchema,
      url: z.string().url(),
      docNumber: z.string(),
      reviewStatus,
      humanReadableType: z.string(),
    })
  ),
});

export const linkedInvoicesResponseSchema = z.object({
  invoices: arraySchema(
    z.object({
      id: idSchema,
      docNumber: z.string(),
      customerId: idSchema,
      humanReadableType: z.enum(["Draw"]),
      url: z.string().url(),
    })
  ),
});

export const expenseUploadAttachableResponse = z.object({
  document: z.string().url().nullish(),
  pdf: z.unknown(),
  id: z.number(),
  realm: z.string().url(),
  parent: z.unknown(),
  url: z.string().url(),
  expense: expenseItemSchema,
});
