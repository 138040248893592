import React, { memo } from "react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import { type Colors } from "../../styles/variables";

import { type IconName } from "./constants";
import styles from "./icon.module.css";

const DEFAULT_COMPONENT = "i";

type Size = "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export type IconProps = {
  name: IconName;
  size?: ResponsiveProp<Size>;
  flip?: "horizontal" | "vertical";
  color?: Colors | (string & {}); // eslint-disable-line @typescript-eslint/ban-types
  animate?:
    | "beat"
    | "fade"
    | "spin"
    | "flip"
    | "shake"
    | "bounce"
    | "beat-fade"
    | "spin-pulse"
    | "spin-reverse";
  variant?: "solid" | "regular" | "light" | "duotone" | "brands";
};

const Icon = forwardRefAs<typeof DEFAULT_COMPONENT, IconProps>(
  (
    {
      as: Component = "i",
      size: rawSize,
      name,
      flip,
      color,
      style,
      animate,
      variant,
      className,
      ...props
    },
    ref
  ) => {
    const size = useResponsiveProp(rawSize, "md");
    const enhancedVariant = variant
      ? variant
      : ["2xs", "xs", "sm"].includes(size)
        ? "regular"
        : "light";

    return (
      <Component
        ref={ref}
        style={{
          ...(color ? { "--icon-color": `var(--color-${color}` } : {}),
          ...style,
        }}
        className={cn(
          className,
          `fa-${name}`,
          styles["icon"],
          animate && `fa-${animate}`,
          `fa-${enhancedVariant}`,
          styles[`-${size}`],
          { [`fa-flip-${flip}`]: flip, [styles[`-override-color`]]: color }
        )}
        data-icon={name}
        aria-hidden={Component === "i" ? true : false}
        {...props}
      />
    );
  }
);

Icon.displayName = "Icon";

const MemoizedIcon = memo(Icon) as typeof Icon;

export { MemoizedIcon as Icon };
