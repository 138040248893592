import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from "react";

import { is } from "../../utils/is";

export const useLocalStorageState = <T>(
  key?: string,
  fallbackValue?: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    if (!key) return fallbackValue;

    const storedValue = localStorage.getItem(key);

    try {
      if (storedValue) return JSON.parse(storedValue);

      localStorage.setItem(key, JSON.stringify(fallbackValue));
      return fallbackValue;
    } catch (error) {
      /**
       * If there's an error parsing the stored value, we'll log
       * a warning and set the state to the fallback value.
       */
      // eslint-disable-next-line no-console
      console.warn(
        `Error parsing local storage value for key "${key}". Falling back to "${fallbackValue}".`,
        error
      );
      localStorage.setItem(key, JSON.stringify(fallbackValue));
      return fallbackValue;
    }
  });

  const enhancedSetState = useCallback(
    (state: SetStateAction<T>) => {
      setState((previousState) => {
        const nextState = is.function(state) ? state(previousState) : state;

        if (key) localStorage.setItem(key, JSON.stringify(nextState));

        return nextState;
      });
    },
    [key]
  );

  return [state, enhancedSetState];
};
