import React, {
  memo,
  type ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router";
import {
  Button,
  Flex,
  Icon,
  Link,
  Loader,
  ResponsiveProvider,
  Tag,
  Text,
  type TextareaAttachmentAddon,
  type TextareaAttachmentAddonFile,
  type TextProps,
  Timeline,
  type TimelineData,
  type TimelineProps,
  Tooltip,
} from "@adaptive/design-system";
import { useMutationObserver } from "@adaptive/design-system/hooks";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  debounce,
  formatCurrency,
  formatDate,
  isEqual,
  parseMention,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { api as notificationsApi } from "@notifications/api";
import type { Selector } from "@reduxjs/toolkit";
import { selectRealmId } from "@store/user/selectors-memoized";
import { profiledUserSelector } from "@store/user/slice";
import * as analytics from "@utils/analytics";
import { getUserByProfile } from "@utils/get-user-by-profile";

import { putComment } from "../../api/comments";
import type {
  Comment as IComment,
  CommentFile,
  LienWaiverRelatedVersion,
  User,
} from "../../api/expenses/types";
import type { RootState } from "../../store/types";
import { Form } from "../form";
import { Section } from "../section";

import { Comment, type CommentProps, type CommentRef } from "./comment";
import {
  COMMENT_COLOR,
  COMMENT_DIFF_TYPES,
  COMMENT_ICON,
  COMMENT_ICON_VARIANT,
  STRINGS,
} from "./constants";
import { getCommentTitle, validateMessage } from "./utils";

type CommentState = { comments: IComment[]; url: string };

type Data = TimelineData & { id: string; url: string };

export type CommentsSelector = Selector<RootState, CommentState> | CommentState;

export type OnAddCommentPayload = {
  user: User;
  text: string;
  id: string;
  url: string;
  files?: CommentFile[];
  parentCommentUrl?: string;
  hasExternalMention: boolean;
};

export type OnAddThreadCommentPayload = {
  user: User;
  text: string;
  id: string;
  url: string;
  files?: CommentFile[];
  parentCommentUrl?: string;
};

export type OnAddCommentHandler = (payload: OnAddCommentPayload) => void;

type SaveCommentHandler = (params: {
  value: string;
  files?: File[];
  onConfirm?: () => void;
  onCancel?: () => void;
  parentCommentUrl?: string;
}) => void;

export type OnAddThreadCommentHandler = (
  payload: OnAddThreadCommentPayload
) => void;

export type ReplyProps = {
  onSubmit: SaveCommentHandler;
  onCancel?: () => void;
  actionSize?: "sm" | "md";
  placeholder?: string;
  parentComment: Data;
} & Pick<CommentProps, "vendor">;

export const Reply = memo(
  ({
    onSubmit,
    onCancel,
    actionSize = "sm",
    parentComment,
    ...props
  }: ReplyProps) => {
    const formId = useId();

    const commentRef = useRef<CommentRef>(null);

    const [isValid, setIsValid] = useState(false);

    const [files, setFiles] = useState<TextareaAttachmentAddonFile[]>([]);

    const attachment = useMemo<TextareaAttachmentAddon>(
      () => ({
        accept: window.EXPECTED_FILE_TYPES,
        maxSize: window.MAX_COMMENT_FILES_SIZE_MB,
        maxSizeValidation: "total",
        onChange: (files) => {
          setFiles(files);

          if (!commentRef.current) return;

          const isEmpty = commentRef.current.isEmpty();
          const hasFiles = commentRef.current.hasFiles();
          const hasInvalidFiles = commentRef.current.hasFiles([
            "error",
            "pending",
          ]);
          setIsValid((!isEmpty && !hasFiles) || (hasFiles && !hasInvalidFiles));
        },
        multiple: window.MAX_NUMBER_OF_COMMENT_FILES,
      }),
      []
    );

    const enhancedOnSubmit = useEvent(() => {
      onSubmit({
        value: commentRef.current!.getValue(),
        files: files,
        onConfirm: () => {
          onCancel?.();
        },
        onCancel: () => {
          commentRef.current?.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          });
          commentRef.current?.focus({ preventScroll: true });
        },
        parentCommentUrl: parentComment.url,
      });
    });

    const onKeyUp = useEvent(() => {
      const isEmpty = commentRef.current!.isEmpty();
      const hasFiles = commentRef.current!.hasFiles();
      const hasInvalidFiles = commentRef.current!.hasFiles([
        "error",
        "pending",
      ]);
      setIsValid((!isEmpty && !hasFiles) || (hasFiles && !hasInvalidFiles));
    });

    return (
      <Flex gap="md" direction="column">
        <Form id={formId} onSubmit={enhancedOnSubmit} />
        <Comment
          ref={commentRef}
          form={formId}
          onKeyUp={onKeyUp}
          autoFocus
          attachment={attachment}
          messageVariant="hidden"
          renderAfter={({ setFilesFromPicker }) => (
            <Flex gap="md" justify="flex-end" shrink={false} grow>
              <Button
                size={actionSize}
                variant="text"
                color="neutral"
                onClick={onCancel}
              >
                Cancel
              </Button>
              {setFilesFromPicker && (
                <Tooltip message={STRINGS.TOOLTIP_BUTTON_UPLOAD_FILE}>
                  <Button
                    size="sm"
                    color="neutral"
                    variant="ghost"
                    onClick={setFilesFromPicker}
                  >
                    <Icon name="paperclip" />
                  </Button>
                </Tooltip>
              )}
              <Button
                type="submit"
                form={formId}
                size={actionSize}
                disabled={!isValid}
              >
                {STRINGS.BUTTON_COMMENT}
              </Button>
            </Flex>
          )}
          {...props}
        />
      </Flex>
    );
  }
);

Reply.displayName = "Reply";

type CommentsProps = {
  vendor?: { url: string; email?: string; displayName?: string };
  selector: CommentsSelector;
  placeholder?: string;
  onAddComment?: OnAddCommentHandler;
  showExternalFlag?: boolean;
  onAddThreadComment?: OnAddThreadCommentHandler;
  showExternalWarning?: boolean;
  title?: string;
};

const breakpoints = {
  mobile: 0,
  desktop: 425,
};

export const Comments = memo(
  ({
    vendor,
    selector,
    placeholder,
    onAddComment,
    showExternalFlag = true,
    onAddThreadComment,
    showExternalWarning = true,
    title = STRINGS.HEADING,
  }: CommentsProps) => {
    const formId = useId();

    const commentRef = useRef<CommentRef>(null);
    const sectionRef = useRef<HTMLDivElement>(null);

    const timelineRef = useRef<HTMLUListElement>(null);

    const [isValid, setIsValid] = useState(false);

    const [params] = useSearchParams();

    const dispatch = useDispatch();

    const location = useLocation();

    const [visibleReplies, setVisibleReplies] = useState<string[]>([]);

    const [files, setFiles] = useState<TextareaAttachmentAddonFile[]>([]);

    const user = useSelector(profiledUserSelector, isEqual);

    const realmId = useSelector(selectRealmId);

    const curriedHideReply = useCallback(
      (id: string) => () => {
        setVisibleReplies((prev) => prev.filter((replyId) => replyId !== id));
      },
      []
    );

    const onCancel = useCallback(() => {
      commentRef.current?.reset();
      commentRef.current?.focus({ preventScroll: true });
    }, []);

    const curriedShowReply = useCallback(
      (id: string) => () => {
        setVisibleReplies((prev) => [...prev, id]);
        analytics.track("commentReplyButton", {
          commentId: id,
          user,
        });
      },
      [user]
    );

    const selectorInfo = useSelector<RootState, CommentState>((state) => {
      const { url, comments } =
        typeof selector === "function"
          ? selector(state)
          : { url: "", comments: [] };

      return { url, comments: [...(comments ?? [])] };
    }, isEqual);

    const { url, comments: externalComments } = useMemo(
      () => (typeof selector === "function" ? selectorInfo : selector),
      [selectorInfo, selector]
    );

    const [comments, setComments] = useState(externalComments);

    const saveComment = useEvent<SaveCommentHandler>(
      async ({ value, files, parentCommentUrl, onCancel, onConfirm }) => {
        const handler = async (hasExternalMention: boolean = false) => {
          onConfirm?.();

          const optimisticComment = {
            id: "0",
            text: value,
            author: user as any,
            files: (files || []).map((file, index) => ({
              id: index.toString(),
              file: URL.createObjectURL(file),
              name: file.name,
            })),
            createdAt: new Date(),
            attachable: "",
            timelineEventType: "COMMENT" as IComment["timelineEventType"],
            hasExternalMention,
          };

          setComments((prev) =>
            parentCommentUrl
              ? comments.map((comment) => {
                  if (comment.url === parentCommentUrl) {
                    return {
                      ...comment,
                      replies: [...(comment.replies ?? []), optimisticComment],
                    };
                  }
                  return comment;
                })
              : [...prev, optimisticComment]
          );

          if (!url) return;

          try {
            const response = await putComment({
              text: optimisticComment.text,
              author: optimisticComment.author.url,
              parent: url,
              parent_comment: parentCommentUrl,
              files,
            });

            if (!response?.data) return;

            const savedComment = {
              id: response.data.id,
              url: response.data.url,
              user: optimisticComment.author,
              text: response.data.text,
              files: response.data.files,
              parentCommentUrl,
              hasExternalMention: response.data.has_external_mention,
            };

            if (parentCommentUrl) onAddThreadComment?.(savedComment);

            onAddComment?.(savedComment);
            dispatch(
              notificationsApi.util.invalidateTags([
                { type: "Notifications", id: "LIST" },
              ])
            );
          } catch (e) {
            handleErrors(e);
          }
        };

        const parentComment = comments.find(
          (comment) => comment.url === parentCommentUrl
        );

        if (parentComment) {
          const threadMessages = [
            parentComment.text,
            parentComment.replies?.map((reply) => reply.text),
          ]
            .flat()
            .join(" ");

          const { promptWarning, hasExternalMentions } =
            validateMessage(threadMessages);

          if (showExternalWarning && hasExternalMentions) {
            return promptWarning({
              message: `This comment thread includes someone external to\n your company:`,
              onCancel,
              onConfirm: handler,
            });
          }
        }

        const { promptWarning, hasExternalMentions } = validateMessage(value);

        if (!showExternalWarning || !hasExternalMentions) return handler();

        promptWarning({ onCancel, onConfirm: handler });
      }
    );

    const onKeyUp = useEvent(() => {
      const isEmpty = commentRef.current!.isEmpty();
      const hasFiles = commentRef.current!.hasFiles();
      const hasInvalidFiles = commentRef.current!.hasFiles([
        "error",
        "pending",
      ]);
      setIsValid((!isEmpty && !hasFiles) || (hasFiles && !hasInvalidFiles));
    });

    const curriedExtraFiles = useCallback(
      (files: CommentFile[], loading: boolean) => {
        return [
          {
            children: (
              <Flex gap="md" wrap grow>
                {files.map((file) => (
                  <Tag
                    as="button"
                    key={file.id}
                    type="button"
                    variant="pill"
                    onClick={() => window.open(file.file, "_blank")}
                  >
                    <Flex gap="md">
                      <Icon
                        size="sm"
                        name="file"
                        variant="light"
                        color="neutral-600"
                      />
                      <Text truncate={1}>{file.name}</Text>
                      {loading && <Loader />}
                    </Flex>
                  </Tag>
                ))}
              </Flex>
            ),
            variant: "hollow",
          },
        ];
      },
      []
    );

    const attachment = useMemo<TextareaAttachmentAddon>(
      () => ({
        accept: window.EXPECTED_FILE_TYPES,
        maxSize: window.MAX_COMMENT_FILES_SIZE_MB,
        maxSizeValidation: "total",
        onChange: (files) => {
          setFiles(files);

          if (!commentRef.current) return;

          const isEmpty = commentRef.current.isEmpty();
          const hasFiles = commentRef.current.hasFiles();
          const hasInvalidFiles = commentRef.current.hasFiles([
            "error",
            "pending",
          ]);
          setIsValid((!isEmpty && !hasFiles) || (hasFiles && !hasInvalidFiles));
        },
        multiple: window.MAX_NUMBER_OF_COMMENT_FILES,
      }),
      []
    );

    const enhancedComments = useMemo(
      () =>
        [
          ...[...(comments || [])]
            .filter(
              (item) =>
                item.timelineEventType !== "EDITED" || item.diffs?.length
            )
            .sort((a, b) =>
              a.createdAt && b.createdAt
                ? a.createdAt.getTime() - b.createdAt.getTime()
                : 0
            )
            .map((item) => {
              let vendorInThread = "";

              const renderChildren = (value: string) => {
                const content = parseMention({
                  value,
                  render: ({ url, mention, trigger }) => {
                    let color: TextProps["color"] = "brand-2";

                    if (url?.includes("/vendors/")) {
                      color = "info-200";
                      vendorInThread = url;
                    }

                    return `<span style="color: var(--color-${color})">${trigger}${mention}</span>`;
                  },
                });

                if (!content) return null;

                return <span dangerouslySetInnerHTML={{ __html: content }} />;
              };

              const mainCommentChildren = item.text
                ? renderChildren(item.text)
                : null;

              const enhancedItem = {
                ...item,
                diffs: item.diffs
                  ?.filter((diff) => diff.field in COMMENT_DIFF_TYPES)
                  .map((diff) => ({
                    icon: "pen",
                    title: `${COMMENT_DIFF_TYPES[diff.field]} ${
                      diff.oldValue && diff.newValue
                        ? "updated"
                        : !diff.oldValue && diff.newValue
                          ? "set"
                          : "removed"
                    }`,
                    children: (
                      <>
                        {diff.oldValue && (
                          <Text decoration="line-through">
                            {["total_amount", "amount_to_pay"].includes(
                              diff.field
                            ) ? (
                              formatCurrency(diff.oldValue, {
                                currencySign: true,
                                allowNegative: true,
                              })
                            ) : diff.field === "attachable" ? (
                              <Link
                                href={diff.oldValue}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Document
                              </Link>
                            ) : diff.field === "scope_of_work" ? (
                              // https://react.dev/reference/react-dom/components/common#dangerously-setting-the-inner-html
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: diff.oldValue,
                                }}
                              />
                            ) : (
                              diff.oldValue
                            )}
                          </Text>
                        )}
                        <Text>
                          {["total_amount", "amount_to_pay"].includes(
                            diff.field
                          ) ? (
                            formatCurrency(diff.newValue, {
                              currencySign: true,
                              allowNegative: true,
                            })
                          ) : diff.field === "attachable" && diff.newValue ? (
                            <Link
                              href={diff.newValue}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Document
                            </Link>
                          ) : diff.field === "scope_of_work" ? (
                            // https://react.dev/reference/react-dom/components/common#dangerously-setting-the-inner-html
                            <div
                              dangerouslySetInnerHTML={{
                                __html: diff.newValue,
                              }}
                            />
                          ) : (
                            diff.newValue
                          )}
                        </Text>
                      </>
                    ),
                  })),
                replies: item.replies?.map((reply) => {
                  const isExternalThread =
                    item.hasExternalMention ||
                    (item.replies || []).some(
                      (reply) => reply.hasExternalMention
                    );

                  const isExternal = reply.author?.type === "external";
                  const replyAuthor =
                    isExternal && !realmId
                      ? user
                      : getUserByProfile(reply.author, realmId);

                  return {
                    icon: {
                      name: isExternalThread ? "paper-plane-top" : "message",
                      flip: isExternal ? "horizontal" : undefined,
                    },
                    title: replyAuthor?.fullName,
                    color: isExternalThread ? "info" : "neutral",
                    hasExternalMention: reply.hasExternalMention,
                    id: reply.id,
                    subtitle: reply.createdAt
                      ? formatDate(reply.createdAt, "MM/dd/yyyy h:mmaaa")
                      : "",
                    children: reply.text ? renderChildren(reply.text) : null,
                    extra: reply.files?.length
                      ? curriedExtraFiles(reply.files, reply.id === "0")
                      : [],
                  };
                }),
              };

              const isDifferentVendor = !!(vendorInThread && vendor?.url
                ? vendorInThread !== vendor?.url
                : false);

              let replyControls = {
                icon: "",
                title: "",
                color: "neutral",
                variant: "hollow",
                children: (
                  <Flex margin={["none", "none", "none", "-13px"]}>
                    <Tooltip
                      message={
                        isDifferentVendor
                          ? STRINGS.DIFFERENT_VENDOR_TOOLTIP
                          : ""
                      }
                    >
                      <Button
                        size="sm"
                        color="neutral"
                        variant={isDifferentVendor ? "solid" : "text"}
                        onClick={curriedShowReply(enhancedItem.id!)}
                        disabled={isDifferentVendor}
                        data-reply=""
                      >
                        <Icon name="reply" />
                        Reply to thread
                      </Button>
                    </Tooltip>
                  </Flex>
                ),
              };

              if (visibleReplies.includes(enhancedItem.id!)) {
                replyControls = {
                  icon: "message",
                  title: user?.fullName,
                  color: "neutral",
                  variant: "hollow",
                  children: (
                    <Reply
                      vendor={vendor}
                      onSubmit={saveComment}
                      onCancel={curriedHideReply(enhancedItem.id!)}
                      placeholder={placeholder}
                      parentComment={enhancedItem as Data}
                    />
                  ),
                };
              }

              const isExternalThread =
                enhancedItem.hasExternalMention ||
                (enhancedItem.replies || []).some(
                  (reply) => reply.hasExternalMention
                );

              const author = getUserByProfile(enhancedItem.author, realmId);

              let extra: TimelineData["extra"];

              if (enhancedItem.timelineEventType !== "COMMENT") {
                if (enhancedItem.timelineEventType.includes("LIEN_WAIVER")) {
                  const versionWithVendor = enhancedItem.relatedVersions?.find(
                    (version) =>
                      version?.timelineEventType ===
                      enhancedItem.timelineEventType
                  ) as LienWaiverRelatedVersion;
                  if (
                    versionWithVendor &&
                    "paymentAmount" in versionWithVendor
                  ) {
                    extra = [
                      {
                        icon: "file",
                        title: STRINGS.TITLE_LIEN_WAIVER,
                        children: (
                          <Flex gap="md" direction="column">
                            <Text>
                              {formatCurrency(versionWithVendor.paymentAmount, {
                                currencySign: true,
                                allowNegative: true,
                              })}
                            </Text>
                            <Text>{versionWithVendor.type}</Text>
                            <Link
                              href={enhancedItem.attachable}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Lien waiver
                            </Link>
                          </Flex>
                        ),
                      },
                    ];
                  }
                } else if (
                  enhancedItem.timelineEventType === "AUTO_SPLIT" &&
                  enhancedItem.autoSplits
                ) {
                  const isOriginal =
                    enhancedItem.autoSplits.sourceObject.url ===
                    window.location.href;

                  extra = [
                    {
                      variant: "hollow",
                      children: (
                        <Flex direction="column" gap="sm">
                          {!isOriginal && (
                            <>
                              <Text>
                                Document split from{" "}
                                {enhancedItem.autoSplits.sourceObject.humanReadableType.toLowerCase()}
                                :
                              </Text>
                              <Tag size="sm">
                                <Link
                                  rel="noopener noreferrer"
                                  href={
                                    enhancedItem.autoSplits.sourceObject.url
                                  }
                                  size="sm"
                                  target="_blank"
                                >
                                  {
                                    enhancedItem.autoSplits.sourceObject
                                      .humanReadableType
                                  }
                                  #
                                  {
                                    enhancedItem.autoSplits.sourceObject
                                      .displayName
                                  }
                                </Link>
                              </Tag>
                            </>
                          )}
                          <Text>
                            {isOriginal ? "This document was split " : ""}
                            into {enhancedItem.autoSplits.splitToObjects.length}{" "}
                            new{" "}
                            {enhancedItem.autoSplits.sourceObject.humanReadableType.toLowerCase()}
                            {enhancedItem.autoSplits.splitToObjects.length > 1
                              ? "s"
                              : ""}
                            :
                          </Text>
                          {enhancedItem.autoSplits.splitToObjects.map(
                            (splitToObject) => (
                              <Tag size="sm" key={splitToObject.url}>
                                <Link
                                  rel="noopener noreferrer"
                                  href={splitToObject.url}
                                  size="sm"
                                  target="_blank"
                                >
                                  {splitToObject.humanReadableType}#
                                  {splitToObject.displayName}
                                </Link>
                              </Tag>
                            )
                          )}
                        </Flex>
                      ),
                    },
                  ];
                } else if (enhancedItem.attachable) {
                  extra = [
                    {
                      icon: "file-plus",
                      title: STRINGS.TITLE_FILE_ATTACHED,
                      children: (
                        <Link
                          href={enhancedItem.attachable}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Document
                        </Link>
                      ),
                    },
                  ];
                } else if (enhancedItem.diffs) {
                  extra = enhancedItem.diffs;
                }
              } else if (enhancedItem.diffs?.length) {
                extra = enhancedItem?.diffs;
              } else if (window.COMMENT_THREADING_ENABLED) {
                extra = [
                  ...(enhancedItem.replies || []),
                  ...((onAddComment || onAddThreadComment) &&
                  replyControls.children
                    ? [replyControls]
                    : []),
                ] as TimelineData["extra"];
              }

              return {
                icon: {
                  name: isExternalThread
                    ? "paper-plane-top"
                    : ((COMMENT_ICON[enhancedItem.timelineEventType] ||
                        author?.fullName) ??
                      "user"),
                  variant: COMMENT_ICON_VARIANT[enhancedItem.timelineEventType],
                },
                title: getCommentTitle({
                  timelineEventType: enhancedItem.timelineEventType,
                  authorName: author?.fullName,
                  relatedVersions: enhancedItem.relatedVersions,
                }),
                renderHeader:
                  enhancedItem.timelineEventType === "COMMENT" &&
                  enhancedItem.id
                    ? (children: ReactNode) => (
                        <Flex
                          grow
                          justify="space-between"
                          gap="md"
                          align="center"
                          shrink={false}
                        >
                          <Flex grow basis={0}>
                            {children}
                          </Flex>
                          <Flex gap="sm">
                            {isExternalThread && showExternalFlag ? (
                              <Tooltip
                                message={
                                  <Text align="center">
                                    This comment thread is visible to people
                                    <br />
                                    external to your company.{" "}
                                    <Text
                                      size="sm"
                                      as={Link}
                                      href="https://help.adaptive.build/en/articles/9216708-sending-messages-to-vendors"
                                      target="_blank"
                                      onClick={(e: any) => e.stopPropagation()}
                                    >
                                      Learn more
                                    </Text>
                                  </Text>
                                }
                              >
                                <Tag color="info">
                                  <Flex gap="sm" width="78px">
                                    <Icon name="paper-plane-top" size="sm" />
                                    <Text>External</Text>
                                  </Flex>
                                </Tag>
                              </Tooltip>
                            ) : null}
                            {window.COMMENT_THREADING_ENABLED &&
                            (onAddComment || onAddThreadComment) ? (
                              <Flex>
                                <Tooltip
                                  message={
                                    isDifferentVendor
                                      ? STRINGS.DIFFERENT_VENDOR_TOOLTIP
                                      : "Reply"
                                  }
                                >
                                  <Button
                                    size="sm"
                                    color="neutral"
                                    variant={
                                      isDifferentVendor ? "solid" : "ghost"
                                    }
                                    onClick={curriedShowReply(
                                      `${enhancedItem.id}`
                                    )}
                                    disabled={isDifferentVendor}
                                    data-reply=""
                                  >
                                    <Icon name="reply" />
                                  </Button>
                                </Tooltip>
                              </Flex>
                            ) : null}
                          </Flex>
                        </Flex>
                      )
                    : undefined,
                subtitle:
                  enhancedItem.createdAt &&
                  enhancedItem.timelineEventType !== "TOO_MANY_VERSIONS"
                    ? formatDate(enhancedItem.createdAt, "MM/dd/yyyy h:mmaaa")
                    : "",
                color: isExternalThread
                  ? "info"
                  : (COMMENT_COLOR[enhancedItem.timelineEventType] ??
                    "success"),
                children: mainCommentChildren,
                id: enhancedItem?.id,
                extra: enhancedItem.files?.length
                  ? [
                      ...curriedExtraFiles(
                        enhancedItem.files,
                        enhancedItem.id === "0"
                      ),
                      ...(extra || []),
                    ]
                  : extra,
              };
            }),
          ...(onAddComment
            ? [
                {
                  icon: "message",
                  title: user.fullName,
                  subtitle: (
                    <span data-intercom-target="main-comment">Now</span>
                  ),
                  color: "neutral",
                  variant: "hollow",
                  children: (
                    <Flex direction="column">
                      <Comment
                        id="comments"
                        ref={commentRef}
                        form={formId}
                        onKeyUp={onKeyUp}
                        attachment={attachment}
                        renderAfter={({ setFilesFromPicker }) => (
                          <Flex gap="md" justify="flex-end" shrink={false} grow>
                            {isValid && (
                              <Button
                                size="sm"
                                variant="text"
                                color="neutral"
                                onClick={onCancel}
                              >
                                Cancel
                              </Button>
                            )}
                            {setFilesFromPicker && (
                              <Tooltip
                                message={STRINGS.TOOLTIP_BUTTON_UPLOAD_FILE}
                              >
                                <Button
                                  size="sm"
                                  color="neutral"
                                  variant="ghost"
                                  onClick={setFilesFromPicker}
                                  data-intercom-target="main-comment-file-trigger"
                                >
                                  <Icon name="paperclip" />
                                </Button>
                              </Tooltip>
                            )}
                            <Button
                              disabled={!isValid}
                              size="sm"
                              type="submit"
                              form={formId}
                            >
                              {STRINGS.BUTTON_COMMENT}
                            </Button>
                          </Flex>
                        )}
                        disabled={!url}
                        helperMessage={
                          !url
                            ? "Click 'Save draft' to enable commenting"
                            : undefined
                        }
                        vendor={vendor}
                        placeholder={placeholder}
                      />
                    </Flex>
                  ),
                },
              ]
            : []),
        ] as TimelineProps["data"],
      [
        comments,
        onAddComment,
        user,
        formId,
        onKeyUp,
        attachment,
        url,
        vendor,
        placeholder,
        curriedShowReply,
        visibleReplies,
        realmId,
        curriedExtraFiles,
        saveComment,
        curriedHideReply,
        onAddThreadComment,
        showExternalFlag,
        isValid,
        onCancel,
      ]
    );

    const onSubmit = useEvent(() => {
      saveComment({
        value: commentRef.current!.getValue(),
        files,
        onConfirm: () => {
          commentRef.current?.reset();
          commentRef.current?.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          });
          commentRef.current?.focus({ preventScroll: true });
        },
        onCancel: () => {
          commentRef.current?.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          });
          commentRef.current?.focus({ preventScroll: true });
          setIsValid(true);
        },
      });

      setIsValid(false);
    });

    const commentIdRef = useRef(
      location.hash.substring(1) || params.get("comment_id")
    );

    const animationTimeoutRef =
      useRef<ReturnType<typeof setTimeout>>(undefined);

    const onMutation = useEvent(() => {
      if (!commentIdRef.current) return;

      const element = document.getElementById(commentIdRef.current);

      if (element) {
        element.style.transition = "background var(--motion-duration-slowly)";
        element.scrollIntoView({ behavior: "instant", block: "center" });

        let replyButton: HTMLElement | null | undefined =
          element.querySelector<HTMLElement>("[data-reply]");

        if (!replyButton) {
          replyButton =
            element.parentElement?.parentElement?.querySelector<HTMLElement>(
              "[data-reply]"
            );
        }

        replyButton?.click();

        animationTimeoutRef.current = setTimeout(() => {
          element.style.background = "var(--color-neutral-300)";
          element.style.borderRadius = "var(--border-radii-xl)";

          animationTimeoutRef.current = setTimeout(() => {
            element.style.background = "none";
            element.style.borderRadius = "none";
          }, 1250);
        }, 0);
      }

      commentIdRef.current = "";
    });

    const debouncedOnMutation = useMemo(
      () => debounce(onMutation, 100),
      [onMutation]
    );

    useMutationObserver(timelineRef, debouncedOnMutation);

    useEffect(() => {
      return () => {
        clearTimeout(animationTimeoutRef.current);
      };
    }, []);

    useEffect(() => {
      setComments(externalComments);
    }, [externalComments]);

    return (
      <Section ref={sectionRef} heading={title} hideBorder hidePadding>
        <Form onSubmit={onSubmit} id={formId} />
        <ResponsiveProvider
          ref={sectionRef}
          breakpoints={breakpoints}
          initialBreakpoint="desktop"
        >
          <Timeline
            size={{ mobile: "sm", desktop: "md" }}
            ref={timelineRef}
            data={enhancedComments}
          />
        </ResponsiveProvider>
      </Section>
    );
  }
);

Comments.displayName = "Comments";
