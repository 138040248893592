import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import {
  ComboBox as BaseComboBox,
  type ComboBoxActionAddon,
  type ComboBoxRef,
  Text,
} from "@adaptive/design-system";
import { isEqual } from "@adaptive/design-system/utils";
import { useVendorsSimplified } from "@hooks/use-vendors-simplified";
import { useOtherNamesSimplified } from "@shared/hooks/useOtherNamesSimplified";
import type { Option } from "@shared/types/objects";
import type { SelectProps } from "@src/expenses/forms";
import { STRINGS } from "@src/expenses/stages/constants";
import type { RootState } from "@store/types";
import { asOption } from "@utils/converters/utils";
import {
  selectedOptionHasValue,
  useCustomValidationComponent,
} from "@utils/validator";
import { useVendorAction, useVendorInfo } from "@vendors/hooks";

export const SelectVendor = forwardRef<
  ComboBoxRef,
  SelectProps & { extraData?: Option[] }
>(
  (
    {
      selector, // eslint-disable-line react/prop-types
      onChange, // eslint-disable-line react/prop-types
      validator, // eslint-disable-line react/prop-types
      extraData,
      allowOtherNames = false, // eslint-disable-line react/prop-types
      ...props
    },
    ref
  ) => {
    const shouldUpdateVendorRef = useRef(false);

    const vendorData = useVendorsSimplified({ includeLabel: true });

    const otherNameData = useOtherNamesSimplified({
      includeLabel: true,
      enabled: allowOtherNames,
    });

    const onlyVendors = useMemo(
      () => otherNameData.data.length === 0 || !allowOtherNames,
      [otherNameData.data.length, allowOtherNames]
    );

    const enhancedValidator = useMemo(
      () =>
        (validator ??
          selectedOptionHasValue(
            onlyVendors ? STRINGS.ERROR_VENDOR : STRINGS.ERROR_VENDOR_OTHER
          )) as any,
      [onlyVendors, validator]
    );

    const ComboBox = useCustomValidationComponent(
      BaseComboBox,
      enhancedValidator
    );

    const status = useMemo(() => {
      if (vendorData.status !== "success" || onlyVendors) {
        return vendorData.status;
      }
      return otherNameData.status;
    }, [vendorData.status, otherNameData.status, onlyVendors]);

    const data = useMemo(() => {
      let temp: Option[] = [];

      if (status === "success") {
        if (onlyVendors) {
          temp = vendorData.data.map((vendor) => ({
            ...vendor,
            groupLabel: undefined,
          }));
        } else {
          temp = vendorData.data.concat(otherNameData.data);
        }
      }

      return temp.concat(extraData ?? []);
    }, [vendorData.data, extraData, otherNameData.data, onlyVendors, status]);

    const { create, creationId } = useVendorAction();

    const { drawerOpen, byCreationId, canManageNonPaymentInfo } =
      useVendorInfo();

    const vendorCreation = useSelector(byCreationId(creationId));

    const vendor = useSelector<RootState>((s) => {
      //should be a referenceNode
      const value = selector(s);
      if (!value) return;

      return asOption<string>(value);
    }, isEqual) as Option | undefined;

    const action = useMemo<ComboBoxActionAddon>(
      () => ({
        icon: "plus",
        mode: ({ group, query }) => {
          if (drawerOpen || !canManageNonPaymentInfo) return false;

          if (onlyVendors || group === "Vendor") return true;

          return !!query;
        },
        onClick: create,
        children: `Add new${!onlyVendors ? " vendor" : ""}`,
      }),
      [create, drawerOpen, canManageNonPaymentInfo, onlyVendors]
    );

    const renderVendorOtherLabel = useCallback(
      ({ group }: { group: string }) => {
        if (onlyVendors) return STRINGS.LABEL_VENDOR;

        const isOther = group === "Other";
        const isVendor = group === "Vendor";

        return (
          <Text as="span">
            <Text
              as={isVendor ? "strong" : "span"}
              weight={isVendor ? "bolder" : "regular"}
            >
              {STRINGS.LABEL_VENDOR}
            </Text>{" "}
            /{" "}
            <Text
              as={isOther ? "strong" : "span"}
              weight={isOther ? "bolder" : "regular"}
            >
              {STRINGS.LABEL_OTHER}
            </Text>
          </Text>
        );
      },
      [onlyVendors]
    );

    useEffect(() => {
      shouldUpdateVendorRef.current = true;
    }, [creationId]);

    useEffect(() => {
      if (
        vendorCreation?.action !== "created" ||
        !shouldUpdateVendorRef.current
      ) {
        return;
      }

      const newOption = data.find(
        (d) => d.value === vendorCreation.vendor?.url
      );

      if (!newOption || vendor?.value === newOption.value) return;

      onChange?.(newOption.value, newOption);

      shouldUpdateVendorRef.current = false;
    }, [creationId, data, onChange, vendorCreation, vendor?.value]);

    return (
      <ComboBox
        ref={ref}
        data={data}
        name="vendor"
        label={renderVendorOtherLabel as any}
        value={(vendor?.value ? vendor : "") as any}
        loading={status === "loading"}
        onChange={onChange as any}
        action={action}
        {...props}
      />
    );
  }
);

SelectVendor.displayName = "SelectVendor";
