import { useMemo, useRef } from "react";

import { isEqual } from "../../utils/is-equal";

/**
 * This hook do the same thing as useMemo but it
 * deep compare the result, so if we return the same
 * value as before instead of returning a new version
 * of it, we return the previous one.
 */
export const useDeepMemo = <T>(
  fn: () => T,
  deps: ReadonlyArray<unknown> | undefined = []
) => {
  const prev = useRef<T>(undefined);
  const next = useMemo(fn, deps); // eslint-disable-line
  const value =
    prev.current && isEqual(prev.current, next) ? prev.current : next;
  prev.current = value;
  return value;
};
