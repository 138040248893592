import React, { type ComponentProps, memo, useCallback } from "react";
import { Icon, Image, Wrapper } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { showOpenFilePicker } from "@adaptive/design-system/utils";
import cn from "clsx";

import styles from "./thumbnail.module.css";

type ThumbnailProps = Omit<ComponentProps<typeof Image>, "onChange"> & {
  accept?: string;
  disabled?: boolean;
  onChange?: (file: File) => void;
};

const Thumbnail = ({
  src,
  alt,
  accept = "image/*,application/pdf",
  disabled,
  onChange,
}: ThumbnailProps) => {
  const onClick = useEvent(async () => {
    const [file] = await showOpenFilePicker({ accept });

    if (!file) return;

    onChange?.(file);
  });

  const render = useCallback(
    (children: React.ReactNode) => (
      <button
        onClick={onClick}
        disabled={disabled}
        className={styles["button"]}
      >
        {children}
      </button>
    ),
    [onClick, disabled]
  );

  return (
    <Wrapper when={!!onChange} render={render}>
      {!src ? (
        <div
          className={cn(styles["thumbnail"], styles["-empty"])}
          data-skeleton=""
        >
          <Icon
            size="lg"
            name="receipt"
            className={styles["icon"]}
            aria-label={alt}
          />
        </div>
      ) : (
        <Image
          fit="cover"
          src={src}
          alt={alt}
          className={styles["thumbnail"]}
          data-skeleton=""
        />
      )}
    </Wrapper>
  );
};

const MemoizedThumbnail = memo(Thumbnail);

export { MemoizedThumbnail as Thumbnail };
