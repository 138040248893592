import React, { useCallback, useEffect, useMemo } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Card,
  ComboBox,
  DateField,
  dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Loader,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useEvent, useForm } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  is,
  isEqual,
} from "@adaptive/design-system/utils";
import {
  billsApi,
  PAYMENT_METHOD,
  type PaymentComment,
  useCancelBillPaymentV2Mutation,
  useVoidBillPaymentV2Mutation,
} from "@api/bills";
import { lienWaiverSchema } from "@api/bills/response";
import { handleErrors } from "@api/handle-errors";
import { CardTransactionField } from "@card-feed/components";
import type { LinkedCost } from "@card-feed/types";
import { Comments, type OnAddCommentHandler } from "@components/comments";
import { useObjectInternalVersion } from "@hooks/use-object-internal-version";
import { usePaymentOptionsInfo } from "@hooks/use-payment-options-info";
import { BillLienWaiverField } from "@lien-waiver/components";
import { LIEN_WAIVER_STATUS } from "@lien-waiver/constants";
import { type LienWaiver } from "@lien-waiver/types";
import { LinkedTransactionsTable } from "@src/bills/components/bill-payment-drawer/bill-payment-linked-transactions";
import { selectVendorPaymentData } from "@src/bills/components/pay-bill-step/utils";
import { PAYMENT_STATUS } from "@src/bills/constants";
import { useBillPaymentV2 } from "@src/bills/hooks/use-bill-payment-v2";
import { type PaymentStatusKey } from "@src/bills/types";
import { selectStaticBill } from "@src/bills/utils";
import { usePatchLienWaiverRequestMutation } from "@src/lien-waiver/hooks";
import { refetchCurrentBill } from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { BasePermissions, useUserInfo } from "@store/user";
import { formatCard } from "@utils/format-card";
import { useVendorAction } from "@vendors/hooks";
import type { Stage } from "@vendors/types";
import type { AnyAction } from "redux";
import { z } from "zod";

import { BillPaymentStatusTag } from "./bill-payment-status-tag";
import { STRINGS } from "./constants";

const schema = z.object({
  payFromAccount: z.string().nullish(),
  payToAccount: z.string().nullish(),
  paymentMethod: z.string().nullish(),
  refNumber: z.string(),
  debitDate: z.date().nullish(),
  deliveryDate: z.object({
    from: z.date().nullish(),
    to: z.date().nullish(),
  }),
  lienWaivers: z.array(lienWaiverSchema).nullish(),
});

type BillPaymentFormFields = z.infer<typeof schema>;

type BillPaymentFormFieldKeys = keyof BillPaymentFormFields;

const DAYS_THRESHOLD = { daysBefore: 15, daysAfter: 15 };

const SELECT_CARD = {
  title: STRINGS.SELECT_TRANSACTION_TITLE,
};

export const BillPaymentForm = ({
  billPaymentId,
  onClose,
}: {
  billPaymentId: string;
  onClose: () => void;
}) => {
  const { hasPermission, canManageLienWaiverRequest } = useUserInfo();
  const dispatch = useAppDispatch();

  const [triggerCancelBillPaymentV2, { isLoading: isLoadingCancelPayment }] =
    useCancelBillPaymentV2Mutation();
  const [triggerVoidBillPaymentV2, { isLoading: isLoadingVoidPayment }] =
    useVoidBillPaymentV2Mutation();
  const rawBill = useAppSelector(selectStaticBill, isEqual);
  const billInternalVersion = useObjectInternalVersion(rawBill);

  const {
    data: billPayment,
    isLoading: isBillPaymentLoading,
    isFetching: isBillPaymentFetching,
    refetch: refetchBillPayment,
  } = useBillPaymentV2({
    billPaymentId,
  });

  const { vendor, addressOptions: vendorAddresses } = useAppSelector(
    selectVendorPaymentData
  );

  const { showVendorById } = useVendorAction();

  const curriedOpenVendor = useCallback(
    (stage: Stage) => () => {
      showVendorById(vendor.id.toString(), stage);
    },
    [vendor.id, showVendorById]
  );

  const commentsSelector = useMemo(
    () =>
      billPayment?.timelineEvents
        ? {
            url: billPayment?.url,
            comments: billPayment?.timelineEvents,
          }
        : undefined,
    [billPayment?.url, billPayment?.timelineEvents]
  );

  const canPayBill = hasPermission(BasePermissions.PAY_BILLS);
  const isPaid = billPayment?.status === PAYMENT_STATUS.PAID;

  const { getPaymentMethods: getPaymentMethodOptions } = usePaymentOptionsInfo({
    skip: true,
  });

  const isLoadingInitialData = isBillPaymentLoading;

  const onCancelPayment = useEvent(async (billPaymentId: string) => {
    if (!billPaymentId) return;
    const handler = async () => {
      try {
        if (isPaid) {
          await triggerVoidBillPaymentV2({
            billPaymentId,
          }).unwrap();
          toast.success("Payment voided");
        } else {
          await triggerCancelBillPaymentV2({
            billPaymentId,
          }).unwrap();
          toast.success("Payment canceled");
        }
        dispatch(
          refetchCurrentBill([
            "bill_payments_v2",
            "total_amount",
            "balance",
            "comments",
          ])
        );
        refetchBillPayment();
        onClose();
      } catch (e) {
        if (!handleErrors(e)) throw e;
      }
    };

    dialog.confirmation({
      title: (
        <>
          {STRINGS.ARE_YOU_SURE_TITLE_PREFIX} <br />
          {isPaid
            ? STRINGS.VOID_TITLE.toLowerCase()
            : STRINGS.CANCEL_TITLE.toLowerCase()}{" "}
          {STRINGS.ARE_YOU_SURE_TITLE_SUFFIX}
        </>
      ),
      message: (
        <>
          {STRINGS.ARE_YOU_SURE_SUBTITLE_PREFIX} <br />
          {STRINGS.ARE_YOU_SURE_SUBTITLE_SUFFIX}
        </>
      ),
      action: {
        primary: {
          children: `${isPaid ? STRINGS.VOID_TITLE : STRINGS.CANCEL_TITLE} ${STRINGS.PAYMENT_TITLE}`,
          color: "error",
          onClick: handler,
        },
        secondary: {
          children: STRINGS.CLOSE_TITLE,
        },
      },
    });
  });

  const initialValues = useMemo(() => {
    if (!billPayment) {
      return {
        paymentMethod: "",
        payFromAccount: "",
        refNumber: "",
        debitDate: undefined,
        payToAccount: "",
        deliveryDate: {
          from: undefined,
          to: undefined,
        },
        lienWaiver: null,
        cardTransaction: null,
      };
    }

    return {
      paymentMethod: billPayment.paymentMethod || "",
      payFromAccount:
        billPayment.customerCard?.url ||
        billPayment.customerBankAccount?.url ||
        "",
      payToAccount: billPayment.vendorBankAccount?.url || "",
      refNumber: billPayment?.docNumber || "",
      debitDate:
        (billPayment?.paymentSchedule?.processedOn &&
          new Date(billPayment?.paymentSchedule?.processedOn)) ||
        undefined,
      deliveryDate: {
        from:
          (billPayment?.paymentSchedule?.expectedDeliveryAfter &&
            new Date(billPayment?.paymentSchedule?.expectedDeliveryAfter)) ||
          undefined,
        to:
          (billPayment?.paymentSchedule?.expectedDeliveryBefore &&
            new Date(billPayment?.paymentSchedule?.expectedDeliveryBefore)) ||
          undefined,
      },
      lienWaivers:
        billPayment?.lienWaivers?.map((lienWaiver) => ({
          ...lienWaiver,
          lienWaiverTemplate:
            lienWaiver?.lienWaiverTemplate ??
            (lienWaiver?.status ===
            LIEN_WAIVER_STATUS.NOT_REQUIRED.toLowerCase()
              ? lienWaiver?.status
              : null),
        })) || [],
      cardTransaction: billPayment?.appliedCardTransaction || null,
    };
  }, [billPayment]);

  const {
    values: formValues,
    setValue: setFormValue,
    reset: resetForm,
    ...form
  } = useForm<BillPaymentFormFields>({
    initialValues,
    schema,
  });

  const isLoadingAction =
    form.isSubmitting || isLoadingCancelPayment || isLoadingVoidPayment;

  const isPaymentAwaitingACHCollection = useMemo(() => {
    return (
      billPayment?.paymentMethod === PAYMENT_METHOD.ACH && !vendor.hasBankingAch
    );
  }, [billPayment?.paymentMethod, vendor.hasBankingAch]);

  const isPaymentAwaitingLienWaiverSignature = useMemo(() => {
    return (
      billPayment?.status === PAYMENT_STATUS.PENDING_PREREQUISITES &&
      billPayment?.processPrerequisites?.[0]?.type === "lien_waiver_signature"
    );
  }, [billPayment?.processPrerequisites, billPayment?.status]);

  const disabledFields: Partial<Record<BillPaymentFormFieldKeys, boolean>> =
    useMemo(() => {
      return {
        refNumber: true,
        paymentMethod: true,
        payFromAccount: true,
        debitDate: true,
        payToAccount: true,
        deliveryDate: true,
      };
    }, []);

  const debitDateHelperMessage = useMemo(() => {
    if (isPaymentAwaitingACHCollection) {
      return STRINGS.PAY_ACH_COLLECTION_TITLE;
    }
    if (isPaymentAwaitingLienWaiverSignature) {
      return STRINGS.PAY_LIEN_WAIVER_TITLE;
    }
    return undefined;
  }, [isPaymentAwaitingACHCollection, isPaymentAwaitingLienWaiverSignature]);

  const showPayTo = formValues.paymentMethod === PAYMENT_METHOD.ACH;

  const showCreditCardTransactionField =
    (billPayment?.appliedCardTransaction ||
      billPayment?.hasUnmatchedCardTransactions) &&
    billPayment?.status !== PAYMENT_STATUS.CANCELLED;

  const showCancelOrVoidButton =
    billPayment?.status !== PAYMENT_STATUS.CANCELLED;

  const showLienWaiverField =
    !isLoadingInitialData &&
    canManageLienWaiverRequest &&
    vendor &&
    (billPayment?.lienWaivers?.length || 0) > 0;

  const [patchLienWaiverRequest] = usePatchLienWaiverRequestMutation();

  const onChangeLienWaiverTemplate = useCallback(
    async (value: string, extra?: { customer?: string }) => {
      const index = formValues?.lienWaivers?.findIndex(
        (lienWaiver) => lienWaiver?.customer?.url === extra?.customer
      );

      if (is.number(index) && index >= 0) {
        const isLienWaiverNotRequired =
          !value || value === LIEN_WAIVER_STATUS.NOT_REQUIRED;

        const payload = {
          status: isLienWaiverNotRequired
            ? value
            : LIEN_WAIVER_STATUS.NOT_REQUESTED,
          lienWaiverTemplate: isLienWaiverNotRequired ? null : value,
        };

        setFormValue(`lienWaivers.${index}`, {
          ...formValues?.lienWaivers?.[index],
          ...payload,
        });

        const lienWaiverId = formValues?.lienWaivers?.[index].id;

        if (
          isLienWaiverNotRequired &&
          billPayment?.lienWaivers?.[index]?.status !==
            LIEN_WAIVER_STATUS.NOT_REQUIRED &&
          lienWaiverId
        ) {
          try {
            await patchLienWaiverRequest({
              id: lienWaiverId,
              status: LIEN_WAIVER_STATUS.NOT_REQUIRED,
              lienWaiverTemplateId: null,
              billPaymentId: billPaymentId,
            }).unwrap();

            dispatch(refetchCurrentBill(["lien_waivers"]));
            toast.success("Lien waiver marked as not required");
          } catch (e) {
            toast.error("Failed to mark lien waiver as not required");
            handleErrors(e);
          }
        }
      }
    },
    [
      billPayment?.lienWaivers,
      billPaymentId,
      dispatch,
      formValues?.lienWaivers,
      patchLienWaiverRequest,
      setFormValue,
    ]
  );

  const costForCardTransaction = useMemo(() => {
    return billPayment?.vendor &&
      billPayment?.vendor.email &&
      formValues?.debitDate
      ? ({
          url: billPayment.url.replace("v2/", ""),
          vendor: {
            displayName: `${billPayment?.vendor.displayName}`,
            email: billPayment?.vendor.email,
            url: billPayment?.vendor.url,
          },
          paymentAccount: billPayment?.customerPaymentAccount,
          date: formValues?.debitDate,
          cardTransaction: billPayment?.appliedCardTransaction,
        } as LinkedCost)
      : undefined;
  }, [
    billPayment?.appliedCardTransaction,
    billPayment?.customerPaymentAccount,
    billPayment?.url,
    billPayment?.vendor,
    formValues?.debitDate,
  ]);

  const onAddComment = useEvent<OnAddCommentHandler>(
    ({ user, text, id, url, parentCommentUrl, files }) => {
      if (!billPayment) throw new Error("Invalid payment");

      const action = billsApi.util.updateQueryData(
        "getBillPaymentV2",
        { billPaymentId: billPayment.id },
        (payment) => {
          const newComment: PaymentComment = {
            text,
            author: user,
            id: id || "",
            url,
            files,
            createdAt: new Date(),
            timelineEventType: "COMMENT",
            attachable: "",
          };
          if (parentCommentUrl) {
            payment.timelineEvents = payment.timelineEvents.map((comment) =>
              comment.url === parentCommentUrl
                ? {
                    ...comment,
                    replies: [...(comment.replies || []), newComment],
                  }
                : comment
            );
          } else {
            payment.timelineEvents.push({ ...newComment, replies: [] });
          }
        }
      ) as unknown as AnyAction;

      dispatch(action);
    }
  );

  const onChangeLienWaiverRequest = useCallback(async () => {
    dispatch(refetchCurrentBill(["lien_waivers"]) as unknown as AnyAction);
    refetchBillPayment();
  }, [dispatch, refetchBillPayment]);

  useEffect(() => {
    resetForm(initialValues);
  }, [initialValues, resetForm]);

  const getLastEventByType = useCallback(
    (type: string) => {
      const events =
        billPayment?.timelineEvents.filter(
          (event) => event.timelineEventType === type
        ) || [];
      return events[events.length - 1];
    },
    [billPayment?.timelineEvents]
  );

  const voidedPayment = useMemo(() => {
    const voidedPaymentEvent = getLastEventByType("PAYMENT_VOIDED");
    const cancelledPaymentEvent = getLastEventByType("PAYMENT_CANCELLED");
    const event = voidedPaymentEvent || cancelledPaymentEvent;

    if (!event) return;

    return {
      author: event?.author?.fullName,
      createdAt: event?.createdAt
        ? formatDate(new Date(event?.createdAt), "MM/dd/yyyy 'at' hh:mma")
        : "",
      actionTitle: cancelledPaymentEvent
        ? STRINGS.PAYMENT_CANCELLED_TITLE
        : STRINGS.PAYMENT_VOIDED_TITLE,
    };
  }, [getLastEventByType]);

  const paymentMethodOptions = useMemo(() => {
    return billPayment?.paymentMethod
      ? getPaymentMethodOptions([billPayment.paymentMethod])
      : [];
  }, [billPayment?.paymentMethod, getPaymentMethodOptions]);

  const paymentFromAccountOptions = useMemo(() => {
    const name =
      billPayment?.customerCard?.fullName ||
      billPayment?.customerBankAccount?.name;

    const mask =
      billPayment?.customerCard?.mask || billPayment?.customerBankAccount?.mask;

    return billPayment?.customerBankAccount
      ? [
          {
            label: formatCard({
              name,
              mask,
            }),
            value:
              billPayment.customerCard?.url ||
              billPayment.customerBankAccount?.url ||
              "",
          },
        ]
      : [];
  }, [billPayment?.customerBankAccount, billPayment?.customerCard]);

  const payToAccountOptions = useMemo(() => {
    return billPayment?.vendorBankAccount
      ? [
          {
            label: `${STRINGS.ROUTING_NUMBER_TITLE} ${billPayment.vendorBankAccount.routingNumber.replaceAll("*", "•")} - ${STRINGS.ACCOUNT_NUMBER_TITLE} ${billPayment.vendorBankAccount.accountNumber.replaceAll("*", "•")}`,
            value: billPayment.vendorBankAccount.url,
          },
        ]
      : [];
  }, [billPayment?.vendorBankAccount]);

  return (
    <>
      {isLoadingAction && <Loader position="absolute" />}
      <DialogHeader>
        <Flex justify="space-between" align="center">
          <Flex gap="md" justify="flex-start" direction="column">
            {isLoadingInitialData && STRINGS.LOADING_TITLE}
            {!isLoadingInitialData && `Payment #${billPayment?.docNumber}`}
            {billPayment && (
              <Text size="md">
                ${formatCurrency(billPayment?.appliedAmount)}
              </Text>
            )}
          </Flex>
          {billPayment && (
            <BillPaymentStatusTag
              status={billPayment.status as PaymentStatusKey}
              paymentMethod={billPayment.paymentMethod}
              isVoided={billPayment.isVoided}
            />
          )}
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Flex width="full" direction="column" gap="4xl">
          <Flex
            width="full"
            justify="flex-start"
            direction="column"
            gap="4xl"
            grow
            as="form"
            {...form.props}
          >
            {(isLoadingInitialData ||
              !billPayment ||
              isBillPaymentFetching) && <Loader />}
            {!isLoadingInitialData && billPayment && (
              <Flex width="full" justify="flex-start" direction="column">
                <Flex gap="lg" direction="column">
                  {billPayment?.isVoided && voidedPayment && (
                    <Alert variant="error">
                      <AlertTitle>
                        {voidedPayment.actionTitle} {voidedPayment.author}
                      </AlertTitle>
                      <AlertContent>{voidedPayment.createdAt}</AlertContent>
                    </Alert>
                  )}
                  <Text as="h2" size="xl" weight="bold">
                    {STRINGS.PAYMENTS_DETAILS_TITLE}
                  </Text>
                  <Card
                    as={Flex}
                    direction="column"
                    width="full"
                    gap={{ mobile: "none", tablet: "lg" }}
                  >
                    <Flex
                      gap={{ mobile: "none", tablet: "xl" }}
                      direction={{ mobile: "column", tablet: "row" }}
                      width="full"
                    >
                      <TextField
                        label={STRINGS.REF_TITLE}
                        disabled={disabledFields["refNumber"]}
                        {...form.register("refNumber")}
                      />
                      <ComboBox
                        disabled={disabledFields["paymentMethod"]}
                        label={STRINGS.PAYMENT_METHOD_TITLE}
                        data={paymentMethodOptions}
                        {...form.register("paymentMethod")}
                      />
                    </Flex>
                    <Flex
                      gap={{ mobile: "none", tablet: "xl" }}
                      direction={{ mobile: "column", tablet: "row" }}
                      width="full"
                    >
                      <Flex direction="column" width="full">
                        <ComboBox
                          disabled={disabledFields["payFromAccount"]}
                          data={paymentFromAccountOptions}
                          prefix={<Icon name="bank" />}
                          label={
                            isPaid
                              ? STRINGS.PAID_FROM_TITLE
                              : STRINGS.PAY_FROM_TITLE
                          }
                          {...form.register("payFromAccount")}
                        />
                      </Flex>
                      <Flex direction="column" width="full">
                        <DateField
                          label={STRINGS.VENDOR_DEBIT_DATE_TITLE}
                          disabled={disabledFields["debitDate"]}
                          fromDate={new Date()}
                          helperMessage={debitDateHelperMessage}
                          {...form.register({
                            name: "debitDate",
                            type: "date",
                          })}
                        />
                      </Flex>
                    </Flex>
                    {formValues.paymentMethod ===
                      PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK && (
                      <Flex gap={{ mobile: "none", tablet: "xl" }}>
                        <Flex direction="column" gap="xs">
                          <TextField
                            disabled
                            width="full"
                            label={STRINGS.VENDOR_EMAIL_TITLE}
                            messageVariant="hidden"
                            value={vendor.email || ""}
                          />
                          <Text size="xs" color="neutral-500">
                            {STRINGS.CHANGE_EMAIL_TITLE}{" "}
                            <Link
                              variant="success"
                              size="xs"
                              onClick={curriedOpenVendor("info")}
                            >
                              {STRINGS.CHANGE_IT_HERE_TITLE}
                            </Link>
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                    {formValues.paymentMethod === PAYMENT_METHOD.MAIL_CHECK && (
                      <Flex gap={{ mobile: "none", tablet: "xl" }}>
                        <Flex direction="column" gap="xs" width="full">
                          <TextField
                            disabled
                            width="full"
                            label={STRINGS.VENDOR_ADDRESS_TITLE}
                            messageVariant="hidden"
                            value={
                              vendorAddresses.length
                                ? vendorAddresses[0].label
                                : ""
                            }
                          />
                          <Text size="xs" color="neutral-500">
                            {STRINGS.CHANGE_ADDRESS_TITLE}{" "}
                            <Link
                              variant="success"
                              size="xs"
                              onClick={curriedOpenVendor("info")}
                            >
                              {STRINGS.CHANGE_IT_HERE_TITLE}
                            </Link>
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                    {formValues.paymentMethod ===
                      PAYMENT_METHOD.VIRTUAL_SMS_CHECK && (
                      <Flex gap={{ mobile: "none", tablet: "xl" }}>
                        <Flex direction="column" gap="xs">
                          <TextField
                            disabled
                            width="full"
                            label={STRINGS.VENDOR_PHONE_NUMBER_TITLE}
                            messageVariant="hidden"
                            value={vendor.phoneNumber || ""}
                          />
                          <Text size="xs" color="neutral-500">
                            {STRINGS.CHANGE_PHONE_NUMBER_TITLE}{" "}
                            <Link
                              variant="success"
                              size="xs"
                              onClick={curriedOpenVendor("info")}
                            >
                              {STRINGS.CHANGE_IT_HERE_TITLE}
                            </Link>
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                    {showPayTo && (
                      <Flex
                        gap={{ mobile: "none", tablet: "xl" }}
                        direction={{ mobile: "column", tablet: "row" }}
                        width="full"
                      >
                        <Flex direction="column" gap="sm" width="full">
                          <ComboBox
                            disabled={disabledFields["payToAccount"]}
                            label={STRINGS.PAY_TO_TITLE}
                            placeholder=""
                            data={payToAccountOptions}
                            helperMessage={
                              isPaymentAwaitingACHCollection &&
                              vendor?.latestAchRequest?.createdAt &&
                              `${STRINGS.REQUESTED_ON_TITLE} ${formatDate(vendor.latestAchRequest.createdAt)}`
                            }
                            {...form.register("payToAccount")}
                          />
                          {isPaymentAwaitingACHCollection && (
                            <Text size="xs" color="neutral-500">
                              {STRINGS.ACH_DETAILS_TITLE}{" "}
                              <Link
                                variant="success"
                                size="xs"
                                onClick={curriedOpenVendor("payments")}
                              >
                                {STRINGS.ADD_IT_HERE_TITLE}
                              </Link>
                            </Text>
                          )}
                        </Flex>
                        <Flex direction="column" width="full">
                          <DateField
                            label="Delivery date"
                            mode="range"
                            disabled={disabledFields["deliveryDate"]}
                            {...form.register({
                              name: "deliveryDate",
                              type: "range-date",
                            })}
                          />
                        </Flex>
                      </Flex>
                    )}
                    {showCreditCardTransactionField &&
                      costForCardTransaction && (
                        <Flex gap={{ mobile: "none", tablet: "xl" }}>
                          <CardTransactionField
                            label={STRINGS.CARD_TRANSACTION_TITLE}
                            valueVariant="long"
                            suffix={<Icon size="md" name="merge" />}
                            placeholder={STRINGS.MATCH_TRANSACTION_TITLE}
                            onChange={refetchBillPayment}
                            selectCard={SELECT_CARD}
                            daysThreshold={DAYS_THRESHOLD}
                            cost={costForCardTransaction}
                          />
                        </Flex>
                      )}
                  </Card>
                </Flex>
              </Flex>
            )}
            {showLienWaiverField && (
              <Flex width="full" justify="flex-start" direction="column">
                <Flex gap="lg" direction="column">
                  <Text as="h2" size="xl" weight="bold">
                    {STRINGS.LIEN_WAIVER_TITLE}
                  </Text>
                  {formValues.lienWaivers?.map((lienWaiver) => (
                    <BillLienWaiverField
                      customer={lienWaiver.customer?.url}
                      key={lienWaiver.id}
                      value={lienWaiver.lienWaiverTemplate || undefined}
                      onChange={onChangeLienWaiverTemplate}
                      recipientEmail={vendor.email || undefined}
                      vendor={vendor}
                      paymentAmount={Number(lienWaiver?.paymentAmount || "0")}
                      billPaymentId={billPayment?.id}
                      billLienWaiver={lienWaiver as LienWaiver}
                      onRequestUpdate={onChangeLienWaiverRequest}
                      billInternalVersion={billInternalVersion}
                      billId={lienWaiver?.billId || undefined}
                    />
                  ))}
                </Flex>
              </Flex>
            )}
            {!isLoadingInitialData && (
              <Flex width="full" justify="flex-start" direction="column">
                <Flex gap="lg" direction="column">
                  <Text as="h2" size="xl" weight="bold">
                    {STRINGS.ASSOCIATED_TRANSACTION_TITLE}
                  </Text>
                  <LinkedTransactionsTable billPaymentId={billPaymentId} />
                </Flex>
              </Flex>
            )}
          </Flex>
          {commentsSelector && !isLoadingInitialData && (
            <Comments
              selector={commentsSelector}
              onAddComment={canPayBill ? onAddComment : undefined}
            />
          )}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex grow justify="space-between">
          <Button
            disabled={isLoadingInitialData || isLoadingAction}
            size="lg"
            color="neutral"
            onClick={() => onClose()}
            variant="text"
          >
            {STRINGS.CLOSE_TITLE}
          </Button>
          <Flex gap="xl">
            {showCancelOrVoidButton && billPayment && (
              <Button
                size="lg"
                disabled={
                  isLoadingInitialData || isLoadingAction || !canPayBill
                }
                color="error"
                variant="ghost"
                onClick={() => onCancelPayment(billPayment.id)}
              >
                {isPaid ? STRINGS.VOID_TITLE : STRINGS.CANCEL_TITLE}{" "}
                {STRINGS.PAYMENT_TITLE}
              </Button>
            )}
          </Flex>
        </Flex>
      </DialogFooter>
    </>
  );
};
