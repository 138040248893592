import { useCallback, useEffect, useMemo, useRef, useState } from "react";

/**
 * Based on https://github.com/mantinedev/mantine/blob/master/packages/@mantine/hooks/src/use-debounced-value/use-debounced-value.ts
 */
export function useDebouncedValue<T = any>(
  value: T,
  wait: number,
  options = { leading: false }
) {
  const [_value, setValue] = useState(value);
  const mountedRef = useRef(false);
  const timeoutRef = useRef<number | null>(null);
  const cooldownRef = useRef(false);

  const cancel = useCallback(
    () => window.clearTimeout(timeoutRef.current!),
    []
  );

  useEffect(() => {
    if (mountedRef.current) {
      if (!cooldownRef.current && options.leading) {
        cooldownRef.current = true;
        setValue(value);
      } else {
        cancel();
        timeoutRef.current = window.setTimeout(() => {
          cooldownRef.current = false;
          setValue(value);
        }, wait);
      }
    }
  }, [value, options.leading, wait, cancel]);

  useEffect(() => {
    mountedRef.current = true;
    return cancel;
  }, [cancel]);

  return useMemo(() => [_value, cancel] as const, [_value, cancel]); // eslint-disable-line
}
