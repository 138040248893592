import React, { memo } from "react";
import { Button, Flex, Tooltip } from "@adaptive/design-system";
import { MainHeader, MainTitle } from "@components/main";

import { STRINGS } from "../constants/constants";
import { useVendorAction } from "../hooks/use-vendor-action";
import { useVendorInfo } from "../hooks/use-vendor-info";

const NewVendorButton = memo(() => {
  const { create } = useVendorAction();

  const { canManageNonPaymentInfo } = useVendorInfo();

  return (
    <Tooltip
      message={
        !canManageNonPaymentInfo ? "You don't have permission to do this" : ""
      }
      placement="left"
    >
      <Button disabled={!canManageNonPaymentInfo} onClick={() => create()}>
        {STRINGS.NEW}
      </Button>
    </Tooltip>
  );
});

NewVendorButton.displayName = "NewVendorButton";

export const PageHeader = memo(() => (
  <MainHeader>
    <Flex align="center" gap="xl">
      <Flex direction="column" grow>
        <MainTitle>{STRINGS.TITLE}</MainTitle>
      </Flex>
      <NewVendorButton />
    </Flex>
  </MainHeader>
));

PageHeader.displayName = "PageHeader";
