import React, { useEffect, useState } from "react";
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router";
import {
  Alert,
  AlertContent,
  Button,
  Checkbox,
  Flex,
  Label,
  Link,
  Loader,
  PasswordField,
  PhoneField,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useDeepMemo, useForm } from "@adaptive/design-system/hooks";
import { isNonFieldErrors, UNKNOWN_ERROR_MESSAGE } from "@api/handle-errors";
import { PasswordRulesAlert } from "@components/password-rules-alert";
import { signUp, validateSignUpToken } from "@shared/api/auth";
import { generatePasswordSchema } from "@utils/schema";
import { z } from "zod";

import { Layout } from "../../../shared/components/layout";

const schema = z
  .object({
    fullName: z.string().min(1, "Your name is required"),
    email: z.string().email("An email address is required"),
    phoneNumber: z.string().min(10, "A phone number is required"),
    password: generatePasswordSchema("Your password doesn't follow the rules"),
    confirm: generatePasswordSchema(
      "Your confirm password doesn't follow the rules"
    ),
    agree: z.boolean().refine((val) => val, {
      message:
        "Please agree to Adaptive's terms of service and privacy policy before continuing",
    }),
  })
  .refine((values) => values.password === values.confirm, {
    message: "These passwords don't match, try again",
    path: ["confirm"],
  });

type Fields = z.infer<typeof schema>;

const INITIAL_VALUES: Fields = {
  fullName: "",
  email: "",
  phoneNumber: "",
  password: "",
  confirm: "",
  agree: false,
};

type Props = {
  values: Fields;
  token: string;
  companyName?: string;
};

export const SignUpForm = ({ values, token, companyName }: Props) => {
  const [errors, setErrors] = useState<string[] | string[][]>([]);
  const hasErrors = errors.length > 0;

  const initialValues = useDeepMemo(
    () => ({ ...INITIAL_VALUES, ...values }),
    [values]
  );

  const form = useForm<Fields>({
    schema,
    onSubmit(values) {
      return signUp({ ...values, token })
        .then((response) => {
          const {
            data: { is_team_member },
          } = response;
          if (is_team_member) {
            window.location.replace("/onboarding/find-team?verified=true");
          } else {
            window.location.replace("/onboarding/company?verified=true");
          }
        })
        .catch((response) => {
          if (isNonFieldErrors(response)) {
            toast.error(UNKNOWN_ERROR_MESSAGE);
          } else {
            const newErrors = [];
            if (response.response.data.token) {
              newErrors.push(response.response.data.token);
            }
            if (response.response.data.email) {
              newErrors.push(response.response.data.email);
            }
            if (response.response.data.phone_number) {
              newErrors.push(response.response.data.phone_number);
            }
            if (response.response.data.password) {
              newErrors.push(response.response.data.password);
            }
            setErrors(newErrors);
          }
        });
    },
    initialValues,
  });

  useEffect(() => {
    setErrors([]);
  }, [form.values]);

  return (
    <Layout
      title={
        companyName ? `Accept invitation to ${companyName}` : "Create account"
      }
      height="full"
      subtitle={companyName ? "Start by creating an account for yourself" : ""}
      onSubmit={form.props.onSubmit}
    >
      {form.isSubmitting && <Loader position="fixed" />}
      <Flex gap="4xl" direction="column">
        <Flex direction="column">
          <TextField
            label="Name"
            autoFocus
            placeholder="Enter your name to continue"
            required
            {...form.register("fullName")}
          />
          <TextField
            label="Email"
            type="email"
            disabled
            placeholder="Enter your email to continue"
            required
            {...form.register("email")}
          />
          <PhoneField
            label="Phone number"
            placeholder="Enter your phone number"
            required
            helperMessage="This number has to be able to receive text messages"
            {...form.register("phoneNumber")}
          />
          <PasswordField
            label="Create password"
            placeholder="Enter your password"
            required
            {...form.register("password")}
          />
          <PasswordField
            label="Reenter password"
            placeholder="Enter your password"
            required
            {...form.register("confirm")}
          />
          <Flex gap="xl" direction="column">
            <PasswordRulesAlert />

            <Label
              variant={
                form.errors.agree && form.touches.agree ? "error" : "neutral"
              }
            >
              <div>
                <Checkbox
                  style={{
                    transform: "translateY(var(--spacing-sm))",
                    marginRight: "var(--spacing-md)",
                  }}
                  {...form.register({ name: "agree", type: "boolean" })}
                />
                By signing up you agree to Adaptive’s{" "}
                <Link
                  rel="noreferrer"
                  size="sm"
                  href="https://www.adaptive.build/terms-conditions"
                  target="_blank"
                >
                  Terms of service
                </Link>{" "}
                and{" "}
                <Link
                  rel="noreferrer"
                  size="sm"
                  href="https://www.adaptive.build/privacy-policy"
                  target="_blank"
                >
                  Privacy policy
                </Link>
              </div>
            </Label>

            {hasErrors && (
              <Alert variant="error">
                <AlertContent as="ul">
                  {errors.map((error) =>
                    Array.isArray(error) ? (
                      error.map((innerError) => (
                        <li key={innerError}>{innerError}</li>
                      ))
                    ) : (
                      <li key={error}>{error}</li>
                    )
                  )}
                </AlertContent>
              </Alert>
            )}
          </Flex>
        </Flex>

        <Button
          data-testid="create-account-button"
          type="submit"
          disabled={form.isSubmitting || !form.isValid || hasErrors}
          block
        >
          Create account
        </Button>

        <Flex justify="center">
          <Link as={ReactRouterLink} to="/accounts/login">
            I already have an account
          </Link>
        </Flex>
      </Flex>
    </Layout>
  );
};

export const SignUp = () => {
  const [values, setValues] = useState<Fields>(INITIAL_VALUES);
  const { token = "" } = useParams();
  const [searchParams] = useSearchParams();
  const [companyName, setCompanyName] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const email = searchParams.get("email")?.replaceAll(" ", "+");
    if (token && email) {
      validateSignUpToken({ token, email })
        .then((response) => {
          if (response.data.company_name) {
            setCompanyName(response.data.company_name);
          }
          const fullName = searchParams.get("name");
          const phone = searchParams.get("phone");
          const newValues = {
            ...INITIAL_VALUES,
            fullName: fullName || "",
            phoneNumber: phone || "",
            email: email,
          };
          setValues(newValues);
        })
        .catch(() => {
          navigate("/accounts/login");
        });
    }
  }, [navigate, token, searchParams]);

  return values.email ? (
    <SignUpForm values={values} token={token} companyName={companyName} />
  ) : (
    <Loader position="fixed" />
  );
};
