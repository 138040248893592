import React, { useCallback, useMemo } from "react";
import { ComboBox, type ComboBoxProps, Flex } from "@adaptive/design-system";
import {
  type AccountFilters,
  useAccountsSimplified,
} from "@hooks/use-accounts-simplified";
import { DEFAULT_VENDOR_INVOICE_OPTION_VALUE } from "@src/jobs";
import { useClientInfo } from "@store/user";

import { useUsersSimplified } from "../hooks/useUsersSimplified";
import type { Option } from "../types";

import { renderCostCodeAccountPlaceholder } from "./cost-code-account-combobox/cost-code-account-combobox-utils";
import {
  CostCodeAccountComboBox,
  type CostCodeAccountComboBoxProps,
} from "./cost-code-account-combobox";
import { CustomersComboBox } from "./customers-combobox";
import { SelectVendor } from "./form";

export type BatchApplyObjectOnChangeHandlerProps = {
  value: string;
  option?: Option;
  skipSync?: boolean;
  fieldName: string;
};

export type BatchApplyObjectOnChangeHandler = (
  props: BatchApplyObjectOnChangeHandlerProps
) => void;

type Props = {
  onChange: BatchApplyObjectOnChangeHandler;
  accountFilters?: AccountFilters;
  includeVendors?: boolean;
  includeClientAsVendor?: boolean;
  includeJobs?: boolean;
  includeUsers?: boolean | { clearable?: boolean; name?: string };
  includeBillableStatus?: boolean;
  includePaymentAccounts?: boolean | { placeholder?: string };
};

const BILLABLE_STATUS_DATA = [
  { label: "Billable", value: "Billable" },
  { label: "Not Billable", value: "NotBillable" },
];

const COMMON_PROPS: Omit<ComboBoxProps<false>, "label"> & { label: string } = {
  flip: true,
  size: "sm",
  value: "",
  label: "",
  portal: true,
  listSize: 6,
  messageVariant: "hidden",
};

const EMPTY_VALUE = "__NONE__";

const COST_CODE_ACCOUNT_COMBOBOX_FILTERS: CostCodeAccountComboBoxProps<false>["filters"] =
  ["costCodeAccount"];

export const BatchApplyObject = ({
  onChange,
  accountFilters,
  includeVendors = true,
  includeClientAsVendor = false,
  includeJobs = true,
  includeUsers,
  includeBillableStatus,
  includePaymentAccounts,
}: Props) => {
  const { client } = useClientInfo();

  const usersName =
    typeof includeUsers === "object" ? includeUsers.name : "user";

  const usersEnabled = typeof includeUsers === "object" ? true : !!includeUsers;

  const usersClearable =
    typeof includeUsers === "object" ? includeUsers.clearable : false;

  const users = useUsersSimplified({
    enabled: usersEnabled,
    filters: { is_staff: false },
  });

  const enhancedUsers = useMemo(() => {
    if (!usersEnabled) return users.data;

    return usersClearable
      ? [...users.data, { label: `Clear ${usersName}`, value: EMPTY_VALUE }]
      : users.data;
  }, [usersEnabled, usersClearable, usersName, users.data]);

  const vendorSelector = useCallback(() => undefined, []);

  const vendorsExtraData = useMemo(() => {
    if (!includeClientAsVendor) return [];

    return [
      { label: `${client?.name}`, value: DEFAULT_VENDOR_INVOICE_OPTION_VALUE },
    ];
  }, [client?.name, includeClientAsVendor]);

  const paymentAccountsEnabled =
    typeof includePaymentAccounts === "object"
      ? true
      : !!includePaymentAccounts;

  const paymentAccountsPlaceholder =
    typeof includePaymentAccounts === "object"
      ? includePaymentAccounts.placeholder
      : "Set payment account";

  const paymentAccounts = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
    enabled: paymentAccountsEnabled,
  });

  const curriedOnChange = useCallback(
    (fieldName: string, skipSync?: boolean) => (value: string, option: any) =>
      onChange({
        value: value === EMPTY_VALUE ? "" : value,
        option,
        fieldName,
        skipSync,
      }),
    [onChange]
  );

  return (
    <Flex gap="xl" width="220px" direction="column">
      {includeVendors && (
        <SelectVendor
          {...COMMON_PROPS}
          selector={vendorSelector}
          onChange={curriedOnChange("vendor")}
          extraData={vendorsExtraData}
          placeholder="Set vendor"
        />
      )}
      <CostCodeAccountComboBox
        {...COMMON_PROPS}
        onChange={curriedOnChange("item_account")}
        placeholder={(data) =>
          `Set ${renderCostCodeAccountPlaceholder({
            data,
            transform: "lowercase",
          })}`
        }
        filters={COST_CODE_ACCOUNT_COMBOBOX_FILTERS}
        accountFilters={accountFilters}
      />
      {includeJobs && (
        <CustomersComboBox
          {...COMMON_PROPS}
          onChange={curriedOnChange("customer")}
          placeholder="Set job"
          messageVariant="hidden"
        />
      )}
      {includePaymentAccounts && (
        <ComboBox
          {...COMMON_PROPS}
          data={paymentAccounts.data}
          loading={paymentAccounts.status === "loading"}
          onChange={curriedOnChange("payment_account", true)}
          placeholder={paymentAccountsPlaceholder}
        />
      )}
      {includeUsers && (
        <ComboBox
          {...COMMON_PROPS}
          data={enhancedUsers}
          loading={users.status === "loading"}
          onChange={curriedOnChange("user")}
          placeholder={`Set ${usersName}`}
        />
      )}
      {includeBillableStatus && (
        <ComboBox
          {...COMMON_PROPS}
          data={BILLABLE_STATUS_DATA}
          onChange={curriedOnChange("billable_status")}
          placeholder="Set billable status"
        />
      )}
    </Flex>
  );
};
