import type { GetLinkedTransactionsReturn } from "../api/types";

import { getDetailsFromUrl } from "./get-details-from-url";
import { getDisplayInfo } from "./get-display-info";

type FlatLinks = GetLinkedTransactionsReturn[number]["links"];
type LinkedLine = FlatLinks[number]["fields"]["linkedLine"];

export const getLinkParentName = (linkedLine: LinkedLine, links: FlatLinks) => {
  if (!linkedLine) return;

  const { parent, url } = linkedLine;

  const parentInfo = parent && getDetailsFromUrl(parent);
  const parentLink =
    parentInfo &&
    links.find(
      (link) => link.id === parentInfo.id && link.type === parentInfo.type
    );
  const parentDisplayInfo = parentLink && getDisplayInfo(parentLink);

  if (parentDisplayInfo) return parentDisplayInfo;

  const urlInfo = url && getDetailsFromUrl(url);
  const urlLink =
    urlInfo &&
    links.find((link) => link.id === urlInfo.id && link.type === urlInfo.type);
  const urlDisplayInfo = urlLink && getDisplayInfo(urlLink);

  return urlDisplayInfo;
};
