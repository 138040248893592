import React, { type ComponentProps, memo } from "react";
import cn from "clsx";

import styles from "./shell.module.css";

type Props = ComponentProps<"header">;

const ShellSidebarSeparator = ({ className, ...props }: Props) => (
  <header
    className={cn(className, styles["shell-sidebar-separator"])}
    {...props}
  />
);

const MemoizedShellSidebarSeparator = memo(ShellSidebarSeparator);

export { MemoizedShellSidebarSeparator as ShellSidebarSeparator };
