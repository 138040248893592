import { useEffect, useRef } from "react";
import { useDrawerVisibility } from "@store/ui";

type UseNotificationsDrawerProps = {
  onClose?: () => void;
};

export const useNotificationsDrawer = ({
  onClose,
}: UseNotificationsDrawerProps = {}) => {
  const isVisibleRef = useRef(false);

  const { visible, setVisible } = useDrawerVisibility("notifications");

  useEffect(() => {
    if (isVisibleRef.current && !visible) {
      onClose?.();
    }

    isVisibleRef.current = visible;
  }, [onClose, visible]);

  return {
    show: () => setVisible(true),
    hide: () => setVisible(false),
    isVisible: visible,
  } as const;
};
