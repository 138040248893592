import React, {
  type ChangeEventHandler,
  type ComponentPropsWithoutRef,
  type ComponentRef,
  type FocusEventHandler,
  forwardRef,
  type KeyboardEventHandler,
  memo,
  useEffect,
  useId,
} from "react";
import * as numberInput from "@zag-js/number-input";
import { normalizeProps, useMachine } from "@zag-js/react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { suffixify } from "../../utils/suffixify";
import { Icon } from "../icon";
import { TextField } from "../text-field";

import styles from "./number-field.module.css";

type Ref = ComponentRef<typeof TextField>;

type Props = Omit<
  ComponentPropsWithoutRef<typeof TextField>,
  "type" | "value" | "align" | "onChange" | "triggerChangeOnFocusedUnmount"
> & {
  min?: number;
  max?: number;
  step?: number;
  value?: number | null;
  onChange?: (value: number | null) => void;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const NumberField = forwardRef<Ref, Props>(
  (
    {
      id,
      min = 0,
      max,
      step = 1,
      size = "md",
      name,
      value,
      onBlur,
      onFocus,
      onInput,
      onChange,
      disabled,
      onKeyDown,
      errorMessage,
      minimumFractionDigits = 0,
      maximumFractionDigits = 0,
      ...props
    },
    ref
  ) => {
    const internalId = useId();

    const enhancedId = id ?? internalId;

    const enhancedValue =
      typeof value === "number"
        ? String(value)
        : value === null
          ? ""
          : undefined;

    const context = {
      id: enhancedId,
      ids: { label: suffixify(enhancedId, "label") },
      min,
      max,
      step,
      name,
      value: enhancedValue,
      invalid: !!errorMessage,
      disabled,
      formatOptions: { minimumFractionDigits, maximumFractionDigits },
    };

    const [state, send] = useMachine(
      numberInput.machine({
        onValueChange: ({ value, valueAsNumber }) =>
          onChange?.(
            value === "" || isNaN(valueAsNumber) ? null : valueAsNumber
          ),
        allowMouseWheel: true,
        ...context,
      }),
      { context }
    );

    const {
      value: internalValue,
      setValue,
      clearValue,
      inputProps: {
        onBlur: onBlurNumber,
        onFocus: onFocusNumber,
        onChange: onChangeNumber,
        onKeyDown: onKeyDownNumber,
        defaultValue,
        ...inputProps
      },
      incrementTriggerProps,
      decrementTriggerProps,
    } = numberInput.connect(state, send, normalizeProps as any);

    const enhancedOnBlur = useEvent<FocusEventHandler<Ref>>((e) => {
      onBlur?.(e);
      onBlurNumber?.(e);
    });

    const enhancedOnFocus = useEvent<FocusEventHandler<Ref>>((e) => {
      onFocus?.(e);
      onFocusNumber?.(e);
    });

    const enhancedOnInput = useEvent<ChangeEventHandler<Ref>>((e) => {
      onInput?.(e);
      onChangeNumber?.(e);
    });

    const enhanceOnKeyDown = useEvent<KeyboardEventHandler<Ref>>((e) => {
      onKeyDown?.(e);
      onKeyDownNumber?.(e);
    });

    useEffect(() => {
      if (enhancedValue !== undefined) {
        if (enhancedValue === "") {
          clearValue();
        } else {
          setValue(Number(enhancedValue));
        }
      }
    }, [setValue, clearValue, enhancedValue]);

    return (
      <TextField
        ref={ref}
        size={size}
        align="right"
        value={internalValue ?? defaultValue}
        onInput={enhancedOnInput}
        addonAfter={
          <div className={cn(styles["control"], [styles[`-${size}`]])}>
            <Icon
              as="button"
              size={size}
              name="chevron-up"
              tabIndex={-1}
              {...(incrementTriggerProps as Record<string, unknown>)}
            />
            <Icon
              as="button"
              size={size}
              name="chevron-down"
              tabIndex={-1}
              {...(decrementTriggerProps as Record<string, unknown>)}
            />
          </div>
        }
        onBlur={enhancedOnBlur}
        onFocus={enhancedOnFocus}
        onKeyDown={enhanceOnKeyDown}
        errorMessage={errorMessage}
        triggerChangeOnFocusedUnmount={false}
        {...(inputProps as Record<string, unknown>)}
        {...props}
      />
    );
  }
);

NumberField.displayName = "NumberField";

const MemoizedNumberField = memo(NumberField);

export { MemoizedNumberField as NumberField };
