import { type RawGetLinkedTransactionReturn } from "../api/types";
import { type LinkTransactionType } from "../types";

import { isPurchaseOrderLink } from "./is-purchase-order-link";

type LinkedTransactionLinks = RawGetLinkedTransactionReturn[number]["links"];

const TYPES_TO_BE_IN_THE_END: LinkTransactionType[] = [
  "invoice",
  "invoice_line",
];

type TransformLinkedTransactionLinksParams = {
  source: string;
  links: LinkedTransactionLinks;
};

const transformLinkedTransactionLinks = ({
  source,
  links,
}: TransformLinkedTransactionLinksParams) => {
  const startLinks: LinkedTransactionLinks = [];
  const endLinks: LinkedTransactionLinks = [];

  for (const link of links) {
    const isEndItem =
      source === "invoice"
        ? isPurchaseOrderLink(link)
        : TYPES_TO_BE_IN_THE_END.some((type) => type === link.type);

    if (isEndItem) {
      endLinks.push(link);
    } else {
      startLinks.push(link);
    }
  }

  return [...startLinks, ...endLinks];
};

type TransformLinkedTransactionsParams = {
  source: string;
  transactions: RawGetLinkedTransactionReturn;
};

export const transformLinkedTransactions = ({
  source,
  transactions,
}: TransformLinkedTransactionsParams) => {
  const startTransactions: RawGetLinkedTransactionReturn = [];
  const endTransactions: RawGetLinkedTransactionReturn = [];

  for (const transaction of transactions) {
    const isEndTransaction = TYPES_TO_BE_IN_THE_END.some(
      (type) => type === transaction.type
    );

    transaction.links = transformLinkedTransactionLinks({
      links: transaction.links,
      source,
    });

    for (const link of transaction.links) {
      if (source === "purchase_order" && link.type === "invoice_line") {
        continue;
      }

      link.fields.rawLinkedLine = link.fields.linkedLine;

      if (
        // Link PO Lines to Bills when propagating from Draws
        source === "invoice" &&
        link.type === "line" &&
        link.fields.parent?.url.includes("/purchaseorders/")
      ) {
        link.fields.linkedLine = link.fields.relatedLines?.find((relatedLine) =>
          relatedLine.parent.includes("/bills/")
        );
      } else if (
        // Link Secondary Draw Lines to Bills when propagating from Draws
        source === "invoice" &&
        link.type === "invoice_line"
      ) {
        link.fields.linkedLine = link.fields.rawLinkedLine;
      } else if (
        // Link Secondary PO to Bills when propagating from POs (not lines) or Draws
        ["purchase_order", "invoice"].includes(source) &&
        link.type === "purchase_order"
      ) {
        const transaction = link.fields.linkedTransactions?.find(
          (transaction) => transaction.includes("/bills/")
        );
        if (transaction) {
          link.fields.linkedLine = {
            url: transaction,
            parent: transaction,
          };
        }
      } else {
        delete link.fields.linkedLine;
      }
    }

    if (isEndTransaction) {
      endTransactions.push(transaction);
    } else {
      startTransactions.push(transaction);
    }
  }

  return [...startTransactions, ...endTransactions];
};
