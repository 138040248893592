import { arraySchema, idSchema, userSchema } from "@utils/schema";
import { z } from "zod";

export const checkBillDuplicationRequestSchema = z.object({
  vendorId: idSchema,
  docNumber: z.string(),
});

export const getLinkedInvoicesRequestSchema = z.object({
  billIds: z.array(idSchema),
});

export const getPaymentOptionsRequestSchema = z.object({
  billIds: z.array(idSchema),
});

export const PAYMENT_METHOD = {
  MARK_AS_PAID: "mark-as-paid",
  ACH: "ACH",
  PRINT_CHECK: "print-check",
  MAIL_CHECK: "mail-check",
  VIRTUAL_EMAIL_CHECK: "virtual-email-check",
  VIRTUAL_SMS_CHECK: "virtual-sms-check",
} as const;

export const paymentMethodEnumSchema = z.enum([
  PAYMENT_METHOD.MARK_AS_PAID,
  PAYMENT_METHOD.ACH,
  PAYMENT_METHOD.PRINT_CHECK,
  PAYMENT_METHOD.MAIL_CHECK,
  PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK,
  PAYMENT_METHOD.VIRTUAL_SMS_CHECK,
]);

export const lienWaiverBasePayloadSchema = z.object({
  customer: z.string().url(),
  status: z.enum(["not_required", "not_selected"]).nullish(),
  sendLienWaiverOnPayment: z.boolean(),
});

export const lienWaiverFormPayloadSchema = lienWaiverBasePayloadSchema.merge(
  z.object({
    lienWaiverTemplate: z.string().url().nullish(),
  })
);

export const lienWaiverPayloadSchema = lienWaiverBasePayloadSchema.merge(
  z.object({
    lienWaiverTemplateId: idSchema.nullish(),
  })
);

export const accountTypeEnumSchema = z.enum([
  "depository",
  "loan",
  "credit",
  "investment",
  "other",
]);

export const accountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  parent: z.string().url().nullish(),
  displayName: z.string(),
  isPaymentAccount: z.boolean(),
  isCreditCard: z.boolean(),
  isBankAccount: z.boolean(),
  fullyQualifiedCategory: z.string(),
  isBudgetCode: z.boolean(),
  isVendorCode: z.boolean(),
  isRemovedVendorCode: z.boolean(),
  parentName: z.string().nullish(),
});

export const customerBankAccountSchema = z.object({
  id: z.string(),
  url: z.string().url(),
  name: z.string(),
  currency: z.string().nullish(),
  type: accountTypeEnumSchema,
  mask: z.string(),
  plaidAccountId: z.string(),
  paymentAccount: accountSchema.nullish(),
  bankName: z.string(),
  verificationStatus: z.string().nullish(),
  isTransactionPullEnabled: z.boolean(),
  checkbookAccountIsActive: z.boolean().nullish(),
  lastSyncDate: z.string().nullish(),
});

export const customerSimpleSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: z.string(),
});

export const itemSchema = z
  .object({
    id: idSchema,
    url: z.string().url(),
    parent: z.string().url().nullish(),
    displayName: z.string(),
    fullyQualifiedName: z.string().nullish(),
  })
  .transform((item) => ({
    ...item,
    fullyQualifiedName: item.fullyQualifiedName ?? item.displayName,
  }));

export const customerCardSchema = z.object({
  id: idSchema,
  name: z.string(),
  mask: z.string(),
  url: z.string().url(),
  user: userSchema.nullish(),
  account: accountSchema.nullish(),
  item: itemSchema.nullish(),
  fullName: z.string(),
  customer: customerSimpleSchema.nullish(),
});

export const paymentScheduleSchema = z.object({
  processOn: z.string().nullish(),
  expectedDeliveryAfter: z.string().nullish(),
  expectedDeliveryBefore: z.string().nullish(),
});

export const bankAccountOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
  mask: z.string(),
  type: z.string().nullish(),
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerBankAccountSchema.nullish(),
  isCreditCard: z.boolean(),
  paymentMethods: arraySchema(
    z.object({
      paymentMethod: paymentMethodEnumSchema,
      customerPaymentAccount: z
        .object({ url: z.string().url().nullish() })
        .nullish(),
      vendorBankAccount: z
        .object({ url: z.string().url().nullish() })
        .nullish(),
      processPrerequisites: arraySchema(
        z.object({
          type: z.string(),
        })
      ),
      paymentSchedules: arraySchema(paymentScheduleSchema),
    })
  ),
});

export const billPaymentFormPayloadSchema = z.object({
  payments: arraySchema(
    z.object({
      amountToPay: z.number(),
      billUrls: arraySchema(z.string().url()),
      balance: z.number(),
      paymentMethod: paymentMethodEnumSchema,
      signature: z.string().nullish(),
      bankAccountOption: bankAccountOptionSchema,
      vendor: z.object({
        id: idSchema,
        email: z.string().nullish(),
      }),
      payWhenLienWaiverIsSigned: z.boolean(),
      currentDebitDate: z.string().nullish(),
    })
  ),
  lienWaivers: arraySchema(lienWaiverFormPayloadSchema),
});

export const billPaymentV2PayloadSchema = z.object({
  bills: arraySchema(idSchema),
  options: z.object({
    appliedAmount: z.number(),
    vendorBankAccount: z.string().url().nullish(),
    customerPaymentAccount: z.string().url().nullish(),
    appliedProcessOn: z.string().nullish(),
    customerBankAccount: z.string().url().nullish(),
    customerCard: z.string().url().nullish(),
    paymentMethod: paymentMethodEnumSchema.nullish(),
    appliedSignature: z.string().nullish(),
    appliedCardTransaction: z.string().url().nullish(),
    processPrerequisites: arraySchema(
      z.object({
        type: z.string(),
      })
    ).nullish(),
  }),
  lienWaivers: arraySchema(lienWaiverPayloadSchema).nullish(),
});

export const getBillPaymentRequestSchema = z.object({
  billPaymentId: idSchema,
});

export const cancelBillPaymentRequestSchema = z.object({
  billPaymentId: idSchema,
});

export const voidBillPaymentRequestSchema = z.object({
  billPaymentId: idSchema,
});
