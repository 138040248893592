import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  type FocusEventHandler,
  forwardRef,
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useTransition,
} from "react";
import { useRifm } from "rifm";

import { useEvent } from "../../hooks/use-event";
import { formatNumber } from "../../utils/format-number";
import { formatPhone } from "../../utils/format-phone";
import { mergeRefs } from "../../utils/merge-refs";
import { Icon } from "../icon";
import { TextField } from "../text-field";

type Ref = ComponentRef<typeof TextField>;

type Props = Omit<
  ComponentPropsWithoutRef<typeof TextField>,
  "type" | "changeMode"
>;

const PhoneField = forwardRef<Ref, Props>(
  (
    {
      size,
      value,
      onBlur,
      onInput,
      onChange,
      triggerChangeOnFocusedUnmount = true,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<Ref>(null);

    const [, startTransition] = useTransition();

    const [maskedValue, setMaskedValue] = useState("");

    const enhancedOnChange = useEvent((value: string) => {
      const formatted = formatPhone(value);
      onChange?.(formatted);
      setMaskedValue(formatted);
    });

    const dispatchChange = useEvent(() => {
      const formattedValue =
        value !== undefined ? formatPhone(value) : undefined;

      const formattedMaskedValue = formatPhone(maskedValue);

      onChange?.(formatNumber(formattedMaskedValue));

      return formattedValue ?? formattedMaskedValue;
    });

    const enhancedOnBlur = useEvent<FocusEventHandler<Ref>>((e) => {
      onBlur?.(e);

      const nextMaskedValue = dispatchChange();

      /**
       * We use startTransition to prevent value
       * flicker when component is controlled.
       */
      startTransition(() => setMaskedValue(nextMaskedValue));
    });

    const enhancedOnInput = useEvent<FocusEventHandler<Ref>>((e) => {
      rifm.onChange(e);
      onInput?.(e);
    });

    const rifm = useRifm({
      value: maskedValue,
      format: formatPhone,
      accept: /[\d.]/g,
      onChange: enhancedOnChange,
    });

    useEffect(() => {
      const nextMaskedValue = !value ? "" : formatPhone(value);

      setMaskedValue(nextMaskedValue);
    }, [value]);

    useLayoutEffect(() => {
      const el = internalRef.current;

      return () => {
        if (!el || !triggerChangeOnFocusedUnmount) return;

        if (document.activeElement === el) dispatchChange();
      };
    }, [dispatchChange, triggerChangeOnFocusedUnmount]);

    return (
      <TextField
        ref={mergeRefs(ref, internalRef)} // eslint-disable-line
        size={size}
        value={rifm.value}
        onBlur={enhancedOnBlur}
        onInput={enhancedOnInput}
        addonBefore={<Icon size={size} name="phone" />}
        triggerChangeOnFocusedUnmount={false}
        {...props}
      />
    );
  }
);

PhoneField.displayName = "PhoneField";

const MemoizedPhoneField = memo(PhoneField);

export { MemoizedPhoneField as PhoneField };
