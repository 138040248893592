import React from "react";
import { Link } from "react-router";
import { Button } from "@adaptive/design-system";
import { Layout } from "@components/layout";
import { capitalize } from "@utils/capitalize";

type Props = {
  to?: string;
  resource?: string;
};

// @todo should replace these as soon as we implement equivalent string utils on the DS side
const pluralize = (str: string) => (str.endsWith("s") ? str : `${str}s`);
const singularize = (str: string) =>
  str.endsWith("s") ? str.slice(0, -1) : str;

const getActionResource = (resource: string) =>
  resource === "page" ? "the main page" : pluralize(resource);

export const NotFound = ({ to = "/", resource = "page" }: Props) => {
  const singularizedResource = singularize(resource);
  const actionResource = getActionResource(resource);

  return (
    <Layout
      title={`${capitalize(singularizedResource)} not found`}
      subtitle={
        <>
          {`We're sorry, the ${singularize(resource)} you requested could not be found.`}
          <br /> {`Please go back to ${actionResource} or contact us.`}
        </>
      }
    >
      <Button as={Link} to={to} replace>
        Go to {actionResource}
      </Button>
    </Layout>
  );
};
