import { is, isEqual, pick } from "@adaptive/design-system/utils";

import type { SaveWithPropagationParams } from "../types";

import { normalizeFields } from "./normalize-fields";

type LinesInfos = {
  lines: SaveWithPropagationParams["lines"];
  values: SaveWithPropagationParams["values"]["lines"];
};

type GetLinesInfos = (params: {
  lines: Record<string, unknown>[];
  staticLines: Record<string, unknown>[];
  parentId: number;
}) => LinesInfos | undefined;

const PROPAGATION_LINE_FIELDS_TO_DIRTY_CHECK = [
  "vendor",
  "item",
  "account",
  "customer",
  "description",
  "deleted",
];

export const getLinesInfos: GetLinesInfos = ({
  lines,
  parentId,
  staticLines,
}) =>
  lines?.reduce(
    (acc: LinesInfos, line) => {
      const staticLine = staticLines?.find(
        (staticLine) => staticLine.id == line.id
      );

      if (!line || !staticLine || !is.number(line.id)) return acc;

      const lineFields = normalizeFields(
        pick(line, PROPAGATION_LINE_FIELDS_TO_DIRTY_CHECK)
      );

      const staticLineFields = normalizeFields(
        pick(staticLine, PROPAGATION_LINE_FIELDS_TO_DIRTY_CHECK)
      );

      const dirtyFields = Object.keys(lineFields).filter(
        (key) => !isEqual(lineFields[key], staticLineFields[key])
      );

      if (!dirtyFields.length || isEqual(lineFields, staticLineFields)) {
        return acc;
      }

      const updatedFields = dirtyFields.filter((field) => field !== "deleted");

      acc.lines?.push({
        id: line.id,
        isDeleted: line.deleted as boolean,
        updatedFields: updatedFields.length ? updatedFields : undefined,
      });

      acc.values?.push({
        id: line.id,
        fields: pick(lineFields, dirtyFields),
        prevValues: pick(staticLineFields, dirtyFields),
        parent: parentId,
        order: line.order as number,
        amount: line.amount as number,
      });

      return acc;
    },
    { lines: [], values: [] }
  );

export const getLinesInfosRaw: GetLinesInfos = ({
  lines,
  staticLines,
  parentId,
}) =>
  lines?.reduce(
    (acc: LinesInfos, line, index) => {
      if (!line || !is.number(line.id)) return acc;

      const staticLine = staticLines?.[index];

      const lineFields = pick(line, PROPAGATION_LINE_FIELDS_TO_DIRTY_CHECK);
      const staticLineFields = staticLine
        ? pick(staticLine, PROPAGATION_LINE_FIELDS_TO_DIRTY_CHECK)
        : {};

      const fields = Object.keys(lineFields);
      const updatedFields = fields.filter((field) => field !== "deleted");

      acc.lines?.push({
        id: line.id,
        isDeleted: line.deleted as boolean,
        updatedFields: updatedFields.length ? updatedFields : undefined,
      });
      acc.values?.push({
        id: line.id,
        fields: normalizeFields(pick(lineFields, fields)),
        prevValues: normalizeFields(pick(staticLineFields, fields)),
        parent: parentId,
        order: line.order as number,
        amount: line.amount as number,
      });

      return acc;
    },
    { lines: [], values: [] }
  );
