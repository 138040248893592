import { z } from "zod";

export const workflowConditionTypesSchema = z.enum([
  "AMOUNT",
  "JOB",
  "COST_CODE",
]);

export const workflowApproverStatusSchema = z.enum([
  "PENDING",
  "APPROVED",
  "REJECTED",
]);

export const workflowStepTypesSchema = z.enum(["ALL_OF", "ONE_OF"]);

export const workflowCustomerSchema = z.object({
  id: z.number(),
  displayName: z.string(),
  url: z.string(),
});

export const workflowItemSchema = z.object({
  id: z.number(),
  displayName: z.string(),
  url: z.string(),
});

export const workflowVendorSchema = z.object({
  id: z.number(),
  displayName: z.string(),
  url: z.string(),
});

export const workflowStepUserApproverSchema = z.object({
  id: z.number(),
  fullName: z.string().nullable(),
  email: z.string().email(),
  type: z.literal("user"),
  url: z.string(),
  status: workflowApproverStatusSchema.nullable(),
});

export const workflowStepRoleApproverSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  type: z.literal("role"),
  url: z.string(),
  status: workflowApproverStatusSchema.nullable(),
});

export const workflowStepSchema = z.object({
  requirementType: workflowStepTypesSchema,
  approvers: z.array(
    z.union([workflowStepRoleApproverSchema, workflowStepUserApproverSchema])
  ),
});

export const transactionWorkflowSchema = z.object({
  steps: workflowStepSchema.array(),
});

export const workflowCustomerRequirementType = z
  .enum(["ONE_OF", "ANY_JOB", "NO_JOB", "NONE_OF"])
  .nullable();

export const workflowSchema = z.object({
  id: z.string().uuid(),
  title: z.string().nullable(),
  types: z.array(z.string()).min(0),
  isDefault: z.boolean(),
  amountFrom: z.string().nullable(),
  amountTo: z.string().nullable(),
  customerRequirementType: workflowCustomerRequirementType,
  customers: workflowCustomerSchema.array().min(0),
  items: workflowItemSchema.array().min(0),
  steps: workflowStepSchema.array(),
  vendors: workflowVendorSchema.array(),
});

export const workflowApprovalsSchema = z.object({
  id: z.string().uuid(),
  objectId: z.number().nullable(),
  objectType: z.enum(["Bill", "Expense", "Invoice"]).nullable(),
  steps: workflowStepSchema.array(),
});

export const workflowApprovalsResponseSchema = z.object({
  steps: z
    .object({
      requirementType: z.string(),
      roles: z.array(z.string()).min(0),
      users: z.array(z.string()).min(0),
    })
    .array(),
});

export const workflowsResponseSchema = z.object({
  count: z.number(),
  next: z.string().url().nullish(),
  previous: z.string().url().nullish(),
  results: workflowSchema.array(),
});
