import React, { useMemo, useRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Textarea,
  type TextareaAttachmentAddon,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, useForm } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { type CommentRef } from "@components/comments/comment";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import { useRequestLienWaiverMutation } from "../api/api";
import { requestLienWaiverFormSchema } from "../api/schemas";
import { STRINGS } from "../constants";
import { getMessageToVendor } from "../utils/get-message-to-vendor";

import { type BillLienWaiverFieldProps } from "./bill-lien-waiver-field";

type BillLienWaiverRequestDialogProps = {
  show: boolean;
  onClose: () => void;
  lienWaiverTemplateId: BillLienWaiverFieldProps["value"];
} & Pick<
  BillLienWaiverFieldProps,
  | "vendor"
  | "recipientEmail"
  | "paymentAmount"
  | "billId"
  | "customer"
  | "onRequestUpdate"
  | "billPaymentId"
>;

export const BillLienWaiverRequestDialog = ({
  show,
  onClose,
  recipientEmail,
  vendor,
  lienWaiverTemplateId,
  paymentAmount,
  billId,
  customer,
  billPaymentId,
  onRequestUpdate,
}: BillLienWaiverRequestDialogProps) => {
  const commentRef = useRef<CommentRef>(null);

  const [requestLienWaiver] = useRequestLienWaiverMutation();
  const form = useForm({
    schema: requestLienWaiverFormSchema,
    initialValues: {
      vendorEmail: recipientEmail,
      files: [],
      message: "",
    },
    onSubmit: async (values) => {
      const templateId = parseRefinementIdFromUrl(lienWaiverTemplateId);

      if (billId && values.vendorEmail && templateId) {
        const payload = {
          billId,
          billPaymentId,
          customerId: parseRefinementIdFromUrl(customer),
          vendorEmail: values.vendorEmail,
          files: values.files,
          message: getMessageToVendor(vendor, values.message),
          vendorId: parseRefinementIdFromUrl(vendor.url)!,
          lienWaiverTemplateId: templateId,
          paymentAmount: paymentAmount ?? null,
        };

        try {
          await requestLienWaiver(payload).unwrap();
          onRequestUpdate?.();

          toast.success(STRINGS.REQUEST_SENT);

          onClose();
        } catch (e) {
          handleErrors(e, { maxWidth: 550 });
        }
      }
    },
  });

  const onAddFiles = useEvent((files: File[]) => {
    form.setValue("files", files);
  });

  const attachment = useMemo<TextareaAttachmentAddon>(
    () => ({
      accept: window.EXPECTED_FILE_TYPES,
      maxSize: window.MAX_COMMENT_FILES_SIZE_MB,
      maxSizeValidation: "total",
      onChange: (files) => {
        onAddFiles(files);
      },
      multiple: window.MAX_NUMBER_OF_COMMENT_FILES,
    }),
    [onAddFiles]
  );

  return (
    <Dialog variant="dialog" size="sm" show={show} onClose={onClose}>
      <DialogHeader>{STRINGS.REQUEST_DIALOG_TITLE}</DialogHeader>
      <DialogContent>
        <Flex
          as="form"
          width="full"
          direction="column"
          gap="xl"
          {...form.props}
        >
          <TextField
            type="email"
            label={STRINGS.VENDOR_EMAIL_ADDRESS}
            size="md"
            required
            disabled={form.isSubmitting}
            placeholder={STRINGS.VENDOR_EMAIL_ADDRESS_PLACEHOLDER}
            {...form.register({ name: "vendorEmail", type: "string" })}
          />
          <Flex direction="column" gap="md">
            <Textarea
              ref={commentRef}
              minHeight={94}
              maxHeight={200}
              disabled={form.isSubmitting}
              messageVariant="absolute"
              label={STRINGS.ADD_COMMENT}
              placeholder={STRINGS.ADD_COMMENT_PLACEHOLDER}
              attachment={attachment}
              {...form.register({ name: "message", type: "string" })}
              renderAfter={({ setFilesFromPicker }) => (
                <Flex gap="md" justify="flex-end" shrink={false} grow>
                  {setFilesFromPicker && (
                    <Tooltip message={STRINGS.ATTACH_FILES}>
                      <Button
                        size="sm"
                        color="neutral"
                        variant="ghost"
                        onClick={setFilesFromPicker}
                      >
                        <Icon name="paperclip" />
                      </Button>
                    </Tooltip>
                  )}
                </Flex>
              )}
            />
          </Flex>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button
          block
          variant="text"
          color="neutral"
          onClick={onClose}
          disabled={form.isSubmitting}
        >
          {STRINGS.CANCEL}
        </Button>
        <Button block type="submit" form={form.id} disabled={form.isSubmitting}>
          {STRINGS.SEND}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
