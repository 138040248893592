import { useCallback, useRef, useState } from "react";

import { getCssVar } from "../../utils/get-css-var";
import { useDeepMemo } from "../use-deep-memo";
import { useEvent } from "../use-event";

export type UseDialogReturn = ReturnType<typeof useDialog>;

export type UseDialogProps =
  | boolean
  | { initialIsVisible?: boolean; lazy?: boolean };

const secondToMillisecond = (str: string) =>
  parseFloat(str.replace("s", "")) * 1000;

export const useDialog = (config: UseDialogProps = false) => {
  const { lazy, initialIsVisible } = useDeepMemo(
    () => ({
      initialIsVisible:
        typeof config === "boolean"
          ? config
          : (config.initialIsVisible ?? false),
      lazy: typeof config !== "boolean" && config.lazy,
    }),
    [config]
  );

  const lazyTimeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined);

  const [isVisible, setIsVisible] = useState(!!initialIsVisible);
  const [isRendered, setIsRendered] = useState(isVisible);

  const show = useCallback(
    (): Promise<void> =>
      new Promise((resolve) => {
        if (!lazy) {
          setIsVisible(true);
          setIsRendered(true);
          resolve();
        } else {
          setIsRendered(true);

          const timeout = secondToMillisecond(
            getCssVar("motion-duration-quickly")
          );

          lazyTimeoutRef.current = setTimeout(() => {
            setIsVisible(true);
            resolve();
          }, timeout);
        }
      }),
    [lazy]
  );

  const hide = useCallback(
    (): Promise<void> =>
      new Promise((resolve) => {
        if (!lazy) {
          setIsVisible(false);
          setIsRendered(false);
          resolve();
        } else {
          setIsVisible(false);

          const timeout = secondToMillisecond(
            getCssVar("motion-duration-promptly")
          );

          lazyTimeoutRef.current = setTimeout(() => {
            setIsRendered(false);
            resolve();
          }, timeout);
        }
      }),
    [lazy]
  );

  const set = useCallback(
    (value: boolean) => (value ? show() : hide()),
    [show, hide]
  );

  const toggle = useEvent(() => set(!isVisible));

  return {
    set,
    show,
    hide,
    toggle,
    isVisible,
    isRendered,
  };
};
