export const BANK_SYNC_STATUS = {
  PROGRESS: "in-progress",
  DONE: "done",
};

export const DELETE_BANK_ACCOUNT_ERROR =
  "You cannot delete this account at this time, try again later";

export const BANK_ALREADY_EXISTS_ERROR = "bank already connected";

export const STRINGS = {
  SET_AS_DEFAULT: "Set as default",
  REMOVE_AS_DEFAULT: "Remove as default",
};

export const defaultSuccessMessage = (isDefault: boolean) =>
  `Default bank account ${isDefault ? "set" : "unset"} successfully`;
