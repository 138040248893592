import React, { type ComponentPropsWithoutRef, memo, useEffect } from "react";
import { DisclosureContent, useDisclosureContext } from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { Text } from "../text";

import styles from "./alert.module.css";

const DEFAULT_COMPONENT = "p";

type Props = Pick<ComponentPropsWithoutRef<typeof Text>, "truncate"> &
  Omit<ComponentPropsWithoutRef<typeof DEFAULT_COMPONENT>, "color">;

const AlertContent = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  ({ as: Component = Text, hidden, className, ...props }, ref) => {
    const { hide } = useDisclosureContext()!;

    const content = (
      <Component
        className={cn(styles["content"], className)}
        {...(props as Props)}
      />
    );

    useEffect(() => {
      if (hidden) hide();
    }, [hidden, hide]);

    return hidden ? (
      <DisclosureContent render={content} ref={ref} {...props} />
    ) : (
      content
    );
  }
);

AlertContent.displayName = "AlertContent";

const MemoAlertContent = memo(AlertContent) as typeof AlertContent;

export { MemoAlertContent as AlertContent };
