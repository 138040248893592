import {
  arraySchema,
  commentSchema,
  currencySchema,
  dateSchema,
  idSchema,
  stringOrEmptySchema,
} from "@utils/schema";
import { z } from "zod";

import { errorSchema } from "../../errors";
import { jobCostMethodSchema } from "../../jobs/response";

const itemSchema = z
  .object({
    id: idSchema,
    url: z.string().url(),
    parent: z.string().url().nullish(),
    displayName: z.string(),
    fullyQualifiedName: z.string().nullish(),
  })
  .transform((item) => ({
    ...item,
    fullyQualifiedName: item.fullyQualifiedName ?? item.displayName,
  }));

const vendorSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: z.string(),
});

const accountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  parent: z.string().url().nullish(),
  displayName: z.string(),
  isPaymentAccount: z.boolean(),
  fullyQualifiedCategory: z.string(),
});

export const markupSchema = z.object({
  id: idSchema,
  item: itemSchema.nullish(),
  itemAccount: accountSchema.nullish(),
  jobCostMethod: jobCostMethodSchema.nullish(),
  lines: arraySchema(idSchema),
  vendor: vendorSchema.nullish(),
  amount: currencySchema,
  percent: z
    .string()
    .nullish()
    .transform((value) => parseFloat(value || "0")),
  description: stringOrEmptySchema,
  isInlineMarkup: z.boolean().nullish(),
  isCustomMarkup: z.boolean().nullish(),
});

const parentSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  docNumber: z.string().nullish(),
  reviewStatus: z.string(),
  humanReadableType: z.string(),
  isInQuickbooks: z.boolean().nullish(),
});

const customerSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  parent: z.string().url().nullish(),
  displayName: z.string(),
  displayNameWithoutCompany: z.string(),
});

const linkedTransactionSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  objectId: idSchema,
});

export const billableExpenseLineSchema = z.object({
  id: idSchema,
  url: z.string().url().nullish(),
  date: dateSchema,
  type: z.string(),
  item: itemSchema.nullish(),
  jobCostMethod: jobCostMethodSchema.nullish(),
  order: z.number().nullish(),
  vendor: vendorSchema.nullish(),
  realm: z.string().url().nullish(),
  amount: currencySchema,
  parent: parentSchema.nullish(),
  status: z.string(),
  account: accountSchema.nullish(),
  customer: customerSchema.nullish(),
  taxAmount: currencySchema,
  totalAmount: currencySchema,
  forceClose: z.boolean(),
  description: stringOrEmptySchema,
  isAVariance: z.boolean(),
  billableStatus: z.enum(["Billable", "NotBillable", "HasBeenBilled"]),
  humanReadableType: z.string().nullish(),
  linkedTransaction: linkedTransactionSchema.nullish(),
});

export const lineSchema = z.object({
  id: idSchema,
  vendor: vendorSchema.nullish(),
  url: z.string().url(),
  item: itemSchema.nullish(),
  itemAccount: accountSchema.nullish(),
  jobCostMethod: jobCostMethodSchema.nullish(),
  amount: currencySchema,
  isExtra: z.boolean().nullish(),
  description: stringOrEmptySchema,
  invoicedAmount: z.number().nullish(),
  transactionLine: billableExpenseLineSchema.nullish(),
});

export const billableExpenseSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  item: itemSchema.nullish(),
  description: stringOrEmptySchema,
  account: accountSchema
    .omit({
      fullyQualifiedCategory: true,
      isPaymentAccount: true,
      parent: true,
    })
    .nullish(),
  billableStatus: z.enum(["Billable", "NotBillable", "HasBeenBilled"]),
  draw: z
    .object({
      id: idSchema,
      url: z.string().url(),
      docNumber: z.string().nullish(),
      humanReadableType: z.string(),
    })
    .nullish(),
  parent: z.object({
    date: dateSchema,
    vendor: vendorSchema.nullish(),
    id: idSchema,
    url: z.string().url(),
    paymentAccount: z.string().url().nullish(),
    docNumber: z.string().nullish(),
    humanReadableType: z.string(),
    reviewStatus: z.string(),
  }),
  amount: currencySchema,
});

export const billableExpensesResponseSchema = arraySchema(
  billableExpenseSchema
);

export const invoiceBillableExpensesResponseSchema = arraySchema(
  billableExpenseSchema
);

export const invoiceGetLinesResponseSchema = arraySchema(lineSchema);

export const invoiceGetMarkupsResponseSchema = arraySchema(markupSchema);

export const invoiceStatusSchema = z.enum([
  "Paid",
  "Draft",
  "Approved",
  "For Approval",
]);

export const invoicesInvoiceSchema = z.object({
  id: idSchema,
  date: dateSchema,
  title: stringOrEmptySchema,
  toName: stringOrEmptySchema,
  dueDate: dateSchema,
  customer: customerSchema,
  docNumber: stringOrEmptySchema,
  totalAmount: currencySchema,
  reviewStatus: invoiceStatusSchema,
  publishedToQuickbooks: z.boolean().nullish(),
  errors: arraySchema(errorSchema),
});

export const invoicesResponseSchema = z.object({
  next: z.string().url().nullish(),
  count: z.number(),
  results: arraySchema(invoicesInvoiceSchema),
  previous: z.string().url().nullish(),
});

const invoiceMutateSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  date: dateSchema,
  title: stringOrEmptySchema,
  realm: z.string().url(),
  lines: arraySchema(
    z.object({
      id: idSchema,
      url: z.string().url(),
      item: z.string().url().nullish(),
      account: z.string().url().nullish(),
      type: z.string(),
      amount: currencySchema,
      description: stringOrEmptySchema,
    })
  ),
  amount: currencySchema,
  errors: arraySchema(errorSchema),
  toName: stringOrEmptySchema,
  markups: arraySchema(markupSchema),
  dueDate: dateSchema,
  customer: customerSchema,
  docNumber: z.string(),
  totalAmount: currencySchema,
  description: stringOrEmptySchema,
  reviewStatus: invoiceStatusSchema,
  markupTotalAmount: currencySchema,
});

export const invoiceStoreResponseSchema = invoiceMutateSchema;

export const invoiceUpdateResponseSchema = invoiceMutateSchema;

export const invoiceUpdateLinesResponseSchema = z.object({
  lines: arraySchema(lineSchema),
});

export const invoiceUpdateMarkupsResponseSchema = z.object({
  markups: arraySchema(markupSchema),
});

export const invoiceEvaluateResponseSchema = z.unknown();

const approvalSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  status: z.enum(["APPROVED", "REJECTED"]),
  approvedBy: z.object({
    id: idSchema,
    url: z.string().url(),
    email: z.string(),
    fullName: z.string(),
  }),
});

const approvalWorkflowStepApproverSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  name: z.string().nullish(),
  type: z.string(),
  full_name: z.string().nullish(),
  status: z.enum(["APPROVED", "REJECTED"]).nullish(),
});

const approvalWorkflowStepSchema = z.object({
  order: z.number(),
  requirementType: z.enum(["ALL_OF", "ONE_OF"]),
  approvers: arraySchema(approvalWorkflowStepApproverSchema),
});

const approvalWorkflowSchema = z.object({
  id: idSchema,
  title: z.string().nullish(),
  steps: arraySchema(approvalWorkflowStepSchema),
});

export const summaryLineSchema = z.object({
  item: itemSchema.nullish(),
  jobCostMethod: jobCostMethodSchema.nullish(),
  budget: currencySchema,
  previousDraws: currencySchema,
  thisDraw: currencySchema,
  revisedBudget: currencySchema,
  ownersAmount: currencySchema,
  externalChangeAmount: currencySchema,
  ownersRevisedBudget: currencySchema,
  changeAmount: currencySchema,
  depth: z.number().nullish(),
  invoicedPercent: z
    .number()
    .nullish()
    .transform((value) => value || 0),
  totalInvoiced: currencySchema,
  balanceToFinish: currencySchema,
  categoryId: idSchema.nullish(),
});

export const invoiceGetResponseSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  date: dateSchema,
  title: stringOrEmptySchema,
  realm: z.string().url(),
  lines: arraySchema(lineSchema),
  linesCount: z.number(),
  canApprove: z.boolean(),
  amount: currencySchema,
  errors: arraySchema(errorSchema),
  relatedErrors: arraySchema(errorSchema),
  toName: stringOrEmptySchema,
  markups: arraySchema(markupSchema),
  dueDate: dateSchema,
  comments: arraySchema(commentSchema),
  customer: customerSchema,
  docNumber: z.string(),
  isApproved: z.boolean(),
  isApprovalEvaluated: z.boolean(),
  approvals: arraySchema(approvalSchema),
  totalCost: currencySchema,
  totalAmount: currencySchema,
  description: stringOrEmptySchema,
  reviewStatus: invoiceStatusSchema,
  humanReadableType: z.string(),
  markupTotalAmount: currencySchema,
  approvalWorkflows: arraySchema(approvalWorkflowSchema),
  publishedToQuickbooks: z.boolean().nullish(),
  summary: z
    .object({
      lines: summaryLineSchema.array(),
      totals: z.object({
        budget: currencySchema.nullish(),
        previousDraws: currencySchema.nullish(),
        thisDraw: currencySchema.nullish(),
        depth: z.number().nullish(),
        invoicedPercent: z.number().nullish(),
        totalInvoiced: currencySchema.nullish(),
        balanceToFinish: currencySchema.nullish(),
        revisedBudget: currencySchema.nullish(),
        ownersAmount: currencySchema.nullish(),
        externalChangeAmount: currencySchema.nullish(),
        ownersRevisedBudget: currencySchema.nullish(),
        changeAmount: currencySchema.nullish(),
      }),
    })
    .nullish(),
});

export const downloadInvoiceFilesResponseSchema = z.object({
  id: idSchema,
});

export const invoiceSettingsResponseSchema = z.object({
  id: idSchema,
  defaultTitle: z.string(),
  defaultDescription: z.string(),
});
