import React, { useCallback, useMemo } from "react";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogStep,
  Flex,
  Icon,
  Table,
  type TableColumn,
  Text,
} from "@adaptive/design-system";
import { parseStringCopy } from "@adaptive/design-system/utils";

import { FIELD_STRINGS, STRINGS, SUMMARY_ICONS } from "../constants/constants";
import { usePropagationContext } from "../hooks/use-propagation-context";
import type { LinkColumn, LinksTables } from "../types";
import { getSummaryTables } from "../utils/get-summary-tables";

type PropagationFinalStepProps = {
  onBack: () => void;
};

export const PropagationFinalStep = ({ onBack }: PropagationFinalStepProps) => {
  const { form, linkedTransactions, propagationFields } =
    usePropagationContext();

  const tables = useMemo<LinksTables>(() => {
    if (!form?.values) return [];

    return getSummaryTables({
      formValues: form.values,
      linkedTransactions,
      propagationFields,
    });
  }, [form?.values, linkedTransactions, propagationFields]);

  const getHeader = useCallback(
    (tableName: string) => ({
      sticky: false,
      render: () => tableName,
    }),
    []
  );

  const columns = useMemo<TableColumn<LinkColumn>[]>(
    () => [
      {
        id: "icon",
        width: 60,
        render: ({ type }) => (
          <Flex justify="center" align="center">
            <Icon name={SUMMARY_ICONS[type]} />
          </Flex>
        ),
      },
      {
        id: "info",
        width: "fill",
        render: (row) => {
          if (row.type === "deleted") {
            return parseStringCopy(STRINGS.SUMMARY_OBJECT_DELETED, {
              object: row.objectType,
            });
          }

          if (row.type === "unlinked") {
            return parseStringCopy(STRINGS.SUMMARY_OBJECT_UNLINKED, {
              from: [...new Set(row.from)].join(", "),
            });
          }

          if (row.type === "fields") {
            return parseStringCopy(STRINGS.SUMMARY_CHANGED_FIELDS, {
              fields: row.fields
                .map((field) => FIELD_STRINGS[field])
                .join(", "),
            });
          }

          if (row.type === "reverted") {
            return STRINGS.SUMMARY_OBJECT_REVERTED;
          }

          if (row.type === "archived") {
            return parseStringCopy(STRINGS.SUMMARY_OBJECT_ARCHIVED, {
              object: row.objectType,
            });
          }

          if (row.type === "linkedTo") {
            return parseStringCopy(STRINGS.SUMMARY_LINKED_TO, {
              linkedTo: [...new Set(row.linkedTo)].join(", "),
            });
          }

          return null;
        },
      },
    ],
    []
  );

  return (
    <DialogStep name="final" onBack={onBack}>
      <DialogHeader>
        <Text weight="bold">{STRINGS.REVIEW_DIALOG_HEADER}</Text>
      </DialogHeader>
      <DialogContent>
        <Flex minWidth="432px" justify="center" direction="column" gap="4xl">
          {tables.map(({ title, data }, index) => (
            <Table
              size="sm"
              key={index}
              data={data}
              header={getHeader(title)}
              columns={columns}
            />
          ))}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button block variant="text" color="neutral" onClick={onBack}>
          Back
        </Button>
        <Button block type="submit" form={form?.id}>
          Confirm
        </Button>
      </DialogFooter>
    </DialogStep>
  );
};
