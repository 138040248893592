import React, { type ComponentProps, memo } from "react";
import cn from "clsx";

import styles from "./shell.module.css";

type Props = ComponentProps<"footer">;

const ShellSidebarFooter = ({ className, ...props }: Props) => (
  <footer
    className={cn(className, styles["shell-sidebar-footer"])}
    {...props}
  />
);

const MemoizedShellSidebarFooter = memo(ShellSidebarFooter);

export { MemoizedShellSidebarFooter as ShellSidebarFooter };
