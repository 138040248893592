import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Loader } from "@adaptive/design-system";
import { useGetInvoiceQuery } from "@api/invoices";
import { NotFound } from "@components/not-found";

export const Invoice = () => {
  const navigate = useNavigate();

  const { invoiceId } = useParams();

  const invoiceQuery = useGetInvoiceQuery(
    { invoiceId: invoiceId as string },
    { skip: invoiceId === undefined }
  );

  useEffect(() => {
    if (invoiceQuery.data) {
      navigate(
        `/jobs/${invoiceQuery.data.customer.id}/invoices/${invoiceQuery.data.id}`,
        { replace: true }
      );
    }
  }, [navigate, invoiceQuery.data]);

  if (!invoiceId || invoiceQuery.isError) return <NotFound />;

  return <Loader position="absolute" />;
};
