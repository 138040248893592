import React from "react";
import { Markdown, toast, type ToastConfig } from "@adaptive/design-system";
import { dotObject } from "@adaptive/design-system/utils";
import { is } from "@adaptive/design-system/utils";
import { captureException } from "@sentry/browser";

type ErrorResponse<Data> = { response: { data: Data } } | Data;

type CustomError = ErrorResponse<{ custom: object }>;

type FlatError = ErrorResponse<{
  error: string | string[];
}>;

type ParsingError = ErrorResponse<{ error: string }>;

type FieldErrors = ErrorResponse<
  | {
      field_errors: Record<string, string | string[]>;
    }
  | {
      fieldErrors: Record<string, string | string[]>;
    }
>;

type NonFieldErrors = ErrorResponse<
  | {
      non_field_errors: string[];
    }
  | {
      nonFieldErrors: string[];
    }
>;

const getError = <Error,>(e: object, key: string): Error =>
  dotObject.get(e, `response.data.${key}`, "") ||
  dotObject.get(e, `data.${key}`, "") ||
  dotObject.get(e, key, "");

const isObject = (e: unknown): e is object =>
  !Array.isArray(e) && typeof e === "object" && e !== null;

const isParsingError = (e: unknown): e is ParsingError =>
  isObject(e) && "status" in e && e.status === "PARSING_ERROR";

export const isError = (e: unknown): e is Error =>
  isObject(e) && "message" in e && !("response" in e);

export const getCustomError = <Error extends object>(e: object) =>
  getError<Error>(e, "custom");

const getFlatError = (e: object) => getError<string | string[]>(e, "error");

const getArrayError = (e: object) =>
  getError<string[]>(e, "response.data") || getError<string[]>(e, "data");

const isFlatError = (e: unknown): e is FlatError =>
  isObject(e) && !isParsingError(e) && !!getFlatError(e);

const isArrayError = (e: unknown): e is FlatError => {
  let error: any[] | ArrayLike<unknown> = [];

  if (isObject(e)) {
    error = getArrayError(e);
  } else if (is.array(e)) {
    error = [...e];
  }

  return is.array(error) && error?.every(is.string);
};

export const isCustomError = (e: unknown): e is CustomError =>
  isObject(e) && !!getCustomError(e);

export const getUnstandardizedErrors = (e: object) => {
  const fields: unknown =
    dotObject.get(e, "response.data", "") || dotObject.get(e, "data", "");

  const errors = isObject(fields)
    ? (Object.values(fields)
        .flatMap((field) => field)
        .filter((error) => typeof error === "string") as string[])
    : [];

  return errors.length ? errors : "";
};

export const isUnstandardizedErrors = (
  e: unknown
): e is ErrorResponse<string[]> => isObject(e) && !!getUnstandardizedErrors(e);

const getFieldErrors = (e: object) =>
  getError<Record<string, string | string[]>>(e, "field_errors") ||
  getError<Record<string, string | string[]>>(e, "fieldErrors");

const isFieldErrors = (e: unknown): e is FieldErrors =>
  isObject(e) && !!getFieldErrors(e);

export const getNonFieldErrors = (e: object) =>
  getError<string[]>(e, "non_field_errors") ||
  getError<string[]>(e, "nonFieldErrors");

export const isNonFieldErrors = (e: unknown): e is NonFieldErrors =>
  isObject(e) && !!getNonFieldErrors(e);

export const UNKNOWN_ERROR_MESSAGE =
  "Unknown error occurred, please try again. If error persists, please contact us";

export const displayError = (
  error: string | string[] | string[][],
  config?: ToastConfig
) => {
  if (window.DISABLE_TOASTS) return;

  (Array.isArray(error) ? error.flat() : [error]).forEach(
    (innerError) =>
      innerError && toast.error(<Markdown>{innerError}</Markdown>, config)
  );
};

type HandleErrorsConfig = ToastConfig & {
  showUnknownErrorMessage?: boolean;
};

const DEFAULT_HANDLE_ERRORS_CONFIG: HandleErrorsConfig = {
  showUnknownErrorMessage: true,
};

type TransformErrorToCustomErrorProps = {
  error: unknown;
  extra: Record<string, unknown>;
  render: (message: string) => string;
};

export const transformErrorToCustomError = ({
  error,
  extra = {},
  render,
}: TransformErrorToCustomErrorProps) => {
  if (isNonFieldErrors(error) || isUnstandardizedErrors(error)) {
    const errors = getNonFieldErrors(error) || getUnstandardizedErrors(error);
    const errorMessage =
      Array.isArray(errors) && errors.length > 0
        ? errors[0]
        : typeof errors === "string"
          ? errors
          : "";

    if (errorMessage) {
      const message = errorMessage.endsWith(".")
        ? errorMessage.slice(0, -1)
        : errorMessage;
      return { custom: { message: render(message), ...extra } };
    }
  }

  return error;
};

type ParseCustomErrorsProps<Extra extends Record<string, unknown>> = {
  errors: unknown[];
  render: (
    props: Extra & {
      message: string;
      isFirst: boolean;
    }
  ) => string;
};

export const parseCustomErrors = <Extra extends Record<string, unknown>>({
  errors,
  render,
}: ParseCustomErrorsProps<Extra>) =>
  errors.reduce((acc: unknown[], error) => {
    if (isCustomError(error)) {
      const { message, ...extra } = getCustomError<Extra & { message: string }>(
        error
      );

      const index = acc.findIndex(
        (item) => isError(item) && item.message.startsWith(message)
      );

      const isFirst = index === -1;

      const enhancedMessage = render({
        message,
        isFirst,
        ...(extra as unknown as Extra),
      });

      if (index === -1) {
        acc.push({ message: enhancedMessage });
      } else if (isError(acc[index])) {
        (acc[index] as Error).message += enhancedMessage.replace(message, "");
      }

      return acc;
    }

    return [...acc, error];
  }, []);

/**
 * Parse backend errors and display correct messages:
 * - if parse it correct it will return `true`
 * - if cannot parse it will return `false`
 */
export const handleErrors = (e: unknown, config?: HandleErrorsConfig) => {
  const { showUnknownErrorMessage, ...toastConfig } = {
    ...DEFAULT_HANDLE_ERRORS_CONFIG,
    ...(config ? config : {}),
  };

  if (window.DEBUG) console.error(e); // eslint-disable-line no-console

  if (!isObject(e)) {
    if (typeof e === "string" && e.length > 0) {
      displayError(e, toastConfig);
      return true;
    } else if (showUnknownErrorMessage) {
      displayError(UNKNOWN_ERROR_MESSAGE, toastConfig);
      captureException(e);
    }
    return false;
  } else if (isError(e)) {
    if (!["CanceledError", "ZodError", "AbortError"].includes(e.name)) {
      displayError(e.message, toastConfig);
    } else if (e.name === "ZodError") {
      captureException(e);
    }

    return true;
  } else if (isFlatError(e)) {
    displayError(getFlatError(e), toastConfig);
    return true;
  } else if (isArrayError(e)) {
    displayError(getArrayError(e), toastConfig);
    return true;
  } else if (isNonFieldErrors(e)) {
    displayError(getNonFieldErrors(e), toastConfig);
    return true;
  } else if (isFieldErrors(e)) {
    Object.values(getFieldErrors(e)).forEach((error) =>
      displayError(error, toastConfig)
    );
    return true;
  } else if (isUnstandardizedErrors(e)) {
    displayError(getUnstandardizedErrors(e), toastConfig);
    return true;
  }

  if (showUnknownErrorMessage) {
    displayError(UNKNOWN_ERROR_MESSAGE, toastConfig);
    captureException(e);
  }

  return false;
};
