import { type Matcher, type Modifiers } from "react-day-picker";

import { is } from "../../utils/is";

import type { CustomModifier } from "./date-picker";

type ParseModifiersParam = {
  type: string;
  modifiers?: CustomModifier | CustomModifier[];
}[];

export const parseModifiers = (customModifiers: ParseModifiersParam) => {
  const nextModifiers = {} as Modifiers;

  for (const { type, modifiers } of customModifiers) {
    const enhancedModifiers = is.array(modifiers)
      ? modifiers
      : modifiers
        ? [modifiers]
        : [];

    for (const modifier of enhancedModifiers) {
      if (!(type in nextModifiers)) {
        nextModifiers[type] = [];
      }

      const matchers: Matcher[] = [];

      if (is.object(modifier) && "message" in modifier) {
        const matcher = (
          is.array(modifier.matcher) ? modifier.matcher : [modifier.matcher]
        ) as Matcher[];
        nextModifiers[`${type}.${modifier.message}`] = matcher;
        matchers.push(...matcher);
      } else {
        matchers.push(modifier);
      }

      nextModifiers[type].push(...matchers);

      for (const matcher of matchers) {
        if (is.object(matcher)) {
          if ("from" in matcher && matcher.from) {
            if (!nextModifiers["range_start"]) {
              nextModifiers["range_start"] = [];
            }
            nextModifiers["range_start"].push(matcher.from);
          }

          if ("to" in matcher && matcher.to) {
            if (!nextModifiers["range_end"]) {
              nextModifiers["range_end"] = [];
            }
            nextModifiers["range_end"].push(matcher.to);
          }
        }
      }
    }
  }

  return nextModifiers;
};
