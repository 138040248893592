import { is } from "@adaptive/design-system/utils";

export const normalizeFields = (obj: Record<string, unknown>) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (!value) return [key, undefined];
      if (is.object(value)) return [key, value?.url ?? undefined];
      if (key === "id" && is.string(value)) return [key, Number(value)];
      return [key, value];
    })
  );
};
