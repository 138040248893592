export const pick = <
  T extends Record<string, unknown>,
  K extends (keyof T | (string & {}))[], // eslint-disable-line @typescript-eslint/ban-types
>(
  obj: T,
  keys: K
) =>
  Object.keys(obj || {}).reduce(
    (acc, key) => (!keys.includes(key) ? acc : { ...acc, [key]: obj[key] }),
    {} as Pick<T, K[number]>
  );
