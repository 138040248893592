import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  type LegacyRef,
  memo,
  type MouseEventHandler,
  type ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "@ariakit/react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import { isEqual } from "../../utils/is-equal";
import { isOverflowing } from "../../utils/is-overflowing";
import { mergeRefs } from "../../utils/merge-refs";
import { suffixify } from "../../utils/suffixify";
import { FieldMessage, type FieldMessageProps } from "../field-message";
import { Label } from "../label";
import { Loader } from "../loader";
import { useProvider } from "../provider/provider-context";
import { Tooltip } from "../tooltip";

import styles from "./button-field.module.css";

type DefaultComponent = "button";

type Ref = ComponentRef<DefaultComponent>;

export type ButtonFieldProps = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "prefix" | "size" | "type"
> & {
  size?: ResponsiveProp<"sm" | "md">;
  label?: ReactNode;
  align?: "left" | "right";
  prefix?: ReactNode;
  suffix?: ReactNode;
  loading?: boolean | string;
  disabled?: boolean;
  required?: boolean;
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  placeholder?: string;
  hintMessage?: ReactNode;
  errorMessage?: ReactNode;
  containerRef?: LegacyRef<HTMLDivElement>;
  helperMessage?: ReactNode;
  "data-testid"?: string;
  warningMessage?: ReactNode;
  messageVariant?: FieldMessageProps["variant"];
};

const DEFAULT_TOOLTIP = { message: "", isVisible: false };

const ButtonField = forwardRef<Ref, ButtonFieldProps>(
  (
    {
      id,
      size: rawSize,
      label,
      align = "left",
      value,
      style,
      prefix,
      suffix,
      loading,
      required,
      disabled,
      autoFocus,
      className,
      addonAfter,
      placeholder,
      addonBefore,
      hintMessage,
      errorMessage,
      containerRef,
      onMouseEnter,
      onMouseLeave,
      helperMessage,
      "data-testid": testId,
      messageVariant = "relative",
      warningMessage,
      "aria-labelledby": labelledBy,
      ...props
    },
    ref
  ) => {
    const internalId = useId();

    const enhancedId = id ?? internalId;

    const internalRef = useRef<Ref>(null);

    const { autoFocus: providerAutoFocus } = useProvider();

    const [tooltip, setTooltip] = useState(DEFAULT_TOOLTIP);

    const size = useResponsiveProp(rawSize, "md");

    const enhancedAutoFocus = autoFocus && providerAutoFocus;

    const enhancedValue = useMemo(() => {
      if (loading && value !== undefined && value !== null) return "";

      return value;
    }, [loading, value]);

    const enhancedSuffix = loading ? <Loader /> : suffix;

    const enhancedPlaceholder = loading
      ? typeof loading === "string"
        ? loading
        : "Loading..."
      : placeholder;

    const showTooltip = useCallback(
      (el: Ref) =>
        setTooltip((prevTooltip) => {
          const contentEl = el.querySelector("div");
          const isVisible = el.value && isOverflowing(contentEl);

          if (isVisible) {
            return { message: el.value, isVisible: isOverflowing(contentEl) };
          }

          return isEqual(prevTooltip, DEFAULT_TOOLTIP)
            ? prevTooltip
            : DEFAULT_TOOLTIP;
        }),
      []
    );

    const hideTooltip = useCallback(() => {
      setTooltip((prevTooltip) =>
        isEqual(prevTooltip, DEFAULT_TOOLTIP) ? prevTooltip : DEFAULT_TOOLTIP
      );
    }, []);

    const focusInput = useEvent(() => {
      internalRef.current?.focus();
    });

    const resetScrollLeft = useEvent(() => {
      requestAnimationFrame(() => {
        if (internalRef.current) {
          internalRef.current.scrollLeft = 0;
        }
      });
    });

    const enhancedOnMouseEnter = useEvent<MouseEventHandler<Ref>>((e) => {
      if (document.activeElement !== e.currentTarget) {
        showTooltip(e.currentTarget);
      }

      onMouseEnter?.(e);
    });

    const enhancedOnMouseLeave = useEvent<MouseEventHandler<Ref>>((e) => {
      hideTooltip();
      resetScrollLeft();
      onMouseLeave?.(e);
    });

    /**
     * Workaround to set `autofocus` attribute since React ignores it
     */
    useEffect(() => {
      if (internalRef.current) {
        if (enhancedAutoFocus) {
          internalRef.current.setAttribute("autofocus", "");
        } else {
          internalRef.current.removeAttribute("autofocus");
        }
      }
    }, [enhancedAutoFocus]);

    return (
      <div
        style={style}
        aria-busy={!!loading}
        className={cn(className, styles["wrapper"], {
          [styles["-error"]]: errorMessage,
          [styles["-prefix"]]: prefix,
          [styles["-suffix"]]: enhancedSuffix,
          [styles[`-${size}`]]: size,
          [styles[`-${align}`]]: align,
          [styles["-disabled"]]: disabled,
          [styles["-addon-after"]]: addonAfter,
          [styles["-placeholder"]]: !enhancedValue,
          [styles["-addon-before"]]: addonBefore,
        })}
        data-testid={suffixify(testId, "wrapper")}
        onMouseLeave={resetScrollLeft}
      >
        {label && (
          <Label
            id={suffixify(enhancedId, "label")}
            htmlFor={enhancedId}
            variant={errorMessage ? "error" : "neutral"}
            required={required}
            hintMessage={hintMessage}
            data-testid={suffixify(testId, "label")}
          >
            {label}
          </Label>
        )}
        <div
          ref={containerRef}
          onClick={focusInput}
          className={styles["container"]}
        >
          {addonBefore && (
            <div
              onClick={focusInput}
              className={cn(styles["addon"], styles["-before"])}
              data-testid={suffixify(testId, "addon-before")}
            >
              {addonBefore}
            </div>
          )}
          <Tooltip
            as="div"
            className={styles["inner"]}
            message={tooltip.isVisible ? tooltip.message : undefined}
          >
            {prefix && (
              <div
                className={cn(styles["affix"], styles["-prefix"])}
                data-testid={suffixify(testId, "prefix")}
              >
                {prefix}
              </div>
            )}

            <Button
              id={enhancedId}
              ref={mergeRefs(internalRef, ref)} // eslint-disable-line
              type="button"
              value={enhancedValue}
              disabled={disabled}
              className={styles["deep"]}
              accessibleWhenDisabled={!!loading}
              autoFocus={enhancedAutoFocus}
              data-testid={testId}
              onMouseEnter={enhancedOnMouseEnter}
              onMouseLeave={enhancedOnMouseLeave}
              aria-invalid={!!errorMessage}
              aria-disabled={disabled}
              aria-required={required}
              aria-labelledby={labelledBy ?? suffixify(enhancedId, "label")}
              aria-describedby={
                errorMessage || warningMessage || helperMessage
                  ? suffixify(enhancedId, "message")
                  : undefined
              }
              {...props}
            >
              <div className={styles["input"]} data-skeleton="">
                {enhancedValue || enhancedPlaceholder}
              </div>
            </Button>

            {enhancedSuffix && (
              <div
                className={cn(styles["affix"], styles["-suffix"])}
                data-testid={suffixify(testId, "suffix")}
              >
                {enhancedSuffix}
              </div>
            )}
          </Tooltip>
          {addonAfter && (
            <div
              onClick={focusInput}
              className={cn(styles["addon"], styles["-after"])}
              data-testid={suffixify(testId, "addon-after")}
            >
              {addonAfter}
            </div>
          )}
        </div>
        <FieldMessage
          id={suffixify(enhancedId, "message")}
          show={!!(errorMessage || warningMessage || helperMessage)}
          color={
            errorMessage ? "error" : warningMessage ? "warning" : "neutral"
          }
          variant={messageVariant}
          data-testid={suffixify(testId, "message")}
        >
          {errorMessage || warningMessage || helperMessage}
        </FieldMessage>
      </div>
    );
  }
);

ButtonField.displayName = "ButtonField";

const MemoizedButtonField = memo(ButtonField);

export { MemoizedButtonField as ButtonField };
