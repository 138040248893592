import { useState } from "react";

import { isEqual } from "../../utils/is-equal";

export function usePrevious<Value>(value: Value, initialValue: Value) {
  const [current, setCurrent] = useState(value);
  const [previous, setPrevious] = useState(initialValue);

  if (!isEqual(value, current)) {
    setPrevious(current);
    setCurrent(value);
  }

  return previous;
}
