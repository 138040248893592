import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  type FocusEventHandler,
  forwardRef,
  type KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useTransition,
} from "react";
import { useRifm } from "rifm";

import { useEvent } from "../../hooks/use-event";
import { formatNumber } from "../../utils/format-number";
import { formatPercentage } from "../../utils/format-percentage";
import { mergeRefs } from "../../utils/merge-refs";
import { parsePercentage } from "../../utils/parse-percentage";
import { setSelectionRange } from "../../utils/set-selection-range";
import { Icon } from "../icon";
import { TextField } from "../text-field";

type Ref = ComponentRef<typeof TextField>;

export type PercentageFieldProps = Omit<
  ComponentPropsWithoutRef<typeof TextField>,
  | "type"
  | "value"
  | "align"
  | "prefix"
  | "suffix"
  | "onChange"
  | "onKeyDown"
  | "addonAfter"
  | "addonBefore"
> & {
  min?: number;
  max?: number;
  value?: number;
  onChange?: (value: number) => void;
  allowNegative?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const PercentageField = forwardRef<Ref, PercentageFieldProps>(
  (
    {
      max = 100,
      min = 0,
      size,
      value,
      onBlur,
      onInput,
      onChange,
      allowNegative,
      minimumFractionDigits,
      maximumFractionDigits,
      triggerChangeOnFocusedUnmount = true,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<Ref>(null);
    const [, startTransition] = useTransition();

    const [maskedValue, setMaskedValue] = useState("");

    const enhancedOnChange = useEvent((value: string) =>
      setMaskedValue(formatNumber(value, allowNegative))
    );

    const dispatchChange = useEvent(() => {
      const formattedValue =
        value !== undefined
          ? formatPercentage(value, {
              allowNegative,
              minimumFractionDigits,
              maximumFractionDigits,
            })
          : undefined;

      const formattedMaskedValue = formatPercentage(maskedValue, {
        allowNegative,
        minimumFractionDigits,
        maximumFractionDigits,
      });

      const nextValue = Number(
        formatNumber(formattedMaskedValue, allowNegative)
      );

      onChange?.(nextValue);

      return formattedValue ?? formattedMaskedValue;
    });

    const enhancedOnBlur = useEvent<FocusEventHandler<Ref>>((e) => {
      const nextMaskedValue = dispatchChange();

      onBlur?.(e);

      /**
       * We use startTransition to prevent value
       * flicker when component is controlled.
       */
      startTransition(() =>
        setMaskedValue(nextMaskedValue === "0" ? "" : nextMaskedValue)
      );
    });

    const adjustValue = useCallback(
      (str: string) => {
        if (str === ".") {
          str = "0.";
        }
        const parsedStr = parsePercentage(str, { maximumFractionDigits }) ?? 0;

        if (parsedStr > max) return String(max);

        if (parsedStr < min) return String(min);

        return str;
      },
      [maximumFractionDigits, max, min]
    );

    const enhancedOnInput = useEvent<FocusEventHandler<Ref>>((e) => {
      rifm.onChange(e);

      /**
       * We adjust the event value to follow min max constraints to
       * make it possible to use onInput directly without needing to
       * worry if the value is valid or not.
       */
      e.currentTarget.value = adjustValue(e.currentTarget.value);
      onInput?.(e);
    });

    const acceptRegex = allowNegative ? /[\d.-]/g : /[\d.]/g;
    const rifm = useRifm({
      value: maskedValue,
      format: (str) =>
        /[^.][.]$/.test(str) || (str === "-" && allowNegative)
          ? str
          : formatPercentage(str, {
              allowNegative,
              minimumFractionDigits: 0,
              maximumFractionDigits,
            }),
      accept: acceptRegex,
      onChange: enhancedOnChange,
      replace: (str) => {
        setSelectionRange(internalRef.current, max, max);
        return adjustValue(str);
      },
    });

    const onKeyDown = useEvent((e: KeyboardEvent<HTMLInputElement>) => {
      if (!["ArrowUp", "ArrowDown"].includes(e.code)) return;

      e.preventDefault();

      let payload = parsePercentage(rifm.value, { maximumFractionDigits }) ?? 0;

      if (e.code === "ArrowUp") {
        payload = Math.min(max, payload + 1);
      } else if (e.code === "ArrowDown") {
        payload = Math.max(min, payload - 1);
      }

      onChange?.(payload);
      enhancedOnChange(String(payload));

      return false;
    });

    useEffect(() => {
      const nextMaskedValue = !value
        ? ""
        : formatPercentage(value, {
            minimumFractionDigits,
            maximumFractionDigits,
            allowNegative,
          });

      setMaskedValue(nextMaskedValue === "0" ? "" : nextMaskedValue);
    }, [value, allowNegative, minimumFractionDigits, maximumFractionDigits]);

    useLayoutEffect(() => {
      const el = internalRef.current;

      return () => {
        if (!el || !triggerChangeOnFocusedUnmount) return;

        if (document.activeElement === el) dispatchChange();
      };
    }, [dispatchChange, triggerChangeOnFocusedUnmount]);

    return (
      <TextField
        ref={mergeRefs(ref, internalRef)} // eslint-disable-line
        size={size}
        align="right"
        value={rifm.value}
        onBlur={enhancedOnBlur}
        onInput={enhancedOnInput}
        onKeyDown={onKeyDown}
        inputMode="decimal"
        addonAfter={<Icon size={size} name="percent" />}
        triggerChangeOnFocusedUnmount={false}
        {...props}
      />
    );
  }
);

PercentageField.displayName = "PercentageField";

const MemoizedPercentageField = memo(PercentageField);

export { MemoizedPercentageField as PercentageField };
