import { fuzzySearch } from "../../utils/fuzzy-search";
import { is } from "../../utils/is";
import { isEqual } from "../../utils/is-equal";
import { isOption } from "../../utils/is-option";

import type {
  EnhancedOption,
  GetInitialStateHandler,
  GetOptionIndexHandler,
  Option,
  State,
} from "./types";

const OPTION_HEIGHT = {
  sm: 40,
  md: 48,
  lg: 48,
};

export const optimizedStateUpdate = (
  currentState: State,
  nextState: Partial<State>
): State =>
  isEqual(currentState, nextState)
    ? currentState
    : { ...currentState, ...nextState };

export const getDefaultOptionHeight = (size: "sm" | "md") =>
  OPTION_HEIGHT[size];

export const getInitialState: GetInitialStateHandler = ({
  size,
  data,
  multiple,
}) => {
  const hasGroup = data.some((option) => option.groupLabel);
  const mode = hasGroup ? "group-interacting" : "option-interacting";

  return {
    mode,
    open: false,
    data,
    query: "",
    group: "",
    index: getNextOptionIndex({ mode, data, currentIndex: -1 }),
    option: multiple ? [] : undefined,
    queryMode: "idle",
    itemsRendered: false,
    tallOptionHeight: getDefaultOptionHeight(size),
  };
};

export const getNextOptionIndex: GetOptionIndexHandler = ({
  data,
  mode,
  strict = false,
  currentIndex = -1,
}) => {
  let nextIndex = currentIndex + 1;
  let option = data[nextIndex];

  while (option && option.disabled && mode !== "group-interacting") {
    nextIndex++;
    if (nextIndex >= data.length) break;
    option = data[nextIndex];
  }

  if (strict && !option) {
    nextIndex = getPreviousOptionIndex({ data, mode, currentIndex: nextIndex });
  }

  return nextIndex;
};

export const getPreviousOptionIndex: GetOptionIndexHandler = ({
  mode,
  data,
  currentIndex = -1,
}) => {
  let previousIndex = currentIndex - 1;
  let option = data[previousIndex];

  while (option && option.disabled && mode !== "group-interacting") {
    previousIndex--;
    if (previousIndex < 0) break;
    option = data[previousIndex];
  }

  return previousIndex;
};

export const search = (
  haystack: Option[],
  needle: string,
  threshold: number
) => {
  const fuzzyInstance = fuzzySearch(haystack, { keys: ["label"], threshold });

  const matches = fuzzyInstance.search(needle) as EnhancedOption[];

  const optionMap = new Map<string, EnhancedOption>();

  const childrenMap = new Map<string, EnhancedOption[]>();

  fuzzyInstance.all.forEach((option) => {
    optionMap.set(option.value, option as EnhancedOption);
    if (option.parent) {
      if (!childrenMap.has(option.parent)) {
        childrenMap.set(option.parent, []);
      }
      childrenMap.get(option.parent)?.push(option as EnhancedOption);
    }
  });

  const results: EnhancedOption[] = [];

  matches.forEach((option) => {
    const stack: EnhancedOption[] = [option];

    const visited = new Set<string>();

    while (stack.length > 0) {
      const current = stack.pop()!;
      if (!visited.has(current.value)) {
        visited.add(current.value);
        results.push(current);
        const children = childrenMap.get(current.value) || [];
        children.forEach((child) => {
          if (!matches.some((match) => match.value === child.value)) {
            results.push(child);
            stack.push(child);
          }
        });
      }
    }
  });

  results.sort((a, b) =>
    a.parent === b.parent
      ? a.label.localeCompare(b.label, "en", { numeric: true })
      : 0
  );

  return [...new Set(results)];
};

export const getMatch = (option: EnhancedOption) =>
  option.matches?.find((match) => match.value === option.label);

export const getDepth = (
  option: Option,
  options: Option[],
  depth = 0
): number => {
  let currentOption = option;
  let currentDepth = depth;

  while (currentOption.parent) {
    const parent = options.find(
      (item) =>
        item.value === currentOption.parent &&
        (!currentOption.groupLabel ||
          item.groupLabel === currentOption.groupLabel)
    );

    if (!parent) break;

    currentOption = parent;
    currentDepth++;
  }

  return currentDepth;
};

export const isSingleOption = (value: unknown): value is Option =>
  isOption(value);

export const isMultipleOption = (value: unknown): value is Option[] =>
  is.array(value) && value.some(isOption);

export const getGroupOrder = (options: Option[]) =>
  Array.from(
    new Set(options.map((option) => option.groupLabel ?? option.label))
  );

export const orderByGroup = (
  options: EnhancedOption[],
  groupOrder?: string[]
) => {
  const groupsMap = new Map<string, EnhancedOption[]>();

  for (const option of options) {
    if (!option.groupLabel) continue;

    if (!groupsMap.has(option.groupLabel)) {
      groupsMap.set(option.groupLabel, []);
    }
    groupsMap.get(option.groupLabel)!.push(option);
  }

  const groupsArray = Array.from(groupsMap.values());

  if (groupOrder?.length) {
    groupsArray.sort(
      (a, b) =>
        groupOrder.indexOf(a[0].groupLabel ?? "") -
        groupOrder.indexOf(b[0].groupLabel ?? "")
    );
  }

  return groupsArray.flat();
};
