import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";

import { useEvent } from "../../hooks/use-event";
import { mergeRefs } from "../../utils/merge-refs";
import { suffixify } from "../../utils/suffixify";
import { Button } from "../button";
import { Icon } from "../icon";
import { TextField } from "../text-field";
import { Tooltip } from "../tooltip";

import styles from "./password-field.module.css";

type Ref = ComponentRef<typeof TextField>;

type Props = Omit<ComponentPropsWithoutRef<typeof TextField>, "type">;

const PasswordField = forwardRef<Ref, Props>(
  ({ size, onBlur, "data-testid": testId, ...props }, ref) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const internalRef = useRef<Ref>(null);

    const cursorPositionRef = useRef(0);

    const [type, setType] = useState<"text" | "password">("password");

    const toggleType = useEvent(() => {
      cursorPositionRef.current = internalRef.current?.selectionStart ?? 0;

      setType((previousType) =>
        previousType === "password" ? "text" : "password"
      );
    });

    const enhancedOnBlur = useEvent((e) => {
      if (e.relatedTarget?.contains(buttonRef.current)) return;

      setType("password");
      onBlur?.(e);
    });

    /**
     * This effect is used to restore the cursor position after the type is toggled.
     * It is necessary because the cursor position is lost when the type is changed.
     */
    useEffect(() => {
      requestAnimationFrame(() => {
        internalRef.current?.setSelectionRange(
          cursorPositionRef.current,
          cursorPositionRef.current
        );
      });
    }, [type]);

    return (
      <TextField
        ref={mergeRefs(ref, internalRef)} // eslint-disable-line
        type={type}
        size={size}
        onBlur={enhancedOnBlur}
        suffix={
          <div className={styles["control"]}>
            <Tooltip message={type === "password" ? "View" : "Hide"}>
              <Button
                ref={buttonRef}
                size={size === "sm" ? "xs" : "sm"}
                color="neutral"
                variant="text"
                onClick={toggleType}
                data-testid={suffixify(testId, "reveal")}
              >
                <Icon name={type === "password" ? "eye" : "eye-slash"} />
              </Button>
            </Tooltip>
          </div>
        }
        data-testid={testId}
        {...props}
      />
    );
  }
);

PasswordField.displayName = "PasswordField";

const MemoizedPasswordField = memo(PasswordField);

export { MemoizedPasswordField as PasswordField };
