import React, { memo, type ReactNode } from "react";
import { dialog, Flex, toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { suffixify } from "@adaptive/design-system/utils";
import { useStageName } from "@components/sequential/context";
import { useStableScrollbar } from "@hooks/useStableScrollbar";
import { useAppSelector } from "@store/hooks";
import { useTwoFactorAuth } from "@store/ui";
import cn from "clsx";

import { STRINGS } from "../constants/constants";
import { useShowNextError } from "../hooks/use-show-next-error";
import { useVendorAction } from "../hooks/use-vendor-action";
import { useVendorInfo } from "../hooks/use-vendor-info";
import { vendorSelectors } from "../store/selectors";

import styles from "./form.module.css";

const Container = memo(
  ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { hasPendingRequests } = useAppSelector((state) =>
      vendorSelectors.info(state)
    );

    const scrollRef = useStableScrollbar<HTMLFormElement>();

    const stageName = useStageName();

    const { saveVendor } = useVendorAction();

    const { hasUnsavedInfoOrBankingChanges, hasEmailChange } = useVendorInfo();

    const { checkTwoFactorAuth } = useTwoFactorAuth();

    useShowNextError();

    const formId = suffixify("vendor", stageName || "unknown-stage");

    const onSubmit = useEvent(
      async (event: React.FormEvent<HTMLFormElement>) => {
        const isCorrectForm =
          formId ===
          (event.target instanceof HTMLElement ? event.target.id : "");

        if (isCorrectForm) {
          event.preventDefault();
          if (hasEmailChange && hasPendingRequests) {
            dialog.confirmation({
              title: STRINGS.EMAIL_CHANGE_CONFIRMATION_DIALOG_TITLE,
              message: STRINGS.EMAIL_CHANGE_CONFIRMATION_DIALOG_MESSAGE,
              action: {
                primary: {
                  color: "error",
                  onClick: () =>
                    checkTwoFactorAuth(async () => {
                      await saveVendor();
                      toast.info(STRINGS.REQUESTS_WERE_EXPIRED_TOAST);
                    }),
                  children: STRINGS.EMAIL_CHANGE_CONFIRMATION_DIALOG_BUTTON,
                },
              },
            });
          } else if (
            hasUnsavedInfoOrBankingChanges &&
            hasUnsavedInfoOrBankingChanges.banking
          ) {
            checkTwoFactorAuth(async () => {
              await saveVendor();
            });
          } else {
            await saveVendor();
          }
        }
      }
    );

    return (
      <form id={formId} ref={scrollRef} onSubmit={onSubmit} noValidate>
        <Flex direction="column" gap="2xl" grow>
          {children}
        </Flex>
      </form>
    );
  }
);

Container.displayName = "Container";

export const GridContent = memo(
  ({ children }: { children: ReactNode | ReactNode[] }) => (
    <Flex
      as="main"
      className={cn(styles["content"])}
      direction="column"
      gap="2xl"
      grow
      shrink={false}
    >
      <Container>{children}</Container>
    </Flex>
  )
);

GridContent.displayName = "GridContent";

export const GridFooter = memo(
  ({ children }: { children: ReactNode | ReactNode[] }) => (
    <Flex as="footer" className={cn(styles["footer"])}>
      {children}
    </Flex>
  )
);

GridFooter.displayName = "GridFooter";
