import React, { memo, useCallback, useEffect, useState } from "react";

import { useDialog } from "../../hooks/use-dialog";
import { useEvent } from "../../hooks/use-event";
import { Button } from "../button";
import { Loader } from "../loader";

import { Dialog, type DialogOnCloseHandler } from "./dialog";
import { DialogContent } from "./dialog-content";
import { emitter, type InternalOptions } from "./dialog-create";
import { DialogFooter } from "./dialog-footer";
import { DialogHeader } from "./dialog-header";

type DialogItemProps = InternalOptions & {
  onClose: (id: InternalOptions["id"]) => void;
};

const DialogItem = ({ autoHide, onClose, ...props }: DialogItemProps) => {
  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);

  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);

  const { set, isRendered, isVisible } = useDialog({ lazy: true });

  const curriedOnPrimaryClick = useCallback(
    (dialog: InternalOptions) => async () => {
      try {
        setIsPrimaryLoading(true);
        await dialog.action?.primary?.onClick?.();
        if (dialog.action?.primary?.autoHide !== false) onClose(dialog.id);
      } finally {
        setIsPrimaryLoading(false);
      }
    },
    [onClose]
  );

  const curriedOnSecondaryClick = useCallback(
    (dialog: InternalOptions) => async () => {
      try {
        setIsSecondaryLoading(true);
        await dialog.action?.secondary?.onClick?.();
        if (dialog.action?.secondary?.autoHide !== false) onClose(dialog.id);
      } finally {
        setIsSecondaryLoading(false);
      }
    },
    [onClose]
  );

  const onInternalClose = useEvent<DialogOnCloseHandler>((trigger) => {
    if (autoHide !== false) set(false);

    props.action?.close?.(trigger);
  });

  useEffect(() => {
    set(props.show).then(() => {
      if (props.show) return;

      emitter.emit(`${props.name}-remove-finished`, props.id);
    });
  }, [set, props.show, props.id, props.name]);

  return isRendered ? (
    <Dialog
      show={isVisible}
      size={props.size}
      align={props.align}
      variant={props.variant || "confirmation"}
      onClose={onInternalClose}
    >
      <DialogHeader>{props.title}</DialogHeader>
      {props.message && <DialogContent>{props.message}</DialogContent>}
      <DialogFooter>
        <Button
          block
          color={props.action?.secondary?.color ?? "neutral"}
          variant={props.action?.secondary?.variant ?? "text"}
          onClick={curriedOnSecondaryClick(props)}
          disabled={isSecondaryLoading || isPrimaryLoading}
          data-testid={props.action?.secondary?.["data-testid"]}
        >
          {isSecondaryLoading ? (
            <Loader />
          ) : (
            (props.action?.secondary?.children ?? "Cancel")
          )}
        </Button>
        <Button
          block
          color={props.action?.primary?.color ?? "primary"}
          variant={props.action?.primary?.variant ?? "solid"}
          onClick={curriedOnPrimaryClick(props)}
          disabled={isPrimaryLoading || isSecondaryLoading}
          data-testid={props.action?.primary?.["data-testid"]}
        >
          {isPrimaryLoading ? (
            <Loader />
          ) : (
            (props.action?.primary?.children ?? "Unknown action")
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  ) : null;
};

const MemoizedDialogItem = memo(DialogItem);

type DialogContainerProps = { name: string };

const DialogContainer = ({ name }: DialogContainerProps) => {
  const [dialogs, setDialogs] = useState<InternalOptions[]>([]);

  const onAdd = useEvent((dialog: InternalOptions) => {
    setDialogs((dialogs) => [...dialogs, dialog]);
  });

  const onRemove = useEvent((id: string) => {
    setDialogs((dialogs) =>
      dialogs.map((dialog) =>
        dialog.id === id ? { ...dialog, show: false } : dialog
      )
    );
  });

  useEffect(() => {
    emitter.on(`${name}-add`, onAdd);
    emitter.on(`${name}-remove-started`, onRemove);

    return () => {
      emitter.off(`${name}-add`, onAdd);
      emitter.off(`${name}-remove-started`, onRemove);
    };
  }, [name, onAdd, onRemove]);

  return (
    <>
      {dialogs.map((dialog, i) => (
        <MemoizedDialogItem key={i} {...dialog} onClose={onRemove} />
      ))}
    </>
  );
};

const MemoizedDialogContainer = memo(DialogContainer);

export { MemoizedDialogContainer as DialogContainer };
