import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  memo,
} from "react";
import { CompositeRow, MenuItem } from "@ariakit/react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { Icon } from "../icon";
import type { IconName } from "../icon/constants";

import { useDropdown } from "./dropdown-context";
import styles from "./dropdown.module.css";

type DefaultComponent = "div";

type Ref = ComponentRef<DefaultComponent>;

type OwnProps = ComponentPropsWithoutRef<DefaultComponent> & {
  icon?: IconName;
  color?: "neutral" | "error";
  htmlFor?: string;
  hideOnClick?: boolean;
};

type StaticProps = Omit<OwnProps, "onClick">;

type InteractiveProps = OwnProps & {
  disabled?: boolean;
};

export type DropdownItemProps = InteractiveProps | StaticProps;

const DropdownItem = forwardRef<Ref, DropdownItemProps>(
  (
    {
      icon,
      color = "neutral",
      htmlFor,
      children,
      className,
      hideOnClick,
      ...props
    },
    ref
  ) => {
    const { trigger, hideOnClick: dropdownHideOnClick } = useDropdown() ?? {};

    const enhancedHideOnClick = hideOnClick ?? dropdownHideOnClick;

    const isInteractive = "onClick" in props || !!htmlFor;

    /**
     * For some reason the native htmlFor doesn't work with the MenuItem component,
     * so we need to manually trigger the click event on the target element
     */
    const onLabelClick = useEvent(() => {
      document.querySelector<HTMLElement>(`#${htmlFor}`)?.click();
    });

    return (
      <CompositeRow role="row">
        <MenuItem
          ref={ref}
          role={undefined}
          render={
            htmlFor ? (
              <label htmlFor={htmlFor} onClick={onLabelClick} />
            ) : (
              <button />
            )
          }
          className={cn(className, styles["dropdown-item"], {
            [styles[`-${color}`]]: color,
            [styles["-interactive"]]: isInteractive,
          })}
          hideOnClick={isInteractive ? enhancedHideOnClick : false}
          focusOnHover={isInteractive && trigger === "click"}
          blurOnHoverEnd={false}
          {...props}
        >
          {icon && <Icon name={icon} className={styles.icon} />}
          {children}
        </MenuItem>
      </CompositeRow>
    );
  }
);

DropdownItem.displayName = "DropdownItem";

const MemoizedDropdownItem = memo(DropdownItem);

export { MemoizedDropdownItem as DropdownItem };
