import React from "react";
import { Tooltip } from "@adaptive/design-system";
import { AdaptiveIcon } from "@components/adaptive-icon";
import { QuickBooksIcon } from "@components/quickbooks-icon";

type PurchaseOrderIconProps = {
  type: "qb" | "adaptive" | (string & {}); // eslint-disable-line @typescript-eslint/ban-types
  size?: number;
  labelled?: boolean;
};

export const PurchaseOrderIcon = ({
  type,
  size = 24,
  labelled = true,
}: PurchaseOrderIconProps) => {
  if (type === "qb") {
    return (
      <Tooltip
        as={QuickBooksIcon}
        size={size}
        message={labelled ? "QuickBooks PO" : ""}
      />
    );
  }

  if (type === "adaptive") {
    return (
      <Tooltip
        as={AdaptiveIcon}
        width={size}
        height={size}
        variant="circle"
        message={labelled ? "Adaptive PO" : ""}
      />
    );
  }

  return null;
};
