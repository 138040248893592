export const STRINGS = {
  PAYMENT_CANCELLED_TITLE: "Payment cancelled by",
  PAYMENT_VOIDED_TITLE: "Payment voided by",
  ARE_YOU_SURE_TITLE_PREFIX: "Are you sure you want to",
  ARE_YOU_SURE_TITLE_SUFFIX: "this payment?",

  ARE_YOU_SURE_SUBTITLE_PREFIX: "This cannot be undone.",
  ARE_YOU_SURE_SUBTITLE_SUFFIX: "You will need to send a new payment.",
  CANCEL_TITLE: "Cancel",
  VOID_TITLE: "Void",
  CLOSE_TITLE: "Close",
  PAYMENT_TITLE: "payment",
  LIEN_WAIVER_TITLE: "Lien waiver",
  SELECT_TRANSACTION_TITLE:
    "Select a transaction from your card history that matches with this payment",
  MATCH_TRANSACTION_TITLE: "Match to an existing card transaction",
  ASSOCIATED_TRANSACTION_TITLE: "Associated transaction",
  CARD_TRANSACTION_TITLE: "Card transaction",
  ACH_DETAILS_TITLE: "Already have their ACH bank details?",
  ADD_IT_HERE_TITLE: "Add it here",
  REQUESTED_ON_TITLE: "Requested on",
  PAY_TO_TITLE: "Pay to",
  VENDOR_ADDRESS_TITLE: "Vendor address",
  VENDOR_EMAIL_TITLE: "Vendor email",
  VENDOR_DEBIT_DATE_TITLE: "Debit date",
  CHANGE_IT_HERE_TITLE: "Change it here",
  VENDOR_PHONE_NUMBER_TITLE: "Vendor phone number",
  CHANGE_PHONE_NUMBER_TITLE: "Do you need to change the vendor’s phone number?",
  CHANGE_ADDRESS_TITLE: "Do you need to change the vendor’s address?",
  CHANGE_EMAIL_TITLE: "Do you need to change the vendor’s email?",
  PAID_FROM_TITLE: "Paid from",
  PAY_FROM_TITLE: "Pay from",
  PAYMENT_METHOD_TITLE: "Payment method",
  REF_TITLE: "Ref #",
  PAYMENTS_DETAILS_TITLE: "Payment details",
  LOADING_TITLE: "Loading...",
  ROUTING_NUMBER_TITLE: "Routing number:",
  ACCOUNT_NUMBER_TITLE: "Account number:",
  PAY_ACH_COLLECTION_TITLE: "Pay on ACH collection",
  PAY_LIEN_WAIVER_TITLE: "Pay on lien waiver signature",
};
