import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EmptyState, Flex, Link, Loader } from "@adaptive/design-system";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";
import { useClientInfo } from "@store/user";
import { toggleChatVisibility } from "@store/user/slice";
import * as analytics from "@utils/analytics";

import styles from "./page.module.css";

type ExploData = string[];

const Page = () => {
  const dispatch = useDispatch();

  const { client } = useClientInfo();

  const openChat = useEvent(() => {
    dispatch(toggleChatVisibility(true));
    analytics.track("chatOpen", { source: "explo-custom-report" });
  });

  const exploToken = client?.settings.explo_token ?? "";

  const dashboardIds = useDeepMemo<ExploData>(
    () =>
      (client
        ? dotObject.get(client, "settings.explo_dashboard_ids", [])
        : []
      ).filter(Boolean),
    [client]
  );

  const reportBuilderIds = useDeepMemo<ExploData>(
    () =>
      (client
        ? dotObject.get(client, "settings.explo_report_builder_ids", [])
        : []
      ).filter(Boolean),
    [client]
  );

  const hasReports = dashboardIds.length > 0 || reportBuilderIds.length > 0;

  const [isLoading, setIsLoading] = useState(hasReports);

  const loadSdk = useCallback(
    () =>
      new Promise<void>((resolve) => {
        if (document.getElementById("explo-sdk")) return resolve();

        const sdkScript = document.createElement("script");
        sdkScript.src = "https://embed.explo.co/bundle.js";
        sdkScript.id = "explo-sdk";
        sdkScript.onload = () => resolve();
        document.body.appendChild(sdkScript);
      }),
    []
  );

  useEffect(() => {
    if (hasReports) loadSdk().then(() => setIsLoading(false));
  }, [hasReports, loadSdk]);

  return (
    <Main>
      <MainHeader>
        <MainTitle>Custom reports</MainTitle>
        <MainSubtitle>
          <Flex gap="sm" wrap align="baseline">
            Need a custom report?
            <Flex gap="md" align="center" height="xl">
              <Link
                as="button"
                type="button"
                variant="success"
                onClick={openChat}
              >
                Chat with us
              </Link>
            </Flex>
          </Flex>
        </MainSubtitle>
      </MainHeader>
      <MainContent className={styles["main"]}>
        {isLoading ? (
          <Loader position="absolute" />
        ) : dashboardIds.length > 0 ? (
          dashboardIds.map((id) => (
            <explo-dashboard
              key={id}
              environment="prod"
              locale-code="us-en"
              isProduction={true}
              refresh-minutes={10}
              updateUrlParams={true}
              dash-customer-token={`${id}:${exploToken}`}
            ></explo-dashboard>
          ))
        ) : reportBuilderIds.length > 0 ? (
          reportBuilderIds.map((id) => (
            <explo-report-builder
              key={id}
              style={{ flex: 1, overflow: "hidden" }}
              environment="prod"
              report-builder-token={`${id}:${exploToken}`}
            ></explo-report-builder>
          ))
        ) : (
          <Flex align="center" height="full" justify="center">
            <EmptyState
              title="No reports configured"
              action={{ onClick: openChat, children: "Chat with us" }}
              subtitle="To request a custom report, chat with us"
            />
          </Flex>
        )}
      </MainContent>
    </Main>
  );
};

const MemoizedPage = memo(Page);

export { MemoizedPage as Page };
