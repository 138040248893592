import { noop } from "@utils/noop";
import { createContext, useContextSelector } from "use-context-selector";

import type { Bill } from "./types";

type SaveHandler = (params?: {
  status?: string;
  onSuccess?: (bill: Bill) => void;
  unlinkInvoiceLinesOption?: unknown;
}) => Promise<void>;

type Context = {
  permissions: {
    canPayBill: boolean;
    canAddBill: boolean;
    canEditBill: boolean;
    canApproveBill: boolean;
    canAddPurchaseOrder: boolean;
    canViewPurchaseOrder: boolean;
    canManagePurchaseOrder: boolean;
  };
  save: SaveHandler;
  close: () => void;
};

export const useBillFormPermissions = () =>
  useContextSelector(BillFormContext, (context) => context.permissions);

export const useBillFormActions = () =>
  useContextSelector(BillFormContext, (context) => ({
    save: context.save,
    close: context.close,
  }));

export const BillFormContext = createContext<Context>({
  permissions: {
    canPayBill: false,
    canAddBill: false,
    canEditBill: false,
    canApproveBill: false,
    canAddPurchaseOrder: false,
    canViewPurchaseOrder: false,
    canManagePurchaseOrder: false,
  },
  save: () => Promise.resolve(),
  close: noop,
});
