type RenderHandler = (props: {
  url?: string;
  trigger: string;
  mention: string;
}) => string | null | undefined | void;

type ParseMentionProps =
  | (string | null | undefined)
  | {
      value?: string | null;
      render?: RenderHandler;
    };

export const parseMention = (valueOrOptions: ParseMentionProps) => {
  let value = "";
  let render: RenderHandler = ({ mention }) => mention;

  if (valueOrOptions && typeof valueOrOptions === "object") {
    value = valueOrOptions.value || "";
    render = valueOrOptions.render || render;
  } else {
    value = valueOrOptions || "";
  }

  return value.replace(/\[([^[\]]+)\]\(([^)]+)\)/g, (_, label, url) => {
    const firstChar = label?.[0];
    const trigger = /[!@#$%^&*(),.?":{}|<>]/.test(firstChar) ? firstChar : "";
    const mention = label.replace(trigger, "");
    return render({ url, mention, trigger }) || "";
  });
};
