import { type RawGetLinkedTransactionReturn } from "../api/types";

type IsPurchaseOrderLink = (
  link?: RawGetLinkedTransactionReturn[number]["links"][number]
) => boolean;

export const isPurchaseOrderLink: IsPurchaseOrderLink = (link): boolean =>
  !!(
    link?.type === "purchase_order" ||
    link?.fields.parent?.url.includes("/purchaseorders/")
  );
