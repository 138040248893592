import React, {
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import type { Expense } from "@api/expenses";
import { expenseSelectors } from "@store/expenses/selectors";
import { expenseFetchStatusSelector } from "@store/expenses/selectors";
import type { CollectionQueries, Entry } from "@store/expenses/types";
import { useAppSelector } from "@store/hooks";

import { useFetchTableData } from "../table";

import { CycleContext } from "./cycle-context";

export const CycleProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const { state: locationState } = useLocation();

  const [isPending, startTransition] = useTransition();

  const transactionId = useAppSelector(expenseSelectors.id, isEqual);

  const urlTransactionId = useParams().expenseId;

  const isLoadingTransaction = useAppSelector(
    (state) => expenseFetchStatusSelector(state) === "pending",
    isEqual
  );

  const isSwitchingTransaction =
    urlTransactionId !== "new" && urlTransactionId != transactionId;

  const [status, setStatus] = useState<CollectionQueries | "">("");

  const [isLoading, setIsLoading] = useState(false);

  const info = useFetchTableData(status || "ALL");

  const enhancedIsLoading =
    isLoading || isPending || isLoadingTransaction || isSwitchingTransaction;

  const {
    data,
    total,
    filters,
    pagination: { page, perPage: limit },
    setQueryFilters,
  } = useMemo(() => {
    if (status) return info;

    return {
      data: [],
      total: 0,
      filters: {},
      pagination: { page: 0, perPage: 0 },
      setQueryFilters: () =>
        Promise.resolve({ result: { results: [], count: 0 } }),
    };
  }, [info, status]);

  const index = useDeepMemo(
    () => data.findIndex((item) => item.id === transactionId),
    [transactionId, data]
  );

  const current = useMemo(() => index + 1 + page * limit, [index, limit, page]);

  const enable = useEvent((status) => setStatus(status));

  const disable = useEvent(() => setStatus(""));

  const next = useEvent(async () => {
    startTransition(() => setIsLoading(true));

    try {
      let nextIndex = index + 1;

      const {
        result: { count, results },
      } = await setQueryFilters(filters);

      if (count < total) {
        nextIndex -= 1;
      }

      let nextData = results as Entry<Expense>[];

      if (nextIndex < nextData.length) {
        navigate(`/expenses/${nextData[nextIndex].id}`, {
          state: locationState,
        });
      } else {
        let nextOffset = (page + 1) * limit;
        if (nextOffset > total) {
          nextIndex = 0;
          nextOffset = 0;
        }

        if (nextOffset < total) {
          const {
            result: { results },
          } = await setQueryFilters({ ...filters, offset: nextOffset });

          nextData = results as Entry<Expense>[];
        }
        navigate(`/expenses/${nextData[0].id}`, { state: locationState });
      }
      return true;
    } catch (e) {
      return false;
    } finally {
      startTransition(() => setIsLoading(false));
    }
  });

  const previous = useEvent(async () => {
    startTransition(() => setIsLoading(true));

    try {
      const nextIndex = index - 1;

      const {
        result: { results },
      } = await setQueryFilters(filters);

      let nextData = results as Entry<Expense>[];

      if (nextIndex > -1) {
        navigate(`/expenses/${nextData[nextIndex].id}`, {
          state: locationState,
        });
      } else {
        let nextOffset = (page - 1) * limit;

        const shouldJumpToLastPage = nextOffset < 0;

        if (shouldJumpToLastPage) {
          nextOffset = Math.floor(total / limit) * limit;
        }

        if (nextOffset < total) {
          const {
            result: { results },
          } = await setQueryFilters({ ...filters, offset: nextOffset });

          nextData = results as Entry<Expense>[];
        }

        navigate(
          `/expenses/${
            nextData[shouldJumpToLastPage ? nextData.length - 1 : limit - 1].id
          }`,
          { state: locationState }
        );
      }

      return true;
    } catch (e) {
      return false;
    } finally {
      startTransition(() => setIsLoading(false));
    }
  });

  /**
   * Disable navigation when the transaction not belongs to current
   * collection query status.
   */
  useEffect(() => {
    if (index === -1 && transactionId && !enhancedIsLoading) disable();
  }, [index, disable, transactionId, enhancedIsLoading]);

  return (
    <CycleContext.Provider
      value={{
        next,
        total,
        enable,
        status,
        current,
        disable,
        previous,
        isLoading: enhancedIsLoading,
        hasNavigation: current > 1 || current < total,
      }}
    >
      {children}
    </CycleContext.Provider>
  );
};
