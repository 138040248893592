import React from "react";
import { useSelector } from "react-redux";
import { Button, Flex, Text, toast, Tooltip } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { useStageName } from "@components/sequential/context";
import { DynamicActions } from "@src/expenses/dynamic-actions";
import { useExpenseAction, useExpensePermissions } from "@store/expenses";
import { expenseNextAction, expenseSelectors } from "@store/expenses/selectors";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";

import { STRINGS } from "../constants";

const messages = {
  publish: STRINGS.BUTTON_PUBLISH,
  unPublish: STRINGS.BUTTON_UNPUBLISH,
  unArchive: STRINGS.BUTTON_RESTORE,
  pending: STRINGS.BUTTON_PENDING,
} as const;

export const Actions = () => {
  const stageName = useStageName();

  const nextAction = useSelector(expenseNextAction);

  const { cardFeedEnabled } = useClientSettings();

  const { saveExpense } = useExpenseAction();

  const { canEditExpense, canPublishExpense } = useExpensePermissions();

  const linesCount = useSelector(expenseSelectors.linesCount);

  const isTransactionGeneratedDraft = useSelector(
    expenseSelectors.isTransactionGeneratedDraft
  );

  const tooManyLines = linesCount > window.MAX_LINES_PAGE_SIZE;

  const onSave = useEvent(async () => {
    const data = await saveExpense();

    analytics.track("expenseUpdate", { expenseId: data.id });

    if (cardFeedEnabled && isTransactionGeneratedDraft) {
      toast.success(
        <Flex as="span" direction="column">
          <Text as="strong" weight="bold">
            Receipt #{data.docNumber} created in For Review status
          </Text>
          <Text as="span">
            Click {STRINGS.BUTTON_PUBLISH} when receipt is ready to sync
          </Text>
        </Flex>
      );
    } else {
      toast.success(`Receipt #${data.docNumber} updated`);
    }
  });

  return (
    <>
      <DynamicActions />

      <Flex justify="flex-end" align="center" gap="xl">
        <Tooltip
          message={
            canEditExpense
              ? tooManyLines
                ? STRINGS.BUTTON_TOO_MANY_LINES
                : undefined
              : STRINGS.ACTION_NOT_ALLOWED
          }
          placement="left"
        >
          <Button
            variant="ghost"
            disabled={!canEditExpense || tooManyLines}
            size="lg"
            onClick={onSave}
            data-testid="expense-save-button"
          >
            {STRINGS.BUTTON_SAVE}
          </Button>
        </Tooltip>
        <Tooltip
          message={
            canPublishExpense
              ? tooManyLines
                ? STRINGS.BUTTON_TOO_MANY_LINES
                : undefined
              : STRINGS.ACTION_NOT_ALLOWED
          }
          placement="left"
        >
          <Button
            variant="solid"
            type="submit"
            size="lg"
            disabled={
              !canPublishExpense || tooManyLines || nextAction === "pending"
            }
            form={stageName}
          >
            {messages[nextAction]}
          </Button>
        </Tooltip>
      </Flex>
    </>
  );
};
