import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";
import {
  Avatar,
  Button,
  Card,
  Flex,
  Icon,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";

import {
  getWorkspaces,
  joinWorkspaces,
  type WorkSpacesResponse,
} from "../../../shared/api/onboarding";
import { Loader } from "../../../shared/components/common/Loader";
import { Layout } from "../layout";

export const FindTeam = () => {
  const [loading, setLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState<
    WorkSpacesResponse[] | undefined
  >();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const onSubmitCompanyName = useEvent(async (e) => {
    e.preventDefault();
    if (workspaces?.length) {
      setLoading(true);
      const joinedWorkspacesIds = workspaces
        .filter((wp) => wp.joined)
        .map((wp) => wp.id);
      await joinWorkspaces(joinedWorkspacesIds);
      window.location.replace("/");
      setLoading(false);
    }
  });

  const loadData = useCallback(async () => {
    const { data: workspaces } = await getWorkspaces();
    if (workspaces.length > 0) {
      setWorkspaces(workspaces);
    } else {
      navigate("/onboarding/company");
    }
  }, [navigate]);

  useEffect(() => {
    if (searchParams.get("verified")) {
      searchParams.delete("verified");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onToggleJoined = useEvent((id: string) => {
    const newWorkspaces = workspaces?.map((workspace) => {
      if (workspace.id === id) workspace.joined = !workspace.joined;

      return workspace;
    });
    setWorkspaces(newWorkspaces);
  });

  return (
    <>
      {!loading ? (
        <Layout
          title="Let’s find your team"
          subtitle="Choose a workspace below to get back to working with your team."
          onSubmit={onSubmitCompanyName}
        >
          <Flex gap="96px" direction="column">
            <Flex gap="xl" direction="column">
              {workspaces?.map((workspace) => (
                <Card key={workspace.client.id}>
                  <Flex gap="md" justify="space-between" align="center">
                    <Flex gap="md" align="center">
                      <Avatar
                        variant="square"
                        name={workspace.client.name}
                        size="lg"
                      />
                      <Flex direction="column">
                        <Text size="md" weight="bolder">
                          {workspace.client.name}
                        </Text>
                        <Text size="xs" color="neutral-500">
                          {workspace.client.members}{" "}
                          {workspace.client.members > 1
                            ? " members"
                            : " member"}
                        </Text>
                      </Flex>
                    </Flex>
                    <Button
                      variant={workspace.joined ? "text" : "solid"}
                      onClick={() => onToggleJoined(workspace.id)}
                    >
                      {workspace.joined ? (
                        <>
                          <Icon name="check" /> Joined
                        </>
                      ) : (
                        "Join workspace"
                      )}
                    </Button>
                  </Flex>
                </Card>
              ))}
            </Flex>
            <Button
              variant="solid"
              data-testid="join-team"
              type="submit"
              disabled={loading || !workspaces?.some((wp) => wp.joined)}
              block
            >
              Continue
            </Button>
          </Flex>
        </Layout>
      ) : (
        <Loader />
      )}
    </>
  );
};
