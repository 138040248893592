import React, {
  type ComponentProps,
  type CSSProperties,
  isValidElement,
  memo,
  type MouseEventHandler,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { useEvent } from "../../hooks/use-event";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import type {
  Colors,
  FontFamilies,
  FontSizes,
  FontWeights,
} from "../../styles/variables";
import { isOverflowing } from "../../utils/is-overflowing";
import { mergeRefs } from "../../utils/merge-refs";
import { Tooltip } from "../tooltip";

import styles from "./text.module.css";

type Placement = ComponentProps<typeof Tooltip>["placement"];

export type TextProps = {
  size?: ResponsiveProp<FontSizes>;
  align?: ResponsiveProp<CSSProperties["textAlign"]>;
  color?: Colors | "inherit";
  family?: Omit<FontFamilies, "icons">;
  weight?: ResponsiveProp<FontWeights>;
  truncate?:
    | boolean
    | number
    | Placement
    | { lines?: number; placement?: Placement; tooltip?: boolean };
  transform?: ResponsiveProp<CSSProperties["textTransform"]>;
  decoration?: CSSProperties["textDecorationLine"];
  "data-testid"?: string;
};

const DEFAULT_COMPONENT = "p";

const Text = forwardRefAs<typeof DEFAULT_COMPONENT, TextProps>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      size: rawSize,
      align: rawAlign,
      color,
      style,
      family,
      weight: rawWeight,
      children,
      truncate,
      className,
      transform: rawTransform,
      decoration,
      onMouseEnter,
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<HTMLParagraphElement>(null);

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const size = useResponsiveProp(rawSize);
    const align = useResponsiveProp(rawAlign);
    const weight = useResponsiveProp(rawWeight);
    const transform = useResponsiveProp(rawTransform);

    const { lines, tooltip, placement } = useMemo(() => {
      if (typeof truncate === "string") {
        return { lines: 1, tooltip: true, placement: truncate };
      }

      if (typeof truncate === "number") {
        return {
          lines: truncate,
          tooltip: true,
          placement: "top" as Placement,
        };
      }

      if (typeof truncate === "object") {
        return {
          lines: truncate.lines ?? 1,
          tooltip: truncate.tooltip ?? true,
          placement: truncate.placement ?? ("top" as Placement),
        };
      }

      return {
        lines: 1,
        tooltip: truncate ?? false,
        placement: "top" as Placement,
      };
    }, [truncate]);

    const enhancedStyle = {
      ...style,
      ...(align && { "--text-align": align }),
      ...(lines && { "--text-truncate-lines": lines }),
      ...(color && { "--text-color": `var(--color-${color})` }),
      ...(family && { "--text-family": `var(--font-family-${family})` }),
      ...(weight && { "--text-weight": `var(--font-weight-${weight})` }),
      ...(transform && { "--text-transform": transform }),
      ...(decoration && { "--text-decoration": decoration }),
    };

    const checkOverflow = useEvent(() => {
      setIsTooltipVisible(
        Boolean(truncate) &&
          isOverflowing(internalRef.current) &&
          !isValidElement(children)
      );
    });

    const enhancedOnMouseEnter = useEvent<
      MouseEventHandler<HTMLParagraphElement>
    >((e) => {
      checkOverflow();
      onMouseEnter?.(e);
    });

    useResizeObserver(internalRef, checkOverflow);

    const enhancedProps = {
      ref: mergeRefs(ref, internalRef),
      size,
      style: enhancedStyle,
      className: cn(className, styles.text, {
        [styles[`-${size}`]]: size,
        [styles["-truncate"]]: truncate && typeof truncate !== "number",
        [styles["-line-clamp"]]: typeof truncate === "number",
      }),
      onMouseEnter: enhancedOnMouseEnter,
      "data-testid": testId,
      ...props,
    };

    return tooltip && isTooltipVisible ? (
      <Tooltip
        as={Component}
        message={children}
        placement={placement}
        {...enhancedProps}
      >
        {children}
      </Tooltip>
    ) : (
      <Component {...enhancedProps}>{children}</Component>
    );
  }
);

const MemoizedText = memo(Text) as typeof Text;

export { MemoizedText as Text };
