import React, { type ComponentPropsWithoutRef, memo } from "react";
import {
  Disclosure,
  useDisclosureContext,
  useStoreState,
} from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { suffixify } from "../../utils/suffixify";
import { Icon } from "../icon";

import { useAlertContext } from "./alert-context";
import styles from "./alert.module.css";

const DEFAULT_COMPONENT = "h3";

type Props = Omit<
  ComponentPropsWithoutRef<typeof DEFAULT_COMPONENT>,
  "color"
> & { "data-testid"?: string };

const AlertTitle = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      "data-testid": testId,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const { icon } = useAlertContext()!;

    const disclosureContext = useDisclosureContext();

    const expanded = useStoreState(disclosureContext, "open");

    const hasContent = !!useStoreState(disclosureContext, "contentElement");

    return (
      <Component
        ref={ref}
        data-testid={testId}
        className={cn(styles["title"], className, {
          [styles["-expandable"]]: hasContent,
        })}
        {...props}
      >
        {hasContent && (
          <>
            <span className={styles["icon"]}>{icon}</span>
            <Disclosure
              className={styles["expand-button"]}
              data-testid={suffixify(testId, "expand-button")}
            >
              <Icon
                size="lg"
                className={styles["expand-icon"]}
                name={expanded ? "chevron-up" : "chevron-down"}
              />
            </Disclosure>
          </>
        )}
        {children}
      </Component>
    );
  }
);

AlertTitle.displayName = "AlertTitle";

const MemoAlertTitle = memo(AlertTitle) as typeof AlertTitle;

export { MemoAlertTitle as AlertTitle };
