import React, { memo, useEffect, useRef, useState } from "react";
import { useClientSettings, useUserInfo } from "@store/user";

import { PDF_BASE64_TYPE } from "./constants";
import styles from "./pdf-viewer.module.css";

export type PdfViewerProps = { src: string };

const PdfViewer = ({ src }: PdfViewerProps) => {
  const { internalPdfReaderEnabled } = useClientSettings();

  const { user } = useUserInfo();

  const containerRef = useRef<HTMLDivElement>(null);

  const [handshake, setHandshake] = useState<Promise<any>>();

  const useNewPdfReader = internalPdfReaderEnabled || user?.type === "external";

  useEffect(() => {
    const rafId = requestAnimationFrame(() => {
      if (!useNewPdfReader || !containerRef.current) return;

      setHandshake(
        new window.Postmate({
          url: `/static/pdf_viewer/viewer.html`,
          name: "pdfViewer",
          container: containerRef.current,
          classListArray: [styles["pdf-viewer-iframe"]],
        })
      );
    });

    return () => {
      cancelAnimationFrame(rafId);
      setHandshake((handshake) => {
        handshake?.then((child: any) => child.destroy());
        return undefined;
      });
    };
  }, [useNewPdfReader]);

  useEffect(() => {
    if (!useNewPdfReader || !handshake) return;

    const isBase64 = src.includes(PDF_BASE64_TYPE);

    const rawSrc = isBase64 ? src.split(PDF_BASE64_TYPE)[1] : src;

    handshake.then((child) => {
      child.call(isBase64 ? "loadPdfBase64" : "loadPdfUrl", rawSrc);
    });
  }, [handshake, src, useNewPdfReader]);

  if (!useNewPdfReader) {
    return (
      <embed
        src={`${src}#view=FitH&toolbar=1&navpanes=0`}
        type="application/pdf"
        className={styles["pdf-viewer-iframe"]}
        data-skip-focusable=""
      />
    );
  }

  return (
    <div
      ref={containerRef}
      className={styles["pdf-viewer-iframe"]}
      data-skip-focusable=""
    />
  );
};

const MemoizedPdfViewer = memo(PdfViewer);

export { MemoizedPdfViewer as PdfViewer };
