import React, { useMemo } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Flex,
  Link,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { formatDate } from "@adaptive/design-system/utils";
import { refetchCurrentBill } from "@store/billSlice";
import { useAppDispatch } from "@store/hooks";
import { DOCUMENT_TYPE_OPTIONS } from "@vendors/constants";
import { useVendorAction } from "@vendors/hooks";
import type {
  Document,
  DocumentType,
  VendorDocRequestType,
} from "@vendors/types";

type AlertProps = {
  vendor?: {
    id: string | number;
    expiredDocuments?: Document[];
    pendingDocRequests: VendorDocRequestType[];
  };
};

const getDocumentLabel = (type: string) =>
  DOCUMENT_TYPE_OPTIONS.find((option) => option.value === type)?.label;

export const ExpiredDocumentsAlert = ({ vendor }: AlertProps) => {
  const dispatch = useAppDispatch();

  const onDrawerClose = useEvent(() =>
    dispatch(refetchCurrentBill(["vendor"]))
  );
  const { showVendorById } = useVendorAction({
    onDrawerClose,
  });

  const {
    id: vendorId,
    expiredDocuments: documents,
    pendingDocRequests: pendingDocRequests,
  } = vendor ?? {};

  const onAddVendorComplianceDocument = useEvent(() =>
    showVendorById(vendorId as string, "docs")
  );

  const unrequestedExpiredDocuments = useMemo(() => {
    const types = Array.from(
      new Set(
        (pendingDocRequests || [])
          .map((request) => request.documentTypes)
          .flat()
      )
    );

    return (documents || []).filter(
      (document) => !types.includes(document.type as DocumentType)
    );
  }, [pendingDocRequests, documents]);

  if (unrequestedExpiredDocuments.length === 0) return null;

  return (
    <Alert variant="warning">
      <AlertTitle>
        This vendor&apos;s compliance documents are expired
      </AlertTitle>
      <AlertContent as="div">
        <Flex gap="xl" direction="column">
          <ul>
            {unrequestedExpiredDocuments.map((document) => (
              <li key={document.id}>
                {document.document ? (
                  <>
                    <Link
                      rel="noreferrer"
                      size="sm"
                      href={document.document}
                      target="_blank"
                      download
                    >
                      {getDocumentLabel(document.type)}
                    </Link>{" "}
                  </>
                ) : (
                  <Text as="span" size="sm">
                    {getDocumentLabel(document.type)}{" "}
                  </Text>
                )}
                {document.expirationDate && (
                  <Text as="span" size="sm">
                    (Expired {formatDate(document?.expirationDate)})
                  </Text>
                )}
              </li>
            ))}
          </ul>

          <Flex>
            <Button
              size="sm"
              color="warning"
              onClick={onAddVendorComplianceDocument}
            >
              Update documents
            </Button>
          </Flex>
        </Flex>
      </AlertContent>
    </Alert>
  );
};
