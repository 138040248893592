import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  PercentageField,
  Text,
  toast,
} from "@adaptive/design-system";
import { type useDialog, useForm } from "@adaptive/design-system/hooks";
import { useUpdateBudgetLineMutation } from "@api/budgets";
import { handleErrors } from "@api/handle-errors";
import type { BudgetLineItems } from "@api/jobs";
import { MARKUP_PERCENTAGE_FORMAT } from "@src/jobs/constants";
import { useJobInfo } from "@store/jobs";
import * as analytics from "@utils/analytics";
import { divide } from "@utils/divide";
import { z } from "zod";

type CalculateMarkupDialogProps = {
  dialog: ReturnType<typeof useDialog>;
  budgetLines: BudgetLineItems[];
};

const INITIAL_VALUES = {
  percentMarkup: 0,
};

const schema = z.object({
  percentMarkup: z.number(),
});

export const CalculateMarkupDialog = ({
  dialog,
  budgetLines,
}: CalculateMarkupDialogProps) => {
  const [updateBudgetLine] = useUpdateBudgetLineMutation();
  const { job } = useJobInfo();
  const form = useForm({
    schema,
    async onSubmit(values) {
      try {
        const percentMultiplier = !values.percentMarkup
          ? 1
          : divide(values.percentMarkup, 100) + 1;

        await Promise.all(
          budgetLines.map((line) => {
            return updateBudgetLine({
              customerId: String(job.id),
              budgetLineId: line.id,
              ownersAmount:
                percentMultiplier *
                (line.builderRevisedAmount ?? line.builderAmount),
            }).unwrap();
          })
        );

        analytics.track("budgetBatchActions", {
          value: values.percentMarkup,
          action: "calculate-external-budget",
          budgetLineIds: budgetLines.map((line) => line.id),
        });
        dialog.hide();
        toast.success(
          `External budget updated on ${budgetLines.length} line${
            budgetLines.length !== 1 && "s"
          }`
        );
      } catch (e) {
        handleErrors(e);
      }
    },
    initialValues: INITIAL_VALUES,
  });

  return (
    <Dialog
      show={dialog.isVisible}
      variant="dialog"
      onClose={dialog.hide}
      size="sm"
    >
      <DialogHeader>Calculate external budget</DialogHeader>
      <DialogContent>
        <Text>
          Set the external budget amount by copying your revised budget and
          optionally adding a percent markup
        </Text>
        <Flex
          as="form"
          {...form.props}
          maxWidth="250px"
          padding={["2xl", "none"]}
        >
          <PercentageField
            {...form.register({ name: "percentMarkup", type: "percentage" })}
            label="Cost budget markup amount"
            width="100px"
            messageVariant="hidden"
            placeholder="0"
            min={MARKUP_PERCENTAGE_FORMAT.min}
            max={MARKUP_PERCENTAGE_FORMAT.max}
            autoFocus
            allowNegative
            minimumFractionDigits={
              MARKUP_PERCENTAGE_FORMAT.minimumFractionDigits
            }
            maximumFractionDigits={
              MARKUP_PERCENTAGE_FORMAT.maximumFractionDigits
            }
          />
        </Flex>
        <Text as="em">
          Note: This is a one time calculation. Run this action again to reflect
          any budget changes.
        </Text>
      </DialogContent>
      <DialogFooter>
        <Button block variant="text" color="neutral" onClick={dialog.hide}>
          Cancel
        </Button>
        <Button block variant="solid" type="submit" form={form.id}>
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
