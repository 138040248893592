import { createContext } from "react";
import { type TableColumn } from "@adaptive/design-system";

import type { PropagationTableDataType } from "../types";
type PropagationTableContextValues = {
  data: PropagationTableDataType[];
  columns: TableColumn<PropagationTableDataType>[];
};

export const PropagationTableContext =
  createContext<PropagationTableContextValues>({ data: [], columns: [] });
