import React, { memo, type PropsWithChildren, useMemo, useState } from "react";

import {
  type Context,
  DialogContext,
  useDialogContext,
} from "./dialog-context";

type Props = PropsWithChildren<Context>;

const DialogProvider = ({ children, ...value }: Props) => {
  const context = useDialogContext();

  const [hasTabs, setHasTabs] = useState(false);
  const [hasHeader, setHasHeader] = useState(false);
  const [hasFooter, setHasFooter] = useState(false);
  const [hasContent, setHasContent] = useState(false);

  const enhancedValue = useMemo(
    () => ({
      ...context,
      hasHeader,
      setHasHeader,
      hasFooter,
      setHasFooter,
      hasContent,
      setHasContent,
      hasTabs,
      setHasTabs,
      ...value,
    }),
    [context, hasContent, hasFooter, hasHeader, hasTabs, value]
  );

  return (
    <DialogContext.Provider value={enhancedValue}>
      {children}
    </DialogContext.Provider>
  );
};

const MemoizedDialogProvider = memo(DialogProvider);

export { MemoizedDialogProvider as DialogProvider };
