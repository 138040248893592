import React, { Fragment } from "react";
import { Text, toast } from "@adaptive/design-system";

import type { ParentInfoObject } from "../types";

import { getDisplayInfo } from "./get-display-info";

export type UnlinkedObject = ParentInfoObject & {
  parent: ParentInfoObject;
};

export const showUnlinkedObjects = (unlinkedObjects: UnlinkedObject[]) => {
  if (!unlinkedObjects.length) return;

  const unlinkedItems = unlinkedObjects.reduce((acc, item) => {
    if (
      !acc.find(
        (parent) =>
          parent.id === item.parent.id && parent.type === item.parent.type
      )
    ) {
      acc.push(item.parent);
    }
    return acc;
  }, [] as ParentInfoObject[]);

  toast.success(
    <Text as="span">
      Unlinked objects:
      <br />
      {unlinkedItems.map((item, index) => (
        <Fragment key={index}>
          {index > 0 && ", "}
          {getDisplayInfo(item, { withLink: true })}
        </Fragment>
      ))}
    </Text>
  );
};
