import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
} from "react";

import { Checkbox } from "../checkbox";
import { Radio } from "../radio";
import { Tooltip } from "../tooltip";
import { VisuallyHidden } from "../visually-hidden";

import styles from "./table.module.css";

type DefaultElement = "input";

type Props = (
  | (ComponentPropsWithoutRef<typeof Checkbox> & { multiple?: true })
  | (ComponentPropsWithoutRef<typeof Radio> & { multiple: false })
) & { label?: string };

export const TableSelect = forwardRef<ComponentRef<DefaultElement>, Props>(
  (
    {
      id,
      label,
      "aria-labelledby": labelledBy,
      multiple,
      disabled,
      className,
      ...props
    },
    ref
  ) => {
    const Component = multiple === false ? Radio : Checkbox;

    const Wrapper = disabled && label ? Tooltip : "span";

    return (
      <Wrapper
        offset={{ x: 4 }}
        message={disabled ? label : undefined}
        tabIndex={disabled ? 0 : -1}
        className={styles["select-tooltip"]}
      >
        <Component
          id={id}
          ref={ref}
          disabled={disabled}
          className={className}
          {...props}
        />
        <label
          htmlFor={id}
          className={styles["select-label"]}
          aria-labelledby={labelledBy}
        >
          {!disabled && label && <VisuallyHidden>{label}</VisuallyHidden>}
        </label>
      </Wrapper>
    );
  }
);

TableSelect.displayName = "TableSelect";
