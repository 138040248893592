import React, { type ReactElement } from "react";
import { Link } from "@adaptive/design-system";
import { is } from "@adaptive/design-system/utils";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import { TYPE_STRINGS } from "../constants/constants";
import type { LinkObject, ParentInfoObject } from "../types";

type ItemType = LinkObject | ParentInfoObject;

type GetDisplayInfoParams<T> = {
  withLink?: T;
  hideName?: boolean;
  capitalize?: boolean;
};

export function getDisplayInfo(
  item: ItemType,
  params?: GetDisplayInfoParams<false>
): string;

export function getDisplayInfo(
  item: ItemType,
  params?: GetDisplayInfoParams<true>
): ReactElement;

export function getDisplayInfo(
  item: ItemType,
  params?: GetDisplayInfoParams<boolean>
): unknown {
  if (!item) return "";

  const { withLink, hideName, capitalize = true } = params || {};

  const isLinkObject = is.object(item) && "fields" in item;
  const url = isLinkObject ? item.fields.parent?.url : item.url;
  const name = isLinkObject
    ? (item.fields.parent?.name ?? item.fields.name)
    : item.name;
  const id = parseRefinementIdFromUrl(url ?? undefined) || item.id;

  let type = "";
  let urlPath = "";

  if (url?.includes("purchaseorder") || item.type === "purchase_order") {
    type = TYPE_STRINGS.purchase_order;
    urlPath = `/purchase-orders/${id}`;
  }

  if (url?.includes("bill") || item.type === "bill") {
    type = capitalize ? TYPE_STRINGS.bill : TYPE_STRINGS.bill.toLowerCase();
    urlPath = `/bills/${id}`;
  }

  if (url?.includes("invoice") || item.type?.includes("invoice")) {
    type = capitalize
      ? TYPE_STRINGS.invoice
      : TYPE_STRINGS.invoice.toLowerCase();
    urlPath = `/invoices/${id}`;
  }

  if (url?.includes("expenses") || item.type === "expense") {
    type = capitalize
      ? TYPE_STRINGS.expense
      : TYPE_STRINGS.expense.toLowerCase();
    urlPath = `/expenses/${id}`;
  }

  if (!type || !name) return "";

  const label = hideName ? type : `${type} #${name}`;

  if (withLink) {
    return (
      <Link size="sm" target="_blank" href={urlPath}>
        {label}
      </Link>
    );
  }

  return label;
}
