import { is } from "@adaptive/design-system/utils";
import { type Option } from "@shared/types";

export function isAccount(
  optionOrStr: Option | undefined
): optionOrStr is Option;
export function isAccount(
  optionOrStr: string | undefined
): optionOrStr is string;
export function isAccount(optionOrStr: null | undefined): false;
export function isAccount(
  optionOrStr: Option | null | undefined | string
): boolean {
  const value = (
    (is.string(optionOrStr) ? optionOrStr : optionOrStr?.value) ?? ""
  ).toLocaleLowerCase();

  return ["/accounts/", "accounts", "account"].some((item) =>
    value.includes(item)
  );
}
