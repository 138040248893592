import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertContent,
  ComboBox,
  Dialog,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  type TableColumn,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { suffixify } from "@adaptive/design-system/utils";
import {
  type CapitalOSCard,
  type UpdateCapitalOSCardPayload,
} from "@api/capital-os-cards";
import { App as CapitalOS } from "@capitalos/react";
import {
  CostCodeAccountComboBox,
  type CostCodeAccountComboBoxProps,
} from "@components/cost-code-account-combobox";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { useCapitalOSCards } from "@hooks/use-capital-os-cards";
import { useCustomersSimplified } from "@hooks/useCustomersSimplified";
import { useAppDispatch } from "@store/hooks";
import { BasePermissions, useClientAction, useUserInfo } from "@store/user";
import { toggleChatVisibility } from "@store/user/slice";
import * as analytics from "@utils/analytics";
import { capitalize } from "@utils/capitalize";
import { formatCard } from "@utils/format-card";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import { AccountCreateForm } from "./../account-select-dialog/account-create-form";

type CurriedOnChangeCapitalOSCard = (params: {
  row: CapitalOSCard;
  field: "user" | "payment-account" | "customer" | "cost_code";
}) => (value: string) => Promise<void>;

const PaymentAccountName = () => (
  <Flex gap="sm">
    <Text weight="bold">Payment account</Text>
    <Tooltip
      size="sm"
      as={Icon}
      name="info-circle"
      message={`Only transactions with an assigned payment\n account will be pulled into Adaptive`}
    />
  </Flex>
);

const CustomerName = () => (
  <Flex gap="sm">
    <Text weight="bold">Job</Text>
    <Tooltip
      size="sm"
      as={Icon}
      name="info-circle"
      message={`Transactions will get automatically\n assigned to this job in Adaptive`}
    />
  </Flex>
);

const CostCodeAccountName = () => (
  <Flex gap="sm">
    <Text weight="bold">Default code</Text>
    <Tooltip
      size="sm"
      as={Icon}
      name="info-circle"
      message={`Transactions will get automatically\n assigned to this cost code / account in Adaptive`}
    />
  </Flex>
);

const COSTCODE_ACCOUNT_COMBOBOX_FILTERS: CostCodeAccountComboBoxProps<false>["filters"] =
  ["budgetCode", "costCodeAccount"];

const CostCodeAccountColumn = ({
  row,
  ...props
}: { row: CapitalOSCard } & CostCodeAccountComboBoxProps<false>) => {
  const customerId = row.customer?.url
    ? parseRefinementIdFromUrl(row.customer.url)
    : undefined;

  const selectedOption: CostCodeAccountComboBoxProps<false>["value"] =
    useMemo(() => {
      const selectedItemAccount = row.item || row.account || "";
      return selectedItemAccount
        ? {
            value: selectedItemAccount.url || "",
            label: selectedItemAccount.displayName || "",
            parent: selectedItemAccount.parent ?? undefined,
          }
        : "";
    }, [row.item, row.account]);

  const costCodeAccountQueryFilters = useMemo(() => {
    return {
      accountFilters: {
        customerId,
      },
      costCodeFilters: {
        customerId,
      },
    };
  }, [customerId]);

  return (
    <CostCodeAccountComboBox
      size="sm"
      label=""
      messageVariant="hidden"
      value={selectedOption}
      filters={COSTCODE_ACCOUNT_COMBOBOX_FILTERS}
      data-testid={suffixify("bank-table", "role")}
      {...costCodeAccountQueryFilters}
      {...props}
    />
  );
};

export const AdaptiveCard = () => {
  const [status, setStatus] = useState("management");
  const { hasPermission } = useUserInfo();
  const canManageCards = hasPermission(BasePermissions.COMPANY_ADMIN);
  const { currentClient } = useClientAction();

  const onTabChange = useEvent((status: string) => {
    setStatus(status);
    if (status === "accounting") {
      refetchCapitalOSCards();
      refetchAccounts();
    }
  });

  const [selectedRow, setSelectedRow] = useState<CapitalOSCard>();

  const { refetch: refetchAccounts, ...accounts } = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });
  const customers = useCustomersSimplified();

  const accountCreateDialog = useDialog({ lazy: true });
  const { show: showAccountCreateDialog } = accountCreateDialog;

  const {
    cards: capitalOSCards,
    cardsIsLoading: capitalOSCardsIsLoading,
    updateCard,
    pagination: capitalOSCardsPagination,
    sort: capitalOSCardsSort,
    refetchCards: refetchCapitalOSCards,
    token: capitalOSToken,
    tokenIsLoading: capitalOSTokenIsLoading,
    refetchToken: refetchCapitalOSToken,
    tokenError: capitalOSTokenError,
  } = useCapitalOSCards();

  useEffect(() => {
    refetchCapitalOSToken();
    refetchAccounts();
  }, [refetchCapitalOSToken, refetchAccounts, currentClient]);

  const curriedOnChangeCapitalOSCard =
    useCallback<CurriedOnChangeCapitalOSCard>(
      ({ row, field }) =>
        async (value) => {
          const payload: UpdateCapitalOSCardPayload = { id: row.id };

          if (field === "user") {
            payload.user = value;
          } else if (field === "payment-account") {
            payload.paymentAccount = value;
          } else if (field === "customer") {
            payload.customer = value;
          } else if (field === "cost_code") {
            if (value.includes("item")) {
              payload.item = value;
            } else {
              payload.account = value;
            }
          }

          await updateCard(payload);

          if (field === "user") {
            toast.success("Successfully set user!");
          } else if (field === "payment-account") {
            toast.success("Successfully set payment account!");
          } else if (field === "customer") {
            toast.success("Successfully set job!");
          } else if (field === "cost_code") {
            if (value.includes("item")) {
              toast.success("Successfully set cost code!");
            } else {
              toast.success("Successfully set account!");
            }
          }
        },
      [updateCard]
    );

  const dispatch = useAppDispatch();

  const openChat = useEvent(() => {
    dispatch(toggleChatVisibility(true));
    analytics.track("chatOpen", { source: "adaptive-card-loading-fail" });
  });

  const capitalOSCardsTableColumns = useMemo<TableColumn<CapitalOSCard>[]>(
    () => [
      {
        id: "user",
        name: "Card holder",
        sortable: "asc",
        render: (row) =>
          row.user?.fullName ||
          `${row.cardHolderFirstName} ${row.cardHolderLastName}`,
      },
      {
        id: "card_nickname",
        sortable: true,
        name: "Nickname",
        render: "cardNickname",
      },
      {
        id: "mask",
        name: "Card number",
        sortable: true,
        width: "fill",
        render: (row) => formatCard({ mask: row.mask }),
      },
      {
        id: "status",
        name: "Status",
        sortable: true,
        render: (row) => capitalize(row.status),
      },
      {
        id: "payment_account",
        name: <PaymentAccountName />,
        sortable: "asc",
        render: (row) => (
          <ComboBox
            size="sm"
            flip
            action={{
              icon: "plus",
              onClick: () => {
                setSelectedRow(row);
                showAccountCreateDialog();
              },
              children: "Add new",
            }}
            onChange={curriedOnChangeCapitalOSCard({
              row,
              field: "payment-account",
            })}
            data={accounts.data}
            loading={accounts.status === "loading"}
            messageVariant="hidden"
            value={row.paymentAccount?.url || ""}
            data-testid={suffixify("bank-table", "role")}
          />
        ),
      },
      {
        id: "customer",
        name: <CustomerName />,
        sortable: "asc",
        render: (row) => (
          <ComboBox
            size="sm"
            flip
            onChange={curriedOnChangeCapitalOSCard({
              row,
              field: "customer",
            })}
            data={customers.data}
            loading={customers.status === "loading"}
            messageVariant="hidden"
            value={row.customer?.url || ""}
            data-testid={suffixify("bank-table", "role")}
          />
        ),
      },
      {
        id: "cost_code_account",
        name: <CostCodeAccountName />,
        sortable: "asc",
        render: (row) => (
          <CostCodeAccountColumn
            row={row}
            onChange={curriedOnChangeCapitalOSCard({
              row,
              field: "cost_code",
            })}
          />
        ),
      },
    ],
    [
      curriedOnChangeCapitalOSCard,
      accounts.data,
      accounts.status,
      customers.data,
      customers.status,
      showAccountCreateDialog,
    ]
  );

  return (
    <Main>
      <MainHeader>
        <Flex align="center" gap="xl">
          <Flex direction="column" grow>
            <MainTitle>Adaptive cards</MainTitle>
            <MainSubtitle>Manage your Adaptive issued cards</MainSubtitle>
          </Flex>
        </Flex>
      </MainHeader>

      <MainContent>
        <Flex gap="2xl" direction="column">
          <Tabs value={status} onChange={onTabChange}>
            {canManageCards && (
              <TabsList>
                <TabsTab value="management">Card management</TabsTab>
                <TabsTab value="accounting">Accounting</TabsTab>
              </TabsList>
            )}
            <TabsPanel
              as={Flex}
              shrink={false}
              direction="column"
              gap="4xl"
              value="management"
            >
              <Flex gap="xl" direction="column">
                {capitalOSToken &&
                !capitalOSTokenIsLoading &&
                !capitalOSTokenError ? (
                  <Flex direction="row" align="center" justify="space-between">
                    <CapitalOS
                      token={capitalOSToken}
                      loadingComponent={<Loader />}
                    />
                  </Flex>
                ) : !capitalOSTokenError ? (
                  <Loader />
                ) : (
                  <Alert variant="error">
                    <AlertContent>
                      Error loading Adaptive card.{" "}
                      <Link as="button" type="button" onClick={openChat}>
                        Contact support
                      </Link>{" "}
                      to get help.
                    </AlertContent>
                  </Alert>
                )}
              </Flex>
            </TabsPanel>
            {canManageCards && (
              <TabsPanel
                as={Flex}
                gap="xl"
                direction="column"
                value="accounting"
              >
                <Table
                  id="cards-capital-os-table"
                  size="sm"
                  data={capitalOSCards?.results}
                  header={{ hide: capitalOSCards?.results?.length === 0 }}
                  columns={capitalOSCardsTableColumns}
                  loading={capitalOSCardsIsLoading}
                  sort={capitalOSCardsSort}
                  emptyState={{
                    title: "You do not have any linked cards yet",
                  }}
                  pagination={{
                    page: capitalOSCardsPagination.page,
                    total: capitalOSCards?.count ?? 0,
                    perPage: capitalOSCardsPagination.perPage,
                    onChange: capitalOSCardsPagination.setPage,
                    onPerPageChange: (perPage) => {
                      capitalOSCardsPagination.setPage(0);
                      capitalOSCardsPagination.setPerPage(perPage);
                      analytics.track("perPageLimitChange", {
                        location: "cards-capital-os-table",
                        limit: perPage,
                      });
                    },
                  }}
                />
              </TabsPanel>
            )}
          </Tabs>
        </Flex>
      </MainContent>
      {accountCreateDialog.isRendered && (
        <Dialog
          show={accountCreateDialog.isVisible}
          onClose={accountCreateDialog.hide}
          variant="dialog"
        >
          <AccountCreateForm
            dialog={accountCreateDialog}
            variant="single"
            initialAccountType="Liability.CreditCard.CreditCard"
            isCreditCard={true}
            isBankAccount={false}
            onCreatedAccountChange={(account) => {
              refetchAccounts();

              if (selectedRow) {
                const handler = curriedOnChangeCapitalOSCard({
                  row: selectedRow as CapitalOSCard,
                  field: "payment-account",
                });
                handler(account.url);
              }

              setSelectedRow(undefined);
            }}
          />
        </Dialog>
      )}
    </Main>
  );
};
