import React, {
  type ComponentPropsWithoutRef,
  memo,
  type ReactNode,
} from "react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { suffixify } from "../../utils/suffixify";
import { Icon } from "../icon";
import { Tooltip } from "../tooltip";
import { VisuallyHidden } from "../visually-hidden";

import styles from "./label.module.css";

const DEFAULT_COMPONENT = "label";

type Props = ComponentPropsWithoutRef<typeof DEFAULT_COMPONENT> & {
  variant?: "neutral" | "error" | "warning";
  required?: boolean;
  hintMessage?: ReactNode;
  "data-testid"?: string;
};

const Label = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      variant,
      required,
      children,
      className,
      hintMessage,
      "data-testid": testId,
      ...props
    },
    ref
  ) => (
    <Component
      ref={ref}
      className={cn(styles["label"], className, {
        [styles[`-${variant}`]]: variant,
      })}
      data-testid={testId}
      {...props}
    >
      {children}
      {required && (
        <>
          <Icon
            size="2xs"
            name="circle"
            color="error-200"
            variant="solid"
            className={styles["asterisk"]}
            data-testid={suffixify(testId, "indicator")}
          />
          <VisuallyHidden data-testid={suffixify(testId, "indicator-message")}>
            (required)
          </VisuallyHidden>
        </>
      )}
      {hintMessage && (
        <Tooltip
          as={Icon}
          size="sm"
          name="info-circle"
          message={hintMessage}
          className={styles["hint-message"]}
          data-testid={suffixify(testId, "hint")}
        />
      )}
    </Component>
  )
);

Label.displayName = "Label";

const MemoizedLabel = memo(Label) as typeof Label;

export { MemoizedLabel as Label };
