import { type CSSProperties } from "react";
import { type Column as ReactTableColumn } from "@tanstack/react-table";

import { dotObject } from "../../utils/dot-object";
import { is } from "../../utils/is";

import type {
  Column,
  DiffInternalValueToExternalValueProps,
  GroupColumn,
  MapExternalValueToInternalValueProps,
  RenderColumn,
  UnknownData,
} from "./types";
import styles from "./table.module.css";

export const getColumnsWidths = (table: HTMLElement) => {
  const rows = table.querySelectorAll("[data-row]");
  const widths: number[] = [];

  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex];
    const columns = row.children;

    for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
      const column = columns[columnIndex];
      const isValidColumn = column.matches(
        "[data-cell]:not([data-empty]), [data-column-header]:not([data-empty])"
      );

      if (!isValidColumn) continue;

      if (column.hasAttribute("data-action")) {
        widths[columnIndex] = 0;
      } else {
        column.classList.add(styles["-reset"]);
        const width = parseFloat(getComputedStyle(column).width) || 0;
        column.classList.remove(styles["-reset"]);

        if (!widths[columnIndex] || widths[columnIndex] < width) {
          widths[columnIndex] = Math.ceil(width);
        }
      }
    }
  }

  return widths;
};

export const getColumnGridStyle = (
  start: number | "span",
  end: number = Infinity
) => ({
  "--table-column-grid": start === "span" ? `span ${end}` : `${start} / ${end}`,
});

export const flattenData = <Data extends UnknownData & { data?: Data["data"] }>(
  data: Data[]
) => {
  const result: Record<string, boolean> = {};
  const stack: { item: Data; parentIndex: string; index: number }[] = [];

  data.forEach((item, index) => {
    stack.push({ item, parentIndex: "", index });
  });

  while (stack.length > 0) {
    const { item, parentIndex, index } = stack.pop()!;
    const currentIndex = parentIndex ? `${parentIndex}.${index}` : `${index}`;

    if (Array.isArray(item?.data)) {
      result[currentIndex] = true;

      item.data.forEach((child, childIndex) => {
        stack.push({
          item: child,
          parentIndex: currentIndex,
          index: childIndex,
        });
      });
    }
  }

  return result;
};

export const isGroupColumn = <Data>(
  column: Column<Data>
): column is GroupColumn<Data> =>
  column && "columns" in column && is.array(column.columns);

export const isRenderColumn = <Data>(
  column: Column<Data>
): column is RenderColumn<Data> => column && "render" in column;

export const getColumnById = <Data, T extends "group" | "render" | undefined>({
  id,
  type,
  columns,
}: {
  id: string;
  type?: T;
  columns: Column<Data>[];
}): T extends "group"
  ? GroupColumn<Data> | undefined
  : T extends "render"
    ? RenderColumn<Data> | undefined
    : Column<Data> | undefined => {
  const stack = [...columns];

  while (stack.length > 0) {
    const column = stack.pop();

    if (!column) continue;

    if (column.id === id) {
      if (type === "group" && isGroupColumn(column)) {
        return column as any;
      } else if (type === "render" && isRenderColumn(column)) {
        return column as any;
      }

      if (!type) return column as any;
    }

    if ("columns" in column) {
      stack.push(...column.columns);
    }
  }

  return undefined as any;
};

export const hasColumnAttr = <Data>({
  attr,
  value,
  columns,
}: {
  attr: string;
  value?: unknown;
  columns: Column<Data>[];
}) => {
  const stack = [...columns];

  while (stack.length > 0) {
    const column = stack.pop();

    if (!column) continue;

    const attrValue = dotObject.get(column, attr);

    if (attrValue) {
      if (value !== undefined) {
        if (attrValue === value) return true;
      } else {
        return true;
      }
    }

    if ("columns" in column) stack.push(...column.columns);
  }

  return false;
};

type GetLeafColumnsReturn<Data> = (RenderColumn<Data> & {
  parent?: GroupColumn<Data>;
})[];

export const getLeafColumns = <Data>(columns: Column<Data>[]) => {
  const stack = [...columns];
  const leafColumns = [];

  while (stack.length > 0) {
    const column = stack.pop()!;

    if ("columns" in column) {
      stack.push(
        ...column.columns.map((child) => ({ ...child, parent: column }))
      );
    } else {
      leafColumns.push(column);
    }
  }

  return leafColumns.reverse() as unknown as GetLeafColumnsReturn<Data>;
};

export const diffInternalValueToExternalValue = <
  ExternalValue extends UnknownData[],
  InternalValue extends Record<string, boolean>,
>({
  next,
  prev,
  value,
  reference = "id",
}: DiffInternalValueToExternalValueProps<ExternalValue, InternalValue>) =>
  Object.keys(value).reduce((acc, path) => {
    const key = path.split(".").join(".data.");
    const oldItem = dotObject.get(prev, key);
    const newItem = dotObject.get(next, key);

    return oldItem === undefined ||
      dotObject.get(oldItem, reference) === dotObject.get(newItem, reference)
      ? { ...acc, [path]: true }
      : acc;
  }, {} as InternalValue);

export const mapExternalValueToInternalValue = <
  ExternalValue extends UnknownData,
  InternalValue,
>({
  data = [],
  value = [],
  reference = "id",
}: MapExternalValueToInternalValueProps<ExternalValue>) => {
  const stack: { items: ExternalValue[]; path: string }[] = [
    { items: data, path: "" },
  ];

  const indexMap = {} as InternalValue;

  while (stack.length > 0) {
    const { items, path } = stack.pop()!;

    for (const itemIndex in items) {
      const item = items[itemIndex];
      const currentPath = path ? `${path}.${itemIndex}` : String(itemIndex);

      const isMatch = value.some(
        (valueItem) =>
          dotObject.get(valueItem, reference) === dotObject.get(item, reference)
      );

      if (isMatch) {
        (indexMap as Record<string, true>)[currentPath] = true;
      }

      if (Array.isArray(item.data)) {
        stack.push({ items: item.data, path: currentPath });
      }
    }
  }

  return indexMap;
};

type GetCommonStickyStylesParams<Data> = {
  def?: "header" | "footer" | "body";
  column: ReactTableColumn<Data>;
};

export const getCommonStickyStyles = <Data>({
  def = "body",
  column,
}: GetCommonStickyStylesParams<Data>): CSSProperties => {
  const isSticky = column.getIsPinned();

  if (!isSticky) return {};

  const isLastLeftPinnedColumn =
    isSticky === "left" &&
    (column.getIsLastColumn("left") ||
      column.columns.some((col) => col.getIsLastColumn("left")));

  const isFirstRightPinnedColumn =
    isSticky === "right" &&
    (column.getIsFirstColumn("right") ||
      column.columns.some((col) => col.getIsLastColumn("right")));

  const columnInfo = column.columns.length > 0 ? column.columns[0] : column;

  return {
    left: isSticky === "left" ? `${columnInfo.getStart("left")}px` : undefined,
    right:
      isSticky === "right" ? `${columnInfo.getAfter("right")}px` : undefined,
    zIndex:
      def === "body"
        ? "var(--z-table-row)"
        : def === "header"
          ? "var(--z-table-header)"
          : "var(--z-table-footer)",
    opacity: 0.95,
    position: "sticky",
    boxShadow: isLastLeftPinnedColumn
      ? "-4px 0 4px -4px gray inset"
      : isFirstRightPinnedColumn
        ? "4px 0 4px -4px gray inset"
        : undefined,
  };
};
