import React, { memo, useEffect, useMemo } from "react";
import { Flex, Text } from "@adaptive/design-system";
import { dialog } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  RequestVendorDocumentAlert,
  RequestVendorDocumentButton,
} from "@components/request-vendor-document";
import {
  VendorDocumentEditForm,
  type VendorDocumentEditFormProps,
} from "@components/vendor-document-edit-form";
import {
  VendorDocumentForm,
  type VendorDocumentFormProps,
} from "@components/vendor-document-form";
import { VendorDocumentPreview } from "@components/vendor-document-preview";
import { api } from "@store/api-simplified";
import { useAppDispatch, useAppSelector } from "@store/hooks";

import { STRINGS } from "../constants/constants";
import { useDocumentAction } from "../hooks/use-document-action";
import { useVendorInfo } from "../hooks/use-vendor-info";
import { selectVendorDocumentItems, vendorSelector } from "../store/selectors";
import type { Document, SavedDocument } from "../store/types";

const DocumentPreview = memo((props: Document & { isVirtual: boolean }) => {
  const { info } = useAppSelector(vendorSelector);
  const { type: documentType, expirationDate, isExpired, id } = props;
  const { removeDocument, toggleEditDocument, syncDocuments } =
    useDocumentAction();
  const { canManageNonPaymentInfo } = useVendorInfo();

  const onSubmit = useEvent<VendorDocumentEditFormProps["onSubmit"]>(
    async ({ type, document, expirationDate }) => {
      const payload = {
        id,
        type,
        ...(document ? { document } : {}),
        expirationDate,
      };

      if (info.id) await syncDocuments({ edited: [payload] });
    }
  );

  const editFormInitialValues = useMemo(
    () => ({
      id,
      type: documentType,
      expirationDate,
    }),
    [documentType, expirationDate, id]
  );

  const onDelete = useEvent(async () => {
    if (!info.id) return removeDocument(props);

    dialog.confirmation({
      title: "Remove document from vendor?",
      action: {
        primary: {
          color: "error",
          onClick: () => {
            const { payload } = removeDocument(props);
            syncDocuments({ removed: [payload as SavedDocument] });
          },
          children: "Remove",
        },
      },
    });
  });

  const onUpdate = useEvent(async () => {
    toggleEditDocument(props.id);
  });

  return props.isEditing ? (
    <VendorDocumentEditForm
      onSubmit={onSubmit}
      initialValues={editFormInitialValues}
    />
  ) : (
    <VendorDocumentPreview
      url={!props.isVirtual ? (props.document as string) : undefined}
      type={documentType}
      onDelete={
        canManageNonPaymentInfo && !props.isVirtual ? onDelete : undefined
      }
      onUpdate={canManageNonPaymentInfo ? onUpdate : undefined}
      isExpired={isExpired ? isExpired : undefined}
      expirationDate={expirationDate ? expirationDate : undefined}
    />
  );
});

DocumentPreview.displayName = "DocumentPreview";

const DocumentPreviews = memo(() => {
  const documents = useAppSelector(selectVendorDocumentItems);

  return documents.map((document) => (
    <DocumentPreview key={document.id} {...document} />
  ));
});

DocumentPreviews.displayName = "DocumentPreviews";

export const FormDocuments = memo(() => {
  const { info } = useAppSelector(vendorSelector);
  const dispatch = useAppDispatch();

  const { canManageNonPaymentInfo, setHasUnsavedDocuments } = useVendorInfo();

  const { addDocument, syncDocuments } = useDocumentAction();

  const onSave = useEvent<VendorDocumentFormProps["onSave"]>(
    async ({ file, type, expirationDate }) => {
      setHasUnsavedDocuments(false);

      const { payload } = addDocument({
        id: file?.name || "",
        type,
        document: file,
        expirationDate,
      });

      if (info.id) {
        await syncDocuments({ added: [payload] });
        dispatch(api.util.invalidateTags(["Vendors"]));
      }
    }
  );

  useEffect(() => {
    return () => setHasUnsavedDocuments(false);
  }, [setHasUnsavedDocuments]);

  return (
    <Flex gap="xl" direction="column">
      <RequestVendorDocumentAlert />

      <DocumentPreviews />

      <Flex gap="xl" direction="column">
        <Flex gap="xl" justify="space-between" align="center">
          <Text weight="bold">{STRINGS.HEADER_ADD_DOCS}</Text>
          <RequestVendorDocumentButton />
        </Flex>
        <VendorDocumentForm
          onSave={onSave}
          onChange={(values) => {
            const hasChanges = !!(
              values.file ||
              values.type ||
              values.expirationDate
            );
            setHasUnsavedDocuments(hasChanges);
          }}
          disabled={!canManageNonPaymentInfo}
        />
      </Flex>
    </Flex>
  );
});

FormDocuments.displayName = "FormDocuments";
