import { currencySchema, idSchema } from "@src/shared/utils/schema";
import { z } from "zod";

import { transformLinkedTransactions } from "../utils/transform-linked-transactions";

const transactionTypeSchema = z.enum([
  "purchase_order",
  "bill",
  "invoice",
  "line",
  "invoice_line",
  "expense",
]);

const transactionLinksSchema = z.object({
  id: idSchema,
  type: transactionTypeSchema,
  fields: z.object({
    vendor: z.string().nullish(),
    item: z.string().nullish(),
    account: z.string().nullish(),
    customer: z.string().nullish(),
    description: z.string().nullish(),
    order: z.number().nullish(),
    name: z.string().nullish(),
    amount: currencySchema.optional(),
    parent: z
      .object({
        url: z.string(),
        name: z.string(),
      })
      .optional(),
    linkedLine: z
      .object({
        parent: z.string(),
        url: z.string(),
      })
      .nullish()
      .optional(),
    rawLinkedLine: z
      .object({
        parent: z.string(),
        url: z.string(),
      })
      .nullish()
      .optional(),
    relatedLines: z
      .array(z.object({ url: z.string(), parent: z.string() }))
      .nullish(),
    linkedTransactions: z.array(z.string()).optional(),
  }),
});

export const linkedTransactionSchema = z.object({
  id: idSchema,
  type: transactionTypeSchema,
  links: z.array(transactionLinksSchema),
});

export const rawLinkedTransactionsSchema = z.array(linkedTransactionSchema);

export const linkedTransactionsSchema = z
  .object({
    source: z.string(),
    transactions: rawLinkedTransactionsSchema,
  })
  .transform(transformLinkedTransactions);

const propagationLineIdsSchema = z.record(z.array(z.number()));

export const propagationObjectSchema = z.object({
  fields: z.record(propagationLineIdsSchema).optional(),
  lines: z
    .array(
      z.object({
        id: z.number(),
        fields: z.record(propagationLineIdsSchema).optional(),
        unlink: propagationLineIdsSchema.optional(),
        delete: propagationLineIdsSchema.optional(),
      })
    )
    .optional(),
});
