import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";

import { logout } from "../../api/auth";

type Props = {
  compact?: boolean;
};

type FullName = string | undefined;

type RootState = { user: { user: { full_name?: FullName } } };

export const SidebarLogout = memo(({ compact }: Props) => {
  const navigate = useNavigate();

  const name = useSelector<RootState, FullName>(
    (state) => state.user.user.full_name
  );

  const onLogoutClick = useEvent(async () => {
    await logout();
    window.location.reload();
  });

  const onMyProfileClick = useEvent(() => {
    navigate("/settings/profile");
  });

  return (
    <Dropdown trigger="hover" placement="top-start" flip={false}>
      <DropdownTrigger onClick={onMyProfileClick} aria-label="More">
        <Flex gap="md" width="full" align="center" padding={["none", "2xl"]}>
          {!compact ? (
            <Flex gap="lg" width="full" align="center">
              <Icon name="user-circle" />
              <Text truncate="right" size="sm">
                {name ?? "Unnamed"}
              </Text>
            </Flex>
          ) : (
            <Icon name="user-circle" />
          )}
        </Flex>
      </DropdownTrigger>
      <DropdownList>
        <DropdownItem onClick={onLogoutClick}>Sign out</DropdownItem>
        <DropdownItem onClick={onMyProfileClick}>My profile</DropdownItem>
      </DropdownList>
    </Dropdown>
  );
});

SidebarLogout.displayName = "SidebarLogout";
