import { useAsSimplifiedData } from "@shared/hooks/useSimplifiedData";
import { useGetVendorsSimplifiedQuery } from "@store/api-simplified";
import { useClientInfo } from "@store/user";

export type UseVendorsSimplifiedProps = {
  enabled?: boolean;
  includeLabel?: boolean;
};

export const useVendorsSimplified = ({
  enabled = true,
  includeLabel = false,
}: UseVendorsSimplifiedProps = {}) => {
  const { realmId } = useClientInfo();

  const query = useGetVendorsSimplifiedQuery(
    { realm: realmId as number },
    { skip: !enabled || !realmId }
  );

  return useAsSimplifiedData(query, {
    groupLabel: includeLabel ? "Vendor" : undefined,
  });
};
