import { useEffect, useState } from "react";
import { useEvent } from "@adaptive/design-system/hooks";
import { useAppSelector } from "@store/hooks";

import { selectErrorsGroups } from "../store/selectors";

// hopefully useForm works and I won't need this
export const useErrorField = (key: string) => {
  const errors = useAppSelector((state) => {
    const groups = selectErrorsGroups(state);

    const match = Object.values(groups || {}).find((group) =>
      Object.keys(group).includes(key)
    );

    if (!match) return null;

    return match ? (match as Record<string, any>)[key] : null;
  });

  // const errKey = key as NonNullable<keyof NonNullable<typeof errors>>;
  const [error, setError] = useState(errors);

  useEffect(() => {
    setError(errors);
  }, [errors]);

  const resetError = useEvent(() => {
    setError(undefined);
  });

  return [error, resetError] as const;
};
