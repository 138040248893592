import type { Vendor } from "../api/types";

import { getInitialAddress } from "./get-initial-address";

export const getInitialVendor = (name?: string): Vendor => ({
  address: getInitialAddress(),
  bankingAchs: undefined,
  displayName: name || "",
  email: "",
  id: "",
  url: "",
  hasExpiredDocuments: false,
  hasDocRequests: false,
  hasOverdueBills: false,
  paymentTerm: null,
  phoneNumber: "",
  taxId: "",
  defaultItem: null,
  commonVendor: null,
  defaultAccount: null,
  types: [],
  errors: [],
  isStoredEmailValid: true,
  averageTransaction: null,
  totalPayables: null,
  hasBankingAch: false,
  publishedToQuickbooks: false,
  realm: "",
  pendingDocRequests: [],
  achCanBeRequested: false,
  hasPendingRequests: false,
  defaultAccounts: [],
  defaultItems: [],
});
