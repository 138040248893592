import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  memo,
  useEffect,
} from "react";
import cn from "clsx";

import { useDialogContext } from "./dialog-context";
import styles from "./dialog.module.css";

type DefaultComponent = "footer";

type Props = ComponentPropsWithoutRef<DefaultComponent>;

const DialogFooter = forwardRef<ComponentRef<DefaultComponent>, Props>(
  ({ children, className, ...props }, ref) => {
    const { setHasFooter } = useDialogContext();

    useEffect(() => {
      setHasFooter?.(true);

      return () => {
        setHasFooter?.(false);
      };
    }, [setHasFooter]);

    return (
      <footer
        ref={ref}
        className={cn(className, styles["dialog-footer"])}
        {...props}
      >
        {children}
      </footer>
    );
  }
);

DialogFooter.displayName = "DialogFooter";

const MemoizedDialogFooter = memo(DialogFooter);

export { MemoizedDialogFooter as DialogFooter };
