import React, { type ComponentPropsWithoutRef, memo } from "react";

import styles from "./visually-hidden.module.css";

type DefaultComponent = "span";

type Props = Omit<ComponentPropsWithoutRef<DefaultComponent>, "className">;

const VisuallyHidden = (props: Props) => (
  <span className={styles["visually-hidden"]} {...props} />
);

const MemoizedVisuallyHidden = memo(VisuallyHidden);

export { MemoizedVisuallyHidden as VisuallyHidden };
