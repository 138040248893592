import React, { type ComponentProps, memo } from "react";
import cn from "clsx";

import styles from "./shell.module.css";

type Props = ComponentProps<"div">;

const ShellSidebarItem = ({ className, ...props }: Props) => (
  <div className={cn(className, styles["shell-sidebar-item"])} {...props} />
);

const MemoizedShellSidebarItem = memo(ShellSidebarItem);

export { MemoizedShellSidebarItem as ShellSidebarItem };
