import React, {
  type Consumer,
  createContext,
  type PropsWithChildren,
  useContext,
} from "react";

import type { UseFormReturn } from "./types";
import { useForm } from "./use-form";

type FormProviderProps<Form> = PropsWithChildren<{ form: Form }>;

export const createFormContext = <Fields extends Record<string, unknown>>() => {
  type Form = UseFormReturn<Fields>;

  const FormContext = createContext<Form | null>(null);

  const FormProvider = ({ form, children }: FormProviderProps<Form>) => (
    <FormContext.Provider value={form}>{children}</FormContext.Provider>
  );

  function useFormContext() {
    const context = useContext(FormContext);

    if (!context) {
      throw new Error("useFormContext must be used within a FormProvider");
    }

    return context;
  }

  return {
    useForm: useForm<Fields>,
    FormProvider,
    FormConsumer: FormContext.Consumer as Consumer<UseFormReturn<Fields>>,
    useFormContext,
  };
};
