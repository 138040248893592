import { curriedNoop, noop } from "@utils/noop";
import { createContext, useContextSelector } from "use-context-selector";

import type { BudgetsTableContextType } from "./types";

export const BudgetsTableContext = createContext<BudgetsTableContextType>({
  spent: 0,
  query: "",
  viewMode: "lines",
  filters: {} as BudgetsTableContextType["filters"],
  hasFilters: false,
  rawFilters: {},
  unpaidBills: 0,
  undrawnCosts: 0,
  builderAmount: 0,
  invoicedAmount: 0,
  totalOwnersAmount: 0,
  totalExternalChangeAmount: 0,
  totalOwnersRevisedAmount: 0,
  totalInvoicedAmount: 0,
  changeAmount: 0,
  builderRevisedAmount: 0,
  ownersAmount: 0,
  externalChangeAmount: 0,
  ownersRevisedAmount: 0,
  isOwnersBudgetLocked: true,
  shouldShowSourceType: false,
  isBuilderBudgetLocked: true,
  invoicedRemainingAmount: 0,
  builderRemainingAmount: 0,
  totalInvoicedRemainingAmount: 0,
  updateCategory: noop,
  onDeleteBudgetLine: curriedNoop,
  onEditBudgetLine: curriedNoop,
  curriedOnDeleteMarkup: curriedNoop,
  curriedOnEditWorkflow: curriedNoop,
  onEditFixedAmountMarkup: noop,
  curriedOnSeeTransactions: curriedNoop,
  curriedOnSeeDrawnToDate: curriedNoop,
  setQuery: noop,
  setFilters: noop,
  curriedOnSeeChanges: curriedNoop,
  onToggleBudgetLockedStatus: noop,
  curriedOnEditOwnersBudgetChange: curriedNoop,
  curriedOnEditBuildersBudgetChange: curriedNoop,
  curriedOnOpenOneSchema: curriedNoop,
});

export const useBudgetsQuery = () =>
  useContextSelector(BudgetsTableContext, (context) => context.query);

export const useBudgetsRawFilters = () =>
  useContextSelector(BudgetsTableContext, (context) => context.rawFilters);

export const useBudgetsHasFilters = () =>
  useContextSelector(BudgetsTableContext, (context) => context.hasFilters);

export const useBudgetsTableSpent = () =>
  useContextSelector(BudgetsTableContext, (context) => context.spent);

export const useBudgetsTableUnpaidBills = () =>
  useContextSelector(BudgetsTableContext, (context) => context.unpaidBills);

export const useBudgetsTableUndrawnCosts = () =>
  useContextSelector(BudgetsTableContext, (context) => context.undrawnCosts);

export const useBudgetsTableOwnersAmount = () =>
  useContextSelector(BudgetsTableContext, (context) => context.ownersAmount);

export const useBudgetsTableOwnersRevisedAmount = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.ownersRevisedAmount
  );

export const useBudgetsTableBuilderAmount = () =>
  useContextSelector(BudgetsTableContext, (context) => context.builderAmount);

export const useBudgetsTableInvoicedAmount = () =>
  useContextSelector(BudgetsTableContext, (context) => context.invoicedAmount);

export const useBudgetsTableTotalOwnersAmount = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.totalOwnersAmount
  );

export const useBudgetsTableTotalOwnersRevisedAmount = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.totalOwnersRevisedAmount
  );

export const useBudgetsTableTotalInvoicedAmount = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.totalInvoicedAmount
  );

export const useBudgetsTableChangeAmount = () =>
  useContextSelector(BudgetsTableContext, (context) => context.changeAmount);

export const useBudgetsTableBuilderRevisedAmount = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.builderRevisedAmount
  );

export const useBudgetsTableExternalChangeAmount = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.externalChangeAmount
  );

export const useBudgetsTableIsOwnersBudgetLocked = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.isOwnersBudgetLocked
  );

export const useBudgetsTableShouldShowSourceType = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.shouldShowSourceType
  );

export const useBudgetsTableViewMode = () =>
  useContextSelector(BudgetsTableContext, (context) => context.viewMode);

export const useBudgetsTableIsBuilderBudgetLocked = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.isBuilderBudgetLocked
  );

export const useBudgetsTableBuilderRemainingAmount = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.builderRemainingAmount
  );

export const useBudgetsTableTotalinvoicedRemainingAmount = () =>
  useContextSelector(
    BudgetsTableContext,
    (context) => context.totalInvoicedRemainingAmount
  );

export const useBudgetsTableActions = () => ({
  setQuery: useContextSelector(
    BudgetsTableContext,
    (context) => context.setQuery
  ),
  setFilters: useContextSelector(
    BudgetsTableContext,
    (context) => context.setFilters
  ),
  updateCategory: useContextSelector(
    BudgetsTableContext,
    (context) => context.updateCategory
  ),
  onDeleteBudgetLine: useContextSelector(
    BudgetsTableContext,
    (context) => context.onDeleteBudgetLine
  ),
  onEditBudgetLine: useContextSelector(
    BudgetsTableContext,
    (context) => context.onEditBudgetLine
  ),
  curriedOnDeleteMarkup: useContextSelector(
    BudgetsTableContext,
    (context) => context.curriedOnDeleteMarkup
  ),
  curriedOnEditWorkflow: useContextSelector(
    BudgetsTableContext,
    (context) => context.curriedOnEditWorkflow
  ),
  onEditFixedAmountMarkup: useContextSelector(
    BudgetsTableContext,
    (context) => context.onEditFixedAmountMarkup
  ),
  curriedOnSeeTransactions: useContextSelector(
    BudgetsTableContext,
    (context) => context.curriedOnSeeTransactions
  ),
  curriedOnSeeDrawnToDate: useContextSelector(
    BudgetsTableContext,
    (context) => context.curriedOnSeeDrawnToDate
  ),
  curriedOnSeeChanges: useContextSelector(
    BudgetsTableContext,
    (context) => context.curriedOnSeeChanges
  ),
  onToggleBudgetLockedStatus: useContextSelector(
    BudgetsTableContext,
    (context) => context.onToggleBudgetLockedStatus
  ),
  curriedOnEditOwnersBudgetChange: useContextSelector(
    BudgetsTableContext,
    (context) => context.curriedOnEditOwnersBudgetChange
  ),
  curriedOnEditBuildersBudgetChange: useContextSelector(
    BudgetsTableContext,
    (context) => context.curriedOnEditBuildersBudgetChange
  ),
  curriedOnOpenOneSchema: useContextSelector(
    BudgetsTableContext,
    (context) => context.curriedOnOpenOneSchema
  ),
});
