import { track } from "@shared/utils/analytics";

import type { GetLinkedTransactionsReturn } from "../api/types";
import type { PropagationDetails, PropagationObject } from "../types";

import { getDetailsFromUrl } from "./get-details-from-url";
import { getStepName } from "./get-step-name";

const TRACKING_EVENTS = {
  open: "propagationDialogOpen",
  save: "propagationDialogSave",
  cancel: "propagationDialogCancel",
} as Record<"open" | "save" | "cancel", Parameters<typeof track>[0]>;

type TrackPropagationAnalyticsParams = {
  event: keyof typeof TRACKING_EVENTS;
  propagationDetails?: PropagationDetails;
  linkedTransactions?: GetLinkedTransactionsReturn;
  propagate?: PropagationObject["propagate"];
  currentStep?: string;
};

type PropagationTrackingParams = {
  location: string | undefined;
  steps: number;
  cancelStep?: number;
  fields?: string[];
};

export const trackPropagationAnalytics = ({
  event,
  propagationDetails,
  linkedTransactions,
  propagate,
  currentStep,
}: TrackPropagationAnalyticsParams) => {
  const eventName = TRACKING_EVENTS[event];
  const objectType = getDetailsFromUrl(
    propagationDetails?.url || undefined
  )?.type;

  const allSteps = (linkedTransactions ?? []).reduce((acc, transaction) => {
    const newSteps = transaction.links.flatMap((item) =>
      getStepName(transaction, item)
    );

    return [...acc, ...newSteps];
  }, [] as string[]);

  const trackingParams: PropagationTrackingParams = {
    location: objectType,
    steps: allSteps.length,
  };

  if (event === "save") {
    const fields = new Set<string>(Object.keys(propagate?.fields || {}));

    propagate?.lines?.forEach((line) => {
      Object.keys(line.fields || {}).forEach((field) => {
        fields.add(field);
      });
    });

    trackingParams.fields = [...fields];
  }

  if (event === "cancel") {
    const cancelStep =
      currentStep === "final"
        ? allSteps.length
        : allSteps.indexOf(currentStep || "");

    trackingParams.cancelStep = cancelStep + 1;
  }

  track(eventName, trackingParams);
};
