import React, { memo } from "react";
import { Loader } from "@adaptive/design-system";
import { type UseMultiStepDialogReturn } from "@adaptive/design-system/hooks";

import { type GetLinkedTransactionsReturn } from "../api/types";
import type { PropagationDetails, PropagationObject } from "../types";

import { PropagationProvider } from "./propagation-provider";
import { PropagationStepsModal } from "./propagation-steps-modal";

type PropagationDialogProps = {
  linkedTransactions?: GetLinkedTransactionsReturn;
  transactionValues?: PropagationDetails;
  dialog: UseMultiStepDialogReturn<string>;
  onDialogClose: () => void;
  onSave: (propagations: PropagationObject) => void;
  loading?: boolean;
  isForcedPropagation: boolean;
};

const PropagationDialogComponent = ({
  linkedTransactions,
  transactionValues,
  dialog,
  onDialogClose,
  onSave,
  loading,
  isForcedPropagation,
}: PropagationDialogProps) => {
  return (
    <PropagationProvider
      linkedTransactions={linkedTransactions}
      propagationFields={transactionValues}
      isForcedPropagation={isForcedPropagation}
      onSave={onSave}
    >
      {loading && <Loader position="fixed" />}
      {dialog.isRendered && (
        <PropagationStepsModal dialog={dialog} onClose={onDialogClose} />
      )}
    </PropagationProvider>
  );
};

export const PropagationDialog = memo(PropagationDialogComponent);
