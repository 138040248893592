import { omit } from "@adaptive/design-system/utils";
import { createAction, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import type { Identifiable } from "@shared/types";
import type { RootState } from "@store/types";
import { withPayloadType } from "@store/ui";
import { selectRealmUrl } from "@store/user/selectors-raw";
import {
  type PromisesSettledSummary,
  summarizeResults,
} from "@utils/all-settled";

import {
  type ACHCreatePayload,
  documentsApi,
  vendorBankingAchApi,
} from "../api/old-api";
import { vendorApi } from "../api/old-api";
import type { OutgoingDocumentPayload, Vendor } from "../api/types";
import { SubmitErrorCodes } from "../constants/constants";

import { creationId as creationIdSelector, vendorSelector } from "./selectors";
import type {
  ChangeSet,
  EditDocument,
  SavedDocument,
  Stage,
  VirtualDocument,
} from "./types";

type FetchByIdPayload = {
  id: string | number;
  initialStage: Stage;
};

export const fetchById = createAsyncThunk<
  Awaited<ReturnType<typeof vendorApi.get>> & { initialStage: Stage },
  FetchByIdPayload,
  { state: RootState }
>("vendors/fetchById", async ({ id, initialStage }, { rejectWithValue }) => {
  try {
    const data = await vendorApi.get({ id });

    if (!data?.id) throw new Error("Not found");
    return { ...data, initialStage };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export type CommitVendorPayload =
  | {
      commit: "updated" | "created";
      vendor: Vendor;
      creationId: string | null;
    }
  | undefined;

export const commitVendor = createAsyncThunk<
  CommitVendorPayload,
  void,
  { state: RootState }
>(
  "vendors/persistToDB",
  async (actionPayload, { getState, rejectWithValue }) => {
    const state = getState();

    const realm = selectRealmUrl(getState());
    if (!realm) {
      return rejectWithValue({ status: SubmitErrorCodes.MISSING_REALM });
    }

    let { info: vendor } = vendorSelector(state);
    const creationId = creationIdSelector(state);

    // the 'create' response doesn't contain the ID outside of the URL,
    // so use that to determine if we're creating or updating
    const persistToDatabase = !vendor.url ? vendorApi.create : vendorApi.put;

    /**
     * This is a workaround to remove invalid email from the
     * request. We need it since in the case when our users have
     * something different from a valid email on their QB side
     */
    if (!vendor.isStoredEmailValid) {
      vendor = omit(vendor, ["email"]) as any;
    }

    try {
      const response = await persistToDatabase({
        ...vendor,
        realm,
        id: vendor.id,
      });

      return {
        commit: vendor.url ? "updated" : "created",
        vendor: response as Vendor,
        creationId: creationId || null,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateVendor = createAsyncThunk<
  Vendor,
  Vendor,
  { state: RootState }
>("vendors/updateVendor", async (actionPayload, { rejectWithValue }) => {
  try {
    const response = await vendorApi.put(actionPayload);

    return response as Vendor;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const addDocument = createAction(
  "vendor/document/add",
  (payload: Omit<VirtualDocument, "id">) => ({
    payload: {
      ...payload,
      id: nanoid(),
    },
  })
);

export const toggleEditDocument = createAction(
  "vendor/document/toggle-edit",
  (id: string | number) => ({
    payload: { id },
  })
);

export const removeDocument = createAction(
  "vendor/document/remove",
  withPayloadType<Identifiable>()
);

export const createDocument = createAsyncThunk<
  Awaited<ReturnType<typeof documentsApi.create>> | undefined,
  OutgoingDocumentPayload | { id: string | number },
  { state: RootState }
>(
  "vendors/documents/create",
  async (document, { getState, rejectWithValue }) => {
    const realm = selectRealmUrl(getState());
    if (!realm) {
      return rejectWithValue({ status: SubmitErrorCodes.MISSING_REALM });
    }

    try {
      return await documentsApi.create({
        ...document,
        realm,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const updateDocument = createAsyncThunk<
  Awaited<ReturnType<typeof documentsApi.put>> | undefined,
  OutgoingDocumentPayload & Identifiable,
  { state: RootState }
>(
  "vendors/documents/update",
  async (document, { getState, rejectWithValue }) => {
    const realm = selectRealmUrl(getState());
    if (!realm) {
      return rejectWithValue({ status: SubmitErrorCodes.MISSING_REALM });
    }

    try {
      return await documentsApi.put({
        ...document,
        realm,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const deleteDocument = createAsyncThunk<
  Identifiable | void,
  Identifiable,
  { state: RootState }
>("vendors/documents/delete", async (doc, { rejectWithValue }) => {
  try {
    const resp = await documentsApi.remove(doc);
    if (resp.status === 204) {
      return doc;
    }
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const syncDocuments = createAsyncThunk<
  Awaited<{
    added?: PromisesSettledSummary;
    removed?: PromisesSettledSummary;
    edited?: PromisesSettledSummary;
  }>,
  ChangeSet<VirtualDocument, SavedDocument, EditDocument>,
  { state: RootState }
>(
  "vendors/documents/sync",
  async (diff, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const realm = selectRealmUrl(state);

    const vendorUrl = state.vendors.form.info?.url;
    if (!realm) {
      return rejectWithValue(
        "Unable to sync documents.  Associated realm not found"
      );
    }

    if (!vendorUrl) {
      return rejectWithValue(
        "Unable to sync documents.  Associated vendor not found"
      );
    }

    const result: {
      added?: PromisesSettledSummary;
      removed?: PromisesSettledSummary;
      edited?: PromisesSettledSummary;
    } = {};

    const { added, removed, edited } = diff;

    if (added) {
      const addedResults = await Promise.allSettled(
        added.map(
          async (document) =>
            await dispatch(
              createDocument({ ...document, parent: vendorUrl })
            ).unwrap()
        )
      );

      result.added = summarizeResults(addedResults);
    }

    if (removed) {
      const removedResults = await Promise.allSettled(
        removed.map(
          async (document) => await dispatch(deleteDocument(document)).unwrap()
        )
      );

      result.removed = summarizeResults(removedResults);
    }

    if (edited) {
      const editedResults = await Promise.allSettled(
        edited.map(
          async (document) => await dispatch(updateDocument(document)).unwrap()
        )
      );
      result.edited = summarizeResults(editedResults);
    }

    return result;
  }
);

export const removeAchInfo = createAsyncThunk<
  Awaited<boolean> | undefined,
  Identifiable,
  { state: RootState }
>("vendors/vendorAchInfo/delete", async (data, { rejectWithValue }) => {
  try {
    const resp = await vendorBankingAchApi.delete(data);
    return resp.status === 204;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const commitAchInfo = createAsyncThunk<
  unknown,
  void,
  { state: RootState }
>(
  "vendors/vendorAchInfo/commit",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState();

    const realm = selectRealmUrl(state);
    if (!realm) {
      return rejectWithValue({ status: SubmitErrorCodes.MISSING_REALM });
    }

    const vendor = vendorSelector(state);
    if (!vendor?.banking || !vendor?.info?.url) {
      // url is required to create a new ACH,
      // but we don't have one until the vendor is created
      return;
    }

    // just plucking ID out of the payload, since we never need it
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...banking } = vendor.banking;

    const payload = {
      ...banking,
      realm,
      vendor: vendor.info.url,
    };

    try {
      if (id && !banking.accountNumber && !banking.routingNumber) {
        return await dispatch(removeAchInfo({ id }));
      }
      return await vendorBankingAchApi.create(payload as ACHCreatePayload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
