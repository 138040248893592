import React, { useMemo } from "react";
import {
  Link,
  Table,
  type TableColumn,
  TagGroup,
} from "@adaptive/design-system";
import { formatCurrency } from "@adaptive/design-system/utils";
import { useBillPaymentV2 } from "@src/bills/hooks/use-bill-payment-v2";

export type BillPaymentLinkedTransaction = {
  id: string;
  type: string;
  amount: string;
  docNumber: string;
  customers: string[];
  balance: string;
};

const COLUMNS: TableColumn<BillPaymentLinkedTransaction>[] = [
  {
    id: "type",
    name: "Type",
    render: (row) => row.type,
  },
  {
    id: "docNumber",
    name: "Ref #",
    render: (row) => {
      return (
        <Link href={`/bills/${row.id}`} target="_blank" size="sm">
          {`#${row.docNumber}`}
        </Link>
      );
    },
  },
  {
    id: "amount",
    name: "Payment amount",
    render: (row) => {
      return formatCurrency(row.amount, { currencySign: true });
    },
  },
  {
    id: "balance",
    name: "Balance",
    render: (row) => formatCurrency(row.balance, { currencySign: true }),
  },
  {
    id: "jobs",
    name: "Jobs",
    render: (row) => {
      return <TagGroup data={row.customers || []} limit="auto" />;
    },
  },
];

export const LinkedTransactionsTable = ({
  billPaymentId,
}: {
  billPaymentId: string;
}) => {
  const { data: billPayment, isLoading: isBillPaymentLoading } =
    useBillPaymentV2({
      billPaymentId,
    });

  const tableData = useMemo(() => {
    const linkedBills =
      billPayment?.bills?.map((linkedBill) => ({
        id: linkedBill.bill.id,
        type: "Bill",
        amount: linkedBill.appliedAmount,
        docNumber: linkedBill.bill.docNumber,
        customers: linkedBill.customers.map((customer) => customer.displayName),
        balance: linkedBill.openBalance,
      })) || [];

    const linkedVendorCredits =
      billPayment?.appliedVendorCredits?.map((linkedVendorCredit) => ({
        id: linkedVendorCredit.vendorCredit.id,
        type: "Vendor credit",
        amount: linkedVendorCredit.appliedAmount,
        docNumber: linkedVendorCredit.vendorCredit.docNumber,
        customers: linkedVendorCredit.customers.map(
          (customer) => customer.displayName
        ),
        balance: linkedVendorCredit.openBalance,
      })) || [];

    return [...linkedBills, ...linkedVendorCredits];
  }, [billPayment?.bills, billPayment?.appliedVendorCredits]);

  return (
    <Table
      columns={COLUMNS}
      size="sm"
      data={tableData}
      loading={isBillPaymentLoading}
    />
  );
};
