import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";

import { dotObject } from "../../utils/dot-object";
import { is } from "../../utils/is";
import { isEqual } from "../../utils/is-equal";
import { useDeepMemo } from "../use-deep-memo";

import type {
  AppendHandler,
  Dirty,
  Errors,
  ExternalSubmitHandler,
  FieldType,
  GetErrorHandler,
  GetTouchHandler,
  GetValueHandler,
  OnSubmitHandler,
  RegisterHandler,
  RemoveHandler,
  ResetHandler,
  Schema,
  SetErrorHandler,
  SetTouchHandler,
  SetValueHandler,
  SetValuesHandler,
  SubmitHandler,
  Touches,
  UseFormProps,
  UseFormReturn,
  ValidateHandler,
} from "./types";

const parseValue = <Value>(type: FieldType, value: Value) => {
  if (type === "boolean") {
    return value ? "on" : "off";
  } else if (type === "inverse-boolean") {
    return !value ? "on" : "off";
  }

  return value;
};

const parseChecked = <Value>(type: FieldType, value: Value) => {
  if (type === "boolean") {
    return !!value;
  } else if (type === "inverse-boolean") {
    return !value;
  }

  return undefined;
};

// based on https://github.com/react-hook-form/resolvers/blob/master/zod/src/zod.ts
const parseSchema = <Fields>(schema: Schema, values: Fields) => {
  const result = schema.safeParse(values);

  if (result.success) return { isValid: true, errors: {} as Errors<Fields> };

  const issues = result.error.issues;

  let errors = {} as Errors<Fields>;

  for (; issues.length; ) {
    const { path, message } = issues[0];

    if (!dotObject.get(errors, path.join("."))) {
      errors = dotObject.set(errors, path.join("."), message);
    }

    issues.shift();
  }

  return { isValid: false, errors };
};

const focusFirstInvalidField = (fields: string[]) => {
  requestAnimationFrame(() => {
    const [first] = [
      ...document.querySelectorAll<HTMLElement>('[aria-invalid="true"]'),
    ].filter((el) => fields.includes(el.id));

    if (!first) return;

    first.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });

    first.focus({ preventScroll: true });
  });
};

const isArrayValue = (value: unknown) =>
  Array.isArray(value) && value.every((item) => typeof item === "string");

const isRangeDate = (value: unknown) =>
  typeof value === "object" &&
  value !== null &&
  !Array.isArray(value) &&
  Object.prototype.hasOwnProperty.call(value, "from") &&
  Object.prototype.hasOwnProperty.call(value, "to");

const flat = <T extends object>(obj: T) =>
  dotObject.flat(obj, {
    filter: (value) => !isRangeDate(value) && !isArrayValue(value),
  });

const isValueDirty = (value: unknown, initialValue: unknown): boolean => {
  if (is.array(value) && is.array(initialValue)) {
    return (
      value.length !== initialValue.length ||
      value.some((item, index) => isValueDirty(item, initialValue[index]))
    );
  } else if (is.object(value) && is.object(initialValue)) {
    return Object.entries(value).some(([key, nestedValue]) =>
      isValueDirty(nestedValue, initialValue[key])
    );
  }
  return value !== initialValue;
};

export const useForm = <Fields extends Record<string, unknown>>({
  id,
  debug,
  schema,
  onSubmit,
  initialValues,
  validationMode = "onBlur",
}: UseFormProps<Fields>): UseFormReturn<Fields> => {
  const formId = useId();
  const enhancedFormId = id ?? formId;

  const valuesRef = useRef<Fields>(initialValues);
  const handlersRef = useRef(
    new Map<
      string,
      {
        onBlur: () => void;
        onChange: (value: any) => void;
        onCustomBlur: (() => void) | undefined;
        onCustomChange: ((value: any) => void) | undefined;
      }
    >()
  );

  /**
   * To prevent multiple submits, we need to use a ref to check if the submit
   * was already triggered to prevent multiple triggers at same time.
   */
  const submitTriggered = useRef(false);

  const initial = useDeepMemo(() => {
    let errors = {} as Errors<Fields>;
    let touches = {} as Touches<Fields>;

    Object.keys(flat(initialValues)).forEach((key) => {
      errors = dotObject.set(errors, key, "");
      touches = dotObject.set(touches, key, false);
    });

    return { errors, touches, values: initialValues };
  }, [initialValues]);

  const [values, internalSetValues] = useState<Fields>(initial.values);
  const [errors, setErrors] = useState<Errors<Fields>>(initial.errors);
  const [touches, setTouches] = useState<Touches<Fields>>(initial.touches);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const optimizedSetValues = useCallback<typeof internalSetValues>(
    (updaterOrValue) => {
      internalSetValues((previousValues) => {
        const nextValues = is.function(updaterOrValue)
          ? updaterOrValue(previousValues)
          : updaterOrValue;

        if (isEqual(previousValues, nextValues)) return previousValues;

        return nextValues;
      });
    },
    []
  );

  const optimizedSetErrors = useCallback<typeof setErrors>((updaterOrValue) => {
    setErrors((previousErrors) => {
      const nextErrors = is.function(updaterOrValue)
        ? updaterOrValue(previousErrors)
        : updaterOrValue;

      if (isEqual(previousErrors, nextErrors)) return previousErrors;

      return nextErrors;
    });
  }, []);

  const optimizedSetTouches = useCallback<typeof setTouches>(
    (updaterOrValue) => {
      setTouches((previousTouches) => {
        const nextTouches = is.function(updaterOrValue)
          ? updaterOrValue(previousTouches)
          : updaterOrValue;

        if (isEqual(previousTouches, nextTouches)) return previousTouches;

        return nextTouches;
      });
    },
    []
  );

  const { dirty, isDirty } = useDeepMemo(() => {
    let isDirty = false;

    const flatValues = flat(values);
    const flatInitialValues = flat(initial.values);

    const dirty = Object.entries(flatValues).reduce(
      (acc, [key, value]) => {
        const isDirtyValue = isValueDirty(value, flatInitialValues[key]);

        if (isDirtyValue) isDirty = true;

        return { ...acc, [key]: isDirtyValue };
      },
      {} as Dirty<Fields>
    );

    return { dirty: dotObject.unflat(dirty), isDirty };
  }, [values, initial.values]);

  const isValid = useMemo(
    () => Object.values(flat(errors) as any).every((error) => error === ""),
    [errors]
  );

  const isTouched = useMemo(
    () => Object.values(flat(touches) as any).some((touch) => touch),
    [touches]
  );

  const getError = useCallback<GetErrorHandler<Fields>>(
    (name) => dotObject.get(errors, name),
    [errors]
  );

  const setError = useCallback<SetErrorHandler<Fields>>(
    (name, value) => {
      optimizedSetErrors((errors) => dotObject.set(errors, name, value));
    },
    [optimizedSetErrors]
  );

  const validate = useCallback<ValidateHandler>(
    (forceTouch = false) => {
      if (!schema) return true;

      const parsedSchema = parseSchema(schema, valuesRef.current);

      let errors = {} as Errors<Fields>;
      let touches = {} as Touches<Fields>;

      Object.keys(flat(valuesRef.current)).forEach((key) => {
        errors = dotObject.set(errors, key, "");
        touches = dotObject.set(touches, key, true);
      });

      if (parsedSchema.isValid) {
        optimizedSetErrors(errors);
        return true;
      }

      optimizedSetErrors({ ...errors, ...parsedSchema.errors });

      if (forceTouch) {
        optimizedSetTouches(touches);
        focusFirstInvalidField(Object.keys(valuesRef.current));
      }

      return false;
    },
    [schema, optimizedSetErrors, optimizedSetTouches]
  );

  const reset = useCallback<ResetHandler<Fields>>(
    (values) => {
      let nextTouches = {} as Touches<Fields>;
      const nextValues = values ?? initial.values;
      Object.keys(flat(nextValues)).forEach((key) => {
        nextTouches = dotObject.set(nextTouches, key, false);
      });
      valuesRef.current = nextValues;
      optimizedSetValues(nextValues);
      validate();
      optimizedSetTouches(nextTouches);
    },
    [validate, initial.values, optimizedSetValues, optimizedSetTouches]
  );

  const getValue = useCallback<GetValueHandler<Fields>>(
    (name) => dotObject.get(values, name),
    [values]
  );

  const setValue = useCallback<SetValueHandler<Fields>>(
    (name, value, options = {}) => {
      if (options.shouldValidate) setError(name, "");

      valuesRef.current = dotObject.set(valuesRef.current, name, value);
      optimizedSetValues((values) => dotObject.set(values, name, value));

      if (options.shouldValidate) validate();
    },
    [setError, validate, optimizedSetValues]
  );

  const setValues = useCallback<SetValuesHandler<Fields>>(
    (updaterOrValue, options) => {
      const enhancedValues =
        typeof updaterOrValue === "function"
          ? updaterOrValue(valuesRef.current)
          : updaterOrValue;

      Object.entries(enhancedValues).forEach(([key, value]) => {
        setValue(key, value, options);
      });
    },
    [setValue]
  );

  const getTouch = useCallback<GetTouchHandler<Fields>>(
    (name) => dotObject.get(touches, name),
    [touches]
  );

  const setTouch = useCallback<SetTouchHandler<Fields>>(
    (name, value) => {
      optimizedSetTouches((touches) => dotObject.set(touches, name, value));
    },
    [optimizedSetTouches]
  );

  const register = useCallback<RegisterHandler<Fields>>(
    (nameOrOptions) => {
      const name =
        typeof nameOrOptions === "object" ? nameOrOptions.name : nameOrOptions;

      const type =
        typeof nameOrOptions === "object"
          ? nameOrOptions.type || "string"
          : "string";

      const onBlur =
        typeof nameOrOptions === "object" ? nameOrOptions?.onBlur : undefined;

      const onChange =
        typeof nameOrOptions === "object" ? nameOrOptions?.onChange : undefined;

      if (!handlersRef.current.has(name)) {
        handlersRef.current.set(name, {
          onBlur: () => {
            if (validationMode === "onBlur") setTouch(name, true);
            validate();
            const onBlurReference =
              handlersRef.current.get(name)?.onCustomBlur ?? onBlur;
            requestAnimationFrame(() => onBlurReference?.());
          },
          onChange: (value) => {
            const enhancedValue = type === "inverse-boolean" ? !value : value;
            if (validationMode === "onChange") setTouch(name, true);
            setValue(name, enhancedValue, { shouldValidate: true });
            const onChangeReference =
              handlersRef.current.get(name)?.onCustomChange ?? onChange;
            requestAnimationFrame(() =>
              onChangeReference?.(enhancedValue as never)
            );
          },
          onCustomBlur: onBlur,
          onCustomChange: onChange,
        });
      } else {
        handlersRef.current.set(name, {
          ...handlersRef.current.get(name)!,
          onCustomBlur: onBlur,
          onCustomChange: onChange,
        });
      }

      const handlers = handlersRef.current.get(name)!;

      const value = getValue(name);

      return {
        id: name,
        name,
        form: enhancedFormId,
        value: parseValue(type, value),
        onBlur: handlers.onBlur,
        checked: parseChecked(type, value),
        onChange: handlers.onChange,
        errorMessage: getTouch(name) && getError(name) ? getError(name) : "",
        "data-testid": name,
      };
    },
    [
      getValue,
      getTouch,
      getError,
      validate,
      setTouch,
      setValue,
      enhancedFormId,
      validationMode,
    ]
  );

  const append = useCallback<AppendHandler<Fields>>(
    (name, value) => {
      const enhancedName = `${String(name)}.${getValue(name).length}`;
      setValue(enhancedName, value);

      if (typeof value === "object") {
        Object.keys(value as object).forEach((key) => {
          setError(`${enhancedName}.${key}`, "");
          setTouch(`${enhancedName}.${key}`, false);
        });
      } else {
        setError(enhancedName, "");
        setTouch(enhancedName, false);
      }

      validate();
    },
    [getValue, setValue, validate, setError, setTouch]
  );

  const remove = useCallback<RemoveHandler<Fields>>(
    (name, index) => {
      setValue(name, dotObject.delete(getValue(name), String(index)));
      setError(
        name,
        dotObject.delete(getError(name), String(index)) as string | string[]
      );
      setTouch(
        name,
        dotObject.delete(getTouch(name), String(index)) as boolean | boolean[]
      );

      validate();
    },
    [getError, getTouch, getValue, setError, setTouch, setValue, validate]
  );

  const submit = useCallback<SubmitHandler<Fields>>(
    async (values = valuesRef.current, e) => {
      if (!validate(true)) return;

      return new Promise<void>((resolve) => {
        requestAnimationFrame(async () => {
          try {
            setIsSubmitting(true);
            await onSubmit?.(values, e);
          } finally {
            setIsSubmitting(false);
            resolve();
          }
        });
      });
    },
    [onSubmit, validate]
  );

  const externalSubmit = useCallback<ExternalSubmitHandler<Fields>>(
    async (values = valuesRef.current) => {
      if (submitTriggered.current) return;

      submitTriggered.current = true;

      try {
        await submit(values);
      } finally {
        submitTriggered.current = false;
      }
    },
    [submit]
  );

  /**
   * Since our CurrencyField only dispatch `onChange` when blur, we need to
   * change the native onSubmit event to "blur" the current field first then dispatch
   * submit, in that way we guarantee that the value is updated before we dispatch.
   * Other libraries like react-aria do the same thing for number fields (like currency).
   */
  const internalSubmit = useCallback<OnSubmitHandler>(
    async (e) => {
      e.preventDefault();

      if (submitTriggered.current) return;

      submitTriggered.current = true;

      const targetId = dotObject.get(e.target, "id", "");
      const isCorrectForm = enhancedFormId === targetId || !targetId;

      if (!isCorrectForm) return;

      const previousFocusedEl = document.activeElement
        ? (document.activeElement as HTMLElement)
        : null;

      previousFocusedEl?.blur();

      try {
        await submit(valuesRef.current, e);
      } finally {
        previousFocusedEl?.focus();
        submitTriggered.current = false;
      }
    },
    [enhancedFormId, submit]
  );

  useEffect(() => {
    validate();
  }, [validate]);

  /* eslint-disable */
  /* c8 ignore next 11 */
  if (debug) {
    console.log({
      dirty,
      isDirty,
      values,
      errors,
      isValid,
      touches,
      isTouched,
      isSubmitting,
    });
  }
  /* eslint-enable */

  return {
    id: enhancedFormId,
    reset,
    props: {
      id: enhancedFormId,
      onSubmit: internalSubmit,
      noValidate: true,
    },
    dirty,
    submit: externalSubmit,
    values,
    errors,
    append,
    remove,
    touches,
    isDirty,
    isValid,
    register,
    setValue,
    validate,
    isTouched,
    setValues,
    isSubmitting,
  };
};
