import React, { type ComponentProps, type ReactNode } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Icon,
  Tooltip,
} from "@adaptive/design-system";

export type DynamicActionsProps = {
  id?: string;
  data?: {
    color?: "neutral" | "error";
    onClick: () => void;
    children: ReactNode;
    disabled?: boolean;
    tooltipMessage?: string;
  }[];
  trigger?: Pick<
    ComponentProps<typeof Button>,
    "children" | "variant" | "color" | "size" | "disabled"
  >;
  fallback?: ReactNode;
  "data-testid"?: string;
};

export const DynamicActions = ({
  id,
  data = [],
  trigger,
  fallback = null,
  "data-testid": testId,
}: DynamicActionsProps) => {
  if (data.length === 1) {
    return (
      <Button
        id={id}
        variant={trigger?.variant ?? "text"}
        color={trigger?.color ?? "neutral"}
        size={trigger?.size ?? "lg"}
        disabled={trigger?.disabled ?? false}
        {...data[0]}
      />
    );
  }

  if (data.length > 1) {
    return (
      <Dropdown>
        <DropdownTrigger
          as={Button}
          id={id}
          size={trigger?.size ?? "lg"}
          color={trigger?.color ?? "neutral"}
          variant={trigger?.variant ?? "text"}
          aria-label={!trigger ? "Actions" : undefined}
          data-testid={testId}
        >
          {trigger ? (
            <>
              {trigger.children}
              <Icon name="chevron-down" />
            </>
          ) : (
            <Icon name="ellipsis-vertical" variant="solid" />
          )}
        </DropdownTrigger>
        <DropdownList>
          {data.map(({ tooltipMessage, ...action }, i) => (
            <Tooltip key={i} message={tooltipMessage} placement="right">
              <DropdownItem {...action} />
            </Tooltip>
          ))}
        </DropdownList>
      </Dropdown>
    );
  }

  return <>{fallback}</>;
};
