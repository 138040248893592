import { is } from "../is";

const parseOrdinal = (value: number): string => {
  let ord = "th";

  if (value % 10 == 1 && value % 100 != 11) {
    ord = "st";
  } else if (value % 10 == 2 && value % 100 != 12) {
    ord = "nd";
  } else if (value % 10 == 3 && value % 100 != 13) {
    ord = "rd";
  }

  return ord;
};

export const parseNumberWithOrdinal = (value: string | number): string => {
  const numberValue = is.string(value) ? Number(value) : value;
  const ordinal = parseOrdinal(numberValue);

  return `${numberValue}${ordinal}`;
};
