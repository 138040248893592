import React, { type ReactNode } from "react";
import {
  Flex,
  Icon,
  type IconProps,
  Tag,
  type TagProps,
} from "@adaptive/design-system";
import { PAYMENT_METHOD } from "@api/bills";
import { type PaymentMethod, type PaymentStatusKey } from "@src/bills/types";

type BillPaymentStatusTagProps = {
  status: PaymentStatusKey;
  paymentMethod: PaymentMethod;
  isVoided: boolean;
};

const PAYMENT_STATUS_VARIANTS: Record<
  PaymentStatusKey,
  {
    color: TagProps["color"];
    icon: IconProps["name"];
    render: (props: Omit<BillPaymentStatusTagProps, "status">) => ReactNode;
  }
> = {
  PAID: {
    color: "success",
    icon: "check-circle",
    render: ({ paymentMethod }) =>
      paymentMethod === PAYMENT_METHOD.MARK_AS_PAID ? "Marked as paid" : "Paid",
  },
  PENDING: {
    color: "info",
    icon: "refresh",
    render: () => "In progress",
  },
  FAILED: {
    color: "error",
    icon: "exclamation-triangle",
    render: () => "Failed",
  },
  CANCELLED: {
    color: "error",
    icon: "circle-x",
    render: ({ isVoided }) => (isVoided ? "Voided" : "Cancelled"),
  },
  ACH_REQUESTED: {
    color: "neutral",
    icon: "clock",
    render: () => "Scheduled",
  },
  PENDING_PREREQUISITES: {
    color: "neutral",
    icon: "clock",
    render: () => "Scheduled",
  },
};

export const BillPaymentStatusTag = ({
  status,
  paymentMethod,
  isVoided,
}: BillPaymentStatusTagProps) => {
  const variant = PAYMENT_STATUS_VARIANTS[status as PaymentStatusKey];
  return (
    <Tag color={variant.color}>
      <Flex gap="md">
        <Icon size="sm" name={variant.icon} />
        {variant.render({ paymentMethod, isVoided })}
      </Flex>
    </Tag>
  );
};
