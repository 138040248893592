import { useCallback } from "react";
import { type Identifiable } from "@shared/types";
import { useAppDispatch } from "@store/hooks";

import { vendorsApi } from "../api/api";
import { setIsSubmitting } from "../store/actions";
import {
  addDocument,
  removeDocument,
  syncDocuments,
  toggleEditDocument,
} from "../store/thunks";
import {
  type ChangeSet,
  type EditDocument,
  type SavedDocument,
  type VirtualDocument,
} from "../store/types";

export const useDocumentAction = () => {
  const dispatch = useAppDispatch();

  const add = useCallback(
    (document: VirtualDocument) => dispatch(addDocument(document)),
    [dispatch]
  );

  const edit = useCallback(
    (id: string | number) => dispatch(toggleEditDocument(id)),
    [dispatch]
  );

  const remove = useCallback(
    (document: Identifiable) => dispatch(removeDocument(document)),
    [dispatch]
  );

  const sync = useCallback(
    async (diff: ChangeSet<VirtualDocument, SavedDocument, EditDocument>) => {
      dispatch(setIsSubmitting(true));
      await dispatch(syncDocuments(diff));
      dispatch(vendorsApi.util.invalidateTags(["Vendors"]));
      dispatch(setIsSubmitting(false));
    },
    [dispatch]
  );

  return {
    addDocument: add,
    toggleEditDocument: edit,
    removeDocument: remove,
    syncDocuments: sync,
  };
};
