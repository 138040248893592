import React, {
  type ComponentPropsWithoutRef,
  forwardRef,
  memo,
  type ReactNode,
  useMemo,
} from "react";
import { Card, Flex, Text } from "@adaptive/design-system";
import cn from "clsx";

import contentStyles from "./section.module.css";

type SectionProps = ComponentPropsWithoutRef<"div"> & {
  margin?: boolean;
  heading?: string | ReactNode;
  hideBorder?: boolean;
  tags?: ReactNode[];
  hidePadding?: boolean;
};

const Section = forwardRef<HTMLDivElement, SectionProps>(
  (
    {
      margin = false,
      heading,
      children,
      tags,
      hideBorder = false,
      hidePadding = false,
    },
    ref
  ) => {
    const sectionHeading = useMemo(() => {
      if (["string", "number"].includes(typeof heading)) {
        return (
          <Text size="xl" weight="bold">
            {heading}
          </Text>
        );
      }

      return heading;
    }, [heading]);

    return (
      <Flex
        as="section"
        ref={ref}
        gap="xl"
        shrink={false}
        direction="column"
        className={cn(contentStyles["section"], {
          [contentStyles["-margin"]]: margin,
          [contentStyles["-hide-border"]]: hideBorder,
          [contentStyles["-hide-padding"]]: hidePadding,
        })}
      >
        <Flex gap="xl" align="flex-start">
          <Flex
            gap="md"
            padding={["xs", "none", "none"]}
            justify="flex-start"
            align="center"
          >
            {sectionHeading}
          </Flex>
          {tags && (
            <Flex gap="md" wrap grow basis={0} justify="flex-end">
              {tags}
            </Flex>
          )}
        </Flex>
        <Card>{children}</Card>
      </Flex>
    );
  }
);

Section.displayName = "Section";

const MemoizedSection = memo(Section);

export { MemoizedSection as Section };
