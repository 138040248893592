import { getApi } from "../../utils/api";
import { toast } from "@adaptive/design-system";
import { handleErrors } from "../handle-errors";

const baseURL = "/api/purchaseorders/";

export const api = getApi();

export const getPurchaseOrder = (purchaseOrderId, source) => {
  return api
    .get(`${baseURL}${purchaseOrderId}/`, {
      cancelToken: source.token,
    })
    .then((response) => {
      if (response.status !== 404) return response;

      throw response;
    });
};

export const getPurchaseOrderBills = ({
  purchaseOrderId,
  signal,
  ...params
}) => {
  return api.get(`${baseURL}${purchaseOrderId}/bills`, {
    signal,
    params,
  });
};

export const deletePurchaseOrder = (purchaseOrderId) => {
  return api.delete(`${baseURL}${purchaseOrderId}/`);
};

export const getPurchaseOrders = async ({ filters, signal }) => {
  const queryParams = new URLSearchParams();

  filters.forEach((filter) => {
    queryParams.append(filter.dataIndex, filter.value);
  });
  return api
    .get(`${baseURL}?${queryParams.toString()}`, { signal })
    .then((response) => response.data);
};

const createPurchaseOrder = (purchaseOrder) => {
  return api.post(baseURL, purchaseOrder);
};

export const updatePurchaseOrder = (purchaseOrder) => {
  return api.put(`${baseURL}${purchaseOrder.id}/`, purchaseOrder);
};

export const savePurchaseOrder = (purchaseOrder) => {
  const saveRequest = !purchaseOrder.id
    ? createPurchaseOrder
    : updatePurchaseOrder;
  const totalAmount =
    typeof purchaseOrder.total_amount === "string"
      ? Number(purchaseOrder.total_amount.replace(/,/g, ""))
      : Number(purchaseOrder.total_amount);

  const purchaseOrderData = {
    total_amount: totalAmount,
    date: purchaseOrder.date,
    doc_number: purchaseOrder.doc_number,
    vendor: purchaseOrder.vendor?.url,
    id: purchaseOrder.id,
    url: purchaseOrder.url,
    private_note: purchaseOrder.private_note,
    po_status: purchaseOrder.po_status,
    shipping_address: purchaseOrder.shipping_address,
    realm: purchaseOrder.realm?.url,
    lines: purchaseOrder.lines
      .filter((line) => !line.deleted)
      .map((line) => ({
        customer: line.customer ? line.customer.url : null,
        item: line.item ? line.item.url : null,
        account: line.account ? line.account.url : null,
        amount: line.amount,
        status: line.status || "Open",
        force_close: line.force_close,
        id: line.created ? null : line.id,
        description: line.description,
        type: line.type,
      })),
    scope_of_work: purchaseOrder.scope_of_work,
    propagate: purchaseOrder.propagate,
  };

  return saveRequest(purchaseOrderData)
    .then((response) => {
      if (response.status === 400) {
        handleErrors(response);
        throw new Error("Error saving purchase order");
      }

      toast.success("Purchase order saved");
      return response.data;
    })
    .catch((error) => {
      handleErrors(error.response);
      throw new Error("Error saving purchase order");
    });
};

export const exportPurchaseOrder = (purchaseOrder, params) =>
  api
    .get(`${baseURL}${purchaseOrder.id}/export/`, { params })
    .then(({ data }) => data?.id);

export const markAsSignedPurchaseOrder = (purchaseOrder, formData) =>
  api
    .put(`${baseURL}${purchaseOrder.id}/mark_as_signed/`, formData, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((response) => response.data);

export const unmarkAsSignedPurchaseOrder = (purchaseOrder) =>
  api
    .put(`${baseURL}${purchaseOrder.id}/unmark_as_signed/`)
    .then((response) => response.data);
