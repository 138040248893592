import { type RefObject, useEffect, useRef } from "react";

import { is } from "../../utils/is";
import { isElement } from "../../utils/is-element";
import { isWindow } from "../../utils/is-window";
import { useDeepMemo } from "../use-deep-memo";
import { useEvent } from "../use-event";

type UseResizeObserverOptions = ResizeObserverOptions & {
  observe?: "width" | "height";
};

export function useResizeObserver(
  ref: RefObject<HTMLElement | null>,
  onChange: (el: HTMLElement) => void,
  options?: UseResizeObserverOptions
): void;

export function useResizeObserver(
  ref: RefObject<Window | null>,
  onChange: (el: Window) => void,
  options?: UseResizeObserverOptions
): void;

export function useResizeObserver<T extends HTMLElement | Window | null>(
  ref: RefObject<T>,
  onChange: (el: T) => void,
  options?: UseResizeObserverOptions
) {
  const prevWidth = useRef(0);
  const prevHeight = useRef(0);

  const memoizedOptions = useDeepMemo(() => options, [options]);

  const enhancedOnChange = useEvent(
    (entriesOrEvent: ResizeObserverEntry[] | UIEvent) => {
      let width = 0;
      let height = 0;

      const el = ref.current;

      if (!el) return;

      if (is.array(entriesOrEvent)) {
        const [entry] = entriesOrEvent;

        const { width, height } = entry.contentRect;

        if (isElement(el)) {
          onChange(el);
          prevWidth.current = width;
          prevHeight.current = height;
        }

        return;
      } else {
        width = window.innerWidth;
        height = window.innerHeight;
      }

      const hasChangedWidth =
        memoizedOptions?.observe === "width" && width !== prevWidth.current;

      const hasChangedHeight =
        memoizedOptions?.observe === "height" && height !== prevHeight.current;

      if (
        el !== null &&
        (!memoizedOptions?.observe || hasChangedWidth || hasChangedHeight)
      ) {
        onChange(el);
        prevWidth.current = width;
        prevHeight.current = height;
      }
    }
  );

  useEffect(() => {
    const el = ref.current;

    if (!el) return;

    if (isWindow(el)) {
      window.addEventListener("resize", enhancedOnChange);

      return () => {
        window.removeEventListener("resize", enhancedOnChange);
      };
    }

    const observer = new ResizeObserver(enhancedOnChange);
    observer.observe(el as HTMLElement, memoizedOptions);

    return () => {
      observer.disconnect();
    };
  }, [ref, enhancedOnChange, memoizedOptions]);
}
