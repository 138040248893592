import React, {
  type ComponentProps,
  type ReactNode,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEvent, useMultiStepDialog } from "@adaptive/design-system/hooks";
import { handleErrors } from "@src/shared/api/handle-errors";

import { useGetLinkedTransactionsMutation } from "../api/api";
import type { GetLinkedTransactionsReturn } from "../api/types";
import { PropagationDialog } from "../components/propagation-dialog";
import type {
  PropagationDetails,
  PropagationObject,
  SaveCallbackWithPropagation,
  SaveWithPropagationParams,
} from "../types";
import { getStepName } from "../utils/get-step-name";
import { trackPropagationAnalytics } from "../utils/track-propagations-analytics";

type UsePropagationDialogReturn = {
  propagationDialog: ReactNode;
  saveWithPropagation: (
    params: SaveWithPropagationParams,
    ...args: unknown[]
  ) => Promise<PropagationObject | void>;
};

type UsePropagationDialog = (
  saveCallback?: SaveCallbackWithPropagation
) => UsePropagationDialogReturn;

const getOnlyInvoiceData = (transactions: GetLinkedTransactionsReturn) =>
  transactions
    .map((transaction) => ({
      ...transaction,
      links: transaction.links.filter((item) => item.type.includes("invoice")),
    }))
    .filter((transaction) => !!transaction.links.length);

export const usePropagationDialog: UsePropagationDialog = (saveCallback) => {
  /** Original form args to be used on final save */
  const [formArgs, setFormArgs] = useState<unknown[]>([]);
  const [transactionValues, setTransactionValues] = useState<
    PropagationDetails | undefined
  >();
  const [isForcedPropagation, setIsForcedPropagation] = useState(false);
  const dialog = useMultiStepDialog({ lazy: true, initialStep: "" });
  const rejectPromise = useRef<(reason?: any) => void>(() => {});
  const resolvePromise = useRef<(value?: any) => void>(() => {});

  const [getLinkedTransactions, { isLoading, data: linkedTransactionsData }] =
    useGetLinkedTransactionsMutation();

  const linkedTransactions = useMemo(() => {
    return linkedTransactionsData &&
      (transactionValues?.fields?.archived ||
        transactionValues?.fields?.reverted)
      ? getOnlyInvoiceData(linkedTransactionsData)
      : linkedTransactionsData;
  }, [
    linkedTransactionsData,
    transactionValues?.fields?.archived,
    transactionValues?.fields?.reverted,
  ]);

  const saveWithPropagation = useEvent<
    UsePropagationDialogReturn["saveWithPropagation"]
  >(async ({ values, forced, ...payload }, ...saveArgs) => {
    const promise = new Promise<void>((resolve, reject) => {
      rejectPromise.current = reject;
      resolvePromise.current = resolve;
    });

    if (!payload.updatedFields?.length && !payload.lines?.length) {
      await saveCallback?.(undefined, ...saveArgs);
      resolvePromise.current();
      return promise;
    }

    try {
      const result = await getLinkedTransactions(payload).unwrap();

      const filteredResult = result.filter(
        (transaction) => !!transaction.links.length
      );
      const transactions =
        values?.fields?.archived || values?.fields?.reverted
          ? getOnlyInvoiceData(filteredResult)
          : filteredResult;

      if (transactions.length) {
        setTransactionValues(values);
        setFormArgs(saveArgs);
        setIsForcedPropagation(!!forced);

        const firstItem = transactions[0];
        const firstLink = firstItem.links[0];

        trackPropagationAnalytics({
          event: "open",
          propagationDetails: values,
          linkedTransactions: transactions,
        });

        dialog.show();
        dialog.setStep(getStepName(firstItem, firstLink));
      } else {
        await saveCallback?.(undefined, ...saveArgs);
        resolvePromise.current();
      }
    } catch (error) {
      handleErrors(error);
      rejectPromise.current();
    }

    return promise;
  });

  const onDialogClose = useEvent(() => {
    setTransactionValues(undefined);
    setFormArgs([]);
    rejectPromise.current();
  });

  const onSave = useEvent(async (propagation: PropagationObject) => {
    trackPropagationAnalytics({
      event: "save",
      propagationDetails: transactionValues,
      linkedTransactions,
      propagate: propagation.propagate,
    });

    setFormArgs([]);
    setTransactionValues(undefined);
    await Promise.all([
      dialog.hide(),
      saveCallback?.(propagation, ...formArgs),
    ]);
    resolvePromise.current(propagation);
  });

  const componentProps = useMemo<ComponentProps<typeof PropagationDialog>>(
    () => ({
      linkedTransactions,
      transactionValues,
      dialog,
      onDialogClose,
      onSave,
      loading: isLoading,
      isForcedPropagation,
    }),
    [
      dialog,
      isLoading,
      linkedTransactions,
      onDialogClose,
      onSave,
      transactionValues,
      isForcedPropagation,
    ]
  );

  const values = useMemo(
    () => ({
      propagationDialog: <PropagationDialog {...componentProps} />,
      saveWithPropagation,
    }),
    [componentProps, saveWithPropagation]
  );

  return values;
};
