import React, {
  type ComponentProps,
  memo,
  useEffect,
  useId,
  useState,
} from "react";
import { Link, NavLink, useLocation } from "react-router";
import { Flex, Icon } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { suffixify } from "@adaptive/design-system/utils";

import styles from "./shell.module.css";

const clearUrl = (url: string) => url.split(/[?#]/)[0];

export type ShellSidebarLinkLink<Extra = Record<string, unknown>> = Extra &
  (
    | {
        to: string;
        icon?: ComponentProps<typeof Icon>["name"];
        label: string;
        links?: never;
      }
    | {
        to?: never;
        icon: ComponentProps<typeof Icon>["name"];
        label: string;
        links: ({
          to: string;
          label: string;
        } & Extra)[];
      }
  );

export type ShellSidebarLinkProps = ShellSidebarLinkLink & {
  collapsed?: boolean;
};

export const ShellSidebarLink = memo(
  ({ to, icon, label, links, collapsed }: ShellSidebarLinkProps) => {
    const id = useId();

    const [isExpanded, setIsExpanded] = useState(false);

    const location = useLocation();

    const isActive =
      (to && location.pathname.startsWith(clearUrl(to))) ||
      links?.some(({ to }) => location.pathname.startsWith(clearUrl(to)));

    const hasLinks = links && links.length > 0;

    const enhancedIsExpanded = window.IS_E2E || (isExpanded && !collapsed);

    const toggle = useEvent(() => {
      setIsExpanded((previousIsExpanded) => !previousIsExpanded);
    });

    useEffect(() => {
      if (collapsed) setIsExpanded(false);
    }, [collapsed]);

    if (hasLinks) {
      return (
        <>
          <button
            id={suffixify(id, "trigger")}
            type="button"
            onClick={toggle}
            aria-current={isActive && !enhancedIsExpanded ? "page" : undefined}
            aria-expanded={enhancedIsExpanded}
            aria-controls={suffixify(id, "panel")}
          >
            {icon ? <Icon name={icon} /> : <Flex width="20px" />}
            {!collapsed && (
              <Flex grow justify="space-between" align="center">
                {label}
                <Icon
                  name={enhancedIsExpanded ? "chevron-up" : "chevron-down"}
                />
              </Flex>
            )}
          </button>
          <Flex
            id={suffixify(id, "panel")}
            shrink={false}
            direction="column"
            className={styles["shell-sidebar-link-group"]}
            aria-hidden={!enhancedIsExpanded}
            aria-labelledby={suffixify(id, "trigger")}
          >
            {links.map(({ to, label }) => (
              <NavLink
                to={to}
                key={to}
                className={styles["shell-sidebar-link-sub-link"]}
              >
                <Flex width="20px" />
                {label}
              </NavLink>
            ))}
          </Flex>
        </>
      );
    }

    return (
      <Link to={to!} aria-current={isActive ? "page" : undefined}>
        {icon ? <Icon name={icon} /> : <Flex width="20px" />}
        {!collapsed && label}
      </Link>
    );
  }
);

ShellSidebarLink.displayName = "ShellSidebarLink";
