import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  dialog,
  Flex,
  Link,
  PasswordField,
  Prose,
  Text,
} from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import { dotObject, is } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { ExternalLayout } from "@components/external-layout";
import { MainContent } from "@components/main";
import { documentsRequestsApi } from "@vendors/api";
import { z } from "zod";

import { RequestFormExpired, RequestFormSubmitted } from "./common";
import { STRINGS } from "./constants";
import type { FormProps } from "./types";
import { VendorRequest } from "./vendor-request";

const schema = z
  .object({
    accountNumber: z.string().min(4, STRINGS.INVALID_ACCOUNT_NUMBER_ERROR),
    routingNumber: z.string().length(9, STRINGS.INVALID_ROUTING_NUMBER_ERROR),
    confirm: z.string().min(4, STRINGS.CONFIRM_ACCOUNT_NUMBER_ERROR),
  })
  .refine((values) => values.accountNumber === values.confirm, {
    message: STRINGS.ACCOUNT_NUMBERS_DONT_MATCH_ERROR,
    path: ["confirm"],
  });

type Fields = z.infer<typeof schema>;

const initialValues: Fields = {
  accountNumber: "",
  routingNumber: "",
  confirm: "",
};

export const VendorACHRequest = () => {
  return <VendorRequest type="ACH" form={ACHRequestForm} />;
};

export const ACHRequestForm = ({
  vendor,
  companyName,
  queryParams,
}: FormProps) => {
  const [errors, setErrors] = useState<Record<keyof Fields, string>>({
    confirm: "",
    accountNumber: "",
    routingNumber: "",
  });

  const [requestExpired, setRequestExpired] = useState<boolean>(false);

  const [isFormSubmitted, setFormSubmitted] = useState<boolean>(false);

  const { register, ...form } = useForm<Fields>({
    schema,
    async onSubmit({ accountNumber, routingNumber }) {
      const handler = async () => {
        try {
          await documentsRequestsApi.postVendorPublicAchRequest(queryParams, {
            account_number: accountNumber,
            routing_number: routingNumber,
            vendor: vendor.url,
          });
          setFormSubmitted(true);
        } catch (e) {
          if (is.object(e)) {
            const data = dotObject.get(e, "response.data", {});
            let handled = false;

            if (data.account_number) {
              handled = true;
              setErrors((errors) => ({
                ...errors,
                accountNumber: data.account_number,
              }));
            }

            if (data.routing_number) {
              handled = true;
              setErrors((errors) => ({
                ...errors,
                routingNumber: data.routing_number,
              }));
            }

            if (data.non_field_errors) {
              data.non_field_errors.map((error: string) => {
                if (error === STRINGS.REQUEST_EXPIRED) {
                  setRequestExpired(true);
                }
              });
            }

            if (handled) return;
          }

          handleErrors(e);
        }
      };

      dialog.confirmation({
        title: STRINGS.ACH_CONFIRMATION_DIALOG_TITLE,
        message: STRINGS.ACH_CONFIRMATION_DIALOG_MESSAGE,
        action: { primary: { children: "Confirm", onClick: handler } },
      });
    },
    initialValues,
  });

  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.length > 0),
    [errors]
  );

  const routingNumberRegister = useMemo(() => {
    const enhancedRegister = register("routingNumber");

    if (errors.routingNumber) {
      enhancedRegister.errorMessage = errors.routingNumber;
    }

    return enhancedRegister;
  }, [register, errors.routingNumber]);

  const confirmRegister = useMemo(() => {
    const enhancedRegister = register("confirm");

    if (errors.confirm) {
      enhancedRegister.errorMessage = errors.confirm;
    }

    return enhancedRegister;
  }, [register, errors.confirm]);

  const accountNumberRegister = useMemo(() => {
    const enhancedRegister = register("accountNumber");

    if (errors.accountNumber) {
      enhancedRegister.errorMessage = errors.accountNumber;
    }

    return enhancedRegister;
  }, [register, errors.accountNumber]);

  useEffect(() => {
    setErrors({
      confirm: "",
      accountNumber: "",
      routingNumber: "",
    });
  }, [form.values]);

  return requestExpired ? (
    <RequestFormExpired companyName={companyName} />
  ) : isFormSubmitted ? (
    <RequestFormSubmitted>
      <Flex
        padding={["48px", "none", "xl"]}
        gap={{
          mobile: "none",
          tablet: "sm",
        }}
        width="full"
        maxWidth={{
          mobile: "full",
          tablet: "full",
        }}
        align="center"
        direction="column"
      >
        <Text size="3xl" as="h1" family="brand" align="center">
          {STRINGS.ACH_SUBMITTED_TITLE}
        </Text>
      </Flex>
      <Flex align="center" direction="column">
        <Text size="md" align="center">
          Thank you for providing your bank details. <br /> {companyName} has
          all the information they need to pay you now.
        </Text>
      </Flex>
    </RequestFormSubmitted>
  ) : (
    <ExternalLayout>
      <Flex
        padding={{
          mobile: ["xl", "sm", "xl"],
          tablet: ["3xl", "none", "xl"],
        }}
        gap={{
          mobile: "none",
          tablet: "sm",
        }}
        width="full"
        align="center"
        direction="column"
      >
        <Text
          size={{
            mobile: "2xl",
            tablet: "3xl",
          }}
          as="h1"
          family="brand"
          align="center"
        >
          {companyName} wants to pay you <br /> via direct deposit
        </Text>
      </Flex>
      <Flex align="center" direction="column">
        <Text size="md" align="center">
          Provide your bank information below to receive payment directly in
          your account.
        </Text>
        <Text size="md" align="center">
          Have a question? Contact{" "}
          <Link
            size="md"
            href="mailto:support@adaptive.build"
            variant="success"
          >
            support@adaptive.build
          </Link>
        </Text>
      </Flex>
      <MainContent
        as="div"
        variant={{ mobile: "spaced-sm", tablet: "spaced-md" }}
      >
        <Flex
          as="form"
          {...form.props}
          gap={{
            mobile: "3xl",
            tablet: "4xl",
          }}
          width="full"
          align="center"
          direction="column"
        >
          <Flex
            width="full"
            maxWidth={{
              mobile: "full",
              tablet: "920px",
            }}
            direction={{ mobile: "column-reverse", tablet: "row" }}
            gap={{
              mobile: "3xl",
              tablet: "2xl",
            }}
            padding={["2xl", "none", "none"]}
          >
            <Flex
              direction="column"
              width="full"
              maxWidth={{
                mobile: "full",
                tablet: "380px",
              }}
              justify="space-between"
              margin={["-24px", "none", "none"]}
            >
              <Flex width="full" align="flex-start">
                <PasswordField
                  label={STRINGS.LABEL_ROUTING_NUMBER}
                  required
                  autoFocus
                  {...routingNumberRegister}
                />
              </Flex>
              <Flex width="full" align="center">
                <PasswordField
                  label={STRINGS.LABEL_ACCOUNT_NUMBER}
                  required
                  {...accountNumberRegister}
                />
              </Flex>
              <Flex width="full" align="flex-end">
                <PasswordField
                  label={STRINGS.LABEL_CONFIRM_ACCOUNT_NUMBER}
                  required
                  {...confirmRegister}
                  messageVariant="absolute"
                />
              </Flex>
            </Flex>
            <Card
              as={Flex}
              maxWidth={{
                mobile: "full",
                tablet: "500px",
              }}
              width="full"
              variant="solid"
              color="neutral"
              size="sm"
            >
              <Prose>
                <strong>Why direct deposit?</strong>
                <ul>
                  <li>
                    <strong>Speed: </strong>
                    Payments arrive faster than mailed checks.
                  </li>
                  <li>
                    <strong>Security: </strong>
                    Your payment is delivered directly to your account, reducing
                    the risk of loss or fraud.
                  </li>
                  <li>
                    <strong>Convenience: </strong>
                    No need to visit the bank or wait for checks to clear.
                  </li>
                </ul>
                <br />
                <strong>How we protect your information</strong>
                <br />
                <p>
                  We take your privacy and security seriously. Your bank details
                  are encrypted and only the last 4 digits of your account
                  number will be shared with {companyName}.
                </p>
              </Prose>
            </Card>
          </Flex>

          <Flex
            maxWidth={{
              mobile: "full",
              tablet: "600px",
            }}
            width="full"
          >
            <Button
              block
              type="submit"
              data-testid="reset-password"
              disabled={form.isSubmitting || !form.isValid || hasErrors}
            >
              Submit bank information
            </Button>
          </Flex>
        </Flex>
      </MainContent>
    </ExternalLayout>
  );
};
