import React, {
  type JSX,
  memo,
  type PropsWithChildren,
  type ReactNode,
} from "react";

type Props = PropsWithChildren<{
  when: boolean;
  render: (children: ReactNode) => JSX.Element;
}>;

const Wrapper = ({ when, render, children }: Props) =>
  when ? render(children) : <>{children}</>;

Wrapper.displayName = "Wrapper";

const MemoizedWrapper = memo(Wrapper);

export { MemoizedWrapper as Wrapper };
