import React from "react";
import {
  type TableColumn,
  TagGroup,
  Text,
  VisuallyHidden,
} from "@adaptive/design-system";
import {
  formatCurrency,
  formatDate,
  parseDate,
  suffixify,
} from "@adaptive/design-system/utils";
import { CostCodeAccountInfo } from "@components/cost-code-account-info";
import type { ImageType } from "@components/draggable";
import { Thumbnail } from "@components/thumbnail";
import {
  approverStatusColor,
  approverStatusHiddenIcon,
  flattenedApprovers,
} from "@shared/utils/usefulFunctions";
import { type BillPaymentV2 } from "@src/shared/api/bills";

import type { Bill, Status } from "../types";
import { getLienWaiverTooltip, isLoadingBillOcr, renderStatus } from "../utils";

export const IMAGE_FORMATS: ImageType[] = ["png", "jpg"];

export const LIST_IMAGE_FORMATS: ImageType[] = [
  ...IMAGE_FORMATS,
  "heic",
  "heif",
];

export const DROPZONE_MESSAGES = {
  IDLE: "Upload or drag and drop a file",
  PENDING: "We are processing your file",
  DRAGGING: "Drop multiple files here to bulk upload them!",
};

export const BILL_STATUS = {
  ALL: "ALL", // only used internally
  PAID: "PAID",
  DRAFT: "DRAFT",
  APPROVAL: "APPROVAL",
  FOR_PAYMENT: "FOR_PAYMENT",
  PARTIALLY_PAID: "PARTIALLY_PAID",
  ACH_PROCESSING: "ACH_PROCESSING",
  ACH_INFO_REQUESTED: "ACH_INFO_REQUESTED",
  PAYMENT_FAILED: "PAYMENT_FAILED",
} as const;

export const UNSYNCED_QUICKBOOKS_STATUS: (typeof BILL_STATUS)[keyof typeof BILL_STATUS][] =
  [BILL_STATUS.DRAFT, BILL_STATUS.APPROVAL];

export const EDITABLE_STATUS: (typeof BILL_STATUS)[keyof typeof BILL_STATUS][] =
  [BILL_STATUS.APPROVAL, BILL_STATUS.FOR_PAYMENT];

export const TAB_STATUS = {
  ALL: "all",
  DRAFT: "draft",
  APPROVAL: "approval",
  FOR_PAYMENT: "for-payment",
} as const;

export const BILL_STATUS_COLOR = {
  PAID: "success",
  APPROVAL: "info",
  FOR_PAYMENT: "info",
  PAYMENT_FAILED: "error",
  PARTIALLY_PAID: "warning",
  ACH_PROCESSING: "warning",
  ACH_INFO_REQUESTED: "warning",
};

export const LIEN_WAIVER_STATUS = {
  NOT_SELECTED: "NOT_SELECTED",
  NOT_REQUIRED: "NOT_REQUIRED",
  NOT_REQUESTED: "NOT_REQUESTED",
  REQUESTED: "REQUESTED",
  MARKED_AS_REQUESTED: "MARKED_AS_REQUESTED",
  SIGNED: "SIGNED",
  MARKED_AS_SIGNED: "MARKED_AS_SIGNED",
} as const;

export const LIEN_WAIVER_REQUESTED_STATUS = [
  LIEN_WAIVER_STATUS.REQUESTED,
  LIEN_WAIVER_STATUS.MARKED_AS_REQUESTED,
] as string[];

export const LIEN_WAIVER_SIGNED_STATUS = [
  LIEN_WAIVER_STATUS.SIGNED,
  LIEN_WAIVER_STATUS.MARKED_AS_SIGNED,
] as string[];

export const LIEN_WAIVER_STATUS_COLOR = {
  NOT_SELECTED: "neutral",
  NOT_REQUIRED: "neutral",
  NOT_REQUESTED: "neutral",
  REQUESTED: "info",
  MARKED_AS_REQUESTED: "info",
  SIGNED: "success",
  MARKED_AS_SIGNED: "success",
} as const;

export const APPROVER_STATUS_COLOR = {
  PENDING: "neutral",
  REJECTED: "error",
  APPROVED: "success",
};

export const APPROVER_STATUS_HIDDEN_ICON = {
  PENDING: "•\u205F",
  APPROVED: "✓",
  REJECTED: "✖",
};

export const HUMAN_READABLE_BILL_STATUS = {
  PAID: "Paid",
  DRAFT: "Draft",
  APPROVAL: "For approval",
  FOR_PAYMENT: "For payment",
  PARTIALLY_PAID: "Partially paid",
  ACH_PROCESSING: "ACH processing",
  ACH_INFO_REQUESTED: "ACH info requested",
  PAYMENT_FAILED: "Payment failed",
};

export const HUMAN_READABLE_PAYMENT_METHOD = {
  ACH: "ACH",
  "mail-check": "Mail check",
  "print-check": "Print check",
  "virtual-email-check": "Virtual email check",
  "virtual-sms-check": "Virtual SMS check",
  "mark-as-paid": "Mark as paid",
};

export const CHECK_STATUS = {
  PAID: "PAID",
  FAILED: "FAILED",
} as const;

export const PAYMENT_METHOD = {
  MARK_AS_PAID: "mark-as-paid",
  ACH: "ACH",
  PRINT_CHECK: "print-check",
  MAIL_CHECK: "mail-check",
  VIRTUAL_EMAIL_CHECK: "virtual-email-check",
  VIRTUAL_SMS_CHECK: "virtual-sms-check",
} as const;

export const PAYMENT_STATUS = {
  PAID: "PAID",
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
  FAILED: "FAILED",
  ACH_REQUESTED: "ACH_REQUESTED",
  PENDING_PREREQUISITES: "PENDING_PREREQUISITES",
} as const;

export const PAYMENT_STATUS_VARIANT = {
  PAID: "success",
  PENDING: "info",
  FAILED: "error",
  CANCELLED: "warning",
  ACH_REQUESTED: "info",
  PENDING_PREREQUISITES: "info",
} as const;

export const PAYMENT_STATUS_VARIANT_V2 = {
  PAID: {
    color: "neutral-800",
    icon: "check-circle",
    iconColor: "success-200",
    background: "success-100",
  },
  SCHEDULED: {
    color: "neutral-800",
    icon: "clock",
    iconColor: "neutral-900",
    background: "neutral-300",
  },
  PENDING: {
    color: "neutral-800",
    icon: "refresh",
    iconColor: "info-200",
    background: "info-100",
  },
  FAILED: {
    color: "neutral-800",
    icon: "exclamation-triangle",
    iconColor: "error-200",
    background: "error-100",
  },
  CANCELLED: {
    color: "neutral-800",
    icon: "circle-x",
    iconColor: "error-200",
    background: "error-100",
  },
  ACH_REQUESTED: {
    color: "neutral-800",
    icon: "refresh",
    iconColor: "info-200",
    background: "info-100",
  },
  PENDING_PREREQUISITES: {
    color: "neutral-800",
    icon: "refresh",
    iconColor: "info-200",
    background: "info-100",
  },
} as const;

export const HUMAN_READABLE_PAYMENT_STATUS = {
  PAID: "made",
  PENDING: "pending",
  FAILED: "failed",
  CANCELLED: "cancelled",
  ACH_REQUESTED: "awaiting ACH information",
  PENDING_PREREQUISITES: "awaiting ACH information",
} as const;

export const GET_HUMAN_READABLE_PAYMENT_STATUS = (
  status: string,
  processPrerequisites: BillPaymentV2["processPrerequisites"]
) => {
  if (status === "PENDING_PREREQUISITES") {
    const hasLienWaiverSignature = processPrerequisites?.some(
      (prerequisite) => prerequisite?.type === "lien_waiver_signature"
    );
    if (hasLienWaiverSignature) {
      return "awaiting for lien waiver signature";
    }
    return "awaiting ACH information";
  }
  return HUMAN_READABLE_PAYMENT_STATUS[
    status as keyof typeof HUMAN_READABLE_PAYMENT_STATUS
  ];
};

export const ARCHIVED_VALID_STATUS: (typeof BILL_STATUS)[keyof typeof BILL_STATUS][] =
  [BILL_STATUS.DRAFT, BILL_STATUS.APPROVAL, BILL_STATUS.FOR_PAYMENT];

export const STATUS_FILTER = [
  { label: "Draft", value: "DRAFT", groupLabel: "Status" },
  { label: "For approval", value: "APPROVAL", groupLabel: "Status" },
  { label: "For payment", value: "FOR_PAYMENT", groupLabel: "Status" },
  {
    label: "Payment processing",
    value: "ACH_PROCESSING",
    groupLabel: "Status",
  },
  { label: "Payment failed", value: "PAYMENT_FAILED", groupLabel: "Status" },
  { label: "Rejected", value: "rejected", groupLabel: "Status" },
  { label: "Paid", value: "PAID", groupLabel: "Status" },
  { label: "Archived", value: "archived", groupLabel: "Status" },
  { label: "Sync errors", value: "errors", groupLabel: "Status" },
  {
    label: "Ignored sync errors",
    value: "ignored_errors",
    groupLabel: "Status",
  },
];

export const PAYMENT_STATUS_FILTER = [
  { label: "For payment", value: "FOR_PAYMENT", groupLabel: "Status" },
  { label: "Partially Paid", value: "PARTIALLY_PAID", groupLabel: "Status" },
];

export const DRAW_STATUS_FILTER = [
  {
    label: "Linked to a paid draw",
    value: "LINKED_AND_PAID",
    groupLabel: "Draw status",
  },
  {
    label: "Linked to an unpaid draw",
    value: "LINKED_AND_UNPAID",
    groupLabel: "Draw status",
  },
  {
    label: "Not linked to a draw",
    value: "UNLINKED",
    groupLabel: "Draw status",
  },
];

export const BILLABLE_STATUS_FILTER = [
  {
    label: "Has been billed",
    value: "HasBeenBilled",
    groupLabel: "Billable status",
  },
  { label: "Billable", value: "Billable", groupLabel: "Billable status" },
  {
    label: "Not billable",
    value: "NotBillable",
    groupLabel: "Billable status",
  },
];

export const LIEN_WAIVER_STATUS_FILTER = [
  {
    label: "Request not sent",
    value: "NOT_REQUESTED",
    groupLabel: "Lien waiver status",
  },
  {
    label: "Request sent",
    value: "REQUESTED",
    groupLabel: "Lien waiver status",
  },
  {
    label: "Signed",
    value: "SIGNED",
    groupLabel: "Lien waiver status",
  },
  {
    label: "Lien waiver not required",
    value: "NOT_REQUIRED",
    groupLabel: "Lien waiver status",
  },
  //TODO: Add filter for ADDED_TO_DRAW and NOT_ADDED_TO_DRAW [BOB-7582]
  // {
  //   label: "Not added to a draw",
  //   value: "NOT_ADDED_TO_DRAW",
  //   groupLabel: "Lien waiver status",
  // },
  // {
  //   label: "Added to a draw",
  //   value: "ADDED_TO_DRAW",
  //   groupLabel: "Lien waiver status",
  // },
];

export const LIEN_WAIVER_TEMPLATE_TYPE_FILTER = [
  {
    label: "Conditional Progress",
    value: "Conditional Progress",
    groupLabel: "Lien waiver type",
  },
  {
    label: "Conditional Final",
    value: "Conditional Final",
    groupLabel: "Lien waiver type",
  },
  {
    label: "Unconditional Progress",
    value: "Unconditional Progress",
    groupLabel: "Lien waiver type",
  },
  {
    label: "Unconditional Final",
    value: "Unconditional Final",
    groupLabel: "Lien waiver type",
  },
];

export const DRAFT_STATUS_FILTER = [
  { label: "Draft", value: "not_rejected", groupLabel: "Status" },
  { label: "Rejected", value: "rejected", groupLabel: "Status" },
];

export const ACCOUNT_TYPE = {
  depository: "Checking Account",
  loan: "Loan Account",
  credit: "Credit Account",
  investment: "Savings Account",
  other: "Other Account",
};

export const PAY_BUTTON_LABEL = {
  ACH_PAYMENT_REQUESTED: "Request ACH & Pay",
  MARK_AS_PAID: "Mark as paid",
  DEFAULT: "Pay now",
  REVIEW_AND_PAY: "Review & pay",
};

export const STRINGS = {
  ACTION_NOT_ALLOWED: "Sorry, you don't have permission to perform this action",
  ACTION_TEMPORARILY_DISABLED: "You can send one request a day",
  ACTION_NOT_AVAILABLE: "Save the vendor before requesting ACH details",
  ACH_INFO_REQUESTED_BILL_ALERT_CONTENT:
    "Adaptive will process the payment as soon as your vendor provides their ACH information",
  PARTIAL_PAYMENTS_REMAINING_BALANCE_MESSAGE:
    "Go to QuickBooks to pay the remaining balance",
  BUTTON_TOO_MANY_LINES: "This bill has too many lines",
  PENDING_ACH_REQUEST_ALERT_CONTENT:
    "Click `Request ACH and Pay` and we will make the payment as soon as the ACH details are provided",
  SALES_TAX_INFO:
    "Sales tax will be allocated proportionally to each line item in QuickBooks",
  PAYMENT_AMOUNT_T0_HIGH: "Payment amount cannot be larger than the bill total",
  PAYMENT_AMOUNT_DISABLED_LIEN_WAIVER:
    "Payment amount not editable when linked to a lien waiver",
  OPEN_VENDOR_PROFILE_LABEL: "Open vendor profile",
};

export const DATE_AFTER_FILTER_NAME = "date_after";
export const DATE_BEFORE_FILTER_NAME = "date_before";

export const ACH_PROCESSING_BILL_ALERT_CONTENT = (achProcessingTime: string) =>
  `Payment processing - expected delivery is ${achProcessingTime} after sending`;

export const getLienWaiverColumn = (tabName: string) =>
  ({
    id: "lien_waivers",
    name: "Lien waiver",
    width: 200,
    render: (row) => (
      <TagGroup
        data={row.lien_waivers}
        limit={1}
        render={(item) => ({
          hidden: getLienWaiverTooltip(item),
          visible: item.status_label,
        })}
        color={(item) => LIEN_WAIVER_STATUS_COLOR[item.status] || "neutral"}
        data-testid={suffixify(tabName, "lien-waiver")}
      />
    ),
  }) as TableColumn<Bill>;

export const ALL_COLUMNS: TableColumn<Bill>[] = [
  {
    id: "attachment",
    name: "Bill",
    width: 80,
    visibility: {
      mode: "always-visible",
      name: "Attachment",
    },
    render: (row) => (
      <>
        <Thumbnail
          alt={`Transaction${row.doc_number ? ` #${row.doc_number}` : ""}`}
          src={row.attachables?.[0]?.thumbnail || undefined}
        />
        {isLoadingBillOcr(row) && (
          <VisuallyHidden
            data-testid={suffixify("all-bills-table", "processing")}
          >
            Processing...
          </VisuallyHidden>
        )}
        <VisuallyHidden data-testid={suffixify("all-bills-table", "number")}>
          {row.doc_number}
        </VisuallyHidden>
      </>
    ),
  },
  {
    id: "vendor__display_name",
    sortable: "asc",
    width: "fill",
    name: "Vendor name",
    minWidth: 200,
    visibility: "always-visible",
    render: (row) =>
      !isLoadingBillOcr(row) && (
        <Text
          as="span"
          data-testid={suffixify("all-bills-table", "vendor")}
          truncate={2}
        >
          {row.vendor?.display_name}
        </Text>
      ),
  },
  { ...getLienWaiverColumn("all-bills-table") },
  {
    id: "doc_number",
    sortable: true,
    name: "Ref #",
    width: 210,
    render: (row) =>
      !isLoadingBillOcr(row) && row.doc_number && `#${row.doc_number}`,
  },
  {
    id: "total_amount",
    sortable: true,
    width: 150,
    name: "Amount",
    textAlign: "right",
    render: (row) =>
      !isLoadingBillOcr(row) &&
      formatCurrency(row.total_amount || 0, {
        currencySign: true,
        allowNegative: true,
      }),
  },
  {
    id: "openBalance",
    name: "Open balance",
    textAlign: "right",
    width: 140,
    render: (row) =>
      !isLoadingBillOcr(row) && (
        <Text
          align="right"
          data-testid={suffixify("all-bills-table", "balance")}
        >
          {formatCurrency(row.balance || 0, {
            currencySign: true,
            allowNegative: true,
          })}
        </Text>
      ),
  },
  {
    id: "date",
    sortable: true,
    name: "Transaction date",
    width: 175,
    render: (row) =>
      !isLoadingBillOcr(row) && row.date
        ? formatDate(parseDate(row.date, "yyyy-MM-dd"))
        : null,
  },
  {
    id: "job_cost_method_display_name",
    sortable: "asc",
    name: "Cost codes / Accounts",
    width: "fill",
    minWidth: 215,
    render: (row) =>
      !isLoadingBillOcr(row) && (
        <CostCodeAccountInfo
          items={row.items?.map((value) => value.display_name)}
          accounts={row.accounts?.map((value) => value.display_name)}
          data-testid={suffixify("all-bills-table", "cost-codes-accounts")}
        />
      ),
  },
  {
    id: "first_customer",
    sortable: "asc",
    width: "fill",
    name: "Jobs",
    minWidth: 215,
    render: (row) =>
      !isLoadingBillOcr(row) && row.customers?.length > 0 ? (
        <TagGroup
          data={row.customers}
          limit="auto"
          render="display_name"
          data-testid={suffixify("all-bills-table", "jobs-customers")}
        />
      ) : null,
  },
  {
    id: "status",
    name: "Status",
    render: (row) =>
      renderStatus(row, suffixify("all-bills-table", "review-status")),
  },
  /**
   * @todo add amount to pay column
   */
  {
    id: "true_created_at",
    width: 150,
    sortable: true,
    name: "Created date",
    visibility: "hidden",
    render: (row) =>
      !isLoadingBillOcr(row) && row.created_at
        ? formatDate(new Date(row.created_at))
        : null,
  },
  {
    id: "due_date",
    sortable: true,
    visibility: "hidden",
    width: 120,
    name: "Due date",
    render: (row) =>
      !isLoadingBillOcr(row) && row.due_date
        ? formatDate(parseDate(row.due_date, "yyyy-MM-dd"))
        : null,
  },
  /**
   * @todo add draw status and billable status columns
   */
];

export const DRAFT_COLUMNS: TableColumn<Bill>[] = [
  {
    id: "attachment",
    name: "Bill",
    width: 80,
    visibility: {
      mode: "always-visible",
      name: "Attachment",
    },
    render: (row) => (
      <>
        <Thumbnail
          alt={`Transaction${row.doc_number ? ` #${row.doc_number}` : ""}`}
          src={row.attachables?.[0]?.thumbnail || undefined}
        />
        {isLoadingBillOcr(row) && (
          <VisuallyHidden
            data-testid={suffixify("draft-bills-table", "processing")}
          >
            Processing...
          </VisuallyHidden>
        )}
        <VisuallyHidden data-testid={suffixify("draft-bills-table", "number")}>
          {row.doc_number}
        </VisuallyHidden>
      </>
    ),
  },
  {
    id: "vendor__display_name",
    sortable: "asc",
    width: "fill",
    minWidth: 200,
    name: "Vendor name",
    visibility: "always-visible",
    render: (row) =>
      !isLoadingBillOcr(row) && (
        <Text
          truncate={2}
          as="span"
          data-testid={suffixify("draft-bills-table", "vendor")}
        >
          {row.vendor?.display_name}
        </Text>
      ),
  },
  {
    id: "doc_number",
    sortable: true,
    name: "Ref #",
    width: 210,
    render: (row) =>
      !isLoadingBillOcr(row) && row.doc_number && `#${row.doc_number}`,
  },
  {
    id: "total_amount",
    sortable: true,
    name: "Amount",
    width: 150,
    textAlign: "right",
    render: (row) =>
      !isLoadingBillOcr(row) &&
      formatCurrency(row.total_amount || 0, {
        currencySign: true,
        allowNegative: true,
      }),
  },
  {
    id: "true_created_at",
    sortable: true,
    width: 150,
    name: "Created date",
    render: (row) =>
      !isLoadingBillOcr(row) && row.created_at
        ? formatDate(new Date(row.created_at))
        : null,
  },
  {
    id: "job_cost_method_display_name",
    sortable: "asc",
    width: "fill",
    name: "Cost codes / Accounts",
    minWidth: 215,
    render: (row) =>
      !isLoadingBillOcr(row) && (
        <CostCodeAccountInfo
          items={row.items?.map((value) => value.display_name)}
          accounts={row.accounts?.map((value) => value.display_name)}
          data-testid={suffixify("draft-bills-table", "cost-codes-accounts")}
        />
      ),
  },
  {
    id: "first_customer",
    sortable: "asc",
    width: "fill",
    name: "Jobs",
    minWidth: 215,
    render: (row) =>
      !isLoadingBillOcr(row) && row.customers?.length > 0 ? (
        <TagGroup
          data={row.customers}
          limit="auto"
          render="display_name"
          data-testid={suffixify("draft-bills-table", "jobs-customers")}
        />
      ) : null,
  },
  {
    id: "status",
    name: "Status",
    render: (row) =>
      renderStatus(row, suffixify("draft-bills-table", "review-status")),
  },
  {
    id: "date",
    sortable: true,
    width: 175,
    visibility: "hidden",
    name: "Transaction date",
    render: (row) =>
      !isLoadingBillOcr(row) && row.date
        ? formatDate(parseDate(row.date, "yyyy-MM-dd"))
        : null,
  },
  {
    id: "due_date",
    sortable: true,
    name: "Due date",
    visibility: "hidden",
    width: 120,
    render: (row) =>
      !isLoadingBillOcr(row) && row.due_date
        ? formatDate(parseDate(row.due_date, "yyyy-MM-dd"))
        : null,
  },
];

export const APPROVAL_COLUMNS: TableColumn<Bill>[] = [
  {
    id: "attachment",
    name: "Bill",
    width: 80,
    visibility: {
      mode: "always-visible",
      name: "Attachment",
    },
    render: (row) => (
      <>
        <Thumbnail
          alt={`Transaction${row.doc_number ? ` #${row.doc_number}` : ""}`}
          src={row.attachables?.[0]?.thumbnail || undefined}
        />
        {isLoadingBillOcr(row) && (
          <VisuallyHidden
            data-testid={suffixify("approvals-bills-table", "processing")}
          >
            Processing...
          </VisuallyHidden>
        )}
        <VisuallyHidden
          data-testid={suffixify("approvals-bills-table", "number")}
        >
          {row.doc_number}
        </VisuallyHidden>
      </>
    ),
  },
  {
    id: "vendor__display_name",
    sortable: "asc",
    width: "fill",
    minWidth: 200,
    name: "Vendor name",
    visibility: "always-visible",
    render: (row) =>
      !isLoadingBillOcr(row) && (
        <Text
          data-testid={suffixify("approvals-bills-table", "vendor")}
          truncate={2}
          as="span"
        >
          {row.vendor?.display_name}
        </Text>
      ),
  },
  {
    id: "doc_number",
    sortable: true,
    name: "Ref #",
    width: 210,
    render: (row) =>
      !isLoadingBillOcr(row) && row.doc_number && `#${row.doc_number}`,
  },
  {
    id: "total_amount",
    sortable: true,
    name: "Amount",
    width: 150,
    textAlign: "right",
    render: (row) =>
      !isLoadingBillOcr(row) &&
      formatCurrency(row.total_amount || 0, {
        currencySign: true,
        allowNegative: true,
      }),
  },
  {
    id: "true_created_at",
    sortable: true,
    width: 150,
    name: "Created date",
    render: (row) =>
      !isLoadingBillOcr(row) && row.created_at
        ? formatDate(new Date(row.created_at))
        : null,
  },
  {
    id: "job_cost_method_display_name",
    sortable: "asc",
    width: "fill",
    name: "Cost codes / Accounts",
    minWidth: 215,
    render: (row) => (
      <CostCodeAccountInfo
        items={row.items?.map((value) => value.display_name)}
        accounts={row.accounts?.map((value) => value.display_name)}
        data-testid={suffixify("approvals-bills-table", "cost-codes-accounts")}
      />
    ),
  },
  {
    id: "first_customer",
    sortable: "asc",
    width: "fill",
    name: "Jobs",
    minWidth: 215,
    render: (row) =>
      row.customers?.length > 0 ? (
        <TagGroup
          data={row.customers}
          limit="auto"
          render="display_name"
          data-testid={suffixify("approvals-bills-table", "jobs-customers")}
        />
      ) : null,
  },
  {
    id: "amountToPay",
    name: "Payment",
    width: 150,
    textAlign: "right",
    render: (row) => (
      <Text data-testid={suffixify("approvals-bills-table", "amount-to-pay")}>
        {formatCurrency(row.amount_to_pay || 0, {
          currencySign: true,
          allowNegative: true,
        })}
      </Text>
    ),
  },
  {
    id: "approvers",
    width: "fill",
    name: "Approvers",
    minWidth: 215,
    render: (row) => (
      <TagGroup
        data={flattenedApprovers(row.approval_workflows, row.approvals)}
        limit="auto"
        render={(item) => ({
          hidden: `${approverStatusHiddenIcon(item.status)} ${item.name}`,
          visible: item.name,
        })}
        color={(item) => approverStatusColor(item.status)}
        data-testid={suffixify("approvals-bills-table", "approvers")}
      />
    ),
  },
  {
    id: "status",
    visibility: "hidden",
    name: "Status",
    render: (row) =>
      renderStatus(row, suffixify("approvals-bills-table", "review-status")),
  },
  {
    id: "date",
    sortable: true,
    name: "Transaction date",
    visibility: "hidden",
    width: 175,
    render: (row) =>
      !isLoadingBillOcr(row) && row.date
        ? formatDate(parseDate(row.date, "yyyy-MM-dd"))
        : null,
  },
  {
    id: "due_date",
    sortable: true,
    visibility: "hidden",
    name: "Due date",
    width: 120,
    render: (row) =>
      !isLoadingBillOcr(row) && row.due_date
        ? formatDate(parseDate(row.due_date, "yyyy-MM-dd"))
        : null,
  },
];

export const FOR_PAYMENT_COLUMNS: TableColumn<Bill>[] = [
  {
    id: "attachment",
    name: "Bill",
    width: 80,
    visibility: {
      mode: "always-visible",
      name: "Attachment",
    },
    render: (row) => (
      <>
        <Thumbnail
          alt={`Transaction${row.doc_number ? ` #${row.doc_number}` : ""}`}
          src={row.attachables?.[0]?.thumbnail || undefined}
        />
        {isLoadingBillOcr(row) && (
          <VisuallyHidden
            data-testid={suffixify("for-payment-bills-table", "processing")}
          >
            Processing...
          </VisuallyHidden>
        )}
        <VisuallyHidden
          data-testid={suffixify("for-payment-bills-table", "number")}
        >
          {row.doc_number}
        </VisuallyHidden>
      </>
    ),
  },
  {
    id: "vendor__display_name",
    sortable: "asc",
    width: "fill",
    minWidth: 200,
    name: "Vendor name",
    visibility: "always-visible",
    render: (row) =>
      !isLoadingBillOcr(row) && (
        <Text
          data-testid={suffixify("for-payment-bills-table", "vendor")}
          truncate={2}
          as="span"
        >
          {row.vendor?.display_name}
        </Text>
      ),
  },
  { ...getLienWaiverColumn("for-payment-bills-table") },
  {
    id: "doc_number",
    sortable: true,
    name: "Ref #",
    width: 210,
    render: (row) =>
      !isLoadingBillOcr(row) && row.doc_number && `#${row.doc_number}`,
  },
  {
    id: "total_amount",
    sortable: true,
    width: 150,
    name: "Amount",
    textAlign: "right",
    render: (row) =>
      !isLoadingBillOcr(row) &&
      formatCurrency(row.total_amount || 0, {
        currencySign: true,
        allowNegative: true,
      }),
  },
  {
    id: "openBalance",
    name: "Open balance",
    width: 140,
    textAlign: "right",
    render: (row) =>
      !isLoadingBillOcr(row) && (
        <Text
          align="right"
          data-testid={suffixify("for-payment-bills-table", "balance")}
        >
          {formatCurrency(row.balance || 0, {
            currencySign: true,
            allowNegative: true,
          })}
        </Text>
      ),
  },
  {
    id: "amountToPay",
    name: "Amount to pay",
    width: 140,
    textAlign: "right",
    render: (row) => (
      <Text
        align="right"
        data-testid={suffixify("for-payment-bills-table", "amount-to-pay")}
      >
        {formatCurrency(row.amount_to_pay || 0, {
          currencySign: true,
          allowNegative: true,
        })}
      </Text>
    ),
  },
  {
    id: "date",
    sortable: true,
    name: "Transaction date",
    width: 175,
    render: (row) =>
      !isLoadingBillOcr(row) && row.date
        ? formatDate(parseDate(row.date, "yyyy-MM-dd"))
        : null,
  },
  {
    id: "job_cost_method_display_name",
    width: "fill",
    sortable: "asc",
    name: "Cost codes / Accounts",
    minWidth: 215,
    render: (row) => (
      <CostCodeAccountInfo
        items={row.items?.map((value) => value.display_name)}
        accounts={row.accounts?.map((value) => value.display_name)}
        data-testid={suffixify(
          "for-payment-bills-table",
          "cost-codes-accounts"
        )}
      />
    ),
  },
  {
    id: "first_customer",
    sortable: "asc",
    width: "fill",
    name: "Jobs",
    minWidth: 215,
    render: (row) =>
      row.customers.length > 0 ? (
        <TagGroup
          data={row.customers}
          limit="auto"
          render="display_name"
          data-testid={suffixify("for-payment-bills-table", "jobs-customers")}
        />
      ) : null,
  },
  {
    id: "due_date",
    sortable: true,
    name: "Due date",
    width: 120,
    render: (row) =>
      !isLoadingBillOcr(row) && row.due_date
        ? formatDate(parseDate(row.due_date, "yyyy-MM-dd"))
        : null,
  },
  {
    id: "true_created_at",
    width: 150,
    sortable: true,
    visibility: "hidden",
    name: "Created date",
    render: (row) =>
      !isLoadingBillOcr(row) && row.created_at
        ? formatDate(new Date(row.created_at))
        : null,
  },
  /**
   * @todo add draw status and billable status columns
   */
];

export const TAB_STATUS_TO_BILLS_CONST: Record<
  Status,
  { status: keyof typeof TAB_STATUS; columns: TableColumn<Bill>[] }
> = {
  [TAB_STATUS.ALL]: {
    status: BILL_STATUS.ALL,
    columns: ALL_COLUMNS,
  },
  [TAB_STATUS.DRAFT]: {
    status: BILL_STATUS.DRAFT,
    columns: DRAFT_COLUMNS,
  },
  [TAB_STATUS.APPROVAL]: {
    status: BILL_STATUS.APPROVAL,
    columns: APPROVAL_COLUMNS,
  },
  [TAB_STATUS.FOR_PAYMENT]: {
    status: BILL_STATUS.FOR_PAYMENT,
    columns: FOR_PAYMENT_COLUMNS,
  },
};

export const getTransactionType = (isVendorCredit: boolean) =>
  isVendorCredit ? "Vendor Credit" : "Bill";
