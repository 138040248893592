import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatPhone } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { updateUser, type UpdateUserPayload } from "@api/users";
import { partialUpdateUser, userSelector } from "@store/user/slice";

type MutateHandler = (payload: UpdateUserPayload) => Promise<void>;

export const useUserMutation = () => {
  const user = useSelector(userSelector) as unknown as UpdateUserPayload;

  const dispatch = useDispatch();

  const mutate = useCallback<MutateHandler>(
    async (payload) => {
      try {
        await updateUser(payload);

        const updatedUser = { ...payload };

        if (payload.phone_number) {
          updatedUser.phone_number = formatPhone(payload.phone_number);

          if (user.preferred_mfa_method === "SMS") {
            updatedUser.two_factor_verified =
              user.phone_number === payload.phone_number;
            updatedUser.phone_number_verified =
              updatedUser.two_factor_verified!;
          }
        }

        if (payload.email && user.preferred_mfa_method === "EMAIL") {
          updatedUser.two_factor_verified = user.email === payload.email;
          updatedUser.email_verified = updatedUser.two_factor_verified!;
        }

        dispatch(partialUpdateUser(updatedUser));
      } catch (e) {
        handleErrors(e);
        throw e;
      }
    },
    [user, dispatch]
  );

  return { mutate };
};
