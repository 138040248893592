import { api } from "../utils/api";

type GetAccountsProps = {
  realm?: string;
  only_payment_accounts?: boolean;
  exclude_accounts_receivable?: boolean;
  exclude_accounts_payable?: boolean;
  exclude_payment_accounts?: boolean;
  limit?: number;
  signal?: AbortSignal;
};

export type Account = {
  display_name: string;
  parent?: string;
  url: string;
  id: number | string;
};

type GetAccountsResponse = {
  next: string;
  count: number;
  previous: string;
  results: Account[];
};

type GetAccountsSimplifiedResponse = Account[];

export const getAccounts = async ({
  signal,
  ...params
}: GetAccountsProps = {}) =>
  await api
    .get<GetAccountsResponse>("/api/accounts/", {
      params,
      signal,
    })
    .then((response) => response.data);

export const getAccountsSimplified = async ({
  signal,
  ...params
}: GetAccountsProps = {}) =>
  await api
    .get<GetAccountsSimplifiedResponse>("/api/accounts/", {
      params: {
        simplified: true,
        ...params,
      },
      signal,
    })
    .then((response) => response.data);

type CreateAccountProps = {
  signal?: AbortSignal;
  display_name: string;
  nominal_code: string;
  realm: string;
  is_credit_card?: boolean;
  is_bank_account?: boolean;
};

type CreateAccountResponse = {
  id: number;
  url: string;
  parent?: string;
  display_name: string;
  is_credit_card: boolean;
  is_bank_account: boolean;
};

export const createAccount = ({ signal, ...params }: CreateAccountProps) =>
  api
    .post<CreateAccountResponse>("/api/accounts/", {
      signal,
      ...params,
    })
    .then((response) => response.data);
