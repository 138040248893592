import React, { memo, type ReactNode, useLayoutEffect, useRef } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Flex,
  Link,
} from "@adaptive/design-system";
import { useEvent, useMutationObserver } from "@adaptive/design-system/hooks";
import { ErrorAlert } from "@components/common/error-alert";
import { ExpiredDocumentsAlert } from "@components/common/expired-documents-alert";
import { TooManyLinesAlert } from "@components/common/too-many-lines-alert";
import { DuplicateAlert } from "@components/duplicate-alert";
import { RequestedVendorDocumentAlert } from "@components/request-vendor-document";
import { useBillFormPermissions } from "@src/bills/bill-form-context";
import {
  ACH_PROCESSING_BILL_ALERT_CONTENT,
  BILL_STATUS,
  getTransactionType,
  PAYMENT_STEP_FORM_STRINGS,
  RESTORE_TRANSACTION_LINK,
  STRINGS,
  TRANSACTION_NOT_EDITABLE,
} from "@src/bills/constants";
import {
  billIdSelector,
  billIsVendorCreditSelector,
  billLinesCountSelector,
  billReviewStatusSelector,
  duplicatesBillSelector,
  errorsBillSelector,
  isArchivedByUserBillSelector,
  relatedErrorsBillSelector,
  vendorBillSelector,
} from "@src/bills/utils";
import { loadBill, unarchiveCurrentBill } from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useClientSettings } from "@store/user";

import { useCycle } from "../cycle-provider";
import { getAchProcessingOption } from "../pay-bill-step/utils";
import { PurchaseOrdersAlert } from "../purchase-orders";

const BillAlert = memo(() => {
  const cycle = useCycle();

  const dispatch = useAppDispatch();

  const permissions = useBillFormPermissions();

  const reviewStatus = useAppSelector(billReviewStatusSelector);

  const isVendorCredit = useAppSelector(billIsVendorCreditSelector);

  const isArchivedByUser = useAppSelector(isArchivedByUserBillSelector);

  const { achProcessingTime } = useClientSettings();

  const onUnarchive = useEvent(() => {
    cycle.disable();
    dispatch(unarchiveCurrentBill());
  });

  if (!permissions.canAddBill) return;

  if (BILL_STATUS.PAID === reviewStatus) return;

  let content: ReactNode = null;

  const transactionType = getTransactionType(isVendorCredit);

  if (reviewStatus === BILL_STATUS.ACH_PROCESSING) {
    content = ACH_PROCESSING_BILL_ALERT_CONTENT(
      `${getAchProcessingOption(achProcessingTime)?.description}`
    );
  } else if (reviewStatus === BILL_STATUS.ACH_INFO_REQUESTED) {
    content = STRINGS.ACH_INFO_REQUESTED_BILL_ALERT_CONTENT;
  } else if (isArchivedByUser && permissions.canEditBill) {
    content = (
      <>
        <Link as="button" type="button" onClick={onUnarchive}>
          {RESTORE_TRANSACTION_LINK(transactionType)}
        </Link>{" "}
        {PAYMENT_STEP_FORM_STRINGS.RESTORE_TRANSACTION_MESSAGE}
      </>
    );
  }

  return content ? (
    <Alert variant="info">
      <AlertTitle>{TRANSACTION_NOT_EDITABLE(transactionType)}</AlertTitle>
      <AlertContent>{content}</AlertContent>
    </Alert>
  ) : null;
});

BillAlert.displayName = "BillAlert";

const BillDuplicatesAlert = memo(() => {
  const duplicates = useAppSelector(duplicatesBillSelector);

  return duplicates.length > 0 && <DuplicateAlert data={duplicates} />;
});

BillDuplicatesAlert.displayName = "BillDuplicatesAlert";

const BillErrorsAlert = memo(() => {
  const id = useAppSelector(billIdSelector);

  const errors = useAppSelector(errorsBillSelector);

  const dispatch = useAppDispatch();

  const relatedErrors = useAppSelector(relatedErrorsBillSelector);

  const refetch = useEvent(() => dispatch(loadBill(id)));

  return (
    (errors.length > 0 || relatedErrors.length > 0) && (
      <ErrorAlert
        data={errors}
        onChange={refetch}
        objectType="Bill"
        relatedData={relatedErrors}
      />
    )
  );
});

BillErrorsAlert.displayName = "BillErrorsAlert";

const BillTooManyLinesAlert = memo(() => {
  const linesCount = useAppSelector(billLinesCountSelector);

  return (
    <TooManyLinesAlert
      maxLines={window.MAX_LINES_PAGE_SIZE}
      linesCount={linesCount}
      transactionType="Bill"
    />
  );
});

BillTooManyLinesAlert.displayName = "BillTooManyLinesAlert";

const BillExpiredDocumentsAlert = memo(() => {
  const billVendor = useAppSelector(vendorBillSelector);

  const reviewStatus = useAppSelector(billReviewStatusSelector);

  return reviewStatus !== BILL_STATUS.DRAFT ? (
    <ExpiredDocumentsAlert vendor={billVendor} />
  ) : null;
});

BillExpiredDocumentsAlert.displayName = "BillExpiredDocumentsAlert";

const BillRequestedVendorDocumentAlert = memo(() => {
  const billVendor = useAppSelector(vendorBillSelector);

  const reviewStatus = useAppSelector(billReviewStatusSelector);

  return reviewStatus !== BILL_STATUS.DRAFT ? (
    <RequestedVendorDocumentAlert vendor={billVendor} />
  ) : null;
});

BillRequestedVendorDocumentAlert.displayName =
  "BillRequestedVendorDocumentAlert";

export const BillAlerts = memo(() => {
  const ref = useRef<HTMLDivElement>(null);

  /**
   * Workaround to do not show the component if there is no content
   */
  const changeVisibility = useEvent(() => {
    if (!ref.current) return;

    ref.current.style.display = ref.current.innerHTML ? "flex" : "none";
  });

  useMutationObserver(ref, changeVisibility);

  useLayoutEffect(() => {
    changeVisibility();
  }, [changeVisibility]);

  return (
    <Flex ref={ref} gap="xl" direction="column">
      <BillAlert />
      <BillDuplicatesAlert />
      <PurchaseOrdersAlert />
      <BillTooManyLinesAlert />
      <BillErrorsAlert />
      <BillExpiredDocumentsAlert />
      <BillRequestedVendorDocumentAlert />
    </Flex>
  );
});

BillAlerts.displayName = "BillAlerts";
