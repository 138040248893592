import React, {
  type ComponentProps,
  type ComponentRef,
  forwardRef,
  useMemo,
} from "react";
import {
  Flex,
  Icon,
  Link,
  Text,
  Textarea,
  type TextareaAttachmentAddon,
  type TextareaMentionAddon,
} from "@adaptive/design-system";
import { useUsersSimplified } from "@shared/hooks/useUsersSimplified";
import { useClientSettings } from "@store/user";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { useVendorAction } from "@vendors/hooks";

import { STRINGS } from "./constants";

export type CommentRef = ComponentRef<typeof Textarea>;

export type CommentProps = Omit<
  ComponentProps<typeof Textarea>,
  "mention" | "mentionPlacement" | "onSubmit"
> & {
  vendor?: { url: string; email?: string; displayName?: string };
  attachment?: TextareaAttachmentAddon;
  userSuggestions?: boolean;
  extraSuggestions?: TextareaMentionAddon["data"];
};

export const Comment = forwardRef<CommentRef, CommentProps>(
  ({ vendor, placeholder, userSuggestions = true, ...props }, ref) => {
    const users = useUsersSimplified({
      filters: { is_staff: false },
      enabled: userSuggestions,
    });

    const settings = useClientSettings();

    const { showVendorById } = useVendorAction();

    const mention = useMemo<TextareaMentionAddon | undefined>(() => {
      let data: TextareaMentionAddon["data"] = [];
      let onAdd: TextareaMentionAddon["onAdd"];
      let renderSuggestion: TextareaMentionAddon["renderSuggestion"];
      let generateMentionProps: TextareaMentionAddon["generateMentionProps"];

      if (vendor && settings.externalCommentsEnabled) {
        const disabled = !vendor.email;

        onAdd = ({ groupLabel, disabled }) => {
          if (groupLabel === "Vendor" && disabled) {
            const vendorId = parseRefinementIdFromUrl(vendor.url);

            if (vendorId) showVendorById(vendorId);
          }
        };

        data = [
          ...data,
          {
            label: vendor.displayName!,
            value: vendor.url,
            disabled: disabled ? (
              <Flex direction="column" gap="sm">
                <Text align="center">
                  Email required to send a message to the
                  <br />
                  vendor of this transaction. Click the{" "}
                  <Icon name="arrow-up-right-from-square" size="sm" /> icon
                  <br />
                  to open this vendor&apos;s profile.{" "}
                  <Link
                    size="sm"
                    href="https://help.adaptive.build/en/articles/9216708-sending-messages-to-vendors"
                    target="_blank"
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    Learn more
                  </Link>
                </Text>
              </Flex>
            ) : null,
            groupLabel: "Vendor",
            hintMessage: !disabled ? (
              <Flex direction="column" gap="sm">
                <Text align="center">
                  Send a message to the vendor of this transaction. <br />
                  They&apos;ll have access to just this comment thread.{" "}
                  <Link
                    size="sm"
                    href="https://help.adaptive.build/en/articles/9216708-sending-messages-to-vendors"
                    target="_blank"
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    Learn more
                  </Link>
                </Text>
              </Flex>
            ) : null,
          },
        ];

        renderSuggestion = ({ disabled, suggestion }) =>
          disabled ? (
            <Flex gap="xl" align="baseline">
              {suggestion}
              <Icon name="arrow-up-right-from-square" size="sm" />
            </Flex>
          ) : (
            suggestion
          );

        generateMentionProps = ({ value }) => {
          if (String(value).includes("/vendors/")) {
            return {
              color: "var(--color-info-200)",
              background: "var(--color-info-100)",
            };
          }
        };
      }

      if (userSuggestions) {
        data = [
          ...data,
          ...users.data.map((user) => ({
            ...user,
            disabled: !!user.disabled,
          })),
        ];
      }

      if (!data.length) return;

      return {
        data,
        onAdd,
        trigger: "@",
        renderSuggestion,
        generateMentionProps,
      };
    }, [
      vendor,
      users.data,
      showVendorById,
      userSuggestions,
      settings.externalCommentsEnabled,
    ]);

    return (
      <Textarea
        ref={ref}
        mention={mention}
        placeholder={
          placeholder
            ? placeholder
            : vendor && settings.externalCommentsEnabled
              ? STRINGS.PLACEHOLDER_WITH_VENDOR
              : STRINGS.PLACEHOLDER
        }
        {...props}
      />
    );
  }
);

Comment.displayName = "Comment";
