import React, { type ComponentProps, memo } from "react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import styles from "./prose.module.css";

const DEFAULT_COMPONENT = "span";

type Props = ComponentProps<typeof DEFAULT_COMPONENT>;

const Prose = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  ({ as: Component = DEFAULT_COMPONENT, className, ...props }, ref) => (
    <Component
      ref={ref}
      className={cn(styles["prose"], className)}
      {...props}
    />
  )
);

Prose.displayName = "Prose";

const MemoizedProse = memo(Prose) as typeof Prose;

export { MemoizedProse as Prose };
