import React, { Fragment, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { dialog, Flex, toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  DynamicActions as SharedDynamicActions,
  type DynamicActionsProps,
} from "@components/dynamic-actions";
import { useBillFormPermissions } from "@src/bills/bill-form-context";
import {
  ARCHIVED_VALID_STATUS,
  BILL_STATUS,
  UNSYNCED_QUICKBOOKS_STATUS,
} from "@src/bills/constants";
import {
  billPropagationPayloadSelector,
  camelCaseBillSelector,
  staticLinesBillSelector,
} from "@src/bills/utils";
import { showUnlinkedObjects } from "@src/transaction-propagation/utils/show-unlinked-objects";
import {
  archiveBill,
  autoSplit,
  convertToExpense,
  convertToPurchaseOrder,
  removeBill,
} from "@store/billSlice";
import { useExpensePermissions } from "@store/expenses";
import { queryExpenses } from "@store/expenses/thunks";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useClientSettings } from "@store/user";
import { usePropagationDialog } from "@transaction-propagation/hooks";
import type { SaveCallbackWithPropagation } from "@transaction-propagation/types";
import * as analytics from "@utils/analytics";
import { UNLINK_INVOICE_LINES_OPTION } from "@utils/transaction-confirm-messages";

export const DynamicActions = () => {
  const {
    id,
    isArchivedByUser,
    docNumber,
    initialReviewStatus,
    linkedInvoices,
    url: billUrl,
  } = useAppSelector(camelCaseBillSelector);

  const billPropagationPayload = useSelector(billPropagationPayloadSelector);

  const staticBillLines = useSelector(staticLinesBillSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const permissions = useBillFormPermissions();

  const { canAddExpense } = useExpensePermissions();

  const { state: locationState } = useLocation();

  const { aiAutoSplitEnabled } = useClientSettings();

  const goToPreviousPage = useCallback(() => {
    navigate(dotObject.get(locationState as object, "prev", "/bills"));
  }, [navigate, locationState]);

  const propagationArchiveSaveCallback = useEvent<SaveCallbackWithPropagation>(
    async (propagation) => {
      const { propagate, toastMessage } = propagation || {};
      try {
        await dispatch(archiveBill(undefined, { propagate }));
        goToPreviousPage();
        if (toastMessage) {
          toast.success(toastMessage);
        }
        toast.success(`Bill ${docNumber ? `#${docNumber} ` : ""}archived!`);
        analytics.track("billDynamicActions", {
          action: "archive",
          billId: id,
          unlinkInvoiceLineOption: UNLINK_INVOICE_LINES_OPTION.SKIP,
        });
      } catch (e) {
        handleErrors(e);
      }
    }
  );

  const { saveWithPropagation, propagationDialog } = usePropagationDialog(
    propagationArchiveSaveCallback
  );

  const onArchive = useEvent(async () => {
    const handler = async (
      unlinkInvoiceLineOption: string = UNLINK_INVOICE_LINES_OPTION.SKIP
    ) => {
      try {
        await dispatch(archiveBill(unlinkInvoiceLineOption));
        goToPreviousPage();
        toast.success(`Bill ${docNumber ? `#${docNumber} ` : ""}archived!`);
        analytics.track("billDynamicActions", {
          action: "archive",
          billId: id,
          unlinkInvoiceLineOption,
        });
      } catch (e) {
        handleErrors(e);
      }
    };

    if (linkedInvoices.length) {
      saveWithPropagation({
        ...billPropagationPayload,
        lines: staticBillLines.map((line) => ({
          id: Number(line.id),
          isDeleted: true,
        })),
        ...billPropagationPayload,
        values: {
          name: docNumber,
          url: billUrl,
          fields: {
            archived: true,
          },
          lines: staticBillLines.map((line) => ({
            id: Number(line.id),
            parent: Number(id),
            order: line.order || null,
            amount: line.amount || null,
            fields: {
              account: line.account?.url,
              item: line.item?.url,
            },
          })),
        },
      });
    } else if (UNSYNCED_QUICKBOOKS_STATUS.includes(initialReviewStatus)) {
      return handler();
    } else {
      dialog.confirmation({
        title: (
          <>
            Are you sure you want to <br />
            archive this transaction?
          </>
        ),
        message: (
          <>
            Archiving will also delete the bill from QuickBooks
            <br />
            and reopen any linked purchase orders.
          </>
        ),
        action: {
          primary: { children: "Archive", color: "error", onClick: handler },
        },
      });
    }
  });

  const onDelete = useEvent(() => {
    const handler = async () => {
      try {
        const response = await dispatch(removeBill());
        goToPreviousPage();
        showUnlinkedObjects(response.unlinked_objects);
        toast.success(`Bill ${docNumber ? `#${docNumber} ` : ""}deleted!`);
        analytics.track("billDynamicActions", {
          action: "delete",
          billId: id,
        });
      } catch (e) {
        handleErrors(e);
      }
    };

    dialog.confirmation({
      title: (
        <>
          Are you sure you want to <br />
          delete this transaction?
        </>
      ),
      message: "You can't undo this action.",
      action: {
        primary: { children: "Delete", color: "error", onClick: handler },
      },
    });
  });

  const onConvert = useEvent(async () => {
    try {
      const { id } = await dispatch(convertToExpense());
      dispatch(queryExpenses("DRAFT"));
      navigate(`/expenses/${id}`, { replace: true });

      toast.success(
        `Bill ${docNumber ? `#${docNumber} ` : ""}re-categorized as a receipt!`
      );

      analytics.track("billDynamicActions", {
        action: "re-categorize-as-receipt",
        billId: id,
      });
    } catch (e) {
      handleErrors(e);
    }
  });

  const onConvertToPurchaseOrder = useEvent(async () => {
    try {
      const { id } = await dispatch(convertToPurchaseOrder());
      navigate(`/purchase-orders/${id}`, { replace: true });

      toast.success(
        `Bill ${docNumber ? `#${docNumber} ` : ""}re-categorized as a purchase order!`
      );
      analytics.track("billDynamicActions", {
        action: "re-categorize-as-purchase-order",
        billId: id,
      });
    } catch (e) {
      handleErrors(e);
    }
  });

  const onAutoSplit = useEvent(async () => {
    dispatch(autoSplit());
    analytics.track("billDynamicActions", { action: "auto-split", billId: id });
  });

  if (!id) return <Flex />;

  let actions: Exclude<DynamicActionsProps["data"], undefined> = [];

  if (
    (initialReviewStatus === BILL_STATUS.DRAFT ||
      (isArchivedByUser &&
        ARCHIVED_VALID_STATUS.includes(initialReviewStatus))) &&
    permissions.canEditBill
  ) {
    actions = [
      ...actions,
      { onClick: onDelete, children: "Delete", color: "error" },
    ];
  }

  if (
    !isArchivedByUser &&
    canAddExpense &&
    (initialReviewStatus === BILL_STATUS.DRAFT ||
      initialReviewStatus === BILL_STATUS.APPROVAL)
  ) {
    actions = [
      ...actions,
      {
        onClick: onConvert,
        children: "Re-categorize as a receipt",
        disabled: !permissions.canEditBill,
        tooltipMessage: !permissions.canEditBill
          ? "You don't have permission to do this"
          : undefined,
      },
      ...(permissions.canAddPurchaseOrder
        ? [
            {
              onClick: onConvertToPurchaseOrder,
              children: "Re-categorize as a purchase order",
              disabled: !permissions.canEditBill,
              tooltipMessage: !permissions.canEditBill
                ? "You don't have permission to do this"
                : undefined,
            },
          ]
        : []),
      ...(aiAutoSplitEnabled
        ? [
            {
              onClick: onAutoSplit,
              children: "Auto-split document",
              disabled: !permissions.canEditBill,
              tooltipMessage: !permissions.canEditBill
                ? "You don't have permission to do this"
                : undefined,
            },
          ]
        : []),
    ];
  }

  if (
    !isArchivedByUser &&
    (initialReviewStatus === BILL_STATUS.DRAFT ||
      initialReviewStatus === BILL_STATUS.APPROVAL ||
      initialReviewStatus === BILL_STATUS.FOR_PAYMENT)
  ) {
    actions = [
      ...actions,
      {
        onClick: onArchive,
        children: "Archive",
        disabled: !permissions.canEditBill,
        tooltipMessage: !permissions.canEditBill
          ? "You don't have permission to do this"
          : undefined,
      },
    ];
  }

  return (
    <>
      <SharedDynamicActions
        data={actions}
        fallback={<Flex />}
        data-testid="bill-actions"
      />
      {propagationDialog}
    </>
  );
};
