import React, { useMemo } from "react";
import {
  Button,
  Checkbox,
  ComboBox,
  Flex,
  Loader,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@shared/api/handle-errors";
import { isDirtyBillSelector } from "@src/bills/utils";
import { useAppSelector } from "@store/hooks";
import { useClientSettings, useUserInfo } from "@store/user";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import {
  useGetLienWaiverTemplatesQuery,
  useGetLienWaiverTypesListQuery,
  useRequestLienWaiverMutation,
  useUpdateLienWaiverRequestMutation,
} from "../api/api";
import {
  LIEN_WAIVER_NOT_REQUESTED_STATUS,
  LIEN_WAIVER_STATUS,
  STRINGS,
  TEMPLATE_NOT_REQUESTED_ID,
} from "../constants/constants";
import { getMessageToVendor } from "../utils/get-message-to-vendor";

import { type BillLienWaiverFieldProps } from "./bill-lien-waiver-field";

type BillLienWaiverFieldComboboxProps = {
  customer?: string;
  openRequestDialog: () => void;
  haveMissingFields: boolean;
} & Pick<
  BillLienWaiverFieldProps,
  | "value"
  | "onChange"
  | "paymentAmount"
  | "onRequestUpdate"
  | "billPaymentId"
  | "billLienWaiver"
  | "billId"
  | "vendor"
  | "requestOnPayment"
>;

export const BillLienWaiverFieldCombobox = ({
  customer,
  value,
  onChange,
  paymentAmount,
  openRequestDialog,
  onRequestUpdate,
  billLienWaiver,
  billPaymentId,
  billId,
  haveMissingFields,
  requestOnPayment,
  vendor,
}: BillLienWaiverFieldComboboxProps) => {
  const { data: lienWaiverTypesData, isLoading: lienWaiverTypesLoading } =
    useGetLienWaiverTypesListQuery();

  const {
    data: lienWaiverTemplatesData,
    isLoading: lienWaiverTemplatesLoading,
  } = useGetLienWaiverTemplatesQuery(
    { withStatus: true },
    { refetchOnFocus: true }
  );

  const [updateLienWaiverRequest, { isLoading: requestUpdateIsLoading }] =
    useUpdateLienWaiverRequestMutation();

  const [requestLienWaiver, { isLoading: requestLienWaiverIsLoading }] =
    useRequestLienWaiverMutation();

  const isBillDirty = useAppSelector(isDirtyBillSelector);
  const { canManageLienWaiverRequest } = useUserInfo();
  const { paymentsRevampEnabled } = useClientSettings();

  const isDataLoading = lienWaiverTypesLoading || lienWaiverTemplatesLoading;
  const isRequestLoading = requestLienWaiverIsLoading || requestUpdateIsLoading;

  const isLoading = isDataLoading || isRequestLoading;

  const templatesData = useMemo(
    () =>
      lienWaiverTemplatesData?.map((template) => ({
        label:
          lienWaiverTypesData?.results?.find(
            (type) => type.url === template.type
          )?.name || template.type,
        value: template.url || TEMPLATE_NOT_REQUESTED_ID,
      })) || [],
    [lienWaiverTemplatesData, lienWaiverTypesData?.results]
  );

  const isEditAction = templatesData.length > 1;

  const isValidValue =
    !!value &&
    !LIEN_WAIVER_NOT_REQUESTED_STATUS.some((status) => status === value);

  const onMarkAsRequested = useEvent(async () => {
    try {
      if (billLienWaiver?.id) {
        await updateLienWaiverRequest({
          id: billLienWaiver.id,
          status: LIEN_WAIVER_STATUS.MARKED_AS_REQUESTED,
          paymentAmount,
          lienWaiverTemplateId: parseRefinementIdFromUrl(value)!,
        }).unwrap();
      } else {
        await requestLienWaiver({
          billId: billId!,
          billPaymentId: billPaymentId,
          vendorId: parseRefinementIdFromUrl(vendor?.url) ?? "",
          vendorEmail: vendor?.email,
          status: LIEN_WAIVER_STATUS.MARKED_AS_REQUESTED,
          lienWaiverTemplateId: parseRefinementIdFromUrl(value)!,
          paymentAmount: paymentAmount,
          message: getMessageToVendor(vendor),
        }).unwrap();
      }
      toast.success(STRINGS.MARKED_AS_REQUESTED);
      onRequestUpdate?.();
    } catch (error) {
      handleErrors(error);
    }
  });

  if (isDataLoading) {
    return (
      <Flex width="full" maxWidth="420px" align="center" justify="center">
        <Loader size="xl" />
      </Flex>
    );
  }

  return (
    <Flex width="full" maxWidth="420px" direction="column" gap="lg">
      {isRequestLoading && <Loader position="fixed" />}
      <ComboBox
        value={value}
        onChange={(value) => onChange?.(value, { customer })}
        data={templatesData}
        messageVariant="hidden"
        action={{
          icon: isEditAction ? "pen" : "plus",
          children: isEditAction
            ? STRINGS.SUPPORT_EDIT_TEMPLATES
            : STRINGS.SUPPORT_ADD_TEMPLATES,
          onClick: () => window.open("/settings/company/general", "_blank"),
        }}
        disabled={isLoading || !canManageLienWaiverRequest}
        data-testid="lien-waiver-combobox"
      />
      {isValidValue &&
        ((paymentsRevampEnabled && billPaymentId) || !paymentsRevampEnabled) &&
        onRequestUpdate && (
          <Flex width="full" minWidth="315px" gap="md">
            <Tooltip
              as={Flex}
              message={
                isBillDirty
                  ? STRINGS.SAVE_BILL_BEFORE_SEND_REQUEST
                  : haveMissingFields
                    ? STRINGS.MISSING_FIELDS_REQUEST_TOOLTIP
                    : undefined
              }
            >
              <Button
                size="sm"
                onClick={openRequestDialog}
                disabled={
                  !paymentAmount ||
                  isLoading ||
                  isBillDirty ||
                  haveMissingFields ||
                  !canManageLienWaiverRequest
                }
                data-testid="request-lien-waiver-button"
              >
                {STRINGS.REQUEST_LIEN_WAIVER}
              </Button>
            </Tooltip>
            <Tooltip
              as={Flex}
              message={
                isBillDirty
                  ? STRINGS.SAVE_BILL_BEFORE_MARKING_AS_REQUESTED
                  : haveMissingFields
                    ? STRINGS.MISSING_FIELDS_MARK_AS_REQUESTED_TOOLTIP
                    : undefined
              }
            >
              <Button
                size="sm"
                variant="ghost"
                onClick={onMarkAsRequested}
                disabled={
                  !paymentAmount ||
                  isLoading ||
                  isBillDirty ||
                  haveMissingFields ||
                  !canManageLienWaiverRequest
                }
                data-testid="mark-as-requested-button"
              >
                {STRINGS.MARK_AS_REQUESTED}
              </Button>
            </Tooltip>
          </Flex>
        )}

      {isValidValue && !billPaymentId && requestOnPayment?.onChange && (
        <Checkbox
          label={STRINGS.SEND_LIEN_WAIVER_ON_PAYMENT_TITLE}
          hintMessage={
            requestOnPayment.disabled
              ? STRINGS.SEND_LIEN_WAIVER_ON_PAYMENT_HINT_DISABLED
              : STRINGS.SEND_LIEN_WAIVER_ON_PAYMENT_HINT
          }
          checked={requestOnPayment.checked ?? false}
          onChange={(value) => requestOnPayment.onChange?.(value, { customer })}
          disabled={requestOnPayment.disabled}
        />
      )}
    </Flex>
  );
};
