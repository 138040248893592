import React, { memo, useEffect, useRef } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router";
import { Main, MainContent } from "@components/main";

import { useVendorAction } from "../hooks/use-vendor-action";
import type { Stage } from "../store/types";

import { PageHeader } from "./page-header";
import { PageTable } from "./page-table";

const DetailRedirect = memo(() => {
  const match = useMatch("/vendors/:vendorId");

  const vendorId = match?.params.vendorId;

  const navigate = useNavigate();

  const { showVendorById } = useVendorAction({
    onDrawerClose: () => navigate("/vendors"),
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const initialStageRef = useRef((searchParams.get("tab") || "info") as Stage);

  useEffect(() => {
    const initialStage = initialStageRef.current;

    if (vendorId) {
      showVendorById(vendorId, initialStage);
      initialStageRef.current = "info";
    }
  }, [vendorId, showVendorById]);

  useEffect(() => {
    setSearchParams((previousSearchParams) => previousSearchParams, {
      replace: true,
    });
  }, [setSearchParams]);

  return null;
});

DetailRedirect.displayName = "DetailRedirect";

export const Page = memo(() => (
  <Main>
    <PageHeader />
    <MainContent>
      <PageTable />
    </MainContent>
    <DetailRedirect />
  </Main>
));

Page.displayName = "Page";
