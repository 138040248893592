import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  dialog as dialogUtils,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  type TableColumn,
  Text,
  Textarea,
  type TextareaAttachmentAddon,
  type TextareaAttachmentAddonFile,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  createFormContext,
  useDeepMemo,
  type UseDialogReturn,
  useEvent,
} from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import {
  handleErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import { type CommentRef } from "@components/comments/comment";
import { summarizeResults } from "@utils/all-settled";
import * as analytics from "@utils/analytics";
import { idSchema } from "@utils/schema";
import { currencySchema } from "@utils/schema";
import { type KeysToCamelCase } from "@utils/schema/converters";
import { documentsRequestsApi } from "@vendors/api";
import { STRINGS as VENDOR_STRINGS } from "@vendors/constants";
import { useVendorAction } from "@vendors/hooks";
import { type AnyZodObject, z } from "zod";

import { getMessageToVendor } from "./utils/get-message-to-vendor";
import { STRINGS } from "./constants";
import { type PurchaseOrder } from "./request-vendor-po-signature";

export type PoRequestDialogProps = {
  dialog: UseDialogReturn;
  multiple: boolean;
  onSubmit?: () => void;
  purchaseOrders: (PurchaseOrder | KeysToCamelCase<PurchaseOrder>)[];
};

export type GetColumnsPropsV2 = {
  includeCustomMessage: boolean;
  dialogHide: () => void;
};
const partialVendorSchema = z.object({
  id: idSchema,
  displayName: z.string(),
  url: z.string(),
});

const partiaPoSchema = z.object({
  files: z.array(z.any()),
  message: z.string(),
  id: idSchema,
  docNumber: z.string(),
  totalAmount: currencySchema,
  vendor: partialVendorSchema,
});

const partialSchema = z.object({
  applyMessageIndex: z.number().nullish(),
  includeCustomMessage: z.boolean(),
});

const setEmailSchema = partialSchema.extend({
  data: z.array(
    partiaPoSchema.extend({
      email: z.string().email("An email address is required"),
    })
  ),
});
type Fields = z.input<typeof setEmailSchema>;

const { useForm, FormConsumer, FormProvider } = createFormContext<Fields>();

const getColumns = ({
  includeCustomMessage,
  dialogHide,
}: GetColumnsPropsV2) => {
  const columns: TableColumn<Fields["data"][number]>[] = [
    {
      id: "doc_number",
      name: "PO #",
      width: "fill",
      align: includeCustomMessage ? "start" : "center",
      render: (row) => <Text size="sm">{row.docNumber}</Text>,
      minWidth: "content",
    },
    {
      id: "total-amount",
      name: "Amount",
      width: "fill",
      align: includeCustomMessage ? "start" : "center",
      render: (row) => <Text size="sm">{row.totalAmount}</Text>,
      minWidth: "content",
    },
    {
      id: "displayName",
      name: "Vendor name",
      width: "fill",
      align: includeCustomMessage ? "start" : "center",
      render: (row) => <Text size="sm">{row.vendor.displayName}</Text>,
      minWidth: "content",
    },
    {
      id: "email",
      name: "Email",
      width: 280,
      align: includeCustomMessage ? "start" : "center",
      render: (row, index) => (
        <FormConsumer>
          {(form) => (
            <TextField
              key={row.id}
              size="sm"
              type="email"
              required
              disabled={form.isSubmitting}
              placeholder="Enter an email address"
              messageVariant="hidden"
              {...form.register(`data.${index}.email`)}
            />
          )}
        </FormConsumer>
      ),
    },
  ];

  if (includeCustomMessage) {
    columns.push({
      id: "message",
      name: "Message",
      align: includeCustomMessage ? "start" : "center",
      width: 380,
      render: (row, index) => (
        <FormConsumer>
          {(form) => (
            <Flex gap="md" direction="column">
              <Textarea
                minHeight={70}
                maxHeight={70}
                disabled={form.isSubmitting}
                placeholder="Optional message included in the request email"
                messageVariant="hidden"
                {...form.register({
                  name: `data.${index}.message`,
                  type: "string",
                  onChange: (value) => {
                    if (form.values.applyMessageIndex === null) return;

                    if (form.values.applyMessageIndex !== index) {
                      return form.setValue("applyMessageIndex", null);
                    }

                    form.values.data.map((_, i) => {
                      if (i === index) return;

                      form.setValue(`data.${i}.message`, value);
                    });
                  },
                })}
              />
              <Checkbox
                label="Apply to all requests"
                checked={form.values.applyMessageIndex !== null}
                disabled={
                  (form.values.applyMessageIndex !== null &&
                    form.values.applyMessageIndex !== index) ||
                  form.isSubmitting
                }
                onChange={(checked) => {
                  form.setValue("applyMessageIndex", checked ? index : null);

                  if (checked) {
                    form.values.data.map((_, i) =>
                      form.setValue(
                        `data.${i}.message`,
                        form.values.data[index].message
                      )
                    );
                  }
                }}
              />
            </Flex>
          )}
        </FormConsumer>
      ),
    });
  }

  columns.push({
    id: "action",
    name: "Actions",
    align: includeCustomMessage ? "start" : "center",
    width: 115,
    render: (_, index) => (
      <FormConsumer>
        {(form) => (
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            disabled={form.isSubmitting}
            onClick={() => {
              const lastElement = form.values.data.length === 1;
              form.remove("data", index);
              if (lastElement) dialogHide();
            }}
            aria-label="Remove email"
            data-testid="po-email-remove-button"
          >
            Remove
          </Button>
        )}
      </FormConsumer>
    ),
  });

  return columns;
};

export const PoRequestDialog = ({
  dialog,
  multiple,
  onSubmit,
  purchaseOrders = [],
}: PoRequestDialogProps) => {
  const commentRef = useRef<CommentRef>(null);
  const [isAttachmentValid, setIsAttachmentValid] = useState(true);
  const [files, setFiles] = useState<TextareaAttachmentAddonFile[]>([]);

  const singlePO = (!multiple && purchaseOrders[0]) || null;

  const confirmEmailAdd =
    singlePO &&
    !singlePO.vendor.email &&
    dotObject.get(
      singlePO.vendor,
      "has_pending_requests",
      dotObject.get(singlePO.vendor, "hasPendingRequests", true)
    );

  const initialValues = useMemo<Fields>(
    () => ({
      applyMessageIndex: null,
      includeCustomMessage: false,
      data: (purchaseOrders ?? []).map((purchaseOrder) => ({
        email: purchaseOrder.vendor.email || "",
        docNumber: dotObject.get(
          purchaseOrder,
          "doc_number",
          dotObject.get(purchaseOrder, "docNumber", "")
        ),
        totalAmount: dotObject.get(
          purchaseOrder,
          "total_amount",
          dotObject.get(purchaseOrder, "totalAmount", 0)
        ),
        id: purchaseOrder.id,
        message: "",
        files: [],
        vendor: {
          displayName: dotObject.get(
            purchaseOrder.vendor,
            "display_name",
            dotObject.get(purchaseOrder.vendor, "displayName", "")
          ),
          id: purchaseOrder.vendor.id,
          url: purchaseOrder.vendor.url,
        },
      })),
    }),
    [purchaseOrders]
  );

  const sendPoRequests = async (data: Fields["data"]) => {
    const requests = data.map(async (po) => {
      try {
        await documentsRequestsApi.postVendorPoRequestV2({
          vendorId: po.vendor.id,
          vendorEmail: po.email,
          message: getMessageToVendor(po.vendor, po.message),
          purchaseOrderId: po.id,
          files: files,
        });
      } catch (error) {
        throw transformErrorToCustomError({
          error,
          extra: {
            docNumber: `PO#${po.docNumber || po.id}`,
          },
          render: (message) =>
            message == "PO already signed"
              ? `${STRINGS.PO_ALREADY_SIGNED_ERROR_TOAST}:`
              : `${message} on the following purchase orders:`,
        });
      }
      if (multiple) {
        analytics.track("purchaseOrderBatchActions", {
          action: "send-po-signature-request",
          location: "dialog",
          purchaseOrderIds: data.map(({ id }) => id),
        });
      }
    });

    const { success, errorResponses } = summarizeResults(
      await Promise.allSettled(requests)
    );

    const enhancedErrors = parseCustomErrors<{ docNumber: string }>({
      errors: errorResponses,
      render: ({ isFirst, message, docNumber }) =>
        `${message}${isFirst ? "" : ","} #${docNumber}`,
    });

    if (enhancedErrors.length) {
      enhancedErrors.forEach((error) =>
        handleErrors(error, { maxWidth: 800, truncate: 2 })
      );
    }
    if (success) {
      const plural = success > 1;
      toast.success(
        `Purchase order${plural ? "s" : ""} ${plural ? "were" : "was"} sent for signature`
      );
    }

    onSubmit?.();

    await dialog.hide();
  };

  const onSendPoRequestWithEmailAdd = useEvent(() => {
    dialogUtils.confirmation({
      title: VENDOR_STRINGS.EMAIL_CHANGE_CONFIRMATION_DIALOG_TITLE,
      message: VENDOR_STRINGS.EMAIL_CHANGE_CONFIRMATION_DIALOG_MESSAGE,
      action: {
        primary: {
          children: VENDOR_STRINGS.EMAIL_CHANGE_CONFIRMATION_DIALOG_BUTTON,
          color: "error",
          onClick: async () => {
            await sendPoRequests(form.values.data);
            toast.info(VENDOR_STRINGS.REQUESTS_WERE_EXPIRED_TOAST);
          },
        },
      },
    });
  });

  const form = useForm({
    /**
     * @todo figure out how to adjust this type issue without casting it
     */
    schema: setEmailSchema as unknown as AnyZodObject,
    async onSubmit({ data }) {
      if (confirmEmailAdd) {
        onSendPoRequestWithEmailAdd();
      } else {
        sendPoRequests(data);
      }
    },
    initialValues,
  });

  const onCancel = useEvent(() => {
    form.reset();
    dialog.hide();
  });

  const { showVendorById } = useVendorAction({
    onDrawerClose: () => {
      if (!multiple) {
        form.setValue("data.0.email", purchaseOrders[0].vendor.email || "");
      }
    },
  });

  const curriedOpenVendor = useCallback(
    (stage: string) => () => {
      showVendorById(singlePO?.vendor.id as string, stage);
    },
    [singlePO, showVendorById]
  );

  const onChangeIncludeCustomMessage = useEvent((checked: boolean) => {
    if (checked) return;

    form.values.data.map((_, index) =>
      form.setValue(`data.${index}.message`, "")
    );
    form.setValue(`applyMessageIndex`, null);
  });

  const data = useDeepMemo(() => form.values.data, [form.values.data]);

  const dialogHide = dialog.hide;

  const columns = useMemo(
    () =>
      getColumns({
        includeCustomMessage: form.values.includeCustomMessage,
        dialogHide,
      }),
    [form.values.includeCustomMessage, dialogHide]
  );

  const attachment = useMemo<TextareaAttachmentAddon>(
    () => ({
      accept: window.EXPECTED_FILE_TYPES,
      maxSize: window.MAX_COMMENT_FILES_SIZE_MB,
      maxSizeValidation: "total",
      onChange: (files) => {
        setFiles(files);

        if (!commentRef.current) return;

        const hasInvalidFiles = commentRef?.current?.hasFiles([
          "error",
          "pending",
        ]);

        setIsAttachmentValid(!hasInvalidFiles);
      },
      multiple: window.MAX_NUMBER_OF_COMMENT_FILES,
    }),

    []
  );

  return (
    <>
      {multiple ? (
        <Dialog
          size="auto"
          show={dialog.isVisible}
          variant="dialog"
          onClose={onCancel}
        >
          <DialogHeader>
            <Flex direction="column">
              <Text size="xl" weight="bold">
                {STRINGS.REQUEST_DIALOG_HEADER_MULTIPLE}
              </Text>
              <Text size="md">{STRINGS.REQUEST_DIALOG_CONTENT_MULTIPLE}</Text>
            </Flex>
          </DialogHeader>
          <DialogContent>
            <Flex
              as="form"
              gap="2xl"
              minWidth={form.values.includeCustomMessage ? "1220px" : "950px"}
              justify="center"
              direction="column"
              {...form.props}
            >
              <Checkbox
                label="Include a custom message"
                {...form.register({
                  name: "includeCustomMessage",
                  type: "boolean",
                  onChange: onChangeIncludeCustomMessage,
                })}
              />
              <FormProvider form={form}>
                <Table
                  id="po-request-dialog-table"
                  size="sm"
                  data={data}
                  columns={columns}
                  maxHeight="500px"
                />
              </FormProvider>
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Flex width="full">
              <Button block color="neutral" variant="text" onClick={onCancel}>
                Cancel
              </Button>
            </Flex>
            <Tooltip
              as={Flex}
              width="full"
              message={
                !form.isValid
                  ? "Email is required to send signature requests"
                  : ""
              }
              placement="left"
            >
              <Button
                type="submit"
                form={form.id}
                block
                disabled={form.isSubmitting || !form.isValid}
              >
                {form.isSubmitting ? <Loader /> : "Send"}
              </Button>
            </Tooltip>
          </DialogFooter>
        </Dialog>
      ) : (
        <Dialog
          variant="dialog"
          size="sm"
          show={dialog.isVisible}
          onClose={onCancel}
        >
          <DialogHeader>{STRINGS.REQUEST_DIALOG_HEADER_SINGLE}</DialogHeader>
          <DialogContent>
            <Flex
              as="form"
              width="full"
              direction="column"
              {...form.props}
              gap="2xl"
            >
              <Flex direction="column">
                <TextField
                  type="email"
                  label={STRINGS.VENDOR_EMAIL_ADDRESS}
                  size="md"
                  required
                  disabled={!!singlePO?.vendor.email || form.isSubmitting}
                  placeholder={STRINGS.VENDOR_EMAIL_ADDRESS_PLACEHOLDER}
                  messageVariant="absolute"
                  {...form.register(`data.0.email`)}
                />

                <Flex align="center" gap="sm">
                  {!singlePO?.vendor.email ? (
                    <Text size="sm" color="neutral-700" weight="regular">
                      {STRINGS.VENDOR_EMAIL_ADD}
                    </Text>
                  ) : (
                    <>
                      <Text size="sm" color="neutral-700" weight="regular">
                        {STRINGS.VENDOR_EMAIL_UPDATE}
                      </Text>
                      <Link
                        as="button"
                        size="sm"
                        type="button"
                        variant="success"
                        onClick={curriedOpenVendor("info")}
                      >
                        Enter it here
                      </Link>
                    </>
                  )}
                </Flex>
              </Flex>
              <Flex direction="column" gap="md">
                <Textarea
                  ref={commentRef}
                  minHeight={94}
                  maxHeight={200}
                  disabled={form.isSubmitting}
                  messageVariant="absolute"
                  label={STRINGS.ADD_COMMENT}
                  placeholder={STRINGS.ADD_COMMENT_PLACEHOLDER}
                  attachment={attachment}
                  {...form.register(`data.0.message`)}
                  renderAfter={({ setFilesFromPicker }) => (
                    <Flex gap="md" justify="flex-end" shrink={false} grow>
                      {setFilesFromPicker && (
                        <Button
                          size="sm"
                          color="neutral"
                          variant="ghost"
                          onClick={setFilesFromPicker}
                        >
                          <Icon name="paperclip" />
                        </Button>
                      )}
                    </Flex>
                  )}
                />
              </Flex>
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Button
              block
              variant="ghost"
              color="neutral"
              onClick={onCancel}
              disabled={form.isSubmitting}
            >
              {STRINGS.CANCEL}
            </Button>
            <Button
              block
              type="submit"
              form={form.id}
              disabled={
                form.isSubmitting || !isAttachmentValid || !form.isValid
              }
            >
              {STRINGS.SEND}
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};
