import React, { type ComponentProps, useEffect, useRef, useState } from "react";
import {
  Flex,
  Label,
  SignaturePad,
  type SignaturePadRef,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  TextField,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";

import { SIGNATURE_TABS, STRINGS } from "./constants";

export type SignatureSavedValue = {
  paths?: string[];
  drawSignature?: string;
  typeSignature?: string;
};

type SignatureFieldProps = {
  mode?: "draw" | "type" | "both";
  height?: ComponentProps<typeof Flex>["height"];
  onChange?: (values: SignatureSavedValue) => void;
  initialValue?: SignatureSavedValue;
};

const PAD_HEIGHT = "200px";

export const SignatureField = ({
  mode = "both",
  onChange,
  initialValue,
}: SignatureFieldProps) => {
  const signaturePadRef = useRef<SignaturePadRef>(null);

  const [values, setValues] = useState<SignatureSavedValue>(initialValue || {});

  const [activeTab, setActiveTab] = useState(() => {
    if (mode === "draw") return SIGNATURE_TABS.DRAW;

    if (mode === "type") return SIGNATURE_TABS.TYPE;

    return initialValue?.typeSignature
      ? SIGNATURE_TABS.TYPE
      : SIGNATURE_TABS.DRAW;
  });

  const canType = mode === "type" || mode === "both";

  const canDraw = mode === "draw" || mode === "both";

  const setDrawSignature = useEvent(({ url, paths }) => {
    setValues((prev) => ({ ...prev, drawSignature: url, paths }));
  });

  const setTypeSignature = useEvent((typeSignature: string) => {
    setValues((prev) => ({ ...prev, typeSignature }));
  });

  const fillInitialValue = useEvent(() => {
    if (signaturePadRef.current && initialValue?.paths) {
      signaturePadRef.current.setPaths?.(initialValue.paths);
    }
  });

  const pad = canDraw ? (
    <Flex direction="column" height={PAD_HEIGHT}>
      {!canType && <Label as="p">{STRINGS.DRAW_SIGNATURE}</Label>}
      <SignaturePad onChange={setDrawSignature} ref={signaturePadRef} />
    </Flex>
  ) : null;

  const type = canType ? (
    <Flex height={canDraw ? PAD_HEIGHT : undefined}>
      <TextField
        label={!canDraw ? STRINGS.TYPE_SIGNATURE : undefined}
        value={values.typeSignature ?? ""}
        onChange={setTypeSignature}
        autoFocus={!canDraw}
      />
    </Flex>
  ) : null;

  useEffect(() => {
    const { drawSignature, typeSignature, paths } = values;
    const value =
      activeTab === SIGNATURE_TABS.DRAW
        ? { drawSignature, paths }
        : { typeSignature };
    onChange?.(value);
  }, [activeTab, onChange, values]);

  useEffect(() => fillInitialValue(), [fillInitialValue]);

  return (
    <Flex direction="column" gap="xl" width="full">
      {mode === "both" ? (
        <Tabs value={activeTab} onChange={setActiveTab}>
          <TabsList>
            <TabsTab value={SIGNATURE_TABS.DRAW}>
              {STRINGS.DRAW_SIGNATURE}
            </TabsTab>
            <TabsTab value={SIGNATURE_TABS.TYPE}>
              {STRINGS.TYPE_SIGNATURE}
            </TabsTab>
          </TabsList>
          <TabsPanel value={SIGNATURE_TABS.DRAW}>{pad}</TabsPanel>
          <TabsPanel value={SIGNATURE_TABS.TYPE}>{type}</TabsPanel>
        </Tabs>
      ) : (
        <>
          {pad}
          {type}
        </>
      )}
    </Flex>
  );
};
