export const isFocusable = (el?: HTMLElement | Element | null) => {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) return false;

  const focusableSelectors = [
    "a[href]",
    "button:not([disabled])",
    "textarea:not([disabled])",
    'input:not([type="hidden"]):not([disabled])',
    "select:not([disabled])",
    '[tabindex]:not([tabindex="-1"])',
    "[contenteditable='true']",
  ];

  return focusableSelectors.some((selector) => el.matches(selector));
};
