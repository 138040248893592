import React, { forwardRef, memo, useState } from "react";
import {
  Toolbar as AriaKitToolbar,
  ToolbarItem as AriaKitToolbarItem,
  type ToolbarItemProps,
} from "@ariakit/react";

import {
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
} from "../dropdown";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Shortcut } from "../shortcut/shortcut";
import { Text } from "../text/text";
import { Tooltip, type TooltipProps } from "../tooltip";

import { useTextarea } from "./textarea-context";
import styles from "./textarea.module.css";

const ToolbarItem = memo(
  forwardRef<HTMLButtonElement, TooltipProps & ToolbarItemProps>(
    (props, ref) => {
      const [isFocused, setIsFocused] = useState<true | undefined>();

      return (
        <AriaKitToolbarItem
          render={
            <Tooltip
              as={AriaKitToolbarItem}
              ref={ref}
              show={isFocused}
              onBlur={() => setIsFocused(undefined)}
              onFocus={() => setIsFocused(true)}
            />
          }
          {...props}
        />
      );
    }
  )
);

ToolbarItem.displayName = "ToolbarItem";

export const Toolbar = memo(() => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { editor, wysiwyg, setFilesFromPicker } = useTextarea();

  if (!editor || !wysiwyg) return null;

  let activeAlign: "left" | "center" | "justify" | "right" = "left";

  if (editor.isActive({ textAlign: "center" })) {
    activeAlign = "center";
  } else if (editor.isActive({ textAlign: "right" })) {
    activeAlign = "right";
  } else if (editor.isActive({ textAlign: "justify" })) {
    activeAlign = "justify";
  }

  return (
    <AriaKitToolbar
      className={styles["toolbar"]}
      data-is-dropdown-open={isDropdownOpen}
    >
      <div>
        {wysiwyg && (
          <>
            <ToolbarItem
              message={
                <Flex gap="sm">
                  Bold <Shortcut meta letter="B" />
                </Flex>
              }
              onClick={() => editor.chain().focus().toggleBold?.().run()}
              disabled={
                !editor.isEditable ||
                !editor.can().chain().focus().toggleBold?.().run()
              }
              tabIndex={editor.isFocused ? 0 : -1}
              aria-pressed={editor.isActive("bold")}
            >
              <Icon name="bold" variant="solid" />
            </ToolbarItem>
            <ToolbarItem
              message={
                <Flex gap="sm">
                  Italic <Shortcut meta letter="I" />
                </Flex>
              }
              onClick={() => editor.chain().focus().toggleItalic?.().run()}
              disabled={
                !editor.isEditable ||
                !editor.can().chain().focus().toggleItalic?.().run()
              }
              tabIndex={editor.isFocused ? 0 : -1}
              aria-pressed={editor.isActive("italic")}
            >
              <Icon name="italic" />
            </ToolbarItem>
            <ToolbarItem
              message={
                <Flex gap="sm">
                  Underline <Shortcut meta letter="U" />
                </Flex>
              }
              onClick={() => editor.chain().focus().toggleUnderline?.().run()}
              disabled={
                !editor.isEditable ||
                !editor.can().chain().focus().toggleUnderline?.().run()
              }
              tabIndex={editor.isFocused ? 0 : -1}
              aria-pressed={editor.isActive("underline")}
            >
              <Icon name="underline" />
            </ToolbarItem>
            <ToolbarItem
              message={
                <Flex gap="sm">
                  Bulleted list <Shortcut meta shift letter="8" />
                </Flex>
              }
              onClick={() => editor.chain().focus().toggleBulletList?.().run()}
              tabIndex={editor.isFocused ? 0 : -1}
              disabled={!editor.isEditable}
              aria-pressed={editor.isActive("bulletList")}
            >
              <Icon name="list-ul" />
            </ToolbarItem>
            <ToolbarItem
              message={
                <Flex gap="sm">
                  Numbered list <Shortcut meta shift letter="7" />
                </Flex>
              }
              onClick={() => editor.chain().focus().toggleOrderedList?.().run()}
              tabIndex={editor.isFocused ? 0 : -1}
              disabled={!editor.isEditable}
              aria-pressed={editor.isActive("orderedList")}
            >
              <Icon name="list-ol" />
            </ToolbarItem>
            <Dropdown onChange={setIsDropdownOpen}>
              <DropdownTrigger
                as={ToolbarItem}
                message="Text alignment"
                tabIndex={editor.isFocused ? 0 : -1}
                disabled={!editor.isEditable}
                aria-pressed={activeAlign !== "left"}
              >
                <Icon name={`align-${activeAlign}`} />
              </DropdownTrigger>
              <DropdownList>
                <DropdownItem
                  icon="align-left"
                  onClick={() =>
                    editor.chain().focus().setTextAlign("left").run()
                  }
                  className={
                    editor.isActive({ textAlign: "left" }) ? "is-active" : ""
                  }
                >
                  <Flex width="full" justify="space-between">
                    <Text>Left align</Text>
                    <Shortcut inverse meta shift letter="L" />
                  </Flex>
                </DropdownItem>
                <DropdownItem
                  icon="align-center"
                  onClick={() =>
                    editor.chain().focus().setTextAlign("center").run()
                  }
                  className={
                    editor.isActive({ textAlign: "center" }) ? "is-active" : ""
                  }
                >
                  <Flex width="full" justify="space-between">
                    <Text>Center align</Text>
                    <Shortcut inverse meta shift letter="E" />
                  </Flex>
                </DropdownItem>
                <DropdownItem
                  icon="align-right"
                  onClick={() =>
                    editor.chain().focus().setTextAlign("right").run()
                  }
                  className={
                    editor.isActive({ textAlign: "right" }) ? "is-active" : ""
                  }
                >
                  <Flex width="full" justify="space-between">
                    <Text>Right align</Text>
                    <Shortcut inverse meta shift letter="R" />
                  </Flex>
                </DropdownItem>
                <DropdownItem
                  icon="align-justify"
                  onClick={() =>
                    editor.chain().focus().setTextAlign("justify").run()
                  }
                  className={
                    editor.isActive({ textAlign: "justify" }) ? "is-active" : ""
                  }
                >
                  <Flex width="full" justify="space-between">
                    <Text>Justify</Text>
                    <Shortcut inverse meta shift letter="J" />
                  </Flex>
                </DropdownItem>
              </DropdownList>
            </Dropdown>
          </>
        )}
      </div>
      <div>
        {setFilesFromPicker && (
          <>
            <ToolbarItem
              message={`Click to attach a file or\n drag & drop to quickly upload`}
              onClick={setFilesFromPicker}
              tabIndex={editor.isFocused ? 0 : -1}
            >
              <Icon name="paperclip" />
            </ToolbarItem>
          </>
        )}
      </div>
    </AriaKitToolbar>
  );
});

Toolbar.displayName = "Toolbar";
