import React, { useMemo } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  Flex,
  Text,
} from "@adaptive/design-system";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { useBankAccountsV1 } from "@hooks/use-bank-accounts";
import { LIEN_WAIVER_REQUESTED_STATUS } from "@lien-waiver/constants";
import { OBJECT_ORIGIN } from "@shared/constants";
import {
  GET_HUMAN_READABLE_PAYMENT_STATUS,
  HUMAN_READABLE_PAYMENT_METHOD,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PAYMENT_STATUS_VARIANT_V2,
  PAYMENT_STEP_INFO_STRINGS,
} from "@src/bills/constants";
import { type BillPaymentV2 } from "@src/shared/api/bills";
import { useClientSettings } from "@store/user";
import { sumBy } from "@utils/sumBy";

import type { PaymentStatusKey } from "../../types";

import { getVendorACHLabel } from "./utils";

type BillPaymentInfoProps = {
  billPayment: BillPaymentV2;
  onViewPayment: (value: string) => void;
};
type PaymentInfoProps = {
  title: string;
  value: string;
  subtitle?: string;
};

const PaymentInfo = ({ title, value, subtitle }: PaymentInfoProps) => (
  <Flex>
    <Flex width="120px" shrink={false}>
      <Text size="sm" color="neutral-500">
        {title}
      </Text>
    </Flex>
    <Flex direction="column">
      <Text weight="bold" size="lg">
        {value}
      </Text>
      {subtitle && <Text size="sm">{subtitle}</Text>}
    </Flex>
  </Flex>
);

export const BillPaymentInfoV2 = ({
  billPayment,
  onViewPayment,
}: BillPaymentInfoProps) => {
  const accountBalances = useBankAccountsV1();

  const { canManageLienWaivers } = useClientSettings();

  const totalRequestedAmount = formatCurrency(
    sumBy(
      (billPayment.lienWaivers ?? [])?.filter(
        (item) =>
          !item?.signedAt &&
          LIEN_WAIVER_REQUESTED_STATUS.some((status) => status === item?.status)
      ) as Record<string, unknown>[],
      "paymentAmount"
    ),
    {
      currencySign: true,
    }
  );

  const totalSignedAmount = formatCurrency(
    sumBy(
      (billPayment.lienWaivers ?? [])?.filter(
        (item) => item?.signedAt
      ) as Record<string, unknown>[],
      "paymentAmount"
    ),
    {
      currencySign: true,
    }
  );

  const paymentAccounts = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });

  const accountBalance = useMemo(
    () =>
      accountBalances.data.find(
        (item) => item.value === billPayment.customerBankAccount?.url
      ),
    [accountBalances, billPayment]
  );

  const account = useMemo(
    () =>
      paymentAccounts.data.find((item) => item.value === billPayment.account)
        ?.label,
    [paymentAccounts, billPayment]
  );

  const {
    totalAmount,
    paymentMethod,
    status,
    vendorBankAccount,
    appliedCardTransaction,
    customerCard,
    id: billPaymentId,
    appliedAmount,
    createdIn,
    docNumber,
    processPrerequisites,
    paymentSchedule,
  } = billPayment;

  const paymentAmount = useMemo(() => {
    if (
      (
        [PAYMENT_STATUS.CANCELLED, PAYMENT_STATUS.FAILED] as PaymentStatusKey[]
      ).includes(status as PaymentStatusKey)
    ) {
      const formattedOriginalTotalAmount = formatCurrency(appliedAmount, {
        currencySign: true,
        allowNegative: true,
      });
      return formattedOriginalTotalAmount;
    }
    const formattedTotalAmount = formatCurrency(totalAmount, {
      currencySign: true,
      allowNegative: true,
    });

    const formattedAmountForBill = formatCurrency(appliedAmount, {
      currencySign: true,
      allowNegative: true,
    });

    // if values differ, it means the payment includes multiple bills
    // and we only want to show the amount paid for this particular bill
    return formattedAmountForBill !== formattedTotalAmount
      ? `${formattedAmountForBill} (of ${formattedTotalAmount} payment)`
      : formattedTotalAmount;
  }, [totalAmount, appliedAmount, status]);

  const payFrom = useMemo(() => {
    if (createdIn === OBJECT_ORIGIN.ADAPTIVE) {
      if (paymentMethod === PAYMENT_METHOD.MARK_AS_PAID && account) {
        return account;
      } else {
        if (accountBalance) {
          return accountBalance?.label;
        } else {
          return PAYMENT_STEP_INFO_STRINGS.EM_DASH;
        }
      }
    } else {
      if (account) {
        return account;
      } else {
        return PAYMENT_STEP_INFO_STRINGS.EM_DASH;
      }
    }
  }, [account, accountBalance, createdIn, paymentMethod]);

  const payFromSubtitle = useMemo(() => {
    if (paymentSchedule.processedOn) {
      return formatDate(
        parseDate(paymentSchedule.processedOn, "yyyy-MM-dd"),
        "P"
      );
    } else {
      return PAYMENT_STEP_INFO_STRINGS.EM_DASH;
    }
  }, [paymentSchedule.processedOn]);

  const payTo = useMemo(() => {
    if (vendorBankAccount) {
      return getVendorACHLabel(vendorBankAccount);
    } else if (appliedCardTransaction?.displayName) {
      return appliedCardTransaction.displayName;
    } else {
      return PAYMENT_STEP_INFO_STRINGS.EM_DASH;
    }
  }, [vendorBankAccount, appliedCardTransaction]);

  const payToSubtitle = useMemo(() => {
    if (appliedCardTransaction) {
      return "";
    } else if (paymentSchedule.deliveredOn) {
      return formatDate(parseDate(paymentSchedule.deliveredOn, "iso"), "P");
    } else if (
      paymentSchedule.expectedDeliveryAfter &&
      paymentSchedule.expectedDeliveryBefore
    ) {
      return `${formatDate(
        parseDate(paymentSchedule.expectedDeliveryAfter, "iso"),
        "P"
      )} - ${formatDate(
        parseDate(paymentSchedule.expectedDeliveryBefore, "iso"),
        "P"
      )}`;
    } else {
      return PAYMENT_STEP_INFO_STRINGS.EM_DASH;
    }
  }, [
    appliedCardTransaction,
    paymentSchedule.deliveredOn,
    paymentSchedule.expectedDeliveryAfter,
    paymentSchedule.expectedDeliveryBefore,
  ]);

  return (
    <Card as={Flex} direction="column" width="full" size="sm" gap="2xl">
      <Alert
        variant={
          status in PAYMENT_STATUS_VARIANT_V2
            ? PAYMENT_STATUS_VARIANT_V2[status as PaymentStatusKey]
            : "info"
        }
      >
        <AlertTitle>
          <Flex gap="xl" justify="space-between" width="full">
            {PAYMENT_STEP_INFO_STRINGS.PAYMENT_INFO_TITLE}{" "}
            {GET_HUMAN_READABLE_PAYMENT_STATUS(status, processPrerequisites)}
            <Text as="strong" weight="bold">
              {paymentAmount}
            </Text>
          </Flex>
        </AlertTitle>
      </Alert>
      <Flex
        gap="2xl"
        direction={{ mobile: "column", tablet: "row" }}
        width="full"
        separator={{ mobile: false, tablet: true }}
      >
        <Flex gap="2xl" direction="column" width="full">
          <PaymentInfo
            title={PAYMENT_STEP_INFO_STRINGS.PAYMENT_FROM_TITLE}
            subtitle={payFromSubtitle}
            value={payFrom}
          />
          {payTo !== PAYMENT_STEP_INFO_STRINGS.EM_DASH ? (
            <PaymentInfo
              title={
                customerCard
                  ? PAYMENT_STEP_INFO_STRINGS.CARD_TRANSACTION_TITLE
                  : PAYMENT_STEP_INFO_STRINGS.PAYMENT_METHOD_TITLE
              }
              value={payTo}
              subtitle={payToSubtitle}
            />
          ) : (
            <PaymentInfo
              title={
                customerCard
                  ? PAYMENT_STEP_INFO_STRINGS.CARD_TRANSACTION_TITLE
                  : PAYMENT_STEP_INFO_STRINGS.PAYMENT_METHOD_TITLE
              }
              value={
                HUMAN_READABLE_PAYMENT_METHOD[
                  paymentMethod as keyof typeof HUMAN_READABLE_PAYMENT_METHOD
                ]
              }
            />
          )}
        </Flex>
        <Flex gap="2xl" direction="column" width="full">
          {canManageLienWaivers && (
            <PaymentInfo
              title={PAYMENT_STEP_INFO_STRINGS.LIEN_WAIVER_TITLE}
              subtitle={`${totalRequestedAmount} ${PAYMENT_STEP_INFO_STRINGS.LIEN_WAIVER_REQUESTED_TITLE}`}
              value={`${totalSignedAmount} ${PAYMENT_STEP_INFO_STRINGS.LIEN_WAIVER_SIGNED_TITLE}`}
            />
          )}
          <PaymentInfo
            title={PAYMENT_STEP_INFO_STRINGS.PAYMENT_REF_TITLE}
            value={`#${docNumber}`}
          />
        </Flex>
      </Flex>
      <Button
        block
        variant="ghost"
        onClick={() => onViewPayment(billPaymentId.toString())}
      >
        {canManageLienWaivers
          ? PAYMENT_STEP_INFO_STRINGS.VIEW_PAYMENT_DETAILS_WITH_LW
          : PAYMENT_STEP_INFO_STRINGS.VIEW_PAYMENT_DETAILS}
      </Button>
    </Card>
  );
};
