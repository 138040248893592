import { api } from "@store/api-simplified";
import { handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import { linkedTransactionsSchema } from "./schemas";
import type {
  GetLinkedTransactionsParams,
  GetLinkedTransactionsReturn,
} from "./types";

const enhancedApi = api.enhanceEndpoints({});

export const linkedTransactionsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getLinkedTransactions: builder.mutation<
      GetLinkedTransactionsReturn,
      GetLinkedTransactionsParams
    >({
      query: (payload) => {
        return {
          url: `linked-transactions/`,
          method: "POST",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async (response) => {
            const transactions = await response.json();

            return handleResponse(
              transformKeysToCamelCase({
                source: payload.type,
                transactions,
              }),
              linkedTransactionsSchema
            );
          },
        };
      },
    }),
  }),
});

export const { useGetLinkedTransactionsMutation } = linkedTransactionsApi;
