import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { DropZone } from "@components/draggable";

import { useUpdateLienWaiverRequestMutation } from "../api/api";
import { LIEN_WAIVER_STATUS, STRINGS } from "../constants/constants";

import { type BillLienWaiverFieldProps } from "./bill-lien-waiver-field";

type BillLienWaiverMarkSignedDialogProps = {
  onClose: () => void;
  show: boolean;
} & Required<
  Pick<BillLienWaiverFieldProps, "onRequestUpdate" | "billLienWaiver">
>;

export const BillLienWaiverMarkSignedDialog = ({
  onClose,
  show,
  billLienWaiver,
  onRequestUpdate,
}: BillLienWaiverMarkSignedDialogProps) => {
  const [file, setFile] = useState<File | null>(null);

  const [updateLienWaiverRequest, { isLoading }] =
    useUpdateLienWaiverRequestMutation();

  const onSubmit = useEvent(async () => {
    if (!billLienWaiver) return;
    const payload = {
      id: billLienWaiver.id,
      status: LIEN_WAIVER_STATUS.MARKED_AS_SIGNED,
      pdf: file,
    };

    try {
      await updateLienWaiverRequest(payload).unwrap();
      onRequestUpdate();
      toast.success(STRINGS.LIEN_WAIVER_MARKED_AS_SIGNED);
      onClose();
    } catch (error) {
      handleErrors(error);
    }
  });

  return (
    <Dialog variant="dialog" size="auto" show={show}>
      <DialogHeader>
        <Text align="center" weight="bold">
          {STRINGS.UPLOAD_SIGNATURE_DIALOG_TITLE}
        </Text>
      </DialogHeader>

      <DialogContent>
        <Flex width="480px" direction="column" gap="2xl">
          <Text align="center">{STRINGS.UPLOAD_SIGNATURE_DIALOG_TEXT}</Text>
          <DropZone
            showBorder
            onDrop={([file]) => setFile(file)}
            justify="space-between"
            flip="horizontal"
            padding="2xl"
            gap="2xl"
            idleMessage={STRINGS.UPLOAD_SIGNATURE_DROPZONE_TITLE}
            draggingMessage={STRINGS.UPLOAD_SIGNATURE_DROPZONE_DROP}
            hasPermission={!isLoading}
          >
            {file ? (
              <>
                <Flex align="center" justify="flex-start">
                  <Icon name="image" size="2xl" />
                </Flex>
                <Flex grow direction="column" gap="sm">
                  <Text weight="bold">Filename</Text>
                  <Text truncate>{file.name}</Text>
                </Flex>
              </>
            ) : null}
          </DropZone>
        </Flex>
      </DialogContent>

      <DialogFooter>
        <Button
          block
          disabled={isLoading}
          onClick={onClose}
          variant="text"
          color="neutral"
        >
          {STRINGS.CANCEL}
        </Button>
        <Button
          block
          disabled={isLoading || !file}
          onClick={onSubmit}
          color="primary"
        >
          {STRINGS.UPLOAD_SIGNATURE_DIALOG_CONFIRM}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
