import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  dialog,
  Flex,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { formatDate, parseDate } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { loadPurchaseOrder } from "@store/purchaseOrderSlice";
import { documentsRequestsApi } from "@vendors/api";
import { type AnyAction } from "redux";

import { STRINGS } from "./constants";
import { poAlertVariant, VENDOR_REQUEST_STATUS } from "./constants";
import { PoRequestDialog } from "./po-signature-request-dialog";
import { type PurchaseOrder } from "./request-vendor-po-signature";

type AlertProps = {
  purchaseOrder: PurchaseOrder;
  onSubmitRequestPo: () => void;
  onMarkAsSigned: () => void;
  disabled: boolean | string;
};

const promptCancellationConfirmation = (onConfirm: () => void) => {
  dialog.confirmation({
    title: (
      <>
        Are you sure you want to cancel
        <br />
        this signature request?
      </>
    ),
    size: "auto",
    align: "center",
    variant: "dialog",
    message: (
      <Flex gap="xl" direction="column" minWidth="505px">
        <Text align="center">
          This cannot be undone. You will need to send a new
          <br />
          request to collect signature from your vendor.
        </Text>
      </Flex>
    ),
    action: {
      primary: {
        children: "Cancel request",
        color: "error",
        onClick: onConfirm,
      },
      secondary: {
        children: "Keep request",
      },
    },
  });
};

export const RequestedVendorPoSignatureAlert = ({
  purchaseOrder,
  onSubmitRequestPo,
  onMarkAsSigned,
  disabled,
}: AlertProps) => {
  const {
    latest_po_signature_request: latestPoRequest,
    po_signature_can_be_requested: signatureCanBeRequested,
    vendor,
  } = purchaseOrder ?? {};

  const selectedPurchaseOrders = useMemo(
    () => [purchaseOrder],
    [purchaseOrder]
  );

  const requestPoDialog = useDialog({ lazy: true });

  const dispatch = useDispatch();
  const refetchPurchaseOrder = useCallback(
    () =>
      purchaseOrder.id
        ? dispatch(loadPurchaseOrder(purchaseOrder.id) as unknown as AnyAction)
        : Promise.resolve(),
    [dispatch, purchaseOrder.id]
  );

  const onConfirmPoRequestCancellation = useEvent(
    async (request_id?: string | number) => {
      try {
        await documentsRequestsApi.deleteVendorPoRequest({
          request_id: request_id,
          vendor_id: vendor.id,
        });
        toast.success("Request for signature canceled");
        refetchPurchaseOrder();
      } catch (error: any) {
        handleErrors(error);
      }
    }
  );

  if (!latestPoRequest || !vendor) return null;

  const date =
    latestPoRequest.created_at &&
    `${formatDate(
      parseDate(latestPoRequest.created_at ?? "", ["yyyy-MM-dd hh:mm", "iso"]),
      "PPPp"
    )}`;

  const variant = poAlertVariant(latestPoRequest.status);
  const message =
    latestPoRequest.status === VENDOR_REQUEST_STATUS.PENDING
      ? `You sent ${latestPoRequest.vendor_email} a request for signature on ${date}`
      : latestPoRequest.status === VENDOR_REQUEST_STATUS.EXPIRED
        ? `The request for signature you sent to ${latestPoRequest.vendor_email} on ${date} has expired`
        : "";

  return (
    <>
      <Alert variant={variant}>
        <AlertTitle>{message}</AlertTitle>
        <AlertContent as="div">
          <Flex gap="xl" direction="column">
            <Flex gap="xl">
              <Tooltip
                message={
                  !signatureCanBeRequested
                    ? "Request sent within 24 hours"
                    : disabled
                      ? typeof disabled === "string"
                        ? disabled
                        : "You must save the purchase order before you can send the request"
                      : ""
                }
                placement="top"
              >
                <Button
                  size="sm"
                  disabled={!!disabled || !signatureCanBeRequested}
                  onClick={requestPoDialog.show}
                >
                  {STRINGS.SEND_REMINDER_LABEL}
                </Button>
              </Tooltip>
              {latestPoRequest.status === VENDOR_REQUEST_STATUS.PENDING ? (
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() =>
                    promptCancellationConfirmation(() =>
                      onConfirmPoRequestCancellation(latestPoRequest.id)
                    )
                  }
                >
                  {STRINGS.CANCEL_REQUEST_LABEL}
                </Button>
              ) : (
                <Tooltip
                  message={
                    disabled
                      ? typeof disabled === "string"
                        ? disabled
                        : "You must save the purchase order before you can mark it as signed"
                      : ""
                  }
                  placement="top"
                >
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={onMarkAsSigned}
                    disabled={!!disabled}
                  >
                    {STRINGS.MARK_AS_SIGNED_LABEL}
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </AlertContent>
      </Alert>
      {requestPoDialog.isRendered ? (
        <PoRequestDialog
          dialog={requestPoDialog}
          multiple={false}
          onSubmit={onSubmitRequestPo}
          purchaseOrders={selectedPurchaseOrders}
        />
      ) : null}
    </>
  );
};
