import type { IconProps } from "@adaptive/design-system";

export const TYPE_STRINGS = {
  purchase_order: "PO",
  bill: "Bill",
  invoice: "Draw",
  line: "Line",
  invoice_line: "Draw line",
  expense: "Receipt",
} as Record<string, string>;

export const TYPE_SORTING_ORDER = [
  TYPE_STRINGS.purchase_order,
  TYPE_STRINGS.bill,
  TYPE_STRINGS.invoice,
];

export const FIELD_STRINGS = {
  vendor: "Vendor",
  account: "Account",
  item: "Cost code",
  accountCostCode: "Cost code / Account",
  customer: "Job",
  description: "Description",
} as Record<string, string>;

export const STRINGS = {
  DIALOG_HEADER_FIELD_CHANGE:
    "You changed 1 field linked to 1 {type}. Apply this change to the {type}?",
  DIALOG_HEADER_FIELDS_CHANGE:
    "You changed fields linked to 1 {type}. Apply this change to the {type}?",

  DIALOG_HEADER_FIELD_CHANGE_TO_N:
    "You changed 1 field linked to {qty} {typePlural}. Apply this change to the {index} {type}?",
  DIALOG_HEADER_FIELDS_CHANGE_TO_N:
    "You changed fields linked to {qty} {typePlural}. Apply this change to the {index} {type}?",

  DIALOG_HEADER_REVERTED:
    "You reverted a {originType} linked to 1 {type}. Apply this change to the {type}?",
  DIALOG_HEADER_REVERTED_TO_N:
    "You reverted a {originType} linked to {qty} {typePlural}. Apply this change to the {index} {type}?",

  DIALOG_HEADER_ARCHIVED:
    "You archived a {originType} linked to 1 {type}. Apply this change to the {type}?",
  DIALOG_HEADER_ARCHIVED_TO_N:
    "You archived a {originType} linked to {qty} {typePlural}. Apply this change to the {index} {type}?",

  DIALOG_HEADER_DELETED:
    "You deleted a line linked to 1 {type}. Apply this change to the {type}?",
  DIALOG_HEADER_DELETED_N:
    "You deleted {originQty} lines linked to 1 {type}. Apply this change to the {type}?",
  DIALOG_HEADER_DELETED_TO_N:
    "You deleted {originQty} lines linked to {qty} {typePlural}. Apply this change to the {index} {type}?",

  REVIEW_DIALOG_HEADER: "Review and save changes",
  NEXT: "Next",
  CANCEL: "Cancel",
  BACK: "Back",
  OPTION_DESCRIPTION_UPDATE: "Update to new {fieldType} from {parentType}",
  OPTION_DESCRIPTION_KEEP: "Keep original {fieldType} on {itemType}",
  OPTION_DESCRIPTION_KEEP_UNLINK:
    "Keep original {fieldType} on {itemType} & unlink from {parentType}",
  OPTION_DELETED_KEEP_DESCRIPTION:
    "Keep original line and unlink from {parentType}",
  DELETE_LINE: "Delete line",
  LINE_DELETED: "Line deleted",
  BILL_REVERTED: "Bill reverted to draft",
  BILL_ARCHIVED: "Bill archived",
  UNLINK_LINE: "Unlink line",
  DELETE_ITEM: "Delete {type}",
  UPDATE_TOAST_MESSAGE: "Linked objects updated:",
  CHANGES_NOT_SAVED: "Your changes have not yet been saved",
  JOB_CANNOT_BE_CHANGED: "Job cannot be changed on a draw line",
  SUMMARY_OBJECT_DELETED: "{object} deleted",
  SUMMARY_OBJECT_UNLINKED: "Unlinked from {from}",
  SUMMARY_OBJECT_REVERTED: "Reverted to draft",
  SUMMARY_OBJECT_ARCHIVED: "{object} archived",
  SUMMARY_CHANGED_FIELDS: "{fields} changed",
  SUMMARY_LINKED_TO: "Linked to {linkedTo}",
};

export const COMBOBOX_VALUES = {
  NEW: "new",
  OLD: "old",
  UNLINK: "unlink",
  DELETE: "delete",
};

export const COMBOBOX_VALUES_STOP_PROPAGATION = [
  COMBOBOX_VALUES.OLD,
  COMBOBOX_VALUES.UNLINK,
];

export const INVOICE_LINE_PROPAGATION_FIELDS = [
  "item",
  "vendor",
  "account",
  "description",
];

export const SUMMARY_ICONS: Record<string, IconProps["name"]> = {
  fields: "pen",
  deleted: "trash",
  linkedTo: "link",
  unlinked: "link-slash",
  reverted: "backward",
  archived: "folder-open",
};
