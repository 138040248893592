import { formatDate } from "@adaptive/design-system/utils";
import { createSlice } from "@reduxjs/toolkit";
import { sumBy } from "@utils/sumBy";
import axios from "axios";
import lodashExtend from "lodash.assignin";

import {
  deletePurchaseOrder,
  getPurchaseOrder,
  purchaseOrdersApi,
  savePurchaseOrder,
} from "../api/purchase-orders";

import { updatePurchaseOrderList } from "./purchaseOrderListSlice";

const DEFAULT_PURCHASE_ORDER = {
  id: null,
  vendor: {
    display_name: null,
    url: null,
    email: [],
  },
  date: formatDate(new Date(), "yyyy-MM-dd"),
  doc_number: "",
  total_amount: 0,
  shipping_address: "",
  private_note: "",
  po_status: "Open",
  realm: null,
  type: "",
  open_balance: 0,
  lines: [
    {
      customer: {
        display_name: null,
        url: null,
      },
      item: {
        display_name: null,
        url: null,
      },
      account: {
        display_name: null,
        url: null,
      },
      amount: 0,
      balance: null,
      description: "",
      status: "Open",
      force_close: false,
      id: "added_line_0",
      created: true,
      linked_bills: [],
      is_linked_to_bill: false,
      linked_bills_count: 0,
    },
  ],
  errors: [],
  is_creator: false,
  is_signed: false,
  related_errors: [],
  comments: [],
  scope_of_work: null,
  url: null,
};

const initialState = {
  purchaseOrder: DEFAULT_PURCHASE_ORDER,
  staticPurchaseOrder: DEFAULT_PURCHASE_ORDER,
  isDirty: false,
  purchaseOrderLoading: false,
  source: null,
  linesLimit: 100,
};

const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    resetPurchaseOrder: (state, action) => {
      if (state.purchaseOrderLoading) {
        state.source?.cancel("Purchase order closed before fetch was complete");
      }
      state = JSON.parse(JSON.stringify(initialState));
      state.purchaseOrder.realm = action.payload;

      return state;
    },
    recordPurchaseOrderUpdate: (state, action) => {
      state.purchaseOrder = lodashExtend(state.purchaseOrder, action.payload);
    },
    recordStaticPurchaseOrderUpdate: (state, action) => {
      state.staticPurchaseOrder = lodashExtend(
        state.purchaseOrder,
        action.payload
      );
    },
    setPurchaseOrderLoading: (state, action) => {
      state.purchaseOrderLoading = action.payload;
    },
    setPurchaseOrderSource: (state, action) => {
      state.source = action.payload;
    },
    recordLineUpdate: (state, action) => {
      const { id, payload } = action.payload;
      const index = state.purchaseOrder.lines.findIndex(
        (line) => line.id === id
      );
      const lineItem = state.purchaseOrder.lines[index];
      state.purchaseOrder.lines[index] = lodashExtend(lineItem, payload);
      state.purchaseOrder.po_status = getPOStatus(state);
    },
    updateTotalFromLines: (state) => {
      const lines = state.purchaseOrder.lines.filter((line) => !line.deleted);
      state.purchaseOrder.total_amount = sumBy(lines, "amount");
      state.purchaseOrder.open_balance = sumBy(lines, "balance");
    },
    createPurchaseOrder: (state, action) => {
      state.purchaseOrder = {
        ...action.payload,
        lines:
          action.payload?.lines?.map((line) => ({
            ...line,
            amount: Number(line.amount),
            balance: Number(line.balance),
          })) || [],
        shipping_address: action.payload.shipping_address || "",
        private_note: action.payload.private_note || "",
      };
      state.staticPurchaseOrder = action.payload;
    },
    addLine: (state, action) => {
      state.purchaseOrder.lines = state.purchaseOrder.lines.concat({
        type: action.payload?.type,
        customer: { display_name: null, url: null },
        item: action.payload?.item ?? { display_name: null, url: null },
        account: action.payload?.account ?? { display_name: null, url: null },
        amount: action.payload?.amount || 0,
        balance: action.payload?.balance || null,
        description: action.payload?.description ?? "",
        status: action.payload?.status ?? "Open",
        force_close: action.payload?.force_close ?? false,
        id: `added_line_${state.purchaseOrder.lines.length}`,
        created: true,
        is_linked_to_bill: false,
        linked_bills_count: 0,
      });
    },
    updateLines: (state, action) => {
      state.purchaseOrder.lines = action.payload;
    },
    addLines: (state, action) => {
      state.purchaseOrder.lines = state.purchaseOrder.lines.concat(
        action.payload
      );
    },
    removeLine: (state, action) => {
      const index = state.purchaseOrder.lines.findIndex(
        (line) => line.id === action.payload
      );
      state.purchaseOrder.lines[index].deleted = true;
      state.purchaseOrder.po_status = getPOStatus(state);
    },
  },
});

const getPOStatus = (state) => {
  const { openLines, closedLines } = state.purchaseOrder.lines.reduce(
    (acc, line) => {
      if (line.status === "Open") {
        return { ...acc, openLines: [...acc.openLines, line] };
      } else if (line.status === "Closed") {
        return { ...acc, closedLines: [...acc.closedLines, line] };
      }
    },
    { openLines: [], closedLines: [] }
  );
  if (closedLines.length === state.purchaseOrder.lines.length) return "Closed";
  if (openLines.length) return "Open";
  return state.purchaseOrder.po_status;
};

export const {
  addLine,
  addLines,
  createPurchaseOrder,
  recordLineUpdate,
  recordPurchaseOrderUpdate,
  recordStaticPurchaseOrderUpdate,
  removeLine,
  resetPurchaseOrder,
  setPurchaseOrderLoading,
  setPurchaseOrderSource,
  updateLines,
  updateTotalFromLines,
} = purchaseOrderSlice.actions;

export const loadPurchaseOrder =
  (id, { skipLoading, fieldsToUpdate } = {}) =>
  async (dispatch, getState) => {
    const CancelToken = axios.CancelToken;

    const source = CancelToken.source();

    const { purchaseOrder, source: previousSource } = getState().purchaseOrder;

    previousSource?.cancel();

    const shouldReset = purchaseOrder.id != id;

    if (!skipLoading) dispatch(setPurchaseOrderLoading(true));

    dispatch(setPurchaseOrderSource(source));

    try {
      const { data } = await getPurchaseOrder(id, source);

      if (shouldReset || !fieldsToUpdate) {
        dispatch(createPurchaseOrder(data));
      } else if (Array.isArray(fieldsToUpdate)) {
        fieldsToUpdate.forEach((field) => {
          dispatch(recordPurchaseOrderUpdate({ [field]: data[field] }));
          dispatch(recordStaticPurchaseOrderUpdate({ [field]: data[field] }));
        });
      } else if (typeof fieldsToUpdate === "function") {
        fieldsToUpdate(data);
      }
      dispatch(setPurchaseOrderLoading(false));
      return data;
    } catch (error) {
      if (!axios.isCancel(error)) throw error;
    }
  };

export const refetchCurrentPurchaseOrder =
  (fieldsToUpdate, skipLoading) => (dispatch, getState) => {
    const { purchaseOrder } = getState().purchaseOrder;

    if (purchaseOrder.id) {
      return dispatch(
        loadPurchaseOrder(purchaseOrder.id, { fieldsToUpdate, skipLoading })
      );
    }
  };

export const putPurchaseOrder = (propagate) => async (dispatch, getState) => {
  const { purchaseOrder } = getState().purchaseOrder;

  const { id } = await savePurchaseOrder({ ...purchaseOrder, propagate });

  dispatch(
    purchaseOrdersApi.util.invalidateTags([
      "CostCodesAccountsSimplified",
      { type: "PurchaseOrders", id },
      { type: "PurchaseOrders", id: "LIST" },
    ])
  );

  const [data] = await Promise.all([
    dispatch(loadPurchaseOrder(id)),
    dispatch(updatePurchaseOrderList()),
  ]);

  return data;
};

export const removePurchaseOrder = () => async (dispatch, getState) => {
  const { purchaseOrder } = getState().purchaseOrder;

  const { data } = await deletePurchaseOrder(purchaseOrder.id);

  dispatch(updatePurchaseOrderList());

  dispatch(resetPurchaseOrder(purchaseOrder.realm));

  dispatch(
    purchaseOrdersApi.util.invalidateTags([
      "CostCodesAccountsSimplified",
      { type: "PurchaseOrders", id: purchaseOrder.id },
      { type: "PurchaseOrders", id: "LIST" },
    ])
  );

  return data;
};

export const { reducer } = purchaseOrderSlice;
