import { type RefObject, useEffect } from "react";

import { debounce } from "../../utils/debounce";
import { is } from "../../utils/is";
import { useDeepMemo } from "../use-deep-memo";
import { useEvent } from "../use-event";

export const useOnInteractOutside = (
  ref: RefObject<HTMLElement | null> | RefObject<HTMLElement | null>[],
  onChange: () => void
) => {
  const enhancedOnInteractOutside = useEvent(onChange);

  const refs = useDeepMemo(
    () => (is.array(ref) ? ref.map((r) => r) : [ref]).filter(Boolean),
    [ref]
  );

  useEffect(() => {
    /**
     * With debounce we guarantee that we execute the callback once
     */
    const onInteract = debounce((el: HTMLElement) => {
      const isOutside = !refs.some(
        (ref) => !ref.current || ref.current.contains(el)
      );

      if (isOutside) enhancedOnInteractOutside();
    });

    const onFocus = () => onInteract(document.activeElement as HTMLElement);
    const onPress = (e: Event) => onInteract(e.target as HTMLElement);

    document.addEventListener("focus", onFocus, true);
    document.addEventListener("mousedown", onPress);
    document.addEventListener("touchstart", onPress);

    return () => {
      document.addEventListener("focusin", onFocus);
      document.removeEventListener("mousedown", onPress);
      document.removeEventListener("touchstart", onPress);
    };
  }, [enhancedOnInteractOutside, refs]);
};
