import React, { memo, type PropsWithChildren, useState } from "react";
import { MenuProvider, type MenuStoreState } from "@ariakit/react";

import { useEvent } from "../../hooks/use-event";
import { is } from "../../utils/is";
import { isEqual } from "../../utils/is-equal";

import { DropdownContext } from "./dropdown-context";

export type DropdownProps = PropsWithChildren<{
  show?: boolean;
  flip?: boolean;
  trigger?: "hover" | "click";
  listSize?: number;
  maxWidth?: number | "max-content";
  onChange?: (show: boolean) => void;
  placement?: MenuStoreState["placement"];
  initialShow?: boolean;
  hideOnClick?: boolean;
}>;

export type DropdownValue = string | boolean | number | Array<string | number>;

export type DropdownValues = Record<string, DropdownValue>;

export type DropdownOnSetValues = Record<
  string,
  (value: DropdownValue) => void
>;

const Dropdown = ({
  flip = true,
  show,
  trigger = "click",
  children,
  listSize,
  onChange,
  maxWidth,
  placement,
  hideOnClick,
  initialShow = false,
}: DropdownProps) => {
  const [values, setValues] = useState<DropdownValues>({});

  const [onSetValues, setOnSetValues] = useState<DropdownOnSetValues>({});

  const enhancedSetValues = useEvent<typeof setValues>((nextValues) =>
    setValues((prevValues) => {
      const newValues = is.function(nextValues)
        ? nextValues(prevValues)
        : nextValues;

      if (isEqual(prevValues, newValues)) return prevValues;

      Object.entries(newValues).forEach(([key, value]) => {
        if (isEqual(prevValues[key], value)) return;

        const onSetValue = onSetValues[key];

        if (!onSetValue) return;

        queueMicrotask(() => onSetValue(value));
      });

      return newValues;
    })
  );

  return (
    <DropdownContext.Provider
      value={{
        flip,
        trigger,
        maxWidth,
        listSize,
        setValues,
        hideOnClick,
        setOnSetValues,
      }}
    >
      <MenuProvider
        open={show}
        parent={null}
        values={values}
        setOpen={onChange}
        focusShift
        focusWrap={false}
        focusLoop="vertical"
        placement={placement}
        setValues={enhancedSetValues}
        defaultOpen={initialShow}
      >
        {children}
      </MenuProvider>
    </DropdownContext.Provider>
  );
};

const MemoizedDropdown = memo(Dropdown);

export { MemoizedDropdown as Dropdown };
