import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Flex,
  Loader,
  Table,
  TableConfigurationButton,
  type TablePaginationAddon,
  TableProvider,
  type TableRenderColumn,
  type TableRowAddon,
  type TableSelectAddon,
  TagGroup,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useEvent,
  useLocalStorageState,
} from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  isEqual,
} from "@adaptive/design-system/utils";
import type {
  ActionType,
  Job,
  OptionType,
  RestrictedUser,
  SearchedJob,
} from "@api/jobs";
import { CURRENCY_FORMAT } from "@src/jobs";
import { DEFAULT_PAGINATION_LIMIT } from "@src/shared/constants";
import { api } from "@store/api-simplified";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  changeSortOption,
  fetchJobs,
  resetFilters,
  setJobsFilter,
  updateFilter,
  updateLimit,
} from "@store/jobs";
import { useDrawerVisibility } from "@store/ui";
import { BasePermissions, useClientInfo, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";

import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "../sticky";

import { CustomersTableFilter } from "./customers-table-filter";
import { batchStatusUpdate, checkFilters } from "./utils";

const DEFAULT_PEOPLE_ON_JOB: RestrictedUser[] = [
  {
    id: 1,
    full_name: "Everyone",
    url: "",
    email: "Everyone",
  },
];

const COLUMNS: TableRenderColumn<Job>[] = [
  {
    id: "display_name",
    sortable: "asc",
    width: "fill",
    minWidth: 200,
    visibility: "always-visible",
    name: "Job name",
    render: (row) => (
      <Text data-testid="jobs-table-display-name">{row.display_name}</Text>
    ),
  },
  {
    id: "parent",
    sortable: "asc",
    name: "Parent",
    minWidth: 200,
    render: "parent_display_name",
  },
  {
    id: "builder_revised_amount",
    visibility: {
      name: "Budget",
      mode: "visible",
    },
    sortable: true,
    name: (
      <Flex>
        <Tooltip message="Revised budget for the job including any changes">
          <Text weight="bold">Budget</Text>
        </Tooltip>
      </Flex>
    ),
    width: 150,
    textAlign: "right",
    render: (row) =>
      formatCurrency(row.builder_revised_amount, CURRENCY_FORMAT),
  },
  {
    id: "spent",
    sortable: true,
    visibility: {
      name: "Costs to date",
      mode: "visible",
    },
    name: (
      <Flex>
        <Tooltip message="Total cost of approved bills & receipt">
          <Text weight="bold">Costs to date</Text>
        </Tooltip>
      </Flex>
    ),
    width: 150,
    textAlign: "right",
    render: (row) => formatCurrency(row.spent, CURRENCY_FORMAT),
  },
  {
    id: "unpaid_bills",
    visibility: {
      name: "Unpaid bills",
      mode: "visible",
    },
    name: (
      <Flex>
        <Tooltip message="Total cost of approved unpaid bills">
          <Text weight="bold">Unpaid bills</Text>
        </Tooltip>
      </Flex>
    ),
    width: 150,
    sortable: true,
    render: (row) => formatCurrency(row.unpaid_bills, CURRENCY_FORMAT),
    textAlign: "right",
  },
  {
    id: "undrawn_costs",
    visibility: {
      name: "Undrawn costs",
      mode: "visible",
    },
    name: (
      <Flex>
        <Tooltip message="Total approved billable costs that have not yet been drawn">
          <Text weight="bold">Undrawn costs</Text>
        </Tooltip>
      </Flex>
    ),
    width: 160,
    sortable: true,
    render: (row) => formatCurrency(row.undrawn_costs, CURRENCY_FORMAT),
    textAlign: "right",
  },
  {
    id: "builder_revised_remaining_amount",
    sortable: true,
    visibility: {
      name: "Budget remaining",
      mode: "visible",
    },
    name: (
      <Flex>
        <Tooltip message="[Revised cost budget] - [Costs to date]">
          <Text weight="bold">Budget remaining</Text>
        </Tooltip>
      </Flex>
    ),
    width: 180,
    textAlign: "right",
    render: (row) =>
      formatCurrency(row.builder_revised_remaining_amount, CURRENCY_FORMAT),
  },
  {
    id: "owners_revised_amount",
    sortable: true,
    name: "Revenue budget",
    width: 170,
    textAlign: "right",
    render: (row) =>
      row.owners_amount_enabled
        ? formatCurrency(row.owners_revised_amount, CURRENCY_FORMAT)
        : "—",
  },
  {
    id: "invoiced_amount",
    sortable: true,
    name: "Drawn",
    width: 150,
    textAlign: "right",
    render: (row) => formatCurrency(row.invoiced_amount, CURRENCY_FORMAT),
  },
  {
    id: "invoiced_remaining_amount",
    sortable: true,
    visibility: {
      name: "Draw remaining",
      mode: "visible",
    },
    name: (
      <Flex>
        <Tooltip message="If an external budget is enabled, this column shows [External budget] - [Drawn]. Otherwise, this column shows [Budget] - [Drawn].">
          <Text weight="bold">Draw remaining</Text>
        </Tooltip>
      </Flex>
    ),
    width: 170,
    textAlign: "right",
    render: (row) =>
      formatCurrency(row.invoiced_remaining_amount, CURRENCY_FORMAT),
  },
  {
    id: "true_created_at",
    sortable: true,
    width: 150,
    name: "Created date",
    render: (row) => row.created_at && formatDate(new Date(row.created_at)),
  },
  {
    id: "most_recent_transaction_date",
    sortable: true,
    width: 160,
    name: "Recent activity",
    render: (row) =>
      row.most_recent_transaction_date &&
      formatDate(new Date(row.most_recent_transaction_date)),
  },
  {
    id: "people-on-job",
    name: "People on job",
    render: (row) => (
      <TagGroup
        data={
          row.restricted_to_users.length == 0
            ? DEFAULT_PEOPLE_ON_JOB
            : row.restricted_to_users
        }
        limit={3}
        render={(user) => user.full_name || user.email}
        data-testid="jobs-table-people-on-job"
      />
    ),
  },
];

export type CustomersTableProps = {
  id: string;
  row?: TableRowAddon<Job>;
  active: boolean;
  sticky?: boolean;
  tableId?: string;
  select?: TableSelectAddon<Job>;
  maxHeight?: string;
  resetOnMount?: boolean;
  visibleColumns?: string[];
  paginationPerPageVariant?: TablePaginationAddon["perPageVariant"];
};

export const CustomersTable = ({
  id,
  sticky = true,
  active,
  tableId,
  maxHeight,
  resetOnMount = false,
  visibleColumns,
  paginationPerPageVariant = "md",
  ...props
}: CustomersTableProps) => {
  const action: ActionType = active ? "active" : "inactive";

  const dispatch = useAppDispatch();

  const { realmId } = useClientInfo();

  const { setVisible } = useDrawerVisibility("job");

  const { hasPermission } = useUserInfo();

  const [fetchFlags, setFetchFlags] = useState<string[]>([]);

  const [selected, setSelected] = useState<Job[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [stickyOffset, setStickyOffset] = useState(0);

  const alreadyResetRef = useRef(false);

  const canAddJobs = useMemo(
    () => hasPermission(BasePermissions.MANAGE_JOBS),
    [hasPermission]
  );

  const [stateLimit, setStateLimit] = useLocalStorageState(
    `${id}-limit`,
    DEFAULT_PAGINATION_LIMIT
  );

  const [stateActiveJobs, setStateActiveJobs] = useLocalStorageState<
    SearchedJob[]
  >(`${id}-active-jobs-filter`, []);

  const [stateInactiveJobs, setStateInactiveJobs] = useLocalStorageState<
    SearchedJob[]
  >(`${id}-inactive-jobs-filter`, []);

  const [stateOrder, setStateOrder] = useLocalStorageState(
    `${id}-order`,
    "-most_recent_transaction_date"
  );

  const { data, page, total, sortBy, status, perPage } = useAppSelector(
    (state) => {
      const jobs = state.jobs;
      const list = jobs[`${action}Jobs`];
      const offset =
        (jobs[`${action}Filters`].find((filter) => filter.index === "offset")
          ?.value as number) || 0;

      let limit = Number(jobs.limit ?? DEFAULT_PAGINATION_LIMIT);

      if (stateLimit !== limit) {
        dispatch(updateLimit(stateLimit));
        limit = stateLimit;
      }

      return {
        data: list.jobs,
        page: offset / limit,
        total: list.total,
        status: list.status,
        sortBy: jobs.sortOption,
        perPage: limit,
      };
    },
    isEqual
  );

  const filters = useAppSelector(
    (state) => [
      ...state.jobs[`${active ? "active" : "inactive"}Filters`],
      { index: "limit", value: state.jobs.limit },
    ],
    isEqual
  );

  const columns = useMemo(
    () =>
      COLUMNS.map((column) => ({
        ...column,
        visibility:
          typeof column.visibility === "object"
            ? {
                ...column.visibility,
                mode: visibleColumns
                  ? visibleColumns.includes(column.id)
                    ? column.visibility.mode === "always-visible"
                      ? "always-visible"
                      : "visible"
                    : "hidden"
                  : column.visibility.mode,
              }
            : visibleColumns
              ? visibleColumns.includes(column.id)
                ? column.visibility === "always-visible"
                  ? "always-visible"
                  : "visible"
                : "hidden"
              : column.visibility,
      })),
    [visibleColumns]
  );

  const select = useMemo<TableSelectAddon<Job>>(
    () => ({ value: selected, onChange: setSelected }),
    [selected]
  );

  const onUpdateStatus = useEvent(async () => {
    setIsLoading(true);

    await batchStatusUpdate(selected, action === "active" ? false : true);

    analytics.track("jobBatchActions", {
      action: action === "active" ? "deactivate" : "activate",
      jobIds: selected.map((job) => job.id),
      location: `${action}_tab`,
    });

    /**
     * @todo move async logic to live on redux RTK to
     * avoid this kind of workaround to invalidate cache
     */
    dispatch(api.util.invalidateTags(["CustomersSimplified"]));
    setSelected([]);
    setIsLoading(false);

    if (checkFilters(filters)) {
      dispatch(fetchJobs("active", filters));
      dispatch(fetchJobs("inactive", filters));
    }
  });

  const onPageChange = useEvent((page: number) => {
    dispatch(
      updateFilter({
        action,
        filter: { index: "offset", value: page * perPage },
      })
    );
  });

  const onPerPageChange = useEvent((perPage: number) => {
    dispatch(updateLimit(perPage));
    setStateLimit(perPage);
    onPageChange(0);
    analytics.track("perPageLimitChange", {
      location: "customers-table",
      limit: perPage,
    });
  });

  const onSortChange = useEvent((value: OptionType) => {
    setStateOrder(value);
    onPageChange(0);
  });

  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setStickyOffset(sticky.height);
  });

  const emptyState = useMemo(
    () => ({
      title: "You currently have no jobs!",
      ...(maxHeight
        ? {}
        : {
            subtitle: "Create your first job.",
            action: {
              disabled: !canAddJobs,
              children: "Create new job",
              onClick: () => setVisible(true),
            },
          }),
    }),
    [maxHeight, canAddJobs, setVisible]
  );

  const pagination = useDeepMemo<Required<TablePaginationAddon>>(
    () => ({
      page,
      total,
      perPage,
      perPageVariant: paginationPerPageVariant,
      onChange: onPageChange,
      onPerPageChange,
    }),
    [page, perPage, total, onPageChange, paginationPerPageVariant]
  );

  const hasData = data.length > 0;

  const header = useMemo(
    () => ({
      hide: !hasData,
      sticky: sticky ? { offset: stickyOffset } : undefined,
    }),
    [hasData, sticky, stickyOffset]
  );

  const sort = useMemo(
    () => ({ value: sortBy, onChange: onSortChange }),
    [sortBy, onSortChange]
  );

  const onFilterChange = useEvent((jobs: SearchedJob[]) => {
    if (active) {
      setStateActiveJobs(jobs);
    } else {
      setStateInactiveJobs(jobs);
    }
  });

  useEffect(() => {
    dispatch(setJobsFilter({ action: "active", jobs: stateActiveJobs }));
    setFetchFlags((filters) =>
      filters.includes("active") ? filters : [...filters, "active"]
    );
  }, [dispatch, stateActiveJobs]);

  useEffect(() => {
    dispatch(setJobsFilter({ action: "inactive", jobs: stateInactiveJobs }));
    setFetchFlags((filters) =>
      filters.includes("inactive") ? filters : [...filters, "inactive"]
    );
  }, [dispatch, stateInactiveJobs]);

  useEffect(() => {
    const filter = { index: "order_by", value: stateOrder };
    dispatch(updateFilter({ action: "active", filter }));
    dispatch(updateFilter({ action: "inactive", filter }));
    dispatch(changeSortOption(stateOrder));
    setFetchFlags((filters) =>
      filters.includes("order") ? filters : [...filters, "order"]
    );
  }, [dispatch, stateOrder]);

  useEffect(() => {
    const currentFilterRealm = filters.find(
      (filter) => filter.index === "realm"
    )?.value;

    const hasChangedRealm = realmId && currentFilterRealm != realmId;

    if (hasChangedRealm) dispatch(resetFilters(realmId));
  }, [realmId, dispatch, filters]);

  useEffect(() => {
    if (resetOnMount && !alreadyResetRef.current && realmId) {
      dispatch(resetFilters(realmId));
      setStateActiveJobs([]);
      setStateInactiveJobs([]);
      alreadyResetRef.current = true;
    }

    setFetchFlags((filters) =>
      filters.includes("reset") ? filters : [...filters, "reset"]
    );
  }, [
    realmId,
    dispatch,
    resetOnMount,
    setStateActiveJobs,
    setStateInactiveJobs,
  ]);

  /**
   * We need to sync localStorage filters with redux filters, but it could
   * happen on different times, so we need to wait until all filters are set
   * to fetch the data.
   */
  const canFetch = fetchFlags.length === 4;

  useEffect(() => {
    if (canFetch && checkFilters(filters)) {
      dispatch(fetchJobs(active ? "active" : "inactive", filters));
    }
  }, [filters, dispatch, canFetch, active]);

  useEffect(() => {
    setSelected([]);
  }, [data]);

  return (
    <>
      {isLoading && <Loader position="fixed" />}
      <Flex width="full" direction="column">
        <TableProvider id={tableId ?? id}>
          <StickyProvider enabled={sticky} onResize={onResize}>
            <Sticky
              style={{
                paddingTop: "var(--spacing-2xl)",
                paddingBottom: "var(--spacing-lg)",
              }}
            >
              <Flex gap="md" direction="column">
                <CustomersTableFilter active={active} onChange={onFilterChange}>
                  <Flex gap="md">
                    <TableConfigurationButton />
                    {selected.length > 0 && (
                      <Button onClick={onUpdateStatus}>
                        {action === "active" ? "Deactivate" : "Activate"}
                      </Button>
                    )}
                  </Flex>
                </CustomersTableFilter>
              </Flex>
            </Sticky>
            <StickyMeasurer>
              <Table
                size="sm"
                data={data}
                sort={sort}
                select={select}
                header={header}
                columns={columns}
                loading={status === "pending"}
                maxHeight={maxHeight}
                emptyState={emptyState}
                pagination={pagination}
                data-testid="jobs-table"
                {...props}
              />
            </StickyMeasurer>
          </StickyProvider>
        </TableProvider>
      </Flex>
    </>
  );
};
