import React, { type FC, type PropsWithChildren, useMemo } from "react";

import { type GetLinkedTransactionsReturn } from "../api/types";
import type {
  PropagationContextValues,
  PropagationDetails,
  PropagationObject,
} from "../types";
import { formatPropagation } from "../utils/format-propagation";
import { getStepName } from "../utils/get-step-name";

import { FormContext, PropagationContext } from "./propagation-context";

type PropagationProviderProps = PropsWithChildren<{
  onSave: (propagation: PropagationObject) => void;
  propagationFields?: PropagationDetails;
  linkedTransactions?: GetLinkedTransactionsReturn;
  isForcedPropagation?: boolean;
}>;

const INITIAL_VALUES = {};

export const PropagationProvider: FC<PropagationProviderProps> = ({
  onSave,
  children,
  propagationFields,
  linkedTransactions,
  isForcedPropagation,
}) => {
  const form = FormContext.useForm({
    onSubmit: async (values) => {
      const propagation = formatPropagation({
        formValues: values,
        linkedTransactions,
      });
      onSave(propagation);
      form.reset();
    },
    initialValues: INITIAL_VALUES,
  });

  const steps = useMemo(
    () =>
      (linkedTransactions ?? []).reduce((acc, transaction) => {
        const newSteps = transaction.links.flatMap((item) =>
          getStepName(transaction, item)
        );

        return [...acc, ...newSteps];
      }, [] as string[]),
    [linkedTransactions]
  );

  const value = useMemo<PropagationContextValues>(
    () => ({
      form,
      steps,
      propagationFields,
      linkedTransactions,
      isForcedPropagation,
    }),
    [steps, form, linkedTransactions, propagationFields, isForcedPropagation]
  );

  return useMemo(
    () => (
      <PropagationContext.Provider {...{ value }}>
        <form style={{ display: "contents" }} {...form.props}>
          {children}
        </form>
      </PropagationContext.Provider>
    ),
    [children, form.props, value]
  );
};
