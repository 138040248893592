import { type RefObject, useEffect, useState } from "react";

import { isWindow } from "../../utils/is-window";
import { useEvent } from "../use-event";
import { useResizeObserver } from "../use-resize-observer";

type UseBreakpointsOptions = {
  breakpoints: Record<string, number>;
  initialBreakpoint: string;
};

export const useBreakpoints = (
  ref: RefObject<HTMLElement | Window | null>,
  { breakpoints, initialBreakpoint }: UseBreakpointsOptions
) => {
  const [breakpoint, setBreakpoint] = useState({
    matches: [initialBreakpoint],
    current: initialBreakpoint,
  });

  const onResize = useEvent((el: HTMLElement | Window) => {
    const width = isWindow(el)
      ? el.innerWidth
      : el.getBoundingClientRect().width;

    const matches = Object.entries(breakpoints).reduce(
      (acc, [key, value]) => (width > value ? [...acc, key] : acc),
      [] as (keyof typeof breakpoints)[]
    );

    setBreakpoint({
      matches,
      /** Spread necessary to not mutate matches. Change to toReversed() once is safe with tests */
      current: [...matches].reverse()[0],
    });
  });

  useEffect(() => {
    const el = ref.current;

    if (el) onResize(el);
  }, [ref, onResize]);

  useResizeObserver(ref as any, onResize, { observe: "width" });

  return breakpoint;
};
