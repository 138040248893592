import React, { memo } from "react";
import {
  ButtonField,
  type ButtonFieldProps,
  Icon,
} from "@adaptive/design-system";
import { useEvent, useMultiStepDialog } from "@adaptive/design-system/hooks";
import { formatCard } from "@utils/format-card";

import type {
  CardTransaction,
  MatchingTransactionDialogSteps,
  MatchingTransactionOnChangeHandler,
} from "../types";

import { MatchingTransactionDialog } from "./matching-transaction-dialog";
import type {
  CardFilter,
  DaysThreshold,
  LinkedCost,
  SelectCardOptions,
} from "./types";

export type CardTransactionFieldOnChangeHandler = (
  value: string,
  option?: { label: string; value: string } & CardTransaction
) => void;

export type CardTransactionFieldProps = {
  cost: LinkedCost;
  onChange?: CardTransactionFieldOnChangeHandler;
  selectCard?: SelectCardOptions;
  // Allows to skip the request to match/unmatch the card transactions
  // Use the onChange callback to update the values manually
  skipRequest?: boolean;
  valueVariant?: "short" | "long";
  daysThreshold?: DaysThreshold;
  cardFilter?: CardFilter;
} & Pick<
  ButtonFieldProps,
  | "size"
  | "label"
  | "suffix"
  | "disabled"
  | "hintMessage"
  | "placeholder"
  | "helperMessage"
  | "messageVariant"
>;

export const CardTransactionField = memo(
  ({
    size,
    cost,
    disabled,
    onChange,
    selectCard,
    skipRequest = false,
    valueVariant = "short",
    daysThreshold = { daysBefore: 1, daysAfter: 1 },
    cardFilter,
    ...props
  }: CardTransactionFieldProps) => {
    const value =
      valueVariant === "short"
        ? formatCard({
            mask: cost.cardTransaction?.mask,
            name: cost.cardTransaction?.plaidAccountOwner?.accountOwner,
          })
        : (cost.cardTransaction?.displayName ?? "");

    const dialog = useMultiStepDialog<MatchingTransactionDialogSteps>({
      lazy: true,
      initialStep: value ? "edit" : "select",
    });

    const enhancedDisabled = disabled || dialog.isRendered;

    const enhancedOnChange = useEvent<MatchingTransactionOnChangeHandler>(
      (payload) => {
        onChange?.(
          payload.cardTransaction?.url ?? "",
          payload.cardTransaction
            ? {
                ...payload.cardTransaction,
                label:
                  valueVariant === "short"
                    ? formatCard({
                        mask: payload.cardTransaction.mask,
                        name: payload.cardTransaction.plaidAccountOwner
                          ?.accountOwner,
                      })
                    : (payload.cardTransaction.displayName ?? ""),
                value: payload.cardTransaction.url,
              }
            : undefined
        );
      }
    );

    return (
      <>
        <ButtonField
          size={size}
          value={value}
          prefix={
            value ? (
              <Icon
                size={size}
                name="credit-card"
                color={!enhancedDisabled ? "neutral-800" : "neutral-700"}
                variant="light"
              />
            ) : null
          }
          suffix={<Icon size={size} name="pen" />}
          disabled={enhancedDisabled}
          onClick={dialog.show}
          loading={dialog.isRendered}
          placeholder="Select card transaction"
          {...props}
        />
        {dialog.isRendered && (
          <MatchingTransactionDialog
            cost={cost}
            cardFilter={cardFilter}
            dialog={dialog}
            onChange={enhancedOnChange}
            selectCard={selectCard}
            daysThreshold={daysThreshold}
            skipRequest={skipRequest}
          />
        )}
      </>
    );
  }
);

CardTransactionField.displayName = "CardTransactionField";
