import { createSelector } from "@reduxjs/toolkit";
import { ACCOUNT_TYPE, PAYMENT_METHOD } from "@src/bills/constants";
import { asOption } from "@utils/converters/utils";
import { achSchema } from "@vendors/api";
import { vendorSelector } from "@vendors/store";

export const hideNumber = (value) => {
  if (typeof value !== "string") return null;

  let string = "";
  for (let i = 0; i < 4; i += 1) string += "\u00B7";
  string += value.slice(Math.max(value.length - 4, 0), value.length);

  return string;
};

const ACH_PROCESSING_OPTIONS = [
  { value: "1", label: "ACH (1 business day)", description: "1 business day" },
  { value: "2", label: "ACH", description: "2-3 business days" },
  { value: "3", label: "ACH", description: "3-5 business days" },
];

export const getAchProcessingOption = (value) =>
  ACH_PROCESSING_OPTIONS.find((option) => option.value === value);

export const getPaymentMethods = (clientSettings) => {
  const methods = [];
  if (window.ACH_CHECK_ENABLED) {
    const label = getAchProcessingOption(
      clientSettings.ach_processing_time
    ).label;
    methods.push({ value: PAYMENT_METHOD.ACH, label: label });
  }
  if (window.MAIL_CHECK_ENABLED) {
    methods.push({
      value: PAYMENT_METHOD.MAIL_CHECK,
      label: "Mail a check",
    });
  }
  if (window.PRINT_CHECK_ENABLED) {
    methods.push({ value: PAYMENT_METHOD.PRINT_CHECK, label: "Print a check" });
  }
  if (window.VIRTUAL_EMAIL_CHECK_ENABLED) {
    methods.push({
      value: PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK,
      label: "Email a virtual check",
    });
  }
  if (window.VIRTUAL_SMS_CHECK_ENABLED) {
    methods.push({
      value: PAYMENT_METHOD.VIRTUAL_SMS_CHECK,
      label: "Text a virtual check",
    });
  }
  methods.push({ value: PAYMENT_METHOD.MARK_AS_PAID, label: "Mark as paid" });
  return methods;
};

const getVendorAddressLabel = (address, maxLength) => {
  const getAddressProperty = (property) => {
    if (address?.[property]) return `${address[property]}, `;
    return "";
  };

  let fullAddress = `${getAddressProperty("line1")}${getAddressProperty(
    "line2"
  )}${getAddressProperty("city")}${getAddressProperty(
    "state"
  )}${getAddressProperty("postal_code")}`;
  if (fullAddress.length >= 2) {
    // cut last comma and space
    fullAddress = fullAddress.slice(0, -2);
  }
  if (fullAddress.length > maxLength) {
    fullAddress = `${fullAddress.slice(0, maxLength)}...`;
  }
  return fullAddress;
};

export const getAccountBalanceLabel = (accountBalance) => {
  const name = accountBalance.name;
  const mask = `••••${accountBalance.mask}`;
  const type = ACCOUNT_TYPE[accountBalance.type] || accountBalance.type;
  return `${name} (${mask}) - ${
    accountBalance?.payment_account?.display_name || type
  }`;
};

export const getVendorACHLabel = ({ routingNumber, accountNumber }) =>
  `Routing Number ${hideNumber(routingNumber)} - Account Number ${hideNumber(
    accountNumber
  )}`;

export const getSignatureString = (url) => url?.split(",")[1];

export const selectVendorPaymentData = createSelector(
  [
    (state) => vendorSelector(state).info,
    (state) => vendorSelector(state).banking,
  ],
  (info, banking) => {
    const recipientOptions = info ? [asOption(info)] : [];

    const { email } = info || {};
    const emailOptions = email ? [{ label: email, value: email }] : [];

    const { address } = info || {};
    const addressOptions = (
      address
        ? [{ label: getVendorAddressLabel(address), value: address.url }]
        : []
    ).filter((vendor) => vendor.label);

    const { phoneNumber } = info || {};
    const phoneNumberOptions = phoneNumber
      ? [{ label: phoneNumber, value: phoneNumber }]
      : [];

    const achValidation = achSchema
      .refine(({ archived }) => !archived)
      .transform((validated) =>
        asOption({
          displayName: getVendorACHLabel(validated),
          ...banking,
        })
      )
      .safeParse(banking);

    const achOptions =
      achValidation.success && achValidation.data ? [achValidation.data] : [];

    return {
      vendor: {
        ...info,
        banking,
      },
      recipientOptions,
      emailOptions,
      addressOptions,
      phoneNumberOptions,
      achOptions,
    };
  }
);
