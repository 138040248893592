import { useContext } from "react";

import { PropagationContext } from "../components/propagation-context";
import type { PropagationContextValues } from "../types";

const defaultValues: PropagationContextValues = {
  steps: [],
};

export const usePropagationContext = (): PropagationContextValues => {
  const context = useContext(PropagationContext);
  return context ?? defaultValues;
};
