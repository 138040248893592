import React, { type ComponentProps, memo, useCallback } from "react";
import {
  Button,
  dialog,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Icon,
  toast,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import {
  BatchApplyObject,
  type BatchApplyObjectOnChangeHandlerProps,
} from "@components/batch-apply-object";
import {
  DEFAULT_VENDOR_INVOICE_OPTION_VALUE,
  INVOICE_STRINGS,
} from "@src/jobs";
import * as analytics from "@utils/analytics";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import {
  useInvoiceActions,
  useInvoiceLines,
  useInvoiceLinesSelected,
} from "./invoice-context";
import { InvoiceCalculateMarkupDialog, type Line } from ".";

type InvoiceActionButtonProps = Pick<
  ComponentProps<typeof Button>,
  "size" | "variant" | "block"
>;

export const InvoiceActionButton = memo(
  ({
    size = "sm",
    block,
    variant = "ghost",
    ...props
  }: InvoiceActionButtonProps) => {
    const onCalculatePrice = () => {
      dialogChangePrice.show();
    };

    const invoiceLinesSelected = useInvoiceLinesSelected();
    // TODO: We use the transaction line data to update the local state
    // for the optimistic render, but we are omitting the url
    // because the url is not necessary for this update. We should
    // remove unnecessary data from the optimistic update
    // payload. Ticket: BOB-6086
    const invoiceLines = useInvoiceLines({ withTransactionLineUrl: false });

    const { updateInvoiceLines } = useInvoiceActions();
    const dialogChangePrice = useDialog({ lazy: true });

    const onDelete = useCallback(() => {
      const handler = async () => {
        updateInvoiceLines(
          invoiceLines.filter((line) => {
            return !invoiceLinesSelected.some(
              (invoiceLineSelected) => invoiceLineSelected.id === line.id
            );
          })
        );

        toast.success(
          `Line${invoiceLinesSelected.length ? "s" : ""} removed from the ${
            INVOICE_STRINGS.LOWERCASE_INVOICE
          }`
        );
        analytics.track("invoiceLineCostBatchActions", {
          action: "calculate-price-markup",
          linesId: invoiceLines.map((line) => line.id),
        });
      };

      dialog.confirmation({
        title: `Remove lines from ${INVOICE_STRINGS.LOWERCASE_INVOICE}?`,
        action: {
          primary: {
            color: "error",
            onClick: () => handler(),
            children: "Remove",
          },
        },
      });
    }, [invoiceLines, invoiceLinesSelected, updateInvoiceLines]);

    const onBatchApply = useEvent(
      async ({
        items = invoiceLinesSelected,
        option,
        fieldName,
      }: BatchApplyObjectOnChangeHandlerProps & {
        items?: Line[];
      }) => {
        if (!option) return;

        const nextLines = invoiceLines.map((invoiceLine) => {
          const lineSelected = items.some((line) => line.id === invoiceLine.id);
          if (!lineSelected) return invoiceLine;

          if (fieldName === "item_account" && option) {
            return {
              ...invoiceLine,
              jobCostMethod: {
                id: Number(parseRefinementIdFromUrl(option.value)),
                url: option.value,
                parent: option.parent || "",
                displayName: option.label,
              },
            };
          } else if (fieldName === "vendor" && option) {
            return {
              ...invoiceLine,
              vendor: {
                id:
                  option.value === DEFAULT_VENDOR_INVOICE_OPTION_VALUE
                    ? ""
                    : `${parseRefinementIdFromUrl(option.value)}`,
                url: option.value,
                displayName: option.label,
              },
            };
          }
          return invoiceLine;
        });

        updateInvoiceLines(nextLines);
        toast.success(
          `Line${invoiceLinesSelected.length ? "s" : ""} updated for ${
            INVOICE_STRINGS.LOWERCASE_INVOICE
          }`
        );
      }
    );
    return (
      <>
        {invoiceLinesSelected.length > 0 ? (
          <Dropdown placement="bottom-end">
            <DropdownTrigger
              as={Button}
              size={size}
              data-testid="budgets-actions-trigger"
              variant={variant}
              block={block}
              {...props}
            >
              Actions
              <Icon name="ellipsis-vertical" variant="solid" />
            </DropdownTrigger>
            <DropdownList>
              <DropdownItem onClick={onCalculatePrice}>
                Calculate price markup
              </DropdownItem>
              <DropdownItem color="error" onClick={onDelete}>
                Delete lines
              </DropdownItem>
              <DropdownItem>
                <BatchApplyObject
                  onChange={onBatchApply}
                  includeVendors={true}
                  includeClientAsVendor={true}
                  includeJobs={false}
                  accountFilters={{
                    only_line_item_accounts: true,
                  }}
                />
              </DropdownItem>
            </DropdownList>
          </Dropdown>
        ) : null}
        {dialogChangePrice.isRendered && (
          <InvoiceCalculateMarkupDialog dialog={dialogChangePrice} />
        )}
      </>
    );
  }
);

InvoiceActionButton.displayName = "InvoiceActionButton";
