import { markupTypeSchema } from "@api/budgets/request";
import {
  arraySchema,
  dateSchema,
  decimalAmountOrZeroSchema,
  decimalAmountSchema,
  idSchema,
} from "@utils/schema";
import { z } from "zod";

import { jobCostMethodSchema, paginatedBaseSchema } from "../../response";

const changeLine = z.object({
  id: idSchema,
  budgetLine: z.string().url(),
  jobCostMethod: jobCostMethodSchema,
  builderAmount: decimalAmountSchema,
  externalAmount: decimalAmountSchema.default(0),
});

export const markupChangeSchema = z.object({
  id: idSchema.nullish(),
  value: decimalAmountOrZeroSchema,
  markupType: markupTypeSchema,
  jobCostMethod: jobCostMethodSchema,
  changeLineJobCostMethods: arraySchema(idSchema),
});

export const changeSchema = z.object({
  id: idSchema,
  description: z.string().nullish(),
  docNumber: z.string(),
  date: dateSchema.transform((value) => value as Date),
  url: z.string().url(),
  lines: z.array(changeLine),
  markups: z.array(
    markupChangeSchema.extend({
      ownersValue: decimalAmountOrZeroSchema,
      changeAmount: decimalAmountOrZeroSchema,
      externalChangeAmount: decimalAmountOrZeroSchema,
    })
  ),
});

export const getChangeResponseSchema = changeSchema;

export const getChangesResponseSchema = paginatedBaseSchema.extend({
  results: z.array(changeSchema),
});
