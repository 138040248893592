import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router";
import { Loader, TableProvider, Tabs, toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { putCustomer } from "@api/customers";
import { handleErrors } from "@api/handle-errors";
import { type InvoicesInvoice, useStoreInvoiceMutation } from "@api/invoices";
import type { GetCustomerCategoriesResponse } from "@api/jobs";
import { useGetCustomerCategoriesQuery } from "@api/jobs/jobs";
import { Main } from "@components/main";
import { NotFound } from "@components/not-found";
import { useCurrentClientFromRealm } from "@hooks/useCurrentClientFromRealm";
import { useAppDispatch } from "@store/hooks";
import {
  fetchJob,
  jobReset,
  setCategoriesEnabled,
  useJobInfo,
} from "@store/jobs";
import { useDrawerVisibility } from "@store/ui";
import { useClientInfo } from "@store/user";
import { scrollMainTop } from "@utils/scroll-main-top";

import type { Line } from "./detail-view/budget/budgets-table/lines";
import { DetailView } from "./detail-view";
import {
  HeadingDetail,
  INVOICE_STRINGS,
  JobContext,
  useJobPermission,
} from ".";

type StatusTab = "budget" | "invoice" | "settings";

export const JobDetailPage = memo(() => {
  const { job } = useJobInfo();

  const { setVisible } = useDrawerVisibility("job");

  const [searchParams, setSearchParams] = useSearchParams({ status: "budget" });

  const currentTab = (searchParams.get("status") ?? "budget") as StatusTab;

  const onTabChange = useEvent(async (status: string) => {
    if (status === "settings") return setVisible(true);

    scrollMainTop(0);
    setSearchParams({ status });
  });

  return (
    <Main>
      <Tabs value={currentTab} onChange={onTabChange}>
        <TableProvider id={`job-${job.id}-${currentTab}-table`}>
          <HeadingDetail />
          <DetailView />
        </TableProvider>
      </Tabs>
    </Main>
  );
});

JobDetailPage.displayName = "JobDetailPage";

const EMPTY_CATEGORIES: GetCustomerCategoriesResponse = [];

export const Job = memo(() => {
  const { jobId } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { job, status } = useJobInfo();

  const { realm, client } = useClientInfo();

  const [budgetSelectedLines, setBudgetSelectedLines] = useState<Line[]>([]);

  const [invoiceSelectedDraws, setInvoiceSelectedDraws] = useState<
    InvoicesInvoice[]
  >([]);

  const [storeInvoiceMutation, { isLoading: storeInvoiceMutationIsLoading }] =
    useStoreInvoiceMutation({
      selectFromResult: ({ isLoading }) => ({ isLoading }),
    });

  const clientCategoriesEnabled = client?.settings.categories_enabled ?? false;

  const clientChangeTrackingEnabled =
    client?.settings.change_tracking_enabled ?? false;

  const {
    data: categories = EMPTY_CATEGORIES,
    isLoading: categoriesIsLoading,
  } = useGetCustomerCategoriesQuery(
    { customerId: jobId! },
    { skip: !jobId || !clientCategoriesEnabled }
  );

  const { canManageJobs, canEditInitialBudget, canSyncJobs } =
    useJobPermission();

  const permissions = useMemo(
    () => ({
      canManage: canManageJobs,
      canEditInitialBudget: canEditInitialBudget,
      canSyncJobs: canSyncJobs,
    }),
    [canManageJobs, canEditInitialBudget, canSyncJobs]
  );

  const settings = useMemo(
    () => ({
      categoriesEnabled:
        (clientCategoriesEnabled && job.categories_enabled) || false,
      clientChangeTrackingEnabled: clientChangeTrackingEnabled,
      changeTrackingEnabled:
        clientChangeTrackingEnabled && job.change_tracking_enabled,
      ownersAmountEnabled: job.owners_amount_enabled,
    }),
    [
      clientCategoriesEnabled,
      clientChangeTrackingEnabled,
      job.categories_enabled,
      job.change_tracking_enabled,
      job.owners_amount_enabled,
    ]
  );

  const isError = !jobId || status === "error";

  const isLoading = status !== "loaded" && !job.id;

  const isFetching = status !== "loaded" && !!job.id;

  const refetchJob = useCallback(
    () => (jobId ? dispatch(fetchJob(jobId)) : Promise.resolve()),
    [dispatch, jobId]
  );

  const updateJobCategoriesEnabled = useCallback(
    async (value: boolean) => {
      dispatch(setCategoriesEnabled(value));
      try {
        await putCustomer(String(job.id), { categories_enabled: value });
      } catch (e) {
        dispatch(setCategoriesEnabled(!value));
        throw e;
      }
    },
    [job.id, dispatch]
  );

  const invoiceCreate = useEvent(async () => {
    if (!realm) throw new Error("Invalid realm");

    try {
      const invoice = await storeInvoiceMutation({
        lines: [],
        realm: realm.url,
        customer: job.url,
      }).unwrap();

      navigate(`/jobs/${jobId}/invoices/${invoice.id}`, {
        state: { prev: window.location.pathname + window.location.search },
      });

      toast.success(`${INVOICE_STRINGS.INVOICE} created`);
    } catch (e: unknown) {
      handleErrors(e);
    }
  });

  useCurrentClientFromRealm(job.realm);

  useEffect(() => {
    refetchJob();

    return () => {
      dispatch(jobReset());
    };
  }, [refetchJob, dispatch]);

  if (isError) return <NotFound to="/jobs" resource="jobs" />;

  if (!job.id) return <Loader position="absolute" />;

  return (
    <JobContext.Provider
      value={{
        settings,
        categories,
        refetchJob,
        permissions,
        invoiceCreate,
        invoiceIsLoading: storeInvoiceMutationIsLoading,
        categoriesIsLoading,
        budgetSelectedLines,
        invoiceSelectedDraws,
        setBudgetSelectedLines,
        setInvoiceSelectedDraws,
        updateJobCategoriesEnabled,
      }}
    >
      {(isFetching || isLoading || storeInvoiceMutationIsLoading) && (
        <Loader position="absolute" />
      )}
      <Outlet />
    </JobContext.Provider>
  );
});

Job.displayName = "Job";
