import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import { TYPE_STRINGS } from "../constants/constants";

export const getDetailsFromUrl = (url?: string) => {
  if (!url) return {};

  const id = parseRefinementIdFromUrl(url);
  let type = "";

  if (url.includes("line")) type = "line";

  if (url.includes("purchaseorder")) type = "purchase_order";

  if (url.includes("bill")) type = "bill";

  if (url.includes("invoice")) type = "invoice";

  if (url.includes("invoice_line")) type = "invoice_line";

  if (url.includes("expenses")) type = "expense";

  return { id, type, typeTitle: TYPE_STRINGS[type] };
};
