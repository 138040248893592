import { arrayToStringWithSeparators } from "@utils/usefulFunctions";
import { DOCUMENT_TYPE_OPTIONS } from "@vendors/constants";
import type { DocumentType } from "@vendors/types";

export const formatDocumentTypes = (documentTypes: DocumentType[]) => {
  const result = documentTypes
    .map(
      (type) => DOCUMENT_TYPE_OPTIONS.find((obj) => obj.value === type)?.label
    )
    .filter((el) => el !== undefined);
  return arrayToStringWithSeparators(result);
};
