import { useEffect, useState } from "react";
import { useStageActions, useStageName } from "@components/sequential/context";
import { useAppSelector } from "@store/hooks";

import { selectErrorsGroups } from "../store/selectors";

export const useShowNextError = () => {
  const currentStageName = useStageName();

  const { setStage } = useStageActions();

  const stagesWithErrors = useAppSelector(selectErrorsGroups);

  const [seenStages, setSeenStages] = useState<string[]>([]);

  useEffect(() => {
    setSeenStages([]);
  }, [stagesWithErrors]);

  useEffect(() => {
    const [nextErrorStage] = Object.keys(stagesWithErrors || {}).filter(
      (stageName) =>
        stageName === currentStageName || !seenStages.includes(stageName)
    );

    if (nextErrorStage) {
      setSeenStages((prev) => {
        return prev.includes(nextErrorStage) ? prev : [...prev, nextErrorStage];
      });
      setStage(nextErrorStage);
      return;
    }
  }, [seenStages, setStage, currentStageName, stagesWithErrors]);
};
