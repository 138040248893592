import React, {
  type ComponentPropsWithoutRef,
  memo,
  useEffect,
  useLayoutEffect,
} from "react";
import { TabList, useStoreState, useTabContext } from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { useDialogContext } from "../dialog/dialog-context";

import styles from "./tabs.module.css";

const DEFAULT_COMPONENT = "div";

type TabsListProps = ComponentPropsWithoutRef<typeof DEFAULT_COMPONENT>;

const TabsList = forwardRefAs<typeof DEFAULT_COMPONENT, TabsListProps>(
  ({ as: Component = DEFAULT_COMPONENT, className, ...props }, ref) => {
    const tabContext = useTabContext();

    const move = tabContext?.move;

    const items = useStoreState(tabContext, "items");

    const selectedId = useStoreState(tabContext, "selectedId");

    const { setHasTabs } = useDialogContext();

    /**
     * @todo adjust it as soon as ariakit fixes this issue https://github.com/ariakit/ariakit/issues/4213
     * This implementation is based on https://github.com/WordPress/gutenberg/pull/56658
     */
    useLayoutEffect(() => {
      const currentItem = items?.find((item) => item.id === selectedId);
      const activeElement = currentItem?.element?.ownerDocument.activeElement;
      const tabsHasFocus = items?.some(
        (item) => activeElement && activeElement === item.element
      );

      if (activeElement && tabsHasFocus && selectedId !== activeElement.id) {
        move?.(selectedId);
      }
    }, [items, move, selectedId]);

    useEffect(() => {
      setHasTabs?.(true);

      return () => {
        setHasTabs?.(false);
      };
    }, [setHasTabs]);

    return (
      <TabList
        ref={ref}
        render={(innerProps) => <Component {...innerProps} />}
        className={cn(styles["list"], className)}
        {...props}
      />
    );
  }
);

const MemoizedTabsList = memo(TabsList) as typeof TabsList;

export { MemoizedTabsList as TabsList };
