import React, { memo, useCallback, useEffect, useMemo } from "react";
import {
  Button,
  ComboBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Loader,
  Table,
  type TableColumn,
  type TableEmptyState,
  type TableHeaderAddon,
  type TableMultipleSelectAddon,
  type TableRow,
  type TableSelectAddon,
  Text,
  TextField,
  toast,
  VisuallyHidden,
} from "@adaptive/design-system";
import {
  createFormContext,
  useDeepMemo,
  useDialog,
  useEvent,
  useForm,
} from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import type { GetCustomerCategoriesResponse } from "@api/jobs";
import {
  useCloneCustomerCategoriesMutation,
  useDeleteCustomerCategoryMutation,
  useGetCustomerCategoriesQuery,
  useUpsertCustomerCategoriesMutation,
} from "@api/jobs/jobs";
import { useCustomersSimplified } from "@hooks/useCustomersSimplified";
import { useJobInfo } from "@store/jobs";
import { useDrawerVisibility } from "@store/ui";
import { summarizeResults } from "@utils/all-settled";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { arraySchema, idSchema } from "@utils/schema";
import { z } from "zod";

const categorySchema = z.object({
  label: z.string().min(1, "Category name is required"),
  value: idSchema.nullish(),
});

const categoriesSchema = z.object({
  selection: arraySchema(categorySchema.extend({ index: z.number() })),
  categories: arraySchema(categorySchema).superRefine((categories, context) => {
    categories.forEach((category, index) => {
      const isDuplicated = categories.some(
        (item, itemIndex) =>
          item.label === category.label && itemIndex !== index
      );

      if (isDuplicated) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: [index, "label"],
          message: "A category with this name already exists",
        });
      }
    });
  }),
});

type CategoriesFields = z.infer<typeof categoriesSchema>;

type Category = CategoriesFields["categories"][number];

type CategoryRow = TableRow<Category> & { index: number };

const {
  useForm: useCategoryForm,
  FormProvider: CategoryFormProvider,
  useFormContext: useCategoryFormContext,
} = createFormContext<CategoriesFields>();

const replacementSchema = z.object({
  category: z.string().min(1, "Category is required"),
  categories: arraySchema(categorySchema.extend({ index: z.number() })),
});

type ReplacementFields = z.infer<typeof replacementSchema>;

const UNCATEGORIZED_OPTION = {
  label: "Clear category",
  value: Symbol("UNCATEGORIZED").toString(),
};

const REPLACEMENT_INITIAL_VALUES: ReplacementFields = {
  category: UNCATEGORIZED_OPTION.value,
  categories: [],
};

const EMPTY_CATEGORIES: GetCustomerCategoriesResponse = [];

type DeleteCategoryButtonProps = {
  categories: CategoriesFields["selection"];
};

const DeleteCategoryButton = memo(
  ({ categories }: DeleteCategoryButtonProps) => {
    const { job } = useJobInfo();

    const categoriesForm = useCategoryFormContext();

    const replacementDialog = useDialog({ lazy: true });

    const { data = EMPTY_CATEGORIES, isLoading: categoriesIsLoading } =
      useGetCustomerCategoriesQuery({ customerId: job.id }, { skip: !job.id });

    const [
      deleteCustomerCategory,
      { isLoading: deleteCustomerCategoryIsLoading },
    ] = useDeleteCustomerCategoryMutation({ fixedCacheKey: "delete-category" });

    const replacementForm = useForm<ReplacementFields>({
      schema: replacementSchema,
      onSubmit: async (values) => {
        const requests = await Promise.allSettled(
          values.categories.map((category) =>
            deleteCustomerCategory({
              switchTo:
                values.category === UNCATEGORIZED_OPTION.value
                  ? ""
                  : values.category,
              customerId: job.id,
              categoryId: category.value!,
            }).unwrap()
          )
        );

        const { success, error } = summarizeResults(requests);

        if (error) {
          toast.error(
            error === 1
              ? "Category failed to delete!"
              : `${error} categories failed to delete!`
          );
        }

        if (success) {
          toast.success(
            success === 1
              ? "Category deleted!"
              : `${success} categories deleted!`
          );
        }

        replacementDialogHide();
      },
      initialValues: REPLACEMENT_INITIAL_VALUES,
    });

    const replacementDialogHide = useEvent(() => {
      replacementForm.reset();
      replacementDialog.hide();
    });

    const onDelete = useEvent(async () => {
      const requests = await Promise.allSettled(
        categories.map(async (category) => {
          if (!category.value) {
            categoriesForm.remove("categories", category.index);
            return category;
          }

          try {
            await deleteCustomerCategory({
              customerId: job.id,
              categoryId: category.value,
            }).unwrap();

            return category;
          } catch (e) {
            return Promise.reject(category);
          }
        })
      );

      const { errorResponses, successResponses } = summarizeResults(requests);

      if (successResponses.length) {
        toast.success(
          successResponses.length === 1
            ? "Category deleted!"
            : `${successResponses.length} categories deleted!`
        );

        (successResponses as CategoriesFields["selection"]).forEach(
          (category) => categoriesForm.remove("categories", category.index)
        );
      }

      if (errorResponses.length) {
        replacementForm.setValue("categories", errorResponses);
        replacementDialog.show();
      }

      categoriesForm.setValue("selection", []);
    });

    const enhancedCategories = useMemo(
      () => [
        ...data.filter((category) =>
          [...categories, ...replacementForm.values.categories].every(
            (item) => item.value !== category.value
          )
        ),
        UNCATEGORIZED_OPTION,
      ],
      [data, categories, replacementForm.values.categories]
    );

    return (
      <>
        <Button
          size="sm"
          color="neutral"
          variant="ghost"
          onClick={onDelete}
          disabled={
            categoriesForm.isSubmitting || deleteCustomerCategoryIsLoading
          }
        >
          {deleteCustomerCategoryIsLoading ? <Loader /> : <Icon name="trash" />}
        </Button>
        {replacementDialog.isRendered && (
          <Dialog
            show={replacementDialog.isVisible}
            variant="dialog"
            onClose={replacementDialogHide}
          >
            <DialogHeader>Select replacement category</DialogHeader>
            <DialogContent>
              <Flex
                as="form"
                gap="xl"
                direction="column"
                {...replacementForm.props}
              >
                <Text>
                  There are line items assigned to the category you’re trying to
                  delete. Select a new category to apply to those line items.
                </Text>
                <ComboBox
                  data={enhancedCategories}
                  loading={categoriesIsLoading}
                  {...replacementForm.register("category")}
                />
              </Flex>
            </DialogContent>
            <DialogFooter>
              <Button
                block
                variant="text"
                color="neutral"
                onClick={replacementDialogHide}
              >
                Close
              </Button>
              <Button
                block
                type="submit"
                form={replacementForm.id}
                disabled={replacementForm.isSubmitting}
              >
                Select
              </Button>
            </DialogFooter>
          </Dialog>
        )}
      </>
    );
  }
);

DeleteCategoryButton.displayName = "DeleteCategoryButton";

const CategoryRender = memo((row: CategoryRow) => {
  const categoriesForm = useCategoryFormContext();

  const [, { isLoading: deleteCustomerCategoryIsLoading }] =
    useDeleteCustomerCategoryMutation({ fixedCacheKey: "delete-category" });

  return (
    <TextField
      size="sm"
      disabled={categoriesForm.isSubmitting || deleteCustomerCategoryIsLoading}
      changeMode="lazy"
      messageVariant="absolute"
      {...categoriesForm.register(`categories.${row.index}.label`)}
    />
  );
});

CategoryRender.displayName = "CategoryRender";

const ActionsName = memo(() => {
  const categoriesForm = useCategoryFormContext();
  const hasSelection = categoriesForm.values.selection.length > 0;

  return (
    <Flex height="2xl" align="center">
      {!hasSelection && <VisuallyHidden>Actions</VisuallyHidden>}
      <div hidden={!hasSelection}>
        <DeleteCategoryButton categories={categoriesForm.values.selection} />
      </div>
    </Flex>
  );
});

ActionsName.displayName = "ActionsName";

const ActionsRender = memo((row: CategoryRow) => {
  const categoriesForm = useCategoryFormContext();

  const categories = useMemo(
    () => [
      { ...categoriesForm.values.categories[row.index], index: row.index },
    ],
    [categoriesForm.values.categories, row.index]
  );

  return <DeleteCategoryButton categories={categories} />;
});

ActionsRender.displayName = "ActionsRender";

const COLUMNS: TableColumn<Category>[] = [
  {
    id: "name",
    width: "fill",
    name: "Category",
    render: (row, index) => <CategoryRender index={index} {...row} />,
  },
  {
    id: "actions",
    name: <ActionsName />,
    width: 65,
    render: (row, index) => <ActionsRender index={index} {...row} />,
  },
];

const importSchema = z.object({
  customer: z.string().min(1, "Job is required"),
});

type ImportFields = z.infer<typeof importSchema>;

const IMPORT_INITIAL_VALUES: ImportFields = { customer: "" };

export const JobFormCategories = memo(() => {
  const { job } = useJobInfo();

  const importDialog = useDialog({ lazy: true });

  const { setStep, visible, setShouldShowHideConfirmation } =
    useDrawerVisibility("job");

  const { data: categories, isLoading: categoriesIsLoading } =
    useGetCustomerCategoriesQuery({ customerId: job.id }, { skip: !job.id });

  const [, { isLoading: deleteCustomerCategoryIsLoading }] =
    useDeleteCustomerCategoryMutation({ fixedCacheKey: "delete-category" });

  const goToInfo = useEvent(() => setStep("info"));

  const [cloneCategories] = useCloneCustomerCategoriesMutation();

  const [upsertCategories] = useUpsertCustomerCategoriesMutation();

  const customersSimplified = useCustomersSimplified({ withCategories: true });

  const categoriesInitialValues = useMemo<CategoriesFields>(
    () => ({ categories: categories ?? [], selection: [] }),
    [categories]
  );

  const categoriesForm = useCategoryForm({
    schema: categoriesSchema,
    onSubmit: async (values) => {
      try {
        await upsertCategories({
          customerId: job.id,
          categories: values.categories.map((category) => ({
            id: category.value,
            displayName: category.label,
          })),
        }).unwrap();

        toast.success("Categories updated!");

        goToInfo();
      } catch (e) {
        handleErrors(e);
      }
    },
    initialValues: categoriesInitialValues,
  });

  const enhancedCustomers = useMemo(
    () =>
      (customersSimplified.data ?? []).filter(
        (customer) => customer.value !== String(job.id)
      ),
    [customersSimplified.data, job.id]
  );

  const importForm = useForm<ImportFields>({
    schema: importSchema,
    onSubmit: async (values) => {
      try {
        await cloneCategories({
          customerId: job.id,
          cloneCustomerId: parseRefinementIdFromUrl(values.customer)!,
        }).unwrap();

        toast.success("Categories updated!");

        importDialogHide();
      } catch (e) {
        handleErrors(e);
      }
    },
    initialValues: IMPORT_INITIAL_VALUES,
  });

  const importDialogHide = useEvent(() => {
    importForm.reset();
    importDialog.hide();
  });

  const categoriesFormReset = categoriesForm.reset;

  const appendCategory = useEvent(() =>
    categoriesForm.append("categories", { label: "" })
  );

  const emptyState = useMemo<TableEmptyState>(
    () => ({
      title: "",
      subtitle: "There are no categories set for this job.",
      action: (
        <Button onClick={appendCategory} variant="ghost">
          <Icon name="plus" />
          Add category
        </Button>
      ),
    }),
    [appendCategory]
  );

  const selectOnChange = useEvent<
    Exclude<TableMultipleSelectAddon<Category>["onChange"], undefined>
  >((categories) => {
    categoriesForm.setValue(
      "selection",
      categories.map((category) => ({
        ...category,
        index: Number(category.path),
      }))
    );
  });

  const selectIsDisabled = useCallback(
    (row: Category) => {
      if (!row.value) return "You can't select unsaved category";

      return categoriesForm.isSubmitting || deleteCustomerCategoryIsLoading;
    },
    [categoriesForm.isSubmitting, deleteCustomerCategoryIsLoading]
  );

  const select = useMemo<TableSelectAddon<Category>>(
    () => ({
      value: categoriesForm.values.selection,
      onChange: selectOnChange,
      isDisabled: selectIsDisabled,
    }),
    [selectOnChange, selectIsDisabled, categoriesForm.values.selection]
  );

  const header = useMemo<TableHeaderAddon>(
    () => ({
      hide: categoriesForm.values.categories.length === 0,
      sticky: { offset: -24 },
    }),
    [categoriesForm.values.categories]
  );

  const data = useDeepMemo(
    () => categoriesForm.values.categories,
    [categoriesForm.values.categories]
  );

  useEffect(() => {
    if (visible) categoriesFormReset();
  }, [visible, categoriesFormReset]);

  useEffect(() => {
    setShouldShowHideConfirmation(categoriesForm.isDirty || importForm.isDirty);
  }, [
    importForm.isDirty,
    categoriesForm.isDirty,
    setShouldShowHideConfirmation,
  ]);

  return (
    <>
      <DialogHeader>Manage categories</DialogHeader>
      <DialogContent>
        <Flex as="form" gap="xl" direction="column" {...categoriesForm.props}>
          <Flex gap="xl" justify="space-between" align="center">
            <Text truncate>{job.display_name}</Text>
            <Flex gap="md" shrink={false}>
              <Button
                disabled={
                  categoriesForm.isSubmitting || deleteCustomerCategoryIsLoading
                }
                onClick={importDialog.show}
                size="sm"
                variant="ghost"
              >
                <Icon name="copy" />
                Copy from another job
              </Button>
              <Button
                disabled={
                  categoriesForm.isSubmitting || deleteCustomerCategoryIsLoading
                }
                onClick={appendCategory}
                size="sm"
                variant="ghost"
              >
                <Icon name="plus" />
                Add category
              </Button>
            </Flex>
          </Flex>
          <CategoryFormProvider form={categoriesForm}>
            <Table
              id="job-form-categories-table"
              size="sm"
              data={data}
              select={select}
              header={header}
              columns={COLUMNS}
              loading={categoriesIsLoading}
              bordered={false}
              emptyState={emptyState}
            />
          </CategoryFormProvider>
        </Flex>

        {importDialog.isRendered && (
          <Dialog
            show={importDialog.isVisible}
            onClose={importDialogHide}
            variant="dialog"
          >
            <DialogHeader>Copy categories from a previous Job</DialogHeader>
            <DialogContent>
              <Flex as="form" direction="column" gap="xl" {...importForm.props}>
                <Text>
                  Would you like to pull the category structure from an existing
                  job?
                </Text>
                <ComboBox
                  data={enhancedCustomers}
                  loading={customersSimplified.status === "loading"}
                  listSize={5}
                  placeholder="Select a job"
                  {...importForm.register("customer")}
                />
              </Flex>
            </DialogContent>
            <DialogFooter>
              <Button
                block
                color="neutral"
                variant="text"
                onClick={importDialogHide}
              >
                Close
              </Button>
              <Button
                block
                type="submit"
                form={importForm.id}
                disabled={importForm.isSubmitting}
              >
                Submit
              </Button>
            </DialogFooter>
          </Dialog>
        )}
      </DialogContent>
      <DialogFooter>
        <Button
          size="lg"
          type="button"
          color="neutral"
          variant="text"
          onClick={goToInfo}
        >
          Back
        </Button>
        <Button
          size="lg"
          type="submit"
          form={categoriesForm.id}
          variant="solid"
          disabled={
            categoriesForm.isSubmitting || deleteCustomerCategoryIsLoading
          }
          data-testid="save-job-categories"
        >
          {categoriesForm.isSubmitting ? <Loader /> : "Save"}
        </Button>
      </DialogFooter>
    </>
  );
});

JobFormCategories.displayName = "JobFormCategories";
