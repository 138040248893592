import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  isValidElement,
  memo,
  useMemo,
} from "react";
import flattenChildren from "react-keyed-flatten-children";
import cn from "clsx";

import type { ButtonProps } from "../button";

import { ButtonGroupContext } from "./button-group-context";
import styles from "./button-group.module.css";

type DefaultComponent = "div";

type Ref = ComponentRef<DefaultComponent>;

type Props = ComponentPropsWithoutRef<DefaultComponent> &
  ButtonProps & { disabled?: boolean; direction?: "horizontal" | "vertical" };

const ButtonGroup = forwardRef<Ref, Props>(
  (
    {
      size = "md",
      color = "primary",
      block,
      variant = "solid",
      children,
      disabled,
      direction = "horizontal",
      className,
      ...props
    },
    ref
  ) => {
    const items = useMemo(
      () => flattenChildren(children).filter(isValidElement),
      [children]
    );

    return (
      <div
        ref={ref}
        className={cn(className, styles["button-group"], {
          [styles["-block"]]: block,
          [styles["-disabled"]]: disabled,
          [styles[`-${direction}`]]: direction,
        })}
        {...props}
      >
        <ButtonGroupContext.Provider
          value={{
            size,
            color,
            block,
            variant,
            disabled,
            className: styles["button"],
          }}
        >
          {items.map((child) =>
            typeof child === "object" ? (
              <div key={child.key} className={styles["wrapper"]}>
                {child}
              </div>
            ) : null
          )}
        </ButtonGroupContext.Provider>
      </div>
    );
  }
);

ButtonGroup.displayName = "ButtonGroup";

const MemoizedButtonGroup = memo(ButtonGroup);

export { MemoizedButtonGroup as ButtonGroup };
