import React, { type ComponentProps, memo } from "react";
import forwardRefAs from "forward-ref-as";

import { markdownToHtml } from "../../utils/markdown-to-html";
import { Prose } from "../prose";

const DEFAULT_COMPONENT = "span";

type Props = Omit<ComponentProps<typeof DEFAULT_COMPONENT>, "children"> & {
  children?: string;
};

const Markdown = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  ({ as: Component = DEFAULT_COMPONENT, children = "", ...props }, ref) => (
    <Prose
      as={Component}
      ref={ref}
      dangerouslySetInnerHTML={{ __html: markdownToHtml(children) }}
      {...props}
    />
  )
);

Markdown.displayName = "Markdown";

const MemoizedMarkdown = memo(Markdown);

export { MemoizedMarkdown as Markdown };
