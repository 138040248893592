import React from "react";
import { Avatar, Flex, Text } from "@adaptive/design-system";
import {
  formatDate,
  parseDate,
  parseStringCopy,
} from "@adaptive/design-system/utils";

import { STRINGS } from "../constants/constants";

type BillLienWaiverFieldSignedProps = {
  markedAsSigned?: boolean;
  vendor?: string;
  user?: string;
  time?: string | null;
};

export const BillLienWaiverFieldSigned = ({
  markedAsSigned,
  vendor,
  user,
  time,
}: BillLienWaiverFieldSignedProps) => {
  const revisionEventDate = time ? parseDate(time, "iso") : null;

  return (
    <Flex width="full" gap="md" align="flex-start">
      <Avatar name="signature" color="success" size="lg" />
      <Flex align="flex-start" direction="column" gap="xs">
        <Text weight="bold">
          {parseStringCopy(
            markedAsSigned
              ? STRINGS.LIEN_WAIVER_SIGNED_UPLOAD_TITLE
              : STRINGS.LIEN_WAIVER_SIGNED_TITLE,
            { vendor, user }
          )}
        </Text>

        <Text color="neutral-700" size="sm">
          {parseStringCopy(STRINGS.LIEN_WAIVER_ACTIVITY_TIME, {
            date: formatDate(revisionEventDate, "MM/dd/yy"),
            time: formatDate(revisionEventDate, "hh:mma"),
          })}
        </Text>
      </Flex>
    </Flex>
  );
};
