import { type z } from "zod";

import type {
  bankAccountOptionSchema,
  billPaymentFormPayloadSchema,
  billPaymentV2PayloadSchema,
  cancelBillPaymentRequestSchema,
  checkBillDuplicationRequestSchema,
  customerBankAccountSchema,
  getBillPaymentRequestSchema,
  getLinkedInvoicesRequestSchema,
  getPaymentOptionsRequestSchema,
  lienWaiverPayloadSchema,
  voidBillPaymentRequestSchema,
} from "./request";
import type {
  accountTypeEnumSchema,
  billPaymentSchema,
  billPaymentV2Schema,
  cancelBillPaymentResponseSchema,
  checkBillDuplicationResponseSchema,
  createBillPaymentV2ResponseSchema,
  linkedInvoicesResponseSchema,
  optionSchema,
  paymentMethodEnumSchema,
  paymentOptionResponseSchema,
  paymentScheduleSchema,
} from "./response";
import { PAYMENT_METHOD as PaymentMethods } from "./response";

// Requests
export type CheckBillDuplicationPayload = z.input<
  typeof checkBillDuplicationRequestSchema
>;

export type VoidBillPaymentPayload = z.input<
  typeof voidBillPaymentRequestSchema
>;

export type CancelBillPaymentPayload = z.input<
  typeof cancelBillPaymentRequestSchema
>;

export type GetPaymentOptionsPayload = z.infer<
  typeof getPaymentOptionsRequestSchema
>;

export type LinkedInvoicesPayload = z.input<
  typeof getLinkedInvoicesRequestSchema
>;

export type GetBillPaymentDetailPayload = z.input<
  typeof getBillPaymentRequestSchema
>;

// Responses
export type CheckBillDuplicationResponse = z.infer<
  typeof checkBillDuplicationResponseSchema
>;

export type LinkedInvoicesResponse = z.infer<
  typeof linkedInvoicesResponseSchema
>;

export type PaymentOptionsResponse = z.infer<
  typeof paymentOptionResponseSchema
>;

export type BillPaymentDetail = z.infer<typeof billPaymentSchema>;

export type PaymentOption = z.infer<typeof optionSchema>;

export type AccountType = z.infer<typeof accountTypeEnumSchema>;

export type PaymentMethod = z.infer<typeof paymentMethodEnumSchema>;

export type PaymentSchedule = z.infer<typeof paymentScheduleSchema>;

export type LienWaiverPayload = z.input<typeof lienWaiverPayloadSchema>;

export type BillPaymentV2Payload = z.input<typeof billPaymentV2PayloadSchema>;

export type BillPaymentV2 = z.input<typeof billPaymentV2Schema>;

export type CustomerBankAccount = z.input<typeof customerBankAccountSchema>;

export type CancelBillPaymentResponse = z.infer<
  typeof cancelBillPaymentResponseSchema
>;

export type PaymentComment = Exclude<
  BillPaymentDetail["timelineEvents"][number],
  "id"
> & {
  id: string;
};

export const PAYMENT_METHOD = PaymentMethods;

export type BillPaymentFormPayload = z.infer<
  typeof billPaymentFormPayloadSchema
>;

export type BankAccountOption = z.infer<typeof bankAccountOptionSchema>;

export type DataBankAccount = {
  accounts: BankAccountOption[];
  canMarkAsPaid: boolean;
};

export type CreateBillPaymentV2Response = z.infer<
  typeof createBillPaymentV2ResponseSchema
>;
