import React, { useState } from "react";
import {
  Button,
  Card,
  ComboBox,
  DateField,
  Flex,
  Icon,
  Loader,
  Text,
} from "@adaptive/design-system";
import { useEvent, useResponsiveProp } from "@adaptive/design-system/hooks";
import { DOCUMENT_TYPE_OPTIONS } from "@vendors/constants";
import type { DocumentType } from "@vendors/types";

import { DropZone } from "../draggable/draggable";

export type VendorDocument = {
  file: File;
  type: DocumentType;
  expirationDate: Date | null;
};

export type VendorDocumentFormProps = {
  onSave: (values: VendorDocument) => Promise<void>;
  disabled?: boolean;
  onChange?: (values: {
    type: DocumentType | "";
    file?: File;
    expirationDate: Date | null;
  }) => void;
  initialValues?: {
    type?: VendorDocument["type"];
    expirationDate?: VendorDocument["expirationDate"];
  };
};

export const VendorDocumentForm = ({
  onSave,
  disabled,
  onChange,
  initialValues,
}: VendorDocumentFormProps) => {
  const isMobile = useResponsiveProp({ mobile: true, tablet: false }, false);

  const [file, setFile] = useState<VendorDocument["file"]>();

  const [isSaving, setIsSaving] = useState(false);

  const [type, setType] = useState(initialValues?.type ?? "");

  const [expirationDate, setExpirationDate] = useState(
    initialValues?.expirationDate ?? null
  );

  const enhancedSetFile = useEvent((file?: File) => {
    setFile(file);
    onChange?.({ type, expirationDate, file } as VendorDocument);
  });

  const enhancedSetType = useEvent((type: string) => {
    setType(type);
    onChange?.({ type, expirationDate, file } as VendorDocument);
  });

  const enhancedSetExpirationDate = useEvent((expirationDate?: Date | null) => {
    setExpirationDate(expirationDate ?? null);
    onChange?.({ type, expirationDate, file } as VendorDocument);
  });

  const onDrop = useEvent((files: File[]) => enhancedSetFile(files[0]));

  const enhancedOnSave = useEvent(async () => {
    setIsSaving(true);
    await onSave({
      type: type as VendorDocument["type"],
      file: file!,
      expirationDate,
    });
    setType(initialValues?.type ?? "");
    setFile(undefined);
    setExpirationDate(initialValues?.expirationDate ?? null);
    setIsSaving(false);
  });

  return (
    <Card size={{ mobile: "sm", tablet: "md" }}>
      <Flex direction="column">
        <Flex direction="column" gap="xl">
          <DropZone
            showBorder
            onDrop={onDrop}
            hasPermission={!disabled}
            justify="space-between"
            gap="2xl"
            flip={{ mobile: "vertical", tablet: "horizontal" }}
            padding={{ mobile: ["2xl", "lg"], tablet: "2xl" }}
            idleMessage={`Upload${isMobile ? "" : " or drag and drop"} a file`}
            pendingMessage="We are processing your file"
            draggingMessage="Drop a file here to upload it!"
          >
            {file ? (
              <>
                <Flex align="center" justify="flex-start">
                  <Icon name="image" size="2xl" />
                </Flex>
                <Flex grow direction="column" gap="sm">
                  <Text weight="bold">Filename</Text>
                  <Text truncate>{file.name}</Text>
                </Flex>
              </>
            ) : null}
          </DropZone>
          <Flex
            justify="space-between"
            direction={{ mobile: "column", tablet: "row" }}
            gap={{ mobile: "none", tablet: "xl" }}
          >
            <ComboBox
              required
              label="Document type"
              size={{ mobile: "md", tablet: "sm" }}
              disabled={disabled}
              value={type || ""}
              data={DOCUMENT_TYPE_OPTIONS}
              onChange={enhancedSetType}
            />
            <DateField
              size={{ mobile: "md", tablet: "sm" }}
              label="Expiration Date"
              value={expirationDate}
              disabled={disabled}
              onChange={enhancedSetExpirationDate}
            />
          </Flex>
        </Flex>

        <Button
          block
          size={{ mobile: "md", tablet: "sm" }}
          disabled={!type || !file || disabled || isSaving}
          onClick={enhancedOnSave}
        >
          {isSaving ? <Loader /> : "Add"}
        </Button>
      </Flex>
    </Card>
  );
};
