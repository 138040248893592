import React, { useMemo } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Flex,
  Text,
} from "@adaptive/design-system";
import { formatDate } from "@adaptive/design-system/utils";
import { useVendorAction } from "@vendors/hooks";
import type { DocumentType, VendorDocRequestType } from "@vendors/types";

import { formatDocumentTypes } from ".";

type AlertProps = {
  vendor?: {
    id: string | number;
    pendingDocRequests: VendorDocRequestType[];
  };
};

export const RequestedVendorDocumentAlert = ({ vendor }: AlertProps) => {
  const { showVendorById } = useVendorAction();

  const { id: vendorId, pendingDocRequests } = vendor ?? {};

  const allUniqueRequestedDocs = useMemo(() => {
    const requestedDocs = (pendingDocRequests || [])
      .map((request) =>
        request.documentTypes.map((doc, index) => ({
          name: doc as DocumentType,
          index: index,
          createdAt: request.createdAt,
        }))
      )
      .flat();

    return Object.values(
      requestedDocs.reduce(
        (acc, doc) => {
          if (doc.createdAt && doc.name && doc.name in acc) {
            const docDate = doc.createdAt;
            const currentDoc = acc[doc.name];
            const currentDocDate = currentDoc.createdAt;

            if (currentDocDate && docDate && currentDocDate > docDate) {
              return { ...acc };
            }
          }
          return { ...acc, [doc.name as DocumentType]: doc };
        },
        {} as Record<
          string,
          { name: DocumentType; index: number; createdAt: Date | null }
        >
      )
    );
  }, [pendingDocRequests]);

  if (allUniqueRequestedDocs.length === 0) return null;

  return (
    <Alert variant="info">
      <AlertTitle>
        You sent the vendor a request to provide these documents
      </AlertTitle>
      <AlertContent as="div">
        <Flex gap="xl" direction="column">
          <ul>
            {allUniqueRequestedDocs.map((doc, index) => {
              const docName = doc.name && formatDocumentTypes([doc.name]);
              const sentOn =
                doc.createdAt && `(Sent on ${formatDate(doc.createdAt)})`;
              return (
                <li key={index}>
                  <Text as="span" size="sm">
                    {docName} {sentOn}
                  </Text>
                </li>
              );
            })}
          </ul>

          <Flex>
            <Button
              size="sm"
              onClick={() => showVendorById(vendorId as string, "docs")}
            >
              Open vendor profile
            </Button>
          </Flex>
        </Flex>
      </AlertContent>
    </Alert>
  );
};
