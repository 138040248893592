import React, { type PropsWithChildren } from "react";
import {
  Button,
  type ComboBoxProps,
  Flex,
  Icon,
  TagGroup,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import type { ActionType, SearchedJob } from "@api/jobs";
import { CustomersComboBox } from "@components/customers-combobox";
import { useAppSelector } from "@store/hooks";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

type ComboBoxOnChangeHandler = Exclude<
  ComboBoxProps<false>["onChange"],
  undefined
>;

type Props = PropsWithChildren<{
  active: boolean;
  onChange: (jobs: SearchedJob[]) => void;
}>;

export const CustomersTableFilter = ({ active, children, onChange }: Props) => {
  const action: ActionType = active ? "active" : "inactive";

  const searchedJobs = useAppSelector(
    (state) => state.jobs[`${action}SearchedJobs`],
    isEqual
  );

  const removeJob = useEvent((job: SearchedJob) => {
    onChange(searchedJobs.filter((j) => j.id !== job.id));
  });

  const addJob = useEvent<ComboBoxOnChangeHandler>((_, option) => {
    if (!option) return;

    const jobId = parseRefinementIdFromUrl(option.value);
    const jobName = option.label;

    if (!jobName || !jobId) return;

    onChange([...searchedJobs, { display_name: jobName, id: jobId }]);
  });

  const onClearFiltersClick = useEvent(() => {
    onChange([]);
  });

  return (
    <Flex gap="md" direction="column">
      <Flex gap="xl" align="center">
        <Flex maxWidth="375px" grow={true}>
          <CustomersComboBox
            value=""
            label=""
            suffix={false}
            active={active}
            onChange={addJob}
            addonAfter={
              <Icon name="search" aria-labelledby="search-by-job-label" />
            }
            withAction={false}
            messageVariant="hidden"
            placeholder="Filter jobs"
          />
        </Flex>
        {searchedJobs.length > 0 && (
          <Button onClick={onClearFiltersClick}>Clear filters</Button>
        )}

        {children && (
          <Flex grow justify="flex-end">
            {children}
          </Flex>
        )}
      </Flex>
      {searchedJobs.length !== 0 && (
        <TagGroup
          data={searchedJobs}
          render="display_name"
          onRemove={removeJob}
        />
      )}
    </Flex>
  );
};
