import React, { type ReactNode } from "react";
import { Text } from "@adaptive/design-system";
import { capitalize } from "@utils/capitalize";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";

import type { Option } from "../../types";

export type RenderCostCodeAccountLabelHandler = (props: {
  data?: Option[];
  group: string;
  option?: Option | Option[];
  transform?: "lowercase" | "capitalize";
}) => ReactNode;

export type RenderCostCodeAccountPlaceholderHandler = (props: {
  data?: Option[];
  transform?: "lowercase" | "capitalize";
}) => string;

const textTransform = (str: string, transform: "capitalize" | "lowercase") => {
  if (transform === "lowercase") return str.toLowerCase();

  if (transform === "capitalize") return capitalize(str);

  return str;
};

export const renderCostCodeAccountLabel: RenderCostCodeAccountLabelHandler = ({
  data = [],
  option,
  transform = "capitalize",
}) => {
  const accountGroup = "Account";
  const costCodeGroup = "Cost code";

  const selectedOptions = Array.isArray(option) ? option : [option];

  const safeData = [
    ...(Array.isArray(option) ? option : [option]),
    ...(data ?? []),
  ];

  const hasCostCode = safeData.some(isCostCode);
  const hasAccount = safeData.some(isAccount);

  if (!hasCostCode && hasAccount) {
    return textTransform(accountGroup, transform);
  }

  if (!hasAccount && hasCostCode) {
    return textTransform(costCodeGroup, transform);
  }

  const isOptionAccount = selectedOptions.some(isAccount);
  const isOptionCostCode = selectedOptions.some(isCostCode);

  return (
    <Text as="span">
      <Text
        as={isOptionCostCode ? "strong" : "span"}
        weight={isOptionCostCode ? "bolder" : "regular"}
      >
        {textTransform(costCodeGroup, transform)}
      </Text>{" "}
      /{" "}
      <Text
        as={isOptionAccount ? "strong" : "span"}
        weight={isOptionAccount ? "bolder" : "regular"}
      >
        {textTransform(accountGroup, transform)}
      </Text>
    </Text>
  );
};

export const renderCostCodeAccountPlaceholder: RenderCostCodeAccountPlaceholderHandler =
  ({ data, transform = "capitalize" }) => {
    const accountString = "Account";
    const costCodeString = "Cost code";

    const safeData = data ?? [];

    const hasCostCode = safeData.some(isCostCode);
    const hasAccount = safeData.some(isAccount);

    if (!hasCostCode && hasAccount) {
      return textTransform(accountString, transform);
    }

    if (!hasAccount && hasCostCode) {
      return textTransform(costCodeString, transform);
    }

    return textTransform(`${costCodeString} / ${accountString}`, transform);
  };
