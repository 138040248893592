import {
  type BaseQueryFn,
  createApi,
  type FetchArgs,
  fetchBaseQuery,
  type FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { Option } from "@shared/types";
import { selectRealmId } from "@store/user/selectors-memoized";
import cookie from "cookie";

import { reAuth } from "../utils/custom-events";
import { formatCard } from "../utils/format-card";

type Data = Record<string, string>;

type FormattedData = Record<string, string> & {
  label: string;
  value: string;
  parent?: string;
  disabled?: string;
  groupLabel?: string;
  isCategory?: string;
};

type KeysParam =
  | FormattedData
  | ((item: Data, options?: QueryParamOptions) => FormattedData);

const DEFAULT_KEYS: FormattedData = {
  label: "display_name",
  value: "url",
  parent: "parent",
  disabled: "disabled",
};

export const transformSimplifiedResponseFor = (
  data: Data[],
  keys: KeysParam = DEFAULT_KEYS,
  options?: QueryParamOptions
) => {
  return data.map((item) => {
    if (typeof keys === "function") return keys(item, options);

    return {
      ...Object.assign(
        {},
        ...Object.entries(keys).map((key) => ({ [key[0]]: item[key[1]] }))
      ),
    };
  });
};

export type QueryParamOptions = {
  realm: string | number;
} & Record<string, number | string | boolean>;

const getSimplifiedQuery = (
  endpoint: string,
  keys: KeysParam = DEFAULT_KEYS
) => ({
  query: (options: QueryParamOptions) => {
    const { realm, ...params } = options;

    const responseHandler = (resp: { json: () => Promise<any> }) =>
      resp
        .json()
        .then((data) => transformSimplifiedResponseFor(data, keys, options));

    return {
      url: `${endpoint}/`,
      params: {
        simplified: true,
        realm: realm.toString(),
        ...params,
      },
      responseHandler,
    };
  },
});

const baseQuery = fetchBaseQuery({
  baseUrl:
    import.meta.env.MODE === "test" ? "http://localhost:8000/api/" : "/api/",

  prepareHeaders: (headers, { getState }) => {
    const { csrftoken } = cookie.parse(document.cookie);
    headers.set("X-CSRFTOKEN", csrftoken);

    // Since we're including the realm in the headers for each request
    // it would be great if the backend could use it from there
    // instead of the FE having to pass it and use it everywhere
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const selectedRealmId = selectRealmId(getState());
    headers.set("Realm", selectedRealmId?.toString() || "");

    return headers;
  },
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (
    result?.error?.status === 401 ||
    (result?.error?.status === 403 &&
      (result?.error?.data as { detail?: string })?.detail ===
        "Authentication credentials were not provided.")
  ) {
    reAuth();
  }

  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    "Change",
    "Changes",
    "ChangesLines",
    "Vendors",
    "CustomerItems",
    "BudgetLines",
    "Customer",
    "Categories",
    "DrawnToDate",
    "UsersSimplified",
    "CustomerMarkup",
    "BudgetLineItems",
    "VendorsSimplified",
    "AccountsSimplified",
    "CostCodesSimplified",
    "CommonVendorsSimplified",
    "CustomersSimplified",
    "BaseCardsSimplified",
    "OtherNamesSimplified",
    "GetLinkedInvoices",
    "CostCodesAccountsSimplified",
    "GetBillPaymentOptions",
  ],
  endpoints: (builder) => ({
    getVendorsSimplified: builder.query<Option[], any>({
      ...getSimplifiedQuery("vendors", { ...DEFAULT_KEYS, email: "email" }),
      providesTags: ["VendorsSimplified"],
    }),
    getOtherNameSimplified: builder.query<Option[], any>({
      ...getSimplifiedQuery("othernames"),
      providesTags: ["OtherNamesSimplified"],
    }),
    getCommonVendorsSimplified: builder.query<Option[], any>({
      ...getSimplifiedQuery("commonvendors"),
      providesTags: ["CommonVendorsSimplified"],
    }),
    getPeopleSimplified: builder.query<Option[], any>({
      query: (clientId) => ({
        url: `clients/${clientId}/users/`,
        params: { simplified: true },
        responseHandler: (resp: { json: () => Promise<any> }) =>
          resp.json().then((data) =>
            transformSimplifiedResponseFor(data, {
              label: "full_name",
              value: "id",
              parent: "parent",
            })
          ),
      }),
    }),
    getRolesSimplified: builder.query<Option[], any>({
      query: (clientId) => ({
        url: `clients/${clientId}/roles/`,
        params: { simplified: true },
        responseHandler: (resp: { json: () => Promise<any> }) =>
          resp.json().then((data) =>
            transformSimplifiedResponseFor(data.results, {
              label: "name",
              value: "id",
              parent: "parent",
            })
          ),
      }),
    }),
    getUsersSimplified: builder.query<Option[], any>({
      ...getSimplifiedQuery("users", { label: "full_name", value: "url" }),
      providesTags: () => ["UsersSimplified"],
    }),
    getCustomersSimplified: builder.query<Option[], any>({
      ...getSimplifiedQuery("customers", {
        ...DEFAULT_KEYS,
        budgetCodesEnabled: "budget_codes_enabled",
      }),
      providesTags: () => ["CustomersSimplified"],
    }),
    getAccountsSimplified: builder.query<Option[], any>({
      ...getSimplifiedQuery("accounts", (account, option) => ({
        label: account.display_name,
        value: account.url,
        parent: account.parent,
        id: account.id,
        isVendorCode: account.is_vendor_code,
        isBudgetCode: account.is_budget_code,
        parentName: account.parent_name,
        vendorId: option?.vendor_id?.toString() || "",
        customerId: option?.customer_id?.toString() || "",
      })),
      providesTags: () => [
        "AccountsSimplified",
        { type: "CostCodesAccountsSimplified", id: "accounts" },
      ],
    }),
    getBaseCardsSimplified: builder.query<Option[], any>({
      ...getSimplifiedQuery("basecards", (item) => ({
        label: formatCard(item),
        value: item.id,
      })),
      providesTags: () => ["BaseCardsSimplified"],
    }),
    getCostCodesSimplified: builder.query<Option[], any>({
      ...getSimplifiedQuery("items", (item, option) => ({
        isCategory: item.is_category,
        label: item.display_name,
        value: item.url,
        parent: item.parent,
        disabled: item.disabled,
        id: item.id,
        isVendorCode: item.is_vendor_code,
        isBudgetCode: item.is_budget_code,
        parentName: item.parent_name,
        vendorId: option?.vendor_id?.toString() || "",
        customerId: option?.customer_id?.toString() || "",
      })),
      providesTags: () => [
        "CostCodesSimplified",
        { type: "CostCodesAccountsSimplified", id: "items" },
      ],
    }),
    getCardTransactionsSimplified: builder.query<Option[], any>(
      getSimplifiedQuery("cardtransactions", {
        label: "display_name",
        value: "url",
        parent: "parent",
        paymentAccount: "payment_account",
      })
    ),
    getQBClassSimplified: builder.query<Option[], any>(
      getSimplifiedQuery("qbclasses", {
        label: "display_name",
        value: "url",
        id: "id",
      })
    ),
    getLocationSimplified: builder.query<Option[], any>(
      getSimplifiedQuery("locations", {
        label: "display_name",
        value: "url",
        id: "id",
        parent: "parent",
      })
    ),
  }),
});

export const {
  useGetRolesSimplifiedQuery,
  useGetPeopleSimplifiedQuery,
  useGetVendorsSimplifiedQuery,
  useGetCommonVendorsSimplifiedQuery,
  useGetCustomersSimplifiedQuery,
  useLazyGetCustomersSimplifiedQuery,
  useGetAccountsSimplifiedQuery,
  useGetCostCodesSimplifiedQuery,
  useGetBaseCardsSimplifiedQuery,
  useGetUsersSimplifiedQuery,
  useGetCardTransactionsSimplifiedQuery,
  useGetQBClassSimplifiedQuery,
  useGetOtherNameSimplifiedQuery,
  useGetLocationSimplifiedQuery,
} = api;
