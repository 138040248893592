import { type ReactNode } from "react";
import {
  parseNumberWithOrdinal,
  parseStringCopy,
} from "@adaptive/design-system/utils";

import type { GetLinkedTransactionsReturn } from "../api/types";
import { STRINGS } from "../constants/constants";
import type {
  LinkObject,
  ParentInfoObject,
  PropagationDetails,
} from "../types";

import { getDetailsFromUrl } from "./get-details-from-url";
import { getDisplayInfo } from "./get-display-info";

type GetDialogHeader = (params: {
  linkedItem: LinkObject;
  parent: ParentInfoObject | LinkObject;
  fieldsToShow: string[];
  propagationFields?: PropagationDetails;
  linkedTransactions?: GetLinkedTransactionsReturn;
}) => ReactNode;

const qtyUniqueLinks = (
  links: GetLinkedTransactionsReturn[number]["links"]
) => {
  return links.reduce((acc, link) => {
    const nameWithoutLine = getDisplayInfo(link).split("-")[0];
    if (!acc.some((item) => item === nameWithoutLine)) {
      acc.push(nameWithoutLine);
    }

    return acc;
  }, [] as string[]).length;
};

export const getDialogHeader: GetDialogHeader = ({
  linkedItem,
  parent,
  fieldsToShow,
  propagationFields,
  linkedTransactions,
}) => {
  const originType = getDisplayInfo(parent, {
    capitalize: false,
    hideName: true,
  });
  const type = getDisplayInfo(linkedItem, {
    capitalize: false,
    hideName: true,
  });
  const propagationFieldInfo = getDetailsFromUrl(propagationFields?.url || "");
  const baseCopyParams = {
    type,
    typePlural: `${type}s`,
    originType,
  };

  if (fieldsToShow.includes("deleted")) {
    const deletedLines = propagationFields?.lines?.filter(
      (line) => line.fields?.deleted
    );
    const deletedLinesIds = deletedLines?.map((line) => line.id);
    const qtyDeleted = deletedLines?.length || 1;

    const deletedTo =
      linkedTransactions
        ?.filter((transaction) =>
          deletedLinesIds?.includes(Number(transaction.id))
        )
        .flatMap((transaction) => transaction.links) || [];
    const qtyDeletedTo = qtyUniqueLinks(deletedTo) || 1;

    const string =
      qtyDeleted > 1
        ? qtyDeletedTo > 1
          ? STRINGS.DIALOG_HEADER_DELETED_TO_N
          : STRINGS.DIALOG_HEADER_DELETED_N
        : STRINGS.DIALOG_HEADER_DELETED;

    const index =
      qtyDeletedTo > 1 && linkedItem
        ? deletedTo
            ?.map((item) => `${item.type}-${item.id}`)
            .indexOf(`${linkedItem.type}-${linkedItem.id}`) + 1
        : 1;

    const copyParams = {
      ...baseCopyParams,
      index: parseNumberWithOrdinal(index),
      qty: qtyDeletedTo,
      originQty: qtyDeleted,
    };

    return parseStringCopy(string, copyParams);
  }

  if (fieldsToShow.includes("reverted")) {
    const revertedTo =
      linkedTransactions
        ?.filter((transaction) => transaction.id === propagationFieldInfo.id)
        .flatMap((transaction) => transaction.links) || [];
    const qty = qtyUniqueLinks(revertedTo) || 1;

    const index =
      qty > 1 && linkedItem
        ? revertedTo
            ?.map((item) => `${item.type}-${item.id}`)
            .indexOf(`${linkedItem.type}-${linkedItem.id}`) + 1
        : 1;

    const string =
      qty > 1
        ? STRINGS.DIALOG_HEADER_REVERTED_TO_N
        : STRINGS.DIALOG_HEADER_REVERTED;
    const copyParams = {
      ...baseCopyParams,
      qty,
      index: parseNumberWithOrdinal(index),
    };
    return parseStringCopy(string, copyParams);
  }

  if (fieldsToShow.includes("archived")) {
    const archivedTo =
      linkedTransactions
        ?.filter((transaction) => transaction.id === propagationFieldInfo.id)
        .flatMap((transaction) => transaction.links) || [];
    const qty = qtyUniqueLinks(archivedTo) || 1;

    const index =
      qty > 1 && linkedItem
        ? archivedTo
            ?.map((item) => `${item.type}-${item.id}`)
            .indexOf(`${linkedItem.type}-${linkedItem.id}`) + 1
        : 1;

    const string =
      qty > 1
        ? STRINGS.DIALOG_HEADER_ARCHIVED_TO_N
        : STRINGS.DIALOG_HEADER_ARCHIVED;
    const copyParams = {
      ...baseCopyParams,
      qty,
      index: parseNumberWithOrdinal(index),
    };
    return parseStringCopy(string, copyParams);
  }

  const changedTo =
    "fields" in parent
      ? linkedTransactions
          ?.find((transaction) => transaction.id === parent.id)
          ?.links.filter((link) => link.type === linkedItem.type) || []
      : [];
  const qtyTo = changedTo.length || 1;

  const isManyTo = qtyTo > 1;
  const string =
    fieldsToShow.length > 1
      ? isManyTo
        ? STRINGS.DIALOG_HEADER_FIELDS_CHANGE_TO_N
        : STRINGS.DIALOG_HEADER_FIELDS_CHANGE
      : isManyTo
        ? STRINGS.DIALOG_HEADER_FIELD_CHANGE_TO_N
        : STRINGS.DIALOG_HEADER_FIELD_CHANGE;
  const index =
    isManyTo && linkedItem
      ? changedTo
          .map((item) => `${item.type}-${item.id}`)
          .indexOf(`${linkedItem.type}-${linkedItem.id}`) + 1
      : 1;

  const copyParams = {
    ...baseCopyParams,
    qty: qtyTo,
    index: parseNumberWithOrdinal(index),
  };

  return parseStringCopy(string, copyParams);
};
