import React, {
  type ComponentRef,
  type FocusEventHandler,
  type ForwardedRef,
  forwardRef,
  type KeyboardEventHandler,
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CompositeRow, useMenuContext, useStoreState } from "@ariakit/react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { is } from "../../utils/is";
import { mergeRefs } from "../../utils/merge-refs";
import { ComboBox, type ComboBoxProps } from "../combobox";

import styles from "./dropdown.module.css";

export type DropdownComboboxItemProps<IsMultiple extends boolean = false> =
  Omit<
    ComboBoxProps<IsMultiple>,
    "inline" | "messageVariant" | "size" | "suffix" | "label"
  > & { gap?: boolean };

type Ref = ComponentRef<typeof ComboBox>;

const getInitialInternalValue = <IsMultiple extends boolean = false>(
  multiple?: IsMultiple
) => (multiple ? [] : "") as DropdownComboboxItemProps<IsMultiple>["value"];

const DropdownComboBoxItem = <IsMultiple extends boolean = false>(
  {
    gap = true,
    value,
    onBlur,
    multiple,
    onChange,
    className,
    hideOnInteractOutside,
    ...props
  }: DropdownComboboxItemProps<IsMultiple>,
  ref: ForwardedRef<Ref>
) => {
  const internalRef = useRef<Ref>(null);
  const timeoutMsRef = useRef(0);

  const [internalValue, setInternalValue] = useState(
    value ?? getInitialInternalValue<IsMultiple>(multiple)
  );

  const menuContext = useMenuContext()!;

  timeoutMsRef.current = useStoreState(menuContext, "timeout"); // eslint-disable-line

  const enhancedValue = useMemo(
    () => value ?? internalValue,
    [value, internalValue]
  );

  const parseHideOnInteractOutside = useCallback(() => {
    if (hideOnInteractOutside === undefined) return true;

    return is.function(hideOnInteractOutside)
      ? hideOnInteractOutside()
      : hideOnInteractOutside;
  }, [hideOnInteractOutside]);

  const enhancedOnChange = useEvent((value, option) => {
    !multiple && value && menuContext.hide();
    onChange?.(value, option);
    setInternalValue(value);
  });

  const enhancedOnBlur = useEvent<FocusEventHandler<HTMLInputElement>>((e) => {
    requestAnimationFrame(() => {
      if (parseHideOnInteractOutside() && !open) menuContext.hide();
    });

    onBlur?.(e);
  });

  const onKeyDown = useEvent<KeyboardEventHandler>((event) => {
    if (event.key === "Escape") menuContext.hide();
  });

  const enhancedClassName = useMemo(
    () => ({
      list: styles["dropdown-combobox-item-list"],
      wrapper: cn(className, styles["dropdown-combobox-item"], {
        [styles["-gap"]]: gap,
      }),
    }),
    [gap, className]
  );

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (!internalRef.current) return;

      internalRef.current.focus();
      internalRef.current.scrollLeft = 0;
    }, timeoutMsRef.current);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <CompositeRow role="row">
      <ComboBox
        ref={mergeRefs(internalRef, ref)} // eslint-disable-line
        size="sm"
        value={enhancedValue}
        inline
        suffix={null}
        onBlur={enhancedOnBlur}
        multiple={multiple}
        onChange={enhancedOnChange}
        onKeyDown={onKeyDown}
        className={enhancedClassName}
        placeholder="Select"
        messageVariant="hidden"
        hideOnInteractOutside={hideOnInteractOutside}
        {...props}
      />
    </CompositeRow>
  );
};

const ForwardedDropdownComboBoxItem = forwardRef(DropdownComboBoxItem) as <
  IsMultiple extends boolean = false,
>(
  props: DropdownComboboxItemProps<IsMultiple> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof DropdownComboBoxItem>;

const MemoizedDropdownComboBoxItem = memo(
  ForwardedDropdownComboBoxItem
) as typeof ForwardedDropdownComboBoxItem;

export { MemoizedDropdownComboBoxItem as DropdownComboBoxItem };
