import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  ComboBox,
  Flex,
  Icon,
  PhoneField,
  Text,
  TextField,
} from "@adaptive/design-system";
import { useDeepMemo, useForm } from "@adaptive/design-system/hooks";
import { useEvent } from "@adaptive/design-system/hooks";
import { getClients } from "@api/clients";
import { saveTeamMembers, skipTeamMembers } from "@api/onboarding";
import { useRoleAction } from "@store/role";
import type { Client } from "@store/user/slice";
import { z } from "zod";

import { Layout } from "../layout";

const schema = z.object({
  teammates: z.array(
    z.object({
      full_name: z.string().min(1, "The name is required"),
      phone_number: z.string().min(1, "A phone number is required"),
      email: z.string().email("An email address is required"),
      role: z.string().min(1, "The role is required"),
    })
  ),
});
type Fields = z.infer<typeof schema>;

const exit = () => {
  localStorage.removeItem("team-members");
  window.location.replace("/");
};

type RoleData = {
  description: string;
  label: string;
  value: string;
};

export const TeamMembers = () => {
  const [loading, setLoading] = useState(false);
  const { loadRoles, roles } = useRoleAction();
  const [client, setClient] = useState<Client | undefined>();
  const localStorageKey = `team-members-${localStorage.getItem(
    "selectedRealmId"
  )}`;

  const initialValues = useDeepMemo(
    () => ({
      teammates:
        !window.IS_E2E && localStorage.getItem(localStorageKey)
          ? JSON.parse(localStorage.getItem(localStorageKey) || "[]")
          : [{ full_name: "", email: "", role: "", phone_number: "" }],
    }),
    [localStorageKey]
  );

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      await saveTeamMembers({
        team_members: values.teammates,
        client_id: client?.id || "",
      });
      exit();
    },
    initialValues,
  });

  const onAddLine = useEvent(() => {
    form.append("teammates", {
      full_name: "",
      email: "",
      role: "",
      phone_number: "",
    });
  });

  const curriedOnRemoveLine = (index: number) => () => {
    form.remove("teammates", index);
  };

  const onSkip = useEvent(async () => {
    await skipTeamMembers();
    exit();
  });

  const loadData = useCallback(async () => {
    setLoading(true);
    const clients = await getClients();

    setLoading(false);
    if (clients.length < 1) {
      exit();
    }
    setClient(clients[0]);

    loadRoles(clients[0].id);
  }, [loadRoles]);

  const roleData: RoleData[] = useMemo(
    () =>
      roles.map((role) => ({
        description: role.description,
        value: role.url || "",
        label: role.name,
      })),
    [roles]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(form.values.teammates)
    );
  }, [form.values.teammates, localStorageKey]);

  return (
    <Layout
      title="Invite your teammates"
      subtitle="Add their emails below or copy the invitation link to get your team onboard!"
      progress={75}
      onSubmit={form.props.onSubmit}
      onSkip={onSkip}
      loading={loading}
      headerWidth="560px"
      formWidth="1024px"
      backUrl="/onboarding/link-accounting-software"
    >
      <Flex gap="4xl" direction="column">
        <Flex direction="column">
          {form.values.teammates.map((_, index: number) => (
            <Flex align="center" key={index} gap="xl">
              <TextField
                label="Name"
                required
                autoFocus={index === 0}
                {...form.register(`teammates.${index}.full_name`)}
              />
              <TextField
                type="email"
                label="Email address"
                required
                {...form.register(`teammates.${index}.email`)}
              />
              <PhoneField
                label="Phone number"
                required
                {...form.register(`teammates.${index}.phone_number`)}
              />
              <ComboBox
                required
                data={roleData}
                label="Role"
                renderOption={(option, { highlightedLabel }) => (
                  <Flex gap="md" direction="column">
                    <Text size="md" weight="bolder">
                      {highlightedLabel}
                    </Text>
                    <Text
                      size="xs"
                      color={option.selected ? undefined : "neutral-700"}
                    >
                      {option.description as string}
                    </Text>
                  </Flex>
                )}
                {...form.register(`teammates.${index}.role`)}
              />
              <Flex margin={["sm", "none", "none"]}>
                <Button
                  variant="ghost"
                  disabled={index === 0 && form.values.teammates.length === 1}
                  color="neutral"
                  onClick={curriedOnRemoveLine(index)}
                >
                  <Icon name="trash" />
                </Button>
              </Flex>
            </Flex>
          ))}
          <Button block onClick={onAddLine} variant="ghost">
            Add teammate
          </Button>
        </Flex>
        <Button
          variant="solid"
          data-testid="save-team-members"
          type="submit"
          disabled={form.isSubmitting || !form.isValid}
          block
        >
          Finish
        </Button>
      </Flex>
    </Layout>
  );
};
