import React from "react";
import { Route, Routes as ReactRouterRoutes } from "react-router";
import { withSentryReactRouterV6Routing } from "@sentry/react";

import {
  VendorACHRequest,
  VendorDocRequest,
} from "./components/vendor-request";

const Routes = withSentryReactRouterV6Routing(ReactRouterRoutes);

export const Public = () => {
  return (
    <Routes>
      <Route
        element={<VendorACHRequest />}
        path="vendor-ach-request/:token/:realmUid/:vendorUid"
      />
      <Route
        element={<VendorDocRequest />}
        path="vendor-doc-request/:token/:realmUid/:vendorUid"
      />
    </Routes>
  );
};
