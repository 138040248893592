import React, {
  Children,
  cloneElement,
  isValidElement,
  memo,
  type ReactElement,
  type ReactNode,
} from "react";
import { Button as AriaKitButton } from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import { is } from "../../utils/is";
import { isIconComponent } from "../../utils/is-icon";
import { useAlertContext } from "../alert/alert-context";
import { Badge } from "../badge";
import { useButtonGroup } from "../button-group/button-group-context";
import { Flex } from "../flex";
import { Wrapper } from "../wrapper";

import styles from "./button.module.css";

type Color = "error" | "primary" | "neutral" | "warning" | "info";

export type Props = {
  size?: ResponsiveProp<"xs" | "sm" | "md" | "lg">;
  color?: Color;
  block?: ResponsiveProp<boolean>;
  variant?: "solid" | "text" | "ghost";
  animated?: boolean;
  focusable?: boolean;
};

const DEFAULT_COLOR_INSIDE_ALERT: Record<string, Color> = {
  "info-100": "info",
  "error-100": "error",
  "warning-100": "warning",
  "success-100": "primary",
  "neutral-300": "neutral",
};

const DEFAULT_COMPONENT = "button";

const isBadge = (children: ReactNode): children is ReactElement =>
  isValidElement(children) && children.type === Badge;

const Button = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = "button",
      size: rawSize,
      color: rawColor,
      block: rawBlock,
      variant = "solid",
      children,
      animated = false,
      disabled,
      className,
      focusable,
      ...props
    },
    ref
  ) => {
    const size = useResponsiveProp(rawSize, "md");
    const block = useResponsiveProp(rawBlock);
    const isOnlyIcon = !Array.isArray(children) && isIconComponent(children);
    const isOnlyBadge = !Array.isArray(children) && isBadge(children);

    /**
     * If the button is children of an alert, we want to
     * adjust some colors to have a better contrast ratio
     */
    const alertContext = useAlertContext();

    const color = rawColor
      ? rawColor
      : alertContext?.background
        ? (DEFAULT_COLOR_INSIDE_ALERT[alertContext?.background] ?? "primary")
        : "primary";

    const buttonGroupProps = useButtonGroup();
    const enhancedSize = buttonGroupProps.size ?? size;
    const enhancedColor = buttonGroupProps.color ?? color;
    const enhancedBlock = buttonGroupProps.block ?? block;
    const enhancedDisabled = buttonGroupProps.disabled ?? disabled;
    const enhancedVariation = buttonGroupProps.variant ?? variant;

    return (
      <AriaKitButton
        ref={ref}
        render={<Component />}
        disabled={enhancedDisabled}
        className={cn(className, styles.button, buttonGroupProps.className, {
          [styles["-icon"]]: isOnlyIcon || isOnlyBadge,
          [styles[`-block`]]: enhancedBlock,
          [styles["-animated"]]: animated,
          [styles[`-alert-child`]]: alertContext !== undefined,
          [styles[`-${enhancedColor}`]]: enhancedColor,
          [styles[`-${enhancedVariation}`]]: enhancedVariation,
          [styles[`-${enhancedSize}`]]: enhancedSize,
        })}
        focusable={focusable}
        aria-disabled={enhancedDisabled ? "true" : undefined}
        {...props}
      >
        {Children.map(children, (child) =>
          isIconComponent(child) ? (
            cloneElement(child, {
              ...(is.object(child.props)
                ? {
                    size: enhancedSize == "lg" ? "md" : enhancedSize,
                    ...child.props,
                  }
                : {}),
            })
          ) : isBadge(child) ? (
            <Flex justify="center" align="center">
              {child}
            </Flex>
          ) : (
            <Wrapper
              when={animated}
              render={(children) => (
                <span className={styles["content"]}>{children}</span>
              )}
            >
              {child}
            </Wrapper>
          )
        )}
      </AriaKitButton>
    );
  }
);

Button.displayName = "Button";

const MemoizedButton = memo(Button) as typeof Button;

export { MemoizedButton as Button };
