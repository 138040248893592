import { is } from "../is";

export type Option = {
  label: string;
  value: string;
  parent?: string;
  disabled?: boolean | string;
};

export const isOption = (option: unknown): option is Option =>
  is.object(option) &&
  "value" in option &&
  option.value !== undefined &&
  "label" in option &&
  !!option.label;
