import { api } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import { getBankAccountsPayloadSchema } from "./requests";
import { getBankAccountsResponseSchema } from "./response";
import type { GetBankAccountsPayload, GetBankAccountsResponse } from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["BankAccounts"],
});

export const bankAccountsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getBankAccounts: builder.query<
      GetBankAccountsResponse,
      GetBankAccountsPayload
    >({
      query: (args) => {
        const { accountBalancesType, ...params } = handleRequest(
          args,
          getBankAccountsPayloadSchema
        );

        return {
          url: "bankaccounts_v2/",
          params: {
            ...transformKeysToSnakeCase(params),
            ...(accountBalancesType
              ? { account_balances__type: accountBalancesType }
              : {}),
          },
          responseHandler: async (response) =>
            handleResponse(
              transformKeysToCamelCase(await response.json()),
              getBankAccountsResponseSchema
            ),
        };
      },
    }),
  }),
});

export const { useGetBankAccountsQuery } = bankAccountsApi;
