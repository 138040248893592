import React, {
  Fragment,
  type PropsWithChildren,
  type ReactElement,
} from "react";
import { Text } from "@adaptive/design-system";
import { dotObject } from "@adaptive/design-system/utils";

import type { GetLinkedTransactionsReturn } from "../api/types";
import { STRINGS } from "../constants/constants";
import type { PropagationFormValues, PropagationObject } from "../types";

import { getDetailsFromUrl } from "./get-details-from-url";
import { getDisplayInfo } from "./get-display-info";

type FormatPropagation = (params: {
  formValues: PropagationFormValues;
  linkedTransactions?: GetLinkedTransactionsReturn;
}) => PropagationObject;

type ElementWithChildren = ReactElement<PropsWithChildren>;

const pushIdFromPath = (params: {
  object: Record<string, unknown>;
  path: string;
  id: number;
}) => {
  const { object, path, id } = params;
  if (!dotObject.get(object, path)) {
    dotObject.set(object, path, [], { mutate: true });
  }

  (dotObject.get(object, path) as number[]).push(id);
};

const formatFormValues = (
  formValues: PropagationFormValues,
  flatLinks: GetLinkedTransactionsReturn[number]["links"]
) => {
  const propagate: PropagationObject["propagate"] = { fields: {}, lines: [] };

  Object.entries(formValues).forEach(([parentKey, transactions]) => {
    const [parentType, parentRawId] = parentKey.split("-");
    const parentId = parseInt(parentRawId);

    Object.entries(transactions).forEach(([transactionKey, fields]) => {
      const [type, rawId] = transactionKey.split("-");
      const id = parseInt(rawId);

      Object.entries(fields).forEach(([fieldKey, value]) => {
        if (value === "new") {
          const typeDir = `${type}_ids`;

          if (["line", "invoice_line"].includes(parentType)) {
            // Lines
            const existingDir = propagate.lines?.find(
              (line) => line.id === parentId
            );

            if (existingDir) {
              if (fieldKey === "accountCostCode") {
                pushIdFromPath({
                  object: existingDir,
                  path: `fields.account.${typeDir}`,
                  id,
                });
                pushIdFromPath({
                  object: existingDir,
                  path: `fields.item.${typeDir}`,
                  id,
                });
              } else {
                const path = `fields.${fieldKey}.${typeDir}`;
                pushIdFromPath({ object: existingDir, path, id });
              }
            } else {
              propagate.lines?.push({ id: parentId });
              const line = propagate.lines?.find(
                (line) => line.id === parentId
              );

              if (fieldKey === "accountCostCode") {
                pushIdFromPath({
                  object: line!,
                  path: `fields.account.${typeDir}`,
                  id,
                });
                pushIdFromPath({
                  object: line!,
                  path: `fields.item.${typeDir}`,
                  id,
                });
              } else {
                const path = `fields.${fieldKey}.${typeDir}`;
                pushIdFromPath({ object: line!, path, id });
              }
            }
          } else {
            // POs and Bills
            if (fieldKey === "accountCostCode") {
              pushIdFromPath({
                object: propagate,
                path: `fields.account.${typeDir}`,
                id,
              });
              pushIdFromPath({
                object: propagate,
                path: `fields.item.${typeDir}`,
                id,
              });
            } else {
              const path = `fields.${fieldKey}.${typeDir}`;
              pushIdFromPath({ object: propagate, path, id });
            }
          }
        }

        if (["delete", "unlink"].includes(value)) {
          const typeDir = `${type}_ids`;
          const path = `${value}.${typeDir}`;

          const existingDir = propagate.lines?.find(
            (line) => line.id === parentId
          );

          if (["bill", "purchase_order"].includes(parentType)) {
            // Lines
            // Delete/unlink lines from POs and Bills (vendor field)
            const linkInfo = flatLinks.find(
              (link) => Number(link.id) == id && link.type === type
            );
            const linkedLineInfo = getDetailsFromUrl(
              linkInfo?.fields.rawLinkedLine?.url
            );
            const linkedLineId = Number(linkedLineInfo.id);

            const existingDir = propagate.lines?.find(
              (line) => line.id === linkedLineId
            );

            if (existingDir) {
              pushIdFromPath({ object: existingDir, path, id });
            } else {
              propagate.lines?.push({ id: linkedLineId });
              const line = propagate.lines?.find(
                (line) => line.id === linkedLineId
              );
              pushIdFromPath({ object: line!, path, id });
            }
          } else if (existingDir) {
            pushIdFromPath({ object: existingDir, path, id });
          } else {
            propagate.lines?.push({ id: parentId });
            const line = propagate.lines?.find((line) => line.id === parentId);
            pushIdFromPath({ object: line!, path, id });
          }
        }
      });
    });
  });

  if (!propagate.fields || Object.keys(propagate.fields).length === 0) {
    delete propagate.fields;
  }

  if (propagate.lines?.length === 0) {
    delete propagate.lines;
  }

  return propagate;
};

const getElementsFromFieldValue = (
  value: Record<string, number[]>,
  flatLinks?: GetLinkedTransactionsReturn[number]["links"]
) => {
  const elements: ElementWithChildren[] = [];

  Object.entries(value).forEach(([key, ids]) => {
    const type = key.replace("_ids", "");
    const keyElements = ids
      .map((id) => {
        const linkedTransaction = flatLinks?.find(
          (transaction) =>
            Number(transaction.id) === id && transaction.type === type
        );

        if (!linkedTransaction) {
          return null;
        }

        return getDisplayInfo(linkedTransaction, { withLink: true });
      })
      .filter(Boolean) as ElementWithChildren[];

    elements.push(...keyElements);
  });

  return elements;
};

const getElementsFromFields = (
  fields: Exclude<PropagationObject["propagate"]["fields"], undefined>,
  flatLinks?: GetLinkedTransactionsReturn[number]["links"]
) => {
  const elements: ElementWithChildren[] = [];

  Object.values(fields).forEach((fieldValues) => {
    elements.push(...getElementsFromFieldValue(fieldValues, flatLinks));
  });

  return elements;
};

export const formatPropagation: FormatPropagation = ({
  formValues,
  linkedTransactions,
}) => {
  const flatLinks =
    linkedTransactions?.flatMap((transaction) => transaction.links) ?? [];

  const propagate = formatFormValues(formValues, flatLinks);

  const elements: ElementWithChildren[] = [];

  if (propagate.fields) {
    elements.push(...getElementsFromFields(propagate.fields, flatLinks));
  }

  if (propagate.lines) {
    propagate.lines.forEach(({ fields, ...rest }) => {
      if (fields) {
        elements.push(...getElementsFromFields(fields, flatLinks));
      }
      if (rest.unlink) {
        elements.push(...getElementsFromFieldValue(rest.unlink, flatLinks));
      }
      if (rest.delete) {
        elements.push(...getElementsFromFieldValue(rest.delete, flatLinks));
      }
    });
  }

  const uniqueLinks = elements.reduce<ElementWithChildren[]>((acc, element) => {
    if (
      !acc.find(
        (accElement) => accElement.props.children === element.props.children
      )
    ) {
      acc.push(element);
    }

    return acc;
  }, []);
  const toastMessage = uniqueLinks.length ? (
    <Text as="span">
      {STRINGS.UPDATE_TOAST_MESSAGE}
      <br />
      {uniqueLinks.map((item, index) => (
        <Fragment key={index}>
          {index > 0 && ", "}
          {item}
        </Fragment>
      ))}
    </Text>
  ) : undefined;

  return {
    propagate,
    toastMessage,
  };
};
