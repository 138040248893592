import { sum } from "@utils/sum";

import {
  useLineItemTotals,
  type UseLineItemTotalsProps,
  type UseLineItemTotalsReturnType,
} from "./use-line-item-totals";
import {
  useMarkupLineTotals,
  type UseMarkupLineTotalsProps,
} from "./use-markup-line-totals";
import { useGetRemaining } from "./use-row-calculations";

export type UseTableTotalsProps = UseLineItemTotalsProps &
  UseMarkupLineTotalsProps;

type UseTableTotalsReturnType = {
  lineItemsTotals: UseLineItemTotalsReturnType;
  markupLineTotals: UseLineItemTotalsReturnType;
  grandLineItemTotals: UseLineItemTotalsReturnType;
};

export const useTableTotals = ({
  budgetLines,
  markupLines,
}: UseTableTotalsProps): UseTableTotalsReturnType => {
  const lineItemsTotals = useLineItemTotals({ budgetLines });

  const markupLineTotals = useMarkupLineTotals({ markupLines });

  const grandLineItemTotals = {
    builderAmount: sum(
      lineItemsTotals.builderAmount,
      markupLineTotals.builderAmount
    ),
    changeAmount: sum(
      lineItemsTotals.changeAmount,
      markupLineTotals.changeAmount
    ),
    builderRevisedAmount: sum(
      lineItemsTotals.builderRevisedAmount,
      markupLineTotals.builderRevisedAmount
    ),
    unpaidBills: sum(lineItemsTotals.unpaidBills, markupLineTotals.unpaidBills),
    undrawnCosts: sum(
      lineItemsTotals.undrawnCosts,
      markupLineTotals.undrawnCosts
    ),
    spent: sum(lineItemsTotals.spent, markupLineTotals.spent),
    ownersAmount: sum(
      lineItemsTotals.ownersAmount,
      markupLineTotals.ownersAmount
    ),
    externalChangeAmount: sum(
      lineItemsTotals.externalChangeAmount,
      markupLineTotals.externalChangeAmount
    ),
    ownersRevisedAmount: sum(
      lineItemsTotals.ownersRevisedAmount,
      markupLineTotals.ownersRevisedAmount
    ),
    invoicedAmount: sum(
      lineItemsTotals.invoicedAmount,
      markupLineTotals.invoicedAmount
    ),
    invoicedRemainingAmount: sum(
      lineItemsTotals.invoicedRemainingAmount,
      markupLineTotals.invoicedRemainingAmount
    ),
  };

  const { builderRemainingAmount, invoicedRemainingAmount } =
    useGetRemaining(grandLineItemTotals);

  return {
    lineItemsTotals,
    markupLineTotals,
    grandLineItemTotals: {
      ...grandLineItemTotals,
      builderRemainingAmount,
      invoicedRemainingAmount,
    },
  };
};
