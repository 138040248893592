import React, { memo, type PropsWithChildren } from "react";
import cn from "clsx";

import styles from "./main.module.css";

type Props = PropsWithChildren<{ className?: string }>;

const MainSubtitle = ({ className, ...props }: Props) => (
  <span className={cn(styles["main-subtitle"], className)} {...props} />
);

const MemoizedMainSubtitle = memo(MainSubtitle);

export { MemoizedMainSubtitle as MainSubtitle };
