import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Link,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { QuickBooksButton } from "@components/quickbooks-button";
import { useAppDispatch } from "@store/hooks";
import { useClientInfo } from "@store/user";
import { toggleChatVisibility } from "@store/user/slice";
import * as analytics from "@utils/analytics";

import { Layout } from "../layout";

const STATUS = {
  LINKED: "1",
  INVALID_REALM: "2",
  QBO_AUTH_ERROR: "3",
  AUTH_ERROR: "4",
};

export const LinkQuickbooks = () => {
  const dialog = useDialog();

  const navigate = useNavigate();

  const { realmId } = useClientInfo();

  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [status] = useState(searchParams.get("status"));

  const loadData = useCallback(async () => {
    if (!realmId) navigate("onboarding/company");
  }, [navigate, realmId]);

  const connectToQuickBooks = useEvent(() => {
    window.location.replace(`/qbo/oauth_login/?realm_id=${realmId}`);
  });

  const goToTeamMembers = useEvent(() => navigate("/onboarding/team-members"));

  const openChat = useEvent(() => {
    dispatch(toggleChatVisibility(true));
    analytics.track("chatOpen", { source: "onboarding-auth-failed" });
  });

  useEffect(() => {
    if (status === STATUS.LINKED) dialog.show();
  }, [dialog, status]);

  useEffect(() => {
    searchParams.delete("status");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return status && status !== STATUS.LINKED ? (
    <Layout
      title="Authentication failed"
      subtitle={
        <>
          We couldn&apos;t connect with QuickBooks. Please try again or reach
          out to support by{" "}
          <Link as="button" type="button" onClick={openChat}>
            clicking here
          </Link>
        </>
      }
      progress={50}
      backUrl="/onboarding/company"
    >
      <Flex direction="column" align="center">
        <QuickBooksButton onClick={connectToQuickBooks} variant="connect" />
      </Flex>
    </Layout>
  ) : (
    <>
      <Layout
        title="Welcome to Adaptive"
        subtitle="Adaptive integrates with your books to save you time and eliminates double entry"
        progress={50}
        backUrl="/onboarding/company"
      >
        <Flex align="center" direction="column">
          <QuickBooksButton onClick={connectToQuickBooks} variant="connect" />
        </Flex>
      </Layout>
      <Dialog
        variant="confirmation"
        show={dialog.isVisible}
        onClose={goToTeamMembers}
      >
        <DialogHeader>Accounting software linked</DialogHeader>
        <DialogContent>
          You successfully linked your accounting software with Adaptive.
        </DialogContent>
        <DialogFooter>
          <Button block onClick={goToTeamMembers}>
            Next
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};
