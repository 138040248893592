import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  memo,
} from "react";
import { Portal, type PortalProps } from "@ariakit/react";
import cn from "clsx";

import { Icon, type IconProps } from "../icon";

import styles from "./loader.module.css";

type DefaultElement = "span";

type Props = Omit<ComponentPropsWithoutRef<"span">, "children"> &
  Pick<IconProps, "size"> & { position?: "fixed" | "absolute" };

const SpanPortal = forwardRef<ComponentRef<DefaultElement>, PortalProps>(
  (props, ref) => <Portal render={<span ref={ref} />} {...props} />
);

SpanPortal.displayName = "Portal";

const MemoizedSpanPortal = memo(SpanPortal);

const Loader = forwardRef<ComponentRef<DefaultElement>, Props>(
  ({ className, position, size = "md", ...props }, ref) => {
    const Component = position === "fixed" ? MemoizedSpanPortal : "span";

    return (
      <Component
        ref={ref}
        className={cn(styles["loader"], className, {
          [styles[`-${position}`]]: position,
        })}
        aria-busy="true"
        aria-live="polite"
        data-loader=""
        {...props}
      >
        <Icon
          name="loader"
          size={size}
          variant="duotone"
          animate="spin-pulse"
        />
      </Component>
    );
  }
);

Loader.displayName = "Loader";

const MemoizedLoader = memo(Loader);

export { MemoizedLoader as Loader };
