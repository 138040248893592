import React, { type ComponentProps, memo } from "react";
import cn from "clsx";

import styles from "./shell.module.css";

type Props = ComponentProps<"header">;

const ShellSidebarHeader = ({ className, ...props }: Props) => (
  <header
    className={cn(className, styles["shell-sidebar-header"])}
    {...props}
  />
);

const MemoizedShellSidebarHeader = memo(ShellSidebarHeader);

export { MemoizedShellSidebarHeader as ShellSidebarHeader };
