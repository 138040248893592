import React, { type ComponentProps, memo } from "react";
import cn from "clsx";

import styles from "./shell.module.css";

type Props = ComponentProps<"div">;

const Shell = ({ className, ...props }: Props) => (
  <div className={cn(className, styles["shell"])} {...props} />
);

const MemoizedShell = memo(Shell);

export { MemoizedShell as Shell };
